<div id="header-row1" class="header-row fx-row fx-row-v-center fx-row-h-space-between">
    <div>
        <span class="d-flex">
            {{ entry?.link?.title }}
            <a class="header-link" href="{{ entry?.link?.href }}">Learn more <mat-icon id="icon" inline="true">chevron_right</mat-icon></a>
        </span>
    </div>
    <div id="link-container">
        <a class="header-link" href="/Partners">Partner with us <mat-icon id="icon" inline="true">chevron_right</mat-icon></a>
        <a class="header-link" href="https://exxactcorporation.zendesk.com/hc/en-us?return_to=%2Fhc%2Frequests"
            >Get Support <mat-icon id="icon" inline="true">chevron_right</mat-icon></a
        >

        <button-component
            data-cy="contact-sales-button"
            [buttonOptions]="{
                text: 'Contact Sales',
                internalLink: '/Contact-Us',
                iconPosition: 'right',
                iconName: 'chevron-right',
                type: 'tiny-primary',
                class: 'ml-2'
            }"
        ></button-component>
    </div>
</div>
