<section id="gated-content-header" class="d-flex flex-row justify-content-center align-items-center">
  <div
    id="gated-content-header-wrapper"
    class="d-flex flex-row justify-content-start w-100 px-3"
    [class.justify-content-between]="showBlogSearchbar"
  >
    <div class="d-flex flex-row justify-content-start" [class.align-items-center]="showBlogSearchbar">
      <mat-icon id="gated-content-header-icon" [svgIcon]="headerData.headerIcon"></mat-icon>
      <h3 class="ml-3 my-0" id="header-title">
        {{ headerData.headerTitle }}
      </h3>
    </div>

    <div *ngIf="showBlogSearchbar">
      <app-cms-blog-search-and-dropdown
        [categories]="blogCategories"
        (categorySelectionHandler)="this.onChosenTitle($event)"
      >
      </app-cms-blog-search-and-dropdown>
    </div>
  </div>
</section>
