import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { SwiperSlide } from 'lib/classes/swiper-slide.class';

const scriptName = 'cms-software-component.class';

@Component({
    selector: 'app-cms-software',
    templateUrl: './cms-software.component.html',
    styleUrls: ['./cms-software.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsSoftwareComponent extends ExxComComponentClass implements OnInit {
    // Dependencies
    cmsService: CmsService;
    metaService: MetaService;
    dialog: MatDialog;

    // Properties: public
    activeCategoryName: string = 'Blog';
    entry: any;
    relatedContentRow: any;
    showBlog: boolean = true;
    showCaseStudies: boolean;
    showEBook: boolean;
    showWhitePapers: boolean;
    slides: SwiperSlide[];

    private xsBreakpointObserver: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
    adblockBackgroundURL: string = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/adblock-desktop.png';

    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        cmsService: CmsService,
        dialog: MatDialog,
        metaService: MetaService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                cmsService,
                dialog,
                environment,
                metaService,
            },
        });
        this.xsBreakpointObserver.subscribe((size) => {
            if (size.matches) {
                this.adblockBackgroundURL = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/adblock-mob.png';
            } else {
                this.adblockBackgroundURL = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/adblock-desktop.png';
            }
        });
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('supported_software_page', {
                references: [
                    'supported_software',
                    'supported_software.category',
                    'category_top_partial_rows.multi_columns.multi_columns',
                    'category_top_partial_rows.image_and_text.image_and_text',
                    'category_top_partial_rows.image_and_text.text_block',
                    'category_top_partial_rows.logos.logos',
                    'category_top_partial_rows.internal_links.internal_link',
                    'category_top_partial_rows.related_content.related_content',
                    'category_top_partial_rows.related_content.related_content.case_studies.gated_content_case_studies',
                    'category_top_partial_rows.related_content.related_content.ebooks.gated_content_ebooks',
                    'category_top_partial_rows.related_content.related_content.whitepapers.gated_content_whitepapers',
                    'category_top_partial_rows.colored_block.colored_block',
                    'category_top_partial_rows.related_content.related_content.blog.blog_category',
                    'category_top_partial_rows.accordion.accordion',
                ],
            });
        } catch (err) {
            console.error(...new ExxComError(484993, scriptName, err).stamp());
        }
    }

    get categories() {
        return ['Blog', 'Case Studies', 'Whitepapers', 'eBooks'];
    }

    reformatDate(entry: any) {
        try {
            if (!entry || !entry.date) {
                return '';
            }
            return new Date(entry.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        } catch (err) {
            console.error(...new ExxComError(666999, scriptName, err).stamp());
        }
    }
}
