import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { CmsBlogPostComponentClass } from 'lib/components/cms/blog/cms-blog-post-component.class';
import { CmsService } from 'lib/services/cms.service';
import { DisqusService } from 'lib/services/disqus.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { isBrowser } from 'lib/tools';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

const scriptName = 'cms-blog-post.component';

@Component({
    selector: 'app-cms-blog-post',
    templateUrl: './cms-blog-post.component.html',
    styleUrls: ['./cms-blog-post.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsBlogPostComponent extends CmsBlogPostComponentClass implements OnInit, AfterViewInit {
    blogCategories: any;
    webpAvailable: boolean = false;
    getWebpImg: (src: string) => string;
    marketoFormId: string = '1125';
    bsModalRef?: BsModalRef;
    activeImage?: string;
    activeCode?: string;
    bsModalService: BsModalService;
    activeTableOfContentIndex: number;
    isContentsOpen: boolean = true;
    showTOC: boolean = false;
    isNewBlog: boolean = false;
    isOldTextEmpty: boolean = false;

    constructor(
        router: Router,
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        bsModalRef: BsModalRef,
        bsModalService: BsModalService,
        cmsService: CmsService,
        public bootstrapMarketoService: BootstrapMarketoService,
        disqusService: DisqusService,
        metaService: MetaService,
        routerService: RouterService,
        webpService: WebpService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                bsModalRef,
                bsModalService,
                cmsService,
                bootstrapMarketoService,
                disqusService,
                environment,
                metaService,
                routerService,
            },
        });
        this.type = 'post';
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            super.ngOnInit();
            this.blogCategories = await this.cmsService.getEntries('exx_blog_category', {});
            if (!isBrowser()) {
                return;
            }
            // used to reset page to the top of the screen
            // const header = document.getElementById('header');
            // header.scrollIntoView();

            const TOCRenderInterval = setInterval(() => {
                if (typeof this.entry?.blog_content_v3?.enable_table_of_contents == 'undefined') return;
                clearInterval(TOCRenderInterval);
                this.showTOC = this.entry?.blog_content_v3?.enable_table_of_contents;
                this.isNewBlog = this.entry?.blog_content_v3?.new_or_old_blog;
                if (this.entry?.article.length < 5) {
                    this.isOldTextEmpty = true;
                }
                console.log(this.isOldTextEmpty);
            }, 10);
        } catch (err) {
            console.error(...new ExxComError(557993, scriptName, err).stamp());
        }
    }

    async ngAfterViewInit() {
        setTimeout(() => {
            const entries = document.querySelectorAll('.mobile-observe');
            if (this.showTOC) {
                entries.forEach((entry) => this.createObserver(entry, this.callback));
            }
        }, 500);
    }

    callback(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const TOCItems = document.querySelectorAll('.blog-table-of-contents-link');
                // removes all active links
                TOCItems.forEach((item, index) => {
                    item.classList.remove('active-contents-section');
                });
                // applies active class to newest section
                document.getElementsByClassName(entry.target.id)[0].classList.toggle('active-contents-section');
            }
        });
    }

    createObserver(target, callback) {
        const options = {
            root: null,
            threshold: 0,
        };
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target);
    }

    onChosenTitle(title: string) {
        try {
            this.routerService.navigate(['blog', title]);
        } catch (err) {
            console.error(...new ExxComError(657993, scriptName, err).stamp());
        }
    }

    scroll(id) {
        // remove all active class elements and add new one to clicked
        const el = document.getElementById(id);
        // offsets 350 is to get near top of the page
        let y = el.getBoundingClientRect().top + window.pageYOffset - 350;
        if (!this.isNarrow) {
            y = el.getBoundingClientRect().top - 100;
        }
        window.scrollTo({ top: y, behavior: 'smooth' });
        const activeTabs = document.querySelectorAll('.blog-table-of-contents-link');
        activeTabs.forEach((tab) => {
            tab.classList.remove('active-contents-section');
        });
    }

    transformTrim(value: string, isMobile: boolean) {
        if (!value) {
            return '';
        }

        if (isMobile) {
            const valueMobile = value.replace(/\s*/g, '');
            return valueMobile + '-mobile';
        } else return value.replace(/\s*/g, '');
    }
}
