<section *ngIf="get(multipleColumnsRow, 'multi_columns.multi_columns')" class="p-0">
    <div
        class="multi-columns"
        *ngFor="let row of get(multipleColumnsRow, 'multi_columns.multi_columns', [])"
        [ngStyle]="{
            'background-color': get(multipleColumnsRow, 'multi_columns.exxcom_color_picker', ''),
            'background-repeat': 'no-repeat',
            'margin-bottom': get(multipleColumnsRow, 'multi_columns.exxcom_color_picker', '').length > 0 ? '24px' : ''
        }"
    >
        <div class="container d-flex flex-column px-2" [class]="get(row, 'columns', '') != 3 ? '' : 'new-container'">
            <div>
                <div *ngIf="multipleColumnsRow?.multi_columns?.title" class="category-top-title d-flex flex-row justify-content-center pb-2">
                    <div class="container d-flex flex-row justify-content-center">
                        <h2 class="text-center title-width">
                            {{ multipleColumnsRow?.multi_columns?.title }}
                        </h2>
                    </div>
                </div>
                <div
                    *ngIf="multipleColumnsRow?.multi_columns?.free_form_content_top"
                    [innerHTML]="multipleColumnsRow?.multi_columns?.free_form_content_top | safe: 'html'"
                ></div>
                <div
                    *ngIf="multipleColumnsRow?.multi_columns?.free_form_content"
                    [innerHTML]="multipleColumnsRow?.multi_columns?.free_form_content | safe: 'html'"
                ></div>
            </div>
            <div *ngIf="get(row, 'columns', '')">
                <div *ngIf="get(row, 'cards', [])" class="grid partial-grid container-padless">
                    <div
                        *ngFor="let column of get(row, 'cards', '')"
                        class="my-2 g-col-12"
                        [class.g-col-md-3]="get(row, 'columns', '') == 4"
                        [class.g-col-md-4]="get(row, 'columns', '') == 3"
                        [class.g-col-md-6]="get(row, 'columns', '') == 2"
                        [class.g-col-md-12]="get(row, 'columns', '') == 1"
                    >
                        <div class="d-flex flex-row align-items-start card-1">
                            <div class="img-container d-flex align-items-center">
                                <img [src]="getWebpImg(get(column, 'card_image.href', ''))" [alt]="get(column, 'card_image.title', '')" />
                            </div>
                            <div class="text-container col-md-12">
                                <h4 *ngIf="get(column, 'card_title', '')">
                                    {{ get(column, 'card_title', '') }}
                                </h4>
                                <div [innerHTML]="get(column, 'card_copy', '') | safe: 'html'"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="get(row, 'misc_', [])" class="grid partial-grid container-padless">
                    <div
                        *ngFor="let column of get(row, 'misc_', '')"
                        class="my-2 g-col-12"
                        [class.g-col-md-3]="get(row, 'columns', '') == 4"
                        [class.g-col-md-4]="get(row, 'columns', '') == 3"
                        [class.g-col-md-6]="get(row, 'columns', '') == 2"
                        [class.g-col-md-12]="get(row, 'columns', '') == 1"
                    >
                        <div class="misc-container d-flex flex-column">
                            <!-- WITH IMAGE ENLARGEMENT -->
                            <ng-container *ngIf="get(column, 'image_enlargement', '')">
                                <!-- NO REDIRECT LINK -->
                                <app-image-zoom
                                    *ngIf="get(column, 'image.href', '') && !get(column, 'image_redirect_link', '')"
                                    [image]="getWebpImg(get(column, 'image.href', ''))"
                                    [altText]="get(column, 'image.title', '')"
                                ></app-image-zoom>
                                <!-- REDIRECT LINK -->
                                <a
                                    *ngIf="get(column, 'image.href', '') && get(column, 'image_redirect_link', '')"
                                    class="misc-image"
                                    [href]="get(column, 'image_redirect_link', '')"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <app-image-zoom
                                        *ngIf="get(column, 'image.href', '') && !get(column, 'image_redirect_link', '')"
                                        [image]="getWebpImg(get(column, 'image.href', ''))"
                                        [altText]="get(column, 'image.title', '')"
                                    ></app-image-zoom>
                                </a>
                            </ng-container>
                            <!-- WITHOUT IMAGE ENLARGEMENT -->
                            <ng-container *ngIf="!get(column, 'image_enlargement', '')">
                                <!-- NO REDIRECT LINK -->
                                <img
                                    *ngIf="get(column, 'image.href', '') && !get(column, 'image_redirect_link', '')"
                                    class="misc-image"
                                    [src]="getWebpImg(get(column, 'image.href', ''))"
                                    [alt]="get(column, 'image.title', '')"
                                />
                                <!-- REDIRECT LINK -->
                                <a
                                    *ngIf="get(column, 'image.href', '') && get(column, 'image_redirect_link', '')"
                                    class="misc-image"
                                    [href]="get(column, 'image_redirect_link', '')"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img [src]="getWebpImg(get(column, 'image.href', ''))" [alt]="get(column, 'image.title', '')" />
                                </a>
                            </ng-container>

                            <h4 *ngIf="get(column, 'card_title', '')" class="text-center">
                                {{ get(column, 'title', '') }}
                            </h4>
                            <div class="misc-inner-html" [innerHTML]="get(column, 'rich_text_editor', '') | safe: 'html'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(multipleColumnsRow, 'multi_columns.split_line', '')" class="m-0 mb-3" />
