import { Component, OnInit } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { CmsService } from 'lib/services/cms.service';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';
import { get } from 'lodash';

const scriptName = 'cms-repository-component';

@Component({
    selector: 'app-cms-repository',
    templateUrl: './cms-repository.component.html',
    styleUrls: ['./cms-repository.component.scss'],
})
export class CmsRepositoryComponent extends ExxComComponentClass implements OnInit {
    cmsService: any;
    entry: any;
    getWebpImg: (src: string) => string;
    // Properties: public

    constructor(
        c: CmsService,
        w: WebpService,
        private m: MetaService
    ) {
        super({
            dependencies: {
                cmsService: c,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getEntries('repository', {
                references: ['repository_reference'],
            });
        } catch (err) {
            console.error(...new ExxComError(680173, scriptName, err).stamp());
        }
    }
    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
