<div id="cms-blog-header-search">
  <div id="cms-blog-header-search-input-container-wide" #searchInputContainer class="fx-row">
    <input
      id="cms-blog-header-search-input-wide"
      data-cy="cms-blog-header-search-input"
      #searchInput
      placeholder="Search Blog"
      (focus)="onFocus()"
      (keyup)="onKeyup($event)"
      (keydown.enter)="onKeydownEnter()"
    />
    <div *ngIf="!displayMagnifier && isNarrow" id="cms-blog-header-search-narrow-close" (click)="navAway()">
      <div></div>
      <div></div>
    </div>
  </div>

  <div id="cms-blog-header-search-menu" *ngIf="isActive" #searchMenu class="mat-elevation-2">
    <div *ngIf="suggestedPosts.length == 0" id="cms-blog-header-search-no-results">
      <h5 class="cms-blog-header-search-menu-title">No results match your search query.</h5>
    </div>

    <div *ngIf="suggestedPosts.length > 0" id="cms-blog-header-search-menu-posts">
      <div *ngFor="let post of suggestedPosts" class="cms-blog-header-search-menu-posts-links">
        <a class="fx-row fx-row-h-left fx-row-v-center fx-wrap" [routerLink]="post.url" (click)="navAway()">
          <p class="col-xs-12 title" [innerHTML]="post.title"></p>
          <p class="col-xs-12 m-0 date">{{ post.date }}</p>
        </a>
      </div>
    </div>

    <div *ngIf="suggestedPosts.length > 0" id="cms-blog-header-search-menu-see-more-results">
      <a
        id="cms-blog-header-search-menu-see-more-results-link"
        [routerLink]="'/blog/result/input/' + searchValue"
        (click)="navAway()"
        >SEE MORE RESULTS</a
      >
    </div>
  </div>

  <lib-overlay [isVisible]="isActive" [zIndex]="1"></lib-overlay>
</div>
