<section
    *ngIf="get(productOptionsRow, 'product_options.product', false)"
    [ngStyle]="{
        'background-color': get(productOptionsRow.product_options, 'exxcom_color_picker', ''),
        'background-repeat': 'no-repeat',
        'margin-bottom': get(productOptionsRow.product_options, 'exxcom_color_picker', '').length > 0 ? '24px' : ''
    }"
>
    <div class="container-gridless d-flex flex-row justify-content-center flex-wrap product-options">
        <div class="product-options-header col-12">
            <span class="tags text-center py-2 mb-1">{{ get(productOptionsRow, 'product_options.small_label', '') }}</span>
            <div class="category-top-title d-flex flex-row justify-content-center pb-2">
                <h2 *ngIf="get(productOptionsRow, 'product_options.title', false)" class="col-md-7 text-center">
                    {{ productOptionsRow.product_options.title }}
                </h2>
            </div>
        </div>

        <a
            *ngFor="let product of get(productOptionsRow, 'product_options.product', '')"
            class="d-flex flex-column flex-wrap align-items-center col-12 col-md-4 product-options-img-sect"
            [routerLink]="get(product, 'product_link.href', '')"
        >
            <img class="product-option-image" [src]="getWebpImg(get(product, 'product_image.href', ''))" [alt]="get(product, 'title', 'product')" />
            <p *ngIf="get(product, 'title', false)" class="product-option-title ml-2">
                {{ product.title }}
            </p>
        </a>
    </div>
    <div *ngIf="!isEmpty(get(productOptionsRow, 'product_options.see_all_options', []))" class="d-flex flex-row justify-content-center pt-2">
        <a class="button-primary" (click)="openProductSpecDialog(null, productOptionsRow?.product_options?.see_all_options)">{{
            get(productOptionsRow, 'product_options.product_options_button_text', 'See all options')
        }}</a>
    </div>
</section>
<hr *ngIf="get(productOptionsRow, 'product_options.split_line', '')" class="m-0 mb-3" />
