<section *ngIf="get(internalLinksRow, 'internal_links', false)" [class.gray-bg]="get(internalLinksRow, 'internal_links.link_type', '') == 'Type 1'">
    <div class="internal-links">
        <div class="category-top-title fx-row fx-row-h-center pb-2">
            <h2 *ngIf="internalLinksRow.internal_links?.title" class="col-md-7 text-center">
                {{ internalLinksRow.internal_links?.title }}
            </h2>
        </div>
        <div
            class="links container-gridless d-flex flex-row justify-content-center flex-wrap"
            *ngIf="get(internalLinksRow, 'internal_links.link_type', '') == 'Type 1'"
        >
            <div
                *ngFor="let link of internalLinksRow.internal_links?.internal_link"
                class="internal-link d-flex col-12 col-md-3 col-sm-6 text-center"
            >
                <a class="internal-link-1" *ngIf="link?.routerLink" [routerLink]="link?.routerLink">
                    <img *ngIf="link?.type_1_image?.href" [src]="getWebpImg(link?.type_1_image?.href)" [alt]="link?.type_1_image?.title" />
                    <h4 *ngIf="link?.title" class="old my-1 px-2">{{ link?.title }}</h4>
                    <a *ngIf="link?.routerLink" class="link-blue" [routerLink]="link?.routerLink"> Explore <mat-icon>chevron_right</mat-icon> </a>
                </a>
            </div>
        </div>
        <div *ngIf="get(internalLinksRow.internal_links, 'link_type', '') == 'Type 2'">
            <div class="links container-gridless d-flex flex-row justify-content-center flex-wrap">
                <div *ngFor="let link of internalLinksRow.internal_links?.internal_link" class="internal-link col-md-4">
                    <img *ngIf="link?.type_2_image?.href" [src]="getWebpImg(link?.type_2_image?.href)" [alt]="link?.type_2_image?.title" />
                    <h4 *ngIf="link?.title" class="old text-center">{{ link?.title }}</h4>
                    <div class="fx-row fx-row-h-center">
                        <button-component
                            *ngIf="link?.routerLink"
                            [buttonOptions]="{
                                text: 'Explore',
                                type: 'secondary',
                                iconPosition: 'right',
                                internalLink: link?.routerLink,
                                iconName: 'chevron-right'
                            }"
                        ></button-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(internalLinksRow, 'internal_links.split_line', '')" class="m-0" />
