<div id="cms-customers" (window:resize)="onResize()">
  <!-- HERO BANNER -->

  <section class="hero-banner">
    <div class="banner-container fx-row-container fx-row-h-left">
      <div class="col-md-5">
        <h5>{{ get(entry, "hero_banner_label", "") }}</h5>
        <h1>{{ get(entry, "hero_banner_headline", "") }}</h1>
        <div class="copy-lg">{{ get(entry, "hero_banner_copy", "") }}</div>
      </div>
    </div>
  </section>

  <!-- TESTIMONIAL ROWS -->
  <section *ngFor="let testimonial of entry?.rows; let i = index">
    <div class="gray-background">
      <div
        [class]="
          i % 2 != 0 ? 'testimonial-row fx-row-container fx-wrap' : 'testimonial-row reverse fx-row-container fx-wrap'
        "
      >
        <div class="col-md-6 col-sm-12">
          <h2>{{ testimonial.headline }}</h2>
        </div>

        <div class="col-md-6 col-sm-12 quote-container">
          <app-quotes
            name="quote-swiper"
            mode="single"
            [slideWidth]="!this.isNarrow ? '470px' : '95%'"
            [data]="[
              {
                person: get(testimonial, 'testimonial_person', ''),
                company: get(testimonial, 'testimonial_company', ''),
                quote_copy: get(testimonial, 'testimonial_copy', ''),
                imageUrl: get(testimonial, 'testimonial_logo.href', null)
              }
            ]"
            selector="testimonial-row-quote-swiper"
            class="quote-swiper-wide"
          >
          </app-quotes>
        </div>
      </div>
    </div>

    <div class="fx-row-container fx-wrap col-md-10">
      <div *ngFor="let logo of get(testimonial, 'logo')" class="logo-container">
        <img [src]="getWebpImg(get(logo, 'href', ''))" alt="Customer Logo" class="logo" />
      </div>
    </div>
  </section>

  <section class="fx-row-container fx-wrap col-md-10 pb-5">
    <div *ngFor="let logo of get(entry, 'logos.logo')" class="logo-container">
      <img [src]="getWebpImg(get(logo, 'href', ''))" alt="Customer Logo" class="logo" />
    </div>
  </section>
</div>
