<div id="cms-blog-post">
    <div id="cms-blog-post-wide" data-cy="cms-blog-post">
        <div class="fx-row fx-row-container">
            <div class="blog-post-buttons col-md-1">
                <a class="share-2 logo-button" (click)="copyLink($event)" target="_blank">
                    <mat-icon svgIcon="share-2"></mat-icon>
                </a>

                <a
                    class="twitter mt-1 logo-button"
                    href="https://twitter.com/intent/tweet?text=https://www.exxactcorp.com{{ entry.url }}"
                    target="_blank"
                >
                    <mat-icon svgIcon="x-logo"></mat-icon>
                </a>

                <a
                    class="facebook mt-1 logo-button"
                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.exxactcorp.com{{ entry.url }}"
                    target="_blank"
                >
                    <mat-icon svgIcon="facebook"></mat-icon>
                </a>

                <a
                    class="linkedin mt-1 logo-button"
                    href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.exxactcorp.com{{ entry.url }}"
                    target="_blank"
                >
                    <mat-icon svgIcon="linkedin"></mat-icon>
                </a>
            </div>

            <div class="col-md-11">
                <div class="post-body-top col-md-8 p-0" data-cy="blog-post-body-top">
                    <div class="category" data-cy="blog-post-category">{{ get(entry, 'blog_category[0].title') }}</div>
                    <h1 class="post-blog-title" data-cy="blog-post-title">{{ entry?.title }}</h1>

                    <div class="date-time fx-row fx-row-v-center">
                        <span data-cy="blog-post-date">{{ date }}</span>
                        <div class="fx-row fx-row-v-center pl-3">
                            <mat-icon class="pr-1" svgIcon="clock"></mat-icon>
                            <span data-cy="blog-post-read-time">{{ entry | readTimeBlog }}</span>
                        </div>
                    </div>
                </div>

                <div class="fx-row fx-row-h-space-between">
                    <div class="post-body-content-left">
                        <div *ngIf="entry.feature_image?.url" class="feature-image mb-4">
                            <img [src]="getWebpImg(entry.feature_image.url)" [alt]="entry.feature_image.title" data-cy="blog-post-img" />
                        </div>

                        <div *ngIf="entry.article" class="article" [innerHTML]="entry.article | safe: 'html'" data-cy="blog-post-article"></div>

                        <div data-cy="blog-post-article" class="article" *ngIf="!entry.article">
                            <div
                                *ngFor="let blogContentSection of get(entry, 'blog_content_v3.section.group')"
                                class="group mb-4"
                                [id]="blogContentSection?.section_title | trim"
                            >
                                <div
                                    class="text-block-code-block mb-3"
                                    *ngIf="blogContentSection?.select == 'text_block' || blogContentSection?.select == 'code_block'"
                                    [innerHTML]="blogContentSection?.rich_text_editor | safe: 'html'"
                                ></div>
                                <div *ngIf="blogContentSection?.select == 'image_block'" class="image-block mb-3">
                                    <div *ngFor="let blogImages of get(blogContentSection, 'images_optional.image', [])">
                                        <h2>{{ blogImages?.image_header }}</h2>
                                        <div class="image-container">
                                            <!-- Image is not a link -->
                                            <img
                                                *ngIf="blogImages?.ad_link == '' && blogImages?.image_enlargement == false"
                                                [src]="
                                                    blogImages?.image_link.length > 0
                                                        ? blogImages?.image_link
                                                        : getWebpImg(blogImages?.image_file?.url)
                                                "
                                                [alt]="blogImages?.image_alt_text"
                                            />
                                            <ng-container *ngIf="blogImages?.ad_link == '' && blogImages?.image_enlargement == true">
                                                <app-image-zoom
                                                    [image]="blogImages?.image_link.length > 0 ? blogImages?.image_link : blogImages?.image_file?.url"
                                                    [altText]="blogImages?.image_alt_text"
                                                ></app-image-zoom>
                                            </ng-container>
                                            <!-- Image is a link -->
                                            <a *ngIf="blogImages?.ad_link" [href]="blogImages?.ad_link" target="_blank">
                                                <img
                                                    [src]="
                                                        blogImages?.image_link.length > 0
                                                            ? blogImages?.image_link
                                                            : getWebpImg(blogImages?.image_file?.url)
                                                    "
                                                    [alt]="blogImages?.image_alt_text"
                                                />
                                            </a>
                                        </div>
                                        <p>{{ blogImages?.image_caption_subheader }}</p>
                                    </div>
                                </div>
                                <div *ngIf="blogContentSection?.select == 'table_block'" class="table-block mb-3">
                                    <div *ngIf="get(blogContentSection, 'tables_optional.table_reference', [])">
                                        <div *ngIf="get(blogContentSection, 'tables_optional.table_reference[0].table_content', [])">
                                            <div
                                                [innerHTML]="
                                                    get(blogContentSection, 'tables_optional.table_reference[0].table_content', []) | safe: 'html'
                                                "
                                            ></div>
                                        </div>

                                        <cms-partial-category-top-component-table
                                            *ngIf="
                                                !isEmpty(
                                                    get(blogContentSection, 'tables_optional.table_reference[0].new_table_format_2.table_group', [])
                                                )
                                            "
                                            [tableRow]="get(blogContentSection, 'tables_optional.table_reference[0]', [])"
                                            [isBlogPage]="true"
                                        >
                                        </cms-partial-category-top-component-table>
                                    </div>
                                </div>

                                <!--  ad -->

                                <div class="color-block-desktop mb-3" *ngIf="blogContentSection?.select == 'ad_block'">
                                    <div
                                        class="color-block-container"
                                        [ngStyle]="{
                                            'background-image':
                                                'url(' +
                                                get(blogContentSection, 'ad_block_reference[0].banner_image_file.url', '') +
                                                ')
        '
                                        }"
                                    >
                                        <div
                                            class="image-container"
                                            *ngIf="get(blogContentSection, 'ad_block_reference[0].product_image_file.url', '')"
                                        >
                                            <img [src]="get(blogContentSection, 'ad_block_reference[0].product_image_file.url', '')" />
                                        </div>
                                        <div class="text-container">
                                            <div>
                                                <h4 class="bold">{{ blogContentSection.ad_block_reference[0].ad_banner_title }}</h4>
                                                <p>{{ blogContentSection.ad_block_reference[0].ad_banner_copy }}</p>

                                                <a [routerLink]="blogContentSection.ad_block_reference[0].ad_url"
                                                    >{{ blogContentSection.ad_block_reference[0].link_anchor_text }}
                                                    <i-feather name="chevron-right"></i-feather
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="entry.table">
                            <div>{{ entry.table.table_title }}</div>
                            <div [innerHTML]="entry.table.rich_text_editor"></div>
                        </div>

                        <div *ngIf="!isNarrow" id="disqus_thread" class="pb-4"></div>
                    </div>

                    <div class="post-body-content-right">
                        <div class="table-of-contents-desktop" *ngIf="showTOC && isNewBlog && isOldTextEmpty">
                            <div class="contents-list-desktop">
                                <h2>Table of Contents</h2>
                                <ol class="toc">
                                    <ng-container *ngFor="let blogTableOfContents of get(entry, 'blog_content_v3.section.group'); let i = index">
                                        <li *ngIf="get(blogTableOfContents, 'section_title', '').length > 0">
                                            <div class="content-link-desktop">
                                                <a
                                                    (click)="
                                                        scroll(transformTrim(blogTableOfContents?.section_title, false));
                                                        activeTableOfContentIndex = i
                                                    "
                                                    class="blog-table-of-contents-link"
                                                    [class.active-section]="i == activeTableOfContentIndex"
                                                >
                                                    <div class="d-flex text-number-container">
                                                        <div>{{ blogTableOfContents?.section_title }}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ol>
                            </div>
                        </div>

                        <div class="sign-up-container my-2">
                            <mat-icon class="icon mail" svgIcon="mail"></mat-icon>
                            <h3 class="py-2 my-0">Sign up for our newsletter.</h3>
                            <button-component
                                (click)="bootstrapMarketoService.openBootstrapMarketoDialog(marketoFormId, 'edit')"
                                [buttonOptions]="{
                                    text: 'Sign up',
                                    type: 'primary',
                                    iconPosition: 'right',
                                    iconName: 'chevron-right',
                                    class: 'w-100'
                                }"
                            ></button-component>
                        </div>

                        <div class="related-posts-three pt-0">
                            <h4>Related Posts</h4>
                            <div *ngIf="allRelatedPosts" class="mt-1">
                                <div *ngFor="let post of allRelatedPosts.slice(0, 3)">
                                    <app-resource-card-related-card
                                        [entry]="post"
                                        [relatedContentTileData]="{
                                            title: post?.title,
                                            categoryTitle: post?.blog_category[0]?.title,
                                            image: post?.feature_image?.url,
                                            imageTitle: post?.feature_image?.title,
                                            date: post?.date,
                                            link: post?.routerLink,
                                            entry: post,
                                            isRelatedContent: true,
                                            hideTimeNarrow: true,
                                            narrowOnlyDesign: true
                                        }"
                                    ></app-resource-card-related-card>
                                </div>
                            </div>
                        </div>

                        <div class="topics">
                            <h4>Topics</h4>
                            <div class="tags fx-row fx-wrap">
                                <button-component
                                    *ngFor="let tag of entry.tags"
                                    data-cy="blog-tag"
                                    [buttonOptions]="{
                                        text: tag,
                                        internalLink: '/blog/result/tag/' + tag,

                                        type: 'tag'
                                    }"
                                ></button-component>
                            </div>
                        </div>

                        <div class="contact-us">
                            <h4>Have any questions?</h4>
                            <a class="link-blue" href="/Contact-Us">Contact us today <mat-icon>chevron_right</mat-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="related-posts-bottom fx-col fx-col-container p-2">
            <div class="related-posts">Related Posts</div>

            <div *ngIf="allRelatedPosts" class="blog-posts fx-wrap fx-row-container">
                <div class="fx-col col-md-4 fx-col-h-center fx-col-v-center" *ngFor="let entry of allRelatedPosts.slice(0, 3)">
                    <app-resource-card-related-card
                        [entry]="entry"
                        [relatedContentTileData]="{
                            title: entry?.title,
                            categoryTitle: entry?.blog_category[0]?.title,
                            image: entry?.feature_image?.url,
                            imageTitle: entry?.feature_image?.title,
                            date: entry?.date,
                            link: entry?.routerLink,
                            entry: entry,
                            isRelatedContent: true,
                            hideTimeNarrow: true
                        }"
                    ></app-resource-card-related-card>
                </div>
            </div>

            <div class="goal fx-row fx-row-h-center">
                <div class="col-md-8 text-center">
                    <h3>Our Goal</h3>
                    <p>
                        We’re developing this blog to help engineers, developers, researchers, and hobbyists on the cutting edge cultivate knowledge,
                        uncover compelling new ideas, and find helpful instruction all in one place.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div id="cms-blog-post-narrow" data-cy="cms-blog-post-mobile">
        <div class="table-of-contents-mobile" *ngIf="showTOC && isNewBlog && isOldTextEmpty">
            <div
                id="table-of-contents-button-mobile"
                class="d-flex justify-content-between"
                [class.mb-3]="isContentsOpen"
                (click)="isContentsOpen = !isContentsOpen"
            >
                <span>Table of Contents</span>
                <i-feather name="chevron-up" [class.transform-open-close]="!isContentsOpen" class="contents-arrow"></i-feather>
            </div>
            <div class="contents-list-mobile" [class.close]="!isContentsOpen">
                <ol class="toc">
                    <ng-container *ngFor="let blogTableOfContents of get(entry, 'blog_content_v3.section.group'); let i = index">
                        <li *ngIf="get(blogTableOfContents, 'section_title', '').length > 0">
                            <div class="content-link-mobile" (click)="isContentsOpen = !isContentsOpen">
                                <a
                                    (click)="scroll(transformTrim(blogTableOfContents?.section_title, true))"
                                    class="blog-table-of-contents-link {{ blogTableOfContents?.section_title + '-mobile' | trim }}"
                                >
                                    <div class="d-flex text-number-container">
                                        <div>{{ blogTableOfContents?.section_title }}</div>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ng-container>
                </ol>
            </div>
        </div>
        <div *ngIf="entry.feature_image?.url" class="feature-image">
            <img [src]="getWebpImg(entry.feature_image.url)" [alt]="entry.feature_image.title" data-cy="blog-post-img-mobile" />
        </div>

        <div class="fx-col p-2">
            <div class="post-body-top" data-cy="blog-post-body-top-mobile">
                <div class="category" data-cy="blog-post-category-mobile">{{ get(entry, 'blog_category[0].title') }}</div>
                <h2 data-cy="blog-post-title-mobile">{{ entry?.title }}</h2>

                <div class="date-time fx-row fx-row-v-center">
                    <span data-cy="blog-post-date-mobile">{{ date }}</span>
                    <span class="fx-row fx-row-v-center pl-3">
                        <mat-icon class="pr-1" svgIcon="clock"></mat-icon>
                        <span data-cy="blog-post-read-time-mobile">{{ entry | readTimeBlog }}</span>
                    </span>
                </div>

                <div class="blog-post-buttons fx-row fx-row-h-left">
                    <div class="pr-2">
                        <a class="share-2 logo-button" (click)="copyLink($event)" target="_blank">
                            <mat-icon svgIcon="share-2"></mat-icon>
                        </a>
                    </div>

                    <div class="pr-2">
                        <a
                            class="twitter logo-button"
                            href="https://twitter.com/intent/tweet?text=https://www.exxactcorp.com{{ entry.url }}"
                            target="_blank"
                        >
                            <mat-icon svgIcon="twitter"></mat-icon>
                        </a>
                    </div>

                    <div class="pr-2">
                        <a
                            class="facebook logo-button"
                            href="https://www.facebook.com/sharer/sharer.php?u=https://www.exxactcorp.com{{ entry.url }}"
                            target="_blank"
                        >
                            <mat-icon svgIcon="facebook"></mat-icon>
                        </a>
                    </div>

                    <div>
                        <a
                            class="linkedin logo-button"
                            href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.exxactcorp.com{{ entry.url }}"
                            target="_blank"
                        >
                            <mat-icon svgIcon="linkedin"></mat-icon>
                        </a>
                    </div>
                </div>

                <div class="post-body-content">
                    <div *ngIf="entry?.article" class="article" [innerHTML]="entry?.article | safe: 'html'" data-cy="blog-post-article-mobile"></div>

                    <div data-cy="blog-post-article" class="article" *ngIf="!entry?.article">
                        <div *ngFor="let blogContentSection of get(entry, 'blog_content_v3.section.group')" class="group mb-4">
                            <span class="mobile-observe" id="{{ blogContentSection?.section_title + '-mobile' | trim }}"></span>
                            <div
                                class="text-block-code-block"
                                *ngIf="blogContentSection?.select == 'text_block' || blogContentSection?.select == 'code_block'"
                                [innerHTML]="blogContentSection?.rich_text_editor | safe: 'html'"
                            ></div>
                            <div *ngIf="blogContentSection?.select == 'image_block'" class="image-block">
                                <div *ngFor="let blogImages of get(blogContentSection, 'images_optional.image', [])">
                                    <h2>{{ blogImages?.image_header }}</h2>
                                    <div class="image-container">
                                        <img
                                            [src]="
                                                blogImages?.image_link.length > 0 ? blogImages?.image_link : getWebpImg(blogImages?.image_file?.url)
                                            "
                                        />
                                    </div>
                                    <p>{{ blogImages?.image_caption_subheader }}</p>
                                </div>
                            </div>
                            <div *ngIf="blogContentSection?.select == 'table_block'" class="table-block">
                                <div *ngIf="get(blogContentSection, 'tables_optional.table_reference', [])">
                                    <div *ngIf="get(blogContentSection, 'tables_optional.table_reference[0].table_content', [])">
                                        <div
                                            [innerHTML]="
                                                get(blogContentSection, 'tables_optional.table_reference[0].table_content', []) | safe: 'html'
                                            "
                                        ></div>
                                    </div>

                                    <cms-partial-category-top-component-table
                                        *ngIf="
                                            !isEmpty(get(blogContentSection, 'tables_optional.table_reference[0].new_table_format_2.table_group', []))
                                        "
                                        [tableRow]="get(blogContentSection, 'tables_optional.table_reference[0]', [])"
                                        [isBlogPage]="true"
                                    >
                                    </cms-partial-category-top-component-table>
                                </div>
                            </div>
                            <div class="color-block-mobile" *ngIf="blogContentSection?.select == 'ad_block'">
                                <div
                                    class="color-block-container"
                                    [ngStyle]="{
                                        'background-image':
                                            'url(' +
                                            get(blogContentSection, 'ad_block_reference[0].banner_image_file.url', '') +
                                            ')
        '
                                    }"
                                >
                                    <div class="image-container" *ngIf="get(blogContentSection, 'ad_block_reference[0].product_image_file.url', '')">
                                        <img [src]="get(blogContentSection, 'ad_block_reference[0].product_image_file.url', '')" />
                                    </div>
                                    <div class="text-container">
                                        <div>
                                            <h4 class="bold">{{ blogContentSection.ad_block_reference[0].ad_banner_title }}</h4>
                                            <p>{{ blogContentSection.ad_block_reference[0].ad_banner_copy }}</p>

                                            <a [routerLink]="blogContentSection.ad_block_reference[0].ad_url"
                                                >{{ blogContentSection.ad_block_reference[0].link_anchor_text }}
                                                <i-feather name="chevron-right"></i-feather
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="entry?.table">
                        <div>{{ entry?.table?.table_title }}</div>
                        <div [innerHTML]="entry?.table?.rich_text_editor | safe: 'html'"></div>
                    </div>

                    <div *ngIf="isNarrow" id="disqus_thread"></div>
                </div>
            </div>

            <div class="related-posts-three">
                <h4 class="mb-1">Related Posts</h4>
                <div *ngIf="allRelatedPosts">
                    <!-- narrow -->
                    <div *ngFor="let post of allRelatedPosts.slice(0, 4)">
                        <app-resource-card-related-card
                            [entry]="post"
                            [relatedContentTileData]="{
                                title: post?.title,
                                categoryTitle: post?.blog_category[0]?.title,
                                image: post?.feature_image?.url,
                                imageTitle: post?.feature_image?.title,
                                date: post?.date,
                                link: post?.routerLink,
                                entry: post,
                                isRelatedContent: true,
                                hideTimeNarrow: true
                            }"
                        ></app-resource-card-related-card>
                    </div>
                </div>
            </div>

            <div class="sign-up-container mb-3">
                <mat-icon class="icon" svgIcon="mail"></mat-icon>
                <h3 class="pt-2 pb-5">Sign up for our newsletter.</h3>
                <a class="button button-signup" (click)="bootstrapMarketoService.openBootstrapMarketoDialog(marketoFormId, 'edit')">
                    <span>Sign up</span>
                    <mat-icon>chevron_right</mat-icon>
                </a>
            </div>

            <div class="topics">
                <h4>Topics</h4>
                <div class="tags fx-row fx-wrap">
                    <button-component
                        *ngFor="let tag of entry.tags"
                        data-cy="blog-tag"
                        [buttonOptions]="{
                            text: tag,
                            internalLink: '/blog/result/tag/' + tag,

                            type: 'tag'
                        }"
                    ></button-component>
                </div>
            </div>

            <div class="contact-us">
                <h4>Have any questions?</h4>
                <a class="link-blue" href="/Contact-Us">Contact us today <mat-icon>chevron_right</mat-icon></a>
            </div>
        </div>
    </div>
</div>

<ng-template #templateImage>
    <div id="modal-parent">
        <div class="close-icon" aria-label="Close" (click)="bsModalRef.hide()">
            <span class="d-flex align-items-center">
                <i-feather name="x"></i-feather>
            </span>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-3">
            <img [src]="getWebpImg(activeImage)" />
        </div>
    </div>
</ng-template>

<ng-template #templateCode>
    <div id="modal-parent">
        <div class="close-icon" aria-label="Close" (click)="bsModalRef.hide()">
            <span class="d-flex align-items-center">
                <i-feather name="x"></i-feather>
            </span>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-3">
            <div [innerHTML]="activeCode | safe: 'html'"></div>
        </div>
    </div>
</ng-template>
