import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'exx-loading-icon',
    templateUrl: './loading-icon.component.html',
    styleUrls: ['./loading-icon.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExxLoadingIconComponent {
    @Input() fill: string = '#4285f4';
    @Input() width: number = 24;
    @Input() height: number = 24;

    constructor() {}
}
