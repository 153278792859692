import { Component, Inject, OnInit } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CmsService } from 'lib/services/cms.service';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { get } from 'lodash';

const scriptName = 'cms-event-form-page.component';

@Component({
    selector: 'app-cms-event-form-page',
    templateUrl: './cms-event-form-page.component.html',
    styleUrls: ['./cms-event-form-page.component.scss'],
})
export class CmsEventFormPageComponent extends CmsPartialComponentClass implements OnInit {
    // Properties: public
    entry: any = {};
    entryRows: any = {};
    formId: string;
    getWebpImg: (src: string) => string;
    routerService: RouterService;

    constructor(
        c: CmsService,
        w: WebpService,
        private m: MetaService,
        r: RouterService,
        @Inject('environment') environment: any
    ) {
        super({
            dependencies: {
                cmsService: c,
                environment,
                routerService: r,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('current_event_page', {
                references: [
                    'rows.category_top_banner.category_top_banner',
                    'rows.image_and_text.image_and_text',
                    'rows.feature_solutions.feature_solutions',
                ],
            });
            this.cmsService.checkEntryExistence(this.entry);
            this.entryRows = { rows: get(this.entry, 'rows', []) };
            const id = await this.entry?.marketo_form?.id;
            this.formId = `mktoForm_${id}`;
        } catch (err) {
            console.error(...new ExxComError(680173, scriptName, err).stamp());
        }
    }
    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
