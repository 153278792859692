<section id="cms-blog-search-page" class="w-100" [class.drawerOpen]="mobileOpen">
  <div class="container-search">
    <!--- DESKTOP SEARCH AND CATEGORY DROPDOWN -->

    <div class="d-flex flex-row">
      <app-cms-blog-header-search id="cms-blog-search" data-cy="cms-blog-search"></app-cms-blog-header-search>

      <div id="categories" #blogCategories>
        <div class="blog-category-container" #blogCategoryButton (click)="blogCategoriesOpen = !blogCategoriesOpen">
          <div
            [class]="
              blogCategoriesOpen
                ? 'expand-button fx-row fx-row-h-space-between fx-row-v-center open'
                : 'expand-button fx-row fx-row-h-space-between fx-row-v-center'
            "
          >
            <span id="wide-button-select" class="d-flex flex-row justify-content-between align-items-center w-100">
              <div class="truncate-text">{{ blogTitle == "" || blogTitle == "Recent" ? "Categories" : blogTitle }}</div>
              <mat-icon>expand_more</mat-icon>
            </span>

            <span id="narrow-button-select" class="d-flex flex-row align-items-center"
              ><mat-icon svgIcon="filter-hopper"></mat-icon><mat-icon>expand_more</mat-icon></span
            >
          </div>
          <div id="options" *ngIf="blogCategoriesOpen">
            <span *ngFor="let category of categories" class="option" (click)="onChosenTitle(category.title)">
              {{ category?.title }}
            </span>
          </div>
        </div>
      </div>

      <!-- <lib-overlay [isVisible]="blogCategoriesOpen" [zIndex]="1"></lib-overlay> -->
    </div>
  </div>
</section>
