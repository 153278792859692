import { ActivatedRoute } from '@angular/router';
import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { CmsBlogSearchComponentClass } from 'lib/components/cms/blog/cms-blog-search-component.class';
import { CmsService } from 'lib/services/cms.service';
import { calculateReadingTime } from 'lib/tools';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { MetaService } from 'lib/services/meta.service';

// const scriptName = 'cms-blog-search.component';

@Component({
    selector: 'app-cms-blog-search',
    templateUrl: './cms-blog-search.component.html',
    styleUrls: ['./cms-blog-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsBlogSearchComponent extends CmsBlogSearchComponentClass {
    calculateReadingTime: (entry: any) => string;
    show: boolean = false;
    entryCategories: any = [];
    constructor(
        @Inject('environment') environment: any,
        activatedRoute: ActivatedRoute,
        cmsService: CmsService,
        infiniteScrollService: InfiniteScrollService,
        metaService: MetaService
    ) {
        super({
            dependencies: {
                activatedRoute,
                cmsService,
                environment,
                infiniteScrollService,
                metaService,
            },
        });
        this.type = 'search';
        this.calculateReadingTime = calculateReadingTime;
    }
}
