import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExxComError } from 'lib/classes/exxcom-error.class';

import { CmsBlogHomepageComponentClass } from 'lib/components/cms/blog/cms-blog-homepage-component.class';
import { CmsService } from 'lib/services/cms.service';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';
import { isBrowser } from 'lib/tools';

const scriptName = 'cms-blog-homepage.component';

@Component({
    selector: 'app-cms-blog-homepage',
    templateUrl: './cms-blog-homepage.component.html',
    styleUrls: ['./cms-blog-homepage.component.scss'],
})
export class CmsBlogHomepageComponent extends CmsBlogHomepageComponentClass implements OnInit, OnDestroy {
    getWebpImg: (src: string) => string;
    @Input() blogTitle: String = 'Recent';
    constructor(
        @Inject('environment') environment: any,
        cmsService: CmsService,
        dialog: MatDialog,
        metaService: MetaService,
        webpService: WebpService
    ) {
        super({
            dependencies: { cmsService, dialog, environment, metaService },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            if (!isBrowser()) {
                return;
            }
            await super.ngOnInit();
        } catch (err) {
            console.error(...new ExxComError(317901, scriptName, err).stamp());
        }
    }

    ngOnDestroy() {
        try {
            if (!isBrowser()) {
                return;
            }
        } catch (err) {
            console.error(...new ExxComError(580347, scriptName, err).stamp());
        }
    }
}
