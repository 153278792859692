<section id="events-page">
  <app-cms-partial-category-top [data]="entryRows"></app-cms-partial-category-top>
  <section id="form-content-container" class="text-center container mb-5">
    <h2 *ngIf="get(entry, 'marketo_form_title', '')">{{ get(entry, "marketo_form_title", "") }}</h2>
    <div id="form-content-text-body" class="mb-2" [innerHTML]="entry?.marketo_form_body | safe: 'html'"></div>
    <div class="d-flex flex-row justify-content-center">
      <div class="form-card" *ngIf="formId">
        <lib-marketo-form [formId]="formId"></lib-marketo-form>
      </div>
    </div>
  </section>
</section>
