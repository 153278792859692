<section>
    <ng-container *ngFor="let config of configurationsRow">
        <div id="homepage-configurations-container" class="container">
            <h2 class="title">{{ config?.title }}</h2>

            <div id="homepage-grid-container">
                <div class="homepage-config-video">
                    <video
                        [src]="config?.configuration_video_and_alt_text.href"
                        type="video/mp4"
                        loop
                        muted
                        autoplay
                        playsinline
                        oncanplay="this.play()"
                        onloadedmetadata="this.muted = true"
                    ></video>
                </div>
                <div class="homepage-config-images">
                    <ng-container *ngFor="let configGridItem of config?.configurations?.configuration">
                        <div class="homepage-config-container">
                            <div class="homepage-config">
                                <img
                                    [src]="getWebpImg(configGridItem?.configuration_image_and_alt?.href)"
                                    [alt]="configGridItem?.configuration_image_and_alt?.title"
                                />
                                <h4 class="config-title">{{ configGridItem?.configuration_title }}</h4>
                                <button-component
                                    class="config-button w-100"
                                    *ngIf="configGridItem?.configuration_link"
                                    [buttonOptions]="{
                                        text: 'Configure',
                                        type: 'primary',
                                        iconPosition: 'right',
                                        iconName: 'tool',
                                        class: 'w-100',
                                        internalLink: !configGridItem?.sierraActive ? '/' + configGridItem.configuration_mpn + '/configurator' : null,
                                        externalLink: configGridItem?.sierraActive
                                            ? 'https://configurator.exxactcorp.com/configure/' + configGridItem.configuration_mpn
                                            : null
                                    }"
                                ></button-component>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</section>
