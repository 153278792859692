import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebpService } from 'lib/services/webp.service';
import { MetaService } from 'lib/services/meta.service';

const scriptName = 'cms-test-drive.component';

@Component({
    selector: 'app-cms-test-drive',
    templateUrl: './cms-test-drive.component.html',
    styleUrls: ['./cms-test-drive.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsTestDriveComponent extends CmsPartialComponentClass implements OnInit {
    // Properties: public
    entry: any = {};
    getWebpImg: (src: string) => string;

    constructor(
        b: BreakpointObserver,
        bms: BootstrapMarketoService,
        c: CmsService,
        w: WebpService,
        private m: MetaService,
        @Inject('environment') environment: any
    ) {
        super({
            dependencies: {
                breakpointObserver: b,
                bootstrapMarketoService: bms,
                cmsService: c,
                environment,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('test_drive', {
                references: ['banner', 'reference', 'quote'],
            });
        } catch (err) {
            console.error(...new ExxComError(680173, scriptName, err).stamp());
        }
    }
}
