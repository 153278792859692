import { Component, Input } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { WebpService } from 'lib/services/webp.service';

@Component({
    selector: 'cms-partial-category-top-step-by-step',
    templateUrl: './step-by-step.component.html',
    styleUrls: ['./step-by-step.component.scss'],
})
export class StepByStepComponent extends CmsPartialComponentClass {
    @Input() stepByStepRow: any;
    getWebpImg: (src: string) => string;

    constructor(webpService: WebpService) {
        super({ dependencies: webpService });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
