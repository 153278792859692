import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterService } from 'lib/services/router.service';

type dropDownDataType = {
    listItems: any;
    dropdownMenuID: String;
};

@Component({
    selector: 'app-resource-center-header',
    templateUrl: './resource-center-header.component.html',
    styleUrls: ['./resource-center-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ResourceCenterHeaderComponent implements OnInit {
    @Input() initialDropdownTitle: string;
    dropdownMenu: dropDownDataType = {
        listItems: [
            { title: 'Blog', href: '/blog' },
            { title: 'Case Studies', href: '/Case-Studies' },
            { title: 'eBooks', href: '/eBooks' },
            {
                title: 'Reference Architecture',
                href: '/Reference-Architecture',
            },
            { title: 'Supported Software', href: '/Supported-Software' },
            { title: 'Whitepapers', href: '/Whitepapers' },
        ],
        dropdownMenuID: 'menu-id-resource-center',
    };
    constructor(private routerService: RouterService) {}

    ngOnInit(): void {
        this.setDropdownMenuTitle();
    }
    /**
     * Purpose: On page load this function will run and determine the title that should be set for the resource header dropdown component
     * It extracts the first path of the url and looks in the list items array for a matching resource center location.
     * firstPath is first path of url
     * currentLocation is the index of the firstPath in the listItems array
     *
     */
    setDropdownMenuTitle(): void {
        const firstPath = location.pathname.split('/')[1];
        const currentLocation = this.dropdownMenu?.listItems?.findIndex((location) => location.href === `/${firstPath}`);
        this.initialDropdownTitle = this.dropdownMenu?.listItems[currentLocation]?.title;
        if (this.initialDropdownTitle === undefined) {
            this.initialDropdownTitle = 'Categories';
        }
    }
}
