import { Component, ViewEncapsulation } from '@angular/core';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { EmliTableModalComponent } from '../emli-table-modal/emli-table-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';

const scriptName = 'cms-partials-emli-table';

@Component({
    selector: 'app-emli-table',
    templateUrl: './emli-table.component.html',
    styleUrls: ['./emli-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmliTableComponent extends ExxComComponentClass {
    // Dependencies

    constructor(
        private dialog: MatDialog,
        breakpointObserver: BreakpointObserver
    ) {
        super({ dependencies: { dialog, breakpointObserver } });
    }

    async openEmliModal() {
        try {
            this.dialog.open(EmliTableModalComponent, {
                panelClass: 'cdk-emli-pane',
            });
        } catch (err) {
            console.error(...new ExxComError(902310, scriptName, err).stamp());
        }
    }
}
