<div id="cms-blog-category">
    <div id="scroll-anchor-container">
        <div id="scroll-anchor"></div>
    </div>
    <section class="container">
        <div id="blog-search-dropdown-title-container" class="d-flex flex-column justify-content-md-between flex-md-row mb-4">
            <h4 *ngIf="!searchInput" class="browse-title bold">
                {{ name == '' || name == 'Recent' ? 'Browse Blogs' : name }}
            </h4>
            <div *ngIf="searchInput" class="w-100" id="search-results-text" data-cy="search-results-text">
                <h5 class="m-0">Search results for:</h5>
                <h3 class="m-0">{{ searchInput }}</h3>
                <hr />
            </div>
            <!-- set input here -->
            <app-cms-blog-search-and-dropdown
                [categories]="entryCategories?.reference || categories"
                [blogTitle]="this.name"
                (categorySelectionHandler)="this.name = $event; changeFilteredEntries(); p = 1; queryLoadingFinished = true"
                data-cy="cms-blog-banner"
            >
            </app-cms-blog-search-and-dropdown>
        </div>
    </section>

    <section class="cms-blog-category-cards fx-row-container fx-row-h-left fx-wrap" data-cy="cms-blog-category">
        <lib-overlay [isVisible]="!queryLoadingFinished" [zIndex]="15512323112323"></lib-overlay>

        <div class="fx-row-container fx-row-h-left fx-wrap w-100" id="blog-card">
            <div
                *ngFor="let entry of entries | paginate: { itemsPerPage: blogsPerPage, currentPage: p, totalItems: itemCount }"
                class="col-md-4 col-sm-6 mb-4 fx-col-h-center blog-card"
                [class.no-pagination]="itemCount <= 21"
                data-cy="blog-resource-card"
            >
                <app-resource-card-related-card
                    [entry]="entry"
                    [relatedContentTileData]="{
                        title: entry?.title,
                        categoryTitle: entry?.blog_category[0]?.title,
                        image: entry?.feature_image?.url,
                        imageTitle: entry?.feature_image?.title,
                        date: entry?.date,
                        link: entry?.routerLink,
                        entry: entry
                    }"
                ></app-resource-card-related-card>
            </div>
        </div>
        <div class="container" id="bottom-container" *ngIf="queryLoadingFinished">
            <div class="fx-row-container no-result-image pt-3" *ngIf="noBlogResults">
                <img class="col-sm-10" src="https://images.sabrepc.com/spc-cms/blog/NoSearchResults.svg" alt="No Search Results" />
            </div>
            <div id="pagination-container" class="d-flex flex-row justify-content-center" *ngIf="!noBlogResults && itemCount > 21">
                <pagination-controls
                    (pageChange)="p = $event; paginate(p)"
                    maxSize="6"
                    directionLinks="true"
                    autoHide="true"
                    previousLabel=""
                    nextLabel=""
                    screenReaderPaginationLabel="Pagination"
                    screenReaderPageLabel="page"
                    screenReaderCurrentLabel="You're on page"
                ></pagination-controls>
            </div>
        </div>
    </section>

    <section id="cms-blog-category-spinner" class="fx-row-container">
        <mat-spinner *ngIf="entries && entries?.length == 0" diameter="50"></mat-spinner>
    </section>
</div>
