import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CmsBlogCategoryComponentClass } from 'lib/components/cms/blog/cms-blog-category-component.class';
import { BlogCountService } from 'lib/services/blog-count.service';
import { CmsService } from 'lib/services/cms.service';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';

// const scriptName = 'cms-blog-category.component';

@Component({
    selector: 'app-cms-blog-category',
    templateUrl: './cms-blog-category.component.html',
    styleUrls: ['./cms-blog-category.component.scss'],
})
export class CmsBlogCategoryComponent extends CmsBlogCategoryComponentClass implements OnInit {
    webpAvailable: boolean = false;
    webpService: WebpService;
    p: number = 1;
    entryCategories: any;
    @Input() blogInfo: any;
    @Input() categories: any = [];
    @Input() searchInput: string;

    blogsPerPage: number = 21;
    // change in component class as well
    id: string = 'string';
    @Input() showHeader: boolean = true;
    getWebpImg: (src: string) => string;

    constructor(
        @Inject('environment') environment: any,
        blogCountService: BlogCountService,
        cmsService: CmsService,
        infiniteScrollService: InfiniteScrollService,
        router: Router,
        metaService: MetaService,
        webpService: WebpService
    ) {
        super({
            dependencies: {
                blogCountService,
                cmsService,
                environment,
                infiniteScrollService,
                metaService,
                router,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        this.entryCategories = await this.cmsService.getPage(`${this.environment.siteAbbr}_blog_homepage`, {
            references: ['reference'],
        });
    }
}
