import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { get } from 'lodash';

import { CmsService } from 'lib/services/cms.service';
import { CategoryService } from 'lib/services/category/category.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';

const scriptName = 'cms-ref-architecture-pages.component';

@Component({
    selector: 'app-cms-ref-architecture-pages',
    templateUrl: './cms-ref-architecture-pages.component.html',
    styleUrls: ['./cms-ref-architecture-pages.component.scss'],
})
export class CmsRefArchitecturePagesComponent extends ExxComComponentClass implements OnInit {
    // Dependencies
    breakpointObserver: BreakpointObserver;
    categoryService: CategoryService;
    cmsService: CmsService;
    dialog: MatDialog;
    metaService: MetaService;
    productService: WebstoreProductService;
    routerService: RouterService;

    // Properties: public
    entry: any = { rows: [] };

    constructor(
        @Inject('environment') e: any,
        b: BreakpointObserver,
        ca: CategoryService,
        c: CmsService,
        d: MatDialog,
        m: MetaService,
        pr: WebstoreProductService,
        r: RouterService
    ) {
        super({
            dependencies: {
                breakpointObserver: b,
                categoryService: ca,
                cmsService: c,
                dialog: d,
                environment: e,
                metaService: m,
                productService: pr,
                routerService: r,
            },
        });
    }

    async ngOnInit() {
        try {
            const res = await this.cmsService.getPage('reference_architecture_page', {
                references: [
                    'rows.category_top_banner.category_top_banner',
                    'rows.image_and_text.image_and_text',
                    'rows.value_props.value_prop_set.value_prop',
                    'rows.feature_solutions.feature_solutions',
                    'rows.image_and_text.text_block',
                    'rows.image_and_text.full_width_image_and_text',
                    'rows.logos.logos',
                    'rows.full_width_block.full_width_block',
                    'rows.accordion.accordion',
                ],
            });
            const rows = get(res, 'rows', []);
            this.entry = { rows: rows };
        } catch (err) {
            console.error(...new ExxComError(476789, scriptName, err).stamp());
        }
    }
}
