<!-- !TODO Create a custom directive or component to accept a url and return the correct href or routerLink attribute depending on path. -->
<section
    class="logos-section"
    [ngStyle]="{
        'background-color': get(logosRow, 'logos.logos[0].background_color', ''),
        'background-repeat': 'no-repeat'
    }"
>
    <div *ngFor="let row of get(logosRow, 'logos.logos', [])">
        <div class="d-flex flex-column container">
            <div *ngIf="get(row, 'partnership', false)" class="partners-top-title fx-row col-xs-12">
                <img src="https://images.exxactcorp.com/img/exx/misc/x-emblem.svg" alt="logo" />
                <div class="d-flex flex-column justify-content-center pl-3">
                    <h2 class="pb-1 mb-0">Partnerships</h2>
                </div>
            </div>
            <div *ngIf="!get(row, 'partnership', false) && get(row, 'logos_title', '')" class="d-flex justify-content-center">
                <h2 *ngIf="get(row, 'logos_title', '')" class="col-md-7 text-center title">
                    {{ get(row, 'logos_title', '') }}
                </h2>
            </div>
            <div class="logos-container" [class.justify-content-center]="get(row, 'justification', '') == 'center'">
                <div class="logo-item" *ngFor="let logo of get(row, 'logos', [])">
                    <ng-container *ngIf="get(logo, 'logo_link', '').length > 0">
                        <a *ngIf="logo.logo_link.startsWith('http')" [href]="logo.logo_link" class="logo-link">
                            <img *ngIf="get(logo, 'image.href', '')" [src]="getWebpImg(logo.image.href)" [alt]="logo.image.title" />
                        </a>
                        <a *ngIf="logo.logo_link.startsWith('/')" [routerLink]="logo.logo_link" class="logo-link">
                            <img *ngIf="get(logo, 'image.href', '')" [src]="getWebpImg(logo.image.href)" [alt]="logo.image.title" />
                        </a>
                    </ng-container>
                    <img
                        *ngIf="get(logo, 'image.href', '') && get(logo, 'logo_link', '') == ''"
                        [src]="getWebpImg(logo.image.href)"
                        [alt]="logo.image.title"
                    />
                </div>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(logosRow, 'logos.split_line', '')" class="m-0 mb-3" />
