import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CmsService } from 'lib/services/cms.service';
import { MarketoDialogComponent } from 'lib/components/marketo-dialog/marketo-dialog.component';
import { MetaService } from 'lib/services/meta.service';

@Component({
    selector: 'app-cms-b2b-service',
    templateUrl: './cms-b2b-service.component.html',
    styleUrls: ['./cms-b2b-service.component.scss'],
})
export class CmsB2bServiceComponent implements OnInit {
    entry: any = {};
    formId: any;

    constructor(
        private cmsService: CmsService,
        private metaService: MetaService,
        public dialog: MatDialog
    ) {}

    async ngOnInit() {
        // this.entry = await this.cmsService.getPage('b2b_services');
        // this.formId = `mktoForm_${this.entry.hero_banner_button_form.id}`;
        // this.metaService.add(this.entry.seo_meta_data);
    }

    openMarketoForm(action: string) {
        this.dialog.open(MarketoDialogComponent, {
            width: '500px',
            height: '750px',
            autoFocus: false,
            data: {
                marketoFormId: this.formId,
                action: action,
            },
        });
    }
}
