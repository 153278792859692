import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragToScroll]',
})
export class DragToScrollDirective {
    private isDragging = false;
    private startX = 0;
    private startY = 0;

    constructor(private el: ElementRef) {}

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.isDragging = true;
        this.startX = event.clientX;
        this.startY = event.clientY;
    }

    @HostListener('document:mouseup')
    onMouseUp() {
        this.isDragging = false;
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (!this.isDragging) {
            return;
        }
        const deltaX = event.clientX - this.startX;
        const deltaY = event.clientY - this.startY;
        this.el.nativeElement.scrollLeft -= deltaX;
        this.el.nativeElement.scrollTop -= deltaY;
        this.startX = event.clientX;
        this.startY = event.clientY;
    }
}
