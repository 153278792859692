import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { includes } from 'lodash';
import { calculateReadingTime } from 'lib/tools';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';

const scriptName = 'cms-supported-software.component';

@Component({
    selector: 'app-cms-supported-software',
    templateUrl: './cms-supported-software.component.html',
    styleUrls: ['./cms-supported-software.component.scss'],
})
export class CmsSupportedSoftwareComponent extends ExxComComponentClass implements OnInit, OnDestroy {
    @HostListener('document:click', ['$event.target']) closeOnClickAway(target: HTMLElement) {
        try {
            if (target == get(this, 'filterMenuButton.nativeElement')) {
                return;
            }
            const filterMenu: HTMLElement = get(this, 'filterMenu.nativeElement');
            if (target != filterMenu && !filterMenu.contains(target)) {
                this.filterMenuOpen = false;
            }
        } catch (err) {
            console.error(...new ExxComError(394993, scriptName, err).stamp());
        }
    }

    @ViewChild('filterMenu') filterMenu: ElementRef;
    @ViewChild('filterMenuButton') filterMenuButton: ElementRef;
    @ViewChild('searchInput') searchInput: ElementRef;

    categories: any = [];
    categoryParam: string;
    categoryTitle: string;
    cardEntryData: any = [];
    selectedCategory: string = 'All';
    entries: any = [];
    featuredSoftwareBlog: any = [];
    featuredContentSoftwareBlog: any = {};
    searchInputValue: string = '';
    filterMenuOpen: boolean = false;
    newEntries: any = [];
    bsModalRef: BsModalRef;

    getWebpImg: (src: string) => string;
    calculateReadingTime: (entry: any) => string;

    constructor(
        breakpointObserver: BreakpointObserver,
        private bsModalService: BsModalService,
        private cmsService: CmsService,
        private routerService: RouterService,
        webpService: WebpService,
        private cd: ChangeDetectorRef
    ) {
        super({
            dependencies: {
                breakpointObserver: breakpointObserver,
            },
        });

        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
        this.calculateReadingTime = calculateReadingTime;
    }
    ngOnDestroy() {
        this.bsModalRef?.hide();
    }

    async ngOnInit() {
        try {
            /** Sets categories */
            this.categoryTitle = this.categoryParam ? decodeURIComponent(this.categoryParam) : null;
            const resCategories = await this.cmsService.getEntries('supported_software_category');
            resCategories.unshift({
                url: '/Supported-Software',
                routerLink: '/Supported-Software',
                title: 'All',
            });
            this.categories = resCategories;

            /** Sets entries */
            this.entries = await this.cmsService.getEntries('supported_software', {
                query: {
                    category: { $in_query: { title: this.categoryParam } },
                },
                references: ['category'],
                sort: { asc: 'title' },
            });
            this.newEntries = await this.entries;

            this.featuredSoftwareBlog = await this.cmsService.getEntries('supported_software_featured', {
                references: ['reference', 'blog_category_reference'],
            });
            // populate data being passed to reusable card component
            this.populateSoftwareBlogData(this.featuredSoftwareBlog);
        } catch (err) {
            console.error(...new ExxComError(394997, scriptName, err).stamp());
        }
    }

    /**
     * passing in content stack data and picking out desired data to be passed to child component
     * @param data content stack reference data
     */
    populateSoftwareBlogData(data: any) {
        data = data[0];
        this.featuredContentSoftwareBlog.featuredContentType = data?.title;
        this.featuredContentSoftwareBlog.featuredContentLink = data?.reference[0]?.url;
        this.featuredContentSoftwareBlog.featuredContentImage = data?.reference[0]?.feature_image?.url;
        this.featuredContentSoftwareBlog.featuredContentTitle = data?.reference[0]?.title;
        this.featuredContentSoftwareBlog.featuredContentCategoryTitle = data?.blog_category_reference[0]?.title;
        this.featuredContentSoftwareBlog.featuredContentReadTime = calculateReadingTime(data?.reference[0]);
        this.featuredContentSoftwareBlog.featuredContentDate = this.reformatDate(data?.reference[0]?.date);
    }

    /**
     *
     * @param searchInput value captured by search input fild
     * If a category is selected, a change in the search field will result in the category being searched.
     * If a category is not selected, a change in the search field will result in all entries being searched
     */
    filterCurrentSoftware(searchInput: string) {
        try {
            if (this.selectedCategory !== 'All') {
                this.newEntries = this.entries.filter((search) => {
                    return includes(search.category[0].title, this.selectedCategory) && includes(search.title.toLowerCase(), searchInput);
                });
            } else {
                this.newEntries = this.entries.filter((search) => {
                    return includes(search.title.toLowerCase(), searchInput.toLowerCase());
                });
            }
        } catch (err) {
            console.error(...new ExxComError(511115, scriptName, err).stamp());
        }
    }

    openCardModal(template: TemplateRef<any>, entry: any = []) {
        this.cardEntryData = entry;
        this.bsModalRef = this.bsModalService.show(template, {
            class: 'supported-software-card-modal modal-dialog-centered',
        });
    }
    /**
     *
     * @param selectedCategory value of the category drop down currently selected
     * When a category is selected, all entries will be filtered by the selected category
     */
    categorySearch(selectedCategory: string) {
        if (selectedCategory !== 'All') {
            this.newEntries = this.entries.filter((search) => {
                return includes(search.category[0].title, selectedCategory) && includes(search.title.toLowerCase(), this.searchInputValue);
            });
        } else {
            this.newEntries = this.entries;
        }
    }
    /**
     *
     * @param date ISO formatted date
     * @returns Month day year in human readable
     */
    reformatDate(date: any) {
        try {
            if (!date || !date) {
                return '';
            }
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        } catch (err) {
            console.error(...new ExxComError(523275, scriptName, err).stamp());
        }
    }
    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
