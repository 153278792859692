import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { WebpService } from 'lib/services/webp.service';

type relatedContentTileAssetData = {
    link: String;
    image: String;
    imageTitle: String;
    categoryTitle: String;
    title: String;
    date: String;
    readTime: Number;
    entry: Object;
    isRelatedContent: Boolean;
    hideTimeNarrow: Boolean;
    narrowOnlyDesign: Boolean;
};

@Component({
    selector: 'app-resource-card-related-card',
    templateUrl: './resource-card-related-card.component.html',
    styleUrls: ['./resource-card-related-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ResourceCardRelatedCardComponent extends ExxComComponentClass {
    @Input() entry: Object;
    @Input() relatedContentTileData: relatedContentTileAssetData;
    getWebpImg: (src: string) => string;

    constructor(webpService: WebpService) {
        super();
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
