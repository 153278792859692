<section class="step-by-step">
    <div *ngFor="let row of get(stepByStepRow, 'step_by_step.step_by_step', '')" class="step-by-step-entry mx-auto my-0">
        <h2 class="label text-center px-3">{{ row.label }}</h2>

        <div *ngIf="get(row, 'cards', [])" class="cards-container">
            <div *ngFor="let card of get(row, 'cards', '')" class="card">
                <!-- Mobile only title ------------------------------------------------>
                <span class="card-title-container-narrow">
                    <h4 *ngIf="get(card, 'card_title', '')" class="card-title m-0">
                        {{ get(card, 'card_title', '') }}
                    </h4>
                    <hr class="mb-2" />
                </span>
                <div class="d-flex flex-row">
                    <img class="card-img" [src]="getWebpImg(get(card, 'card_image.href', ''))" [alt]="get(card, 'card_image.title', '')" />
                    <span class="card-right d-flex flex-column">
                        <span>
                            <!-- Tablet/Desktop only title ---------------------------------->
                            <span class="card-title-container-wide">
                                <h4 *ngIf="get(card, 'card_title', '')" class="card-title m-0">
                                    {{ get(card, 'card_title', '') }}
                                </h4>
                                <hr />
                            </span>
                            <p *ngIf="get(card, 'card_text')" class="card-text mt-0 mb-2">
                                {{ get(card, 'card_text', '') }}
                            </p>
                        </span>
                        <span class="links-container d-grid">
                            <a class="mb-base" *ngFor="let link of get(card, 'links')" [href]="link.href">
                                {{ link.title }}
                            </a>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
