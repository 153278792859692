import { Component, AfterViewInit, OnDestroy, Renderer2, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements AfterViewInit, OnDestroy {
    private termlyScriptId = 'termly-jssdk';
    private isBrowser: boolean;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngAfterViewInit() {
        if (!this.isBrowser) return;
        const divId = 'cookies-policy';

        const termlyDiv = this.renderer.createElement('div');
        this.renderer.setAttribute(termlyDiv, 'name', 'termly-embed');
        this.renderer.setAttribute(termlyDiv, 'data-id', 'ab84e2d4-3217-4538-830e-c428dcf3ea1f');

        const termlyScript = this.renderer.createElement('script');
        this.renderer.setAttribute(termlyScript, 'type', 'text/javascript');
        this.renderer.appendChild(
            termlyScript,
            this.renderer.createText(`
            (function(d, s, id) {
                var js, tjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://app.termly.io/embed-policy.min.js";
                tjs.parentNode.insertBefore(js, tjs);
            }(document, 'script', '${this.termlyScriptId}'));
        `)
        );

        const cookiesPolicyDiv = this.el.nativeElement.querySelector(`#${divId}`);
        if (cookiesPolicyDiv) {
            this.renderer.appendChild(cookiesPolicyDiv, termlyDiv);
            this.renderer.appendChild(cookiesPolicyDiv, termlyScript);
        }
    }

    ngOnDestroy() {
        if (!this.isBrowser) return;
        const termlyScript = document.getElementById(this.termlyScriptId);
        if (termlyScript) {
            termlyScript.remove();
        }
    }
}
