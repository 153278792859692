import { Component, Input } from '@angular/core';
import { get } from 'lodash';

type dropDownDataType = {
    buttonTitle: String;
    listItems: String[];
    dropdownMenuID: String;
};

@Component({
    selector: 'app-resource-center-dropdown',
    templateUrl: './resource-center-dropdown.component.html',
    styleUrls: ['./resource-center-dropdown.component.scss'],
})
export class ResourceCenterDropdownComponent {
    @Input() initialDropdownTitle: string;
    @Input() dropdownData: dropDownDataType;
    @Input() returnToHomepageLink: boolean;
    currentTitle: string;

    constructor() {}
    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
