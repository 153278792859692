import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { ApiService } from 'lib/services/api.service';
import { CmsService } from 'lib/services/cms.service';
import { assign } from 'lodash';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { MessageService } from 'lib/services/message.service';
import { MetaService } from 'lib/services/meta.service';
import { validatePhone } from 'lib/validators/phone.validator';
import { getDistanceFromTop, timestamp, wait } from 'lib/tools';

@Component({
    selector: 'app-cms-rma-request',
    templateUrl: './cms-rma-request.component.html',
    styleUrls: ['./cms-rma-request.component.scss'],
})
export class CmsRmaRequestComponent implements OnInit {
    actionPending: boolean = false;
    entry: any = {};
    form: FormGroup;
    formSubmitted: boolean = false;

    constructor(
        private apiService: ApiService,
        private cmsService: CmsService,
        private formBuilder: FormBuilder,
        private grecaptchaService: GrecaptchaService,
        private metaService: MetaService,
        public messageService: MessageService
    ) {}

    async ngOnInit() {
        // this.initForm();
        // this.entry = await this.cmsService.getPage('rma_request');
        // this.metaService.add(this.entry.seo_meta_data);
        // this.onSubmit = debounce(this.onSubmit, 3000, { leading: true });
    }

    private initForm() {
        this.form = this.formBuilder.group({
            first: ['', { validators: [Validators.required] }],
            address1: ['', { validators: [Validators.required] }],
            last: ['', { validators: [Validators.required] }],
            city: ['', { validators: [Validators.required] }],
            company: [''],
            state: ['', { validators: [Validators.required] }],
            country: ['', { validators: [Validators.required] }],
            email: ['', { validators: [Validators.required, Validators.email] }],
            phone: [
                '',
                {
                    validators: [Validators.required, validatePhone],
                    updateOn: 'blur',
                },
            ],
            zip: ['', { validators: [Validators.required] }],
            returnList: this.formBuilder.array([this.createRow()]),
        });
    }

    get address1() {
        return this.form.get('address1');
    }
    get city() {
        return this.form.get('city');
    }
    get country() {
        return this.form.get('country');
    }
    get company() {
        return this.form.get('company');
    }
    get email() {
        return this.form.get('email');
    }
    get first() {
        return this.form.get('first');
    }
    get grecaptcha() {
        return this.form.get('grecaptcha');
    }
    get last() {
        return this.form.get('last');
    }
    get phone() {
        return this.form.get('phone');
    }
    get returnList() {
        return this.form.get('returnList') as FormArray;
    }
    get state() {
        return this.form.get('state');
    }
    get zip() {
        return this.form.get('zip');
    }

    orderNumber(index: number) {
        return this.getReturnListFormGroup(index).controls.orderNumber;
    }
    partNumber(index: number) {
        return this.getReturnListFormGroup(index).controls.partNumber;
    }
    quantity(index: number) {
        return this.getReturnListFormGroup(index).controls.quantity;
    }
    requestType(index: number) {
        return this.getReturnListFormGroup(index).controls.requestType;
    }
    reason(index: number) {
        return this.getReturnListFormGroup(index).controls.reason;
    }
    serialNumber(index: number) {
        return this.getReturnListFormGroup(index).controls.serialNumber;
    }

    getReturnListFormGroup(index: number) {
        return this.returnList.controls[index] as FormGroup;
    }

    createRow() {
        return this.formBuilder.group({
            orderNumber: ['', { validators: [Validators.required] }],
            partNumber: ['', { validators: [Validators.required] }],
            quantity: [{ validators: [Validators.required] }],
            serialNumber: ['', { validators: [Validators.required] }],
            requestType: ['', { validators: [Validators.required] }],
            reason: ['', { validators: [Validators.required] }],
        });
    }

    addRow() {
        this.returnList.push(this.createRow());
    }

    removeRow(index: number) {
        this.returnList.removeAt(index);
    }

    hasError(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }
    hasErrorRequired(control: any) {
        return control.errors && control.errors.required;
    }
    hasErrorInvalid(control: any) {
        return control.errors && control.errors.email;
    }
    hasErrorPhoneInvalid(control: any) {
        return control.errors && control.errors.phoneInvalid;
    }

    async onSubmit() {
        if (!this.form.valid) {
            return this.form.markAllAsTouched();
        }
        this.actionPending = true;
        await this.sendEmail();
        this.actionPending = false;
    }

    private async sendEmail() {
        const values = assign(
            {
                subject: 'SabrePC Webstore RMA Request',
                webstore: 'spc',
                grecaptchaToken: await this.grecaptchaService.getToken('cms_rmaRequest_sendEmail'),
            },
            this.form.value
        );
        const res = await this.apiService.post('email/rma-request', values);
        if (!res.success) {
            console.error(timestamp(), 'cms-rma-request.component', 839564, res);
            this.messageService.addLocal('cms_rmaRequest', res.error && res.error.message);
        } else {
            this.formSubmitted = true;
            await wait('100ms');
            window.scrollTo(0, getDistanceFromTop(document.getElementById('cms-rma-request-form-heading')) - 10);
        }
    }
}
