<div id="product-page-slider" *ngIf="productImages.length > 1">
  <div class="swiper-bottom-navigation fx-row fx-row-v-center fx-row-h-center">
    <mat-icon
      (click)="slideChange(null, 'left')"
      svgIcon="left-pagination-button"
      class="mat-icon notranslate swiper-button-prev-unique outline mr-2 mat-icon-no-color"
    ></mat-icon>
    <div id="product-swiper-image-container-preview" [style.maxWidth.px]="widthPerSwiperImage * slidesPerView">
      <div
        id="image-swiper-container"
        class="d-flex flex-row justify-content-center"
        [ngStyle]="{
          width: widthPerSwiperImage * productImages.length + 'px',
          transform: 'translateX(' + translateAmount + 'px)'
        }"
      >
        <div
          *ngFor="let swiperSlide of productImages; let i = index"
          (click)="slideChange(i)"
          class="product-swiper-image-container product-swiper-image"
          [class.active-swiper-product]="i == amount"
        >
          <img [src]="swiperSlide" />
        </div>
      </div>
    </div>
    <mat-icon
      (click)="slideChange(null, 'right')"
      svgIcon="right-pagination-button"
      class="mat-icon notranslate swiper-button-next-unique outline ml-2 mat-icon-no-color"
    ></mat-icon>
  </div>
</div>
