<div id="spc-dropdown" class="dropdown" [style.width.px]="dropdownWidth">
  <button
    [style.width.px]="dropdownWidth"
    class="dropdown-toggle dropdown-button"
    type="button"
    [id]="dropdownData?.dropdownMenuID"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <div class="d-flex flex-row align-items-center justify-content-between">
      <span>{{ get(dropdownData, "buttonTitle", "Dropdown") }}</span>
      <mat-icon id="gated-content-header-icon" svgIcon="chevron_down"></mat-icon>
    </div>
  </button>
  <ul class="dropdown-menu" [attr.aria-labelledby]="dropdownData?.dropdownMenuID">
    <li *ngFor="let item of dropdownData.listItems" class="dropdown-list">
      <a class="dropdown-item">{{ item }}</a>
    </li>
  </ul>
</div>
