<div id="product" *ngIf="isVisible" data-cy="product-container">
    <header id="product-header" class="non-flex-container px-3">
        <div id="product-breadcrumb" data-cy="breadcrumb">
            <span *ngFor="let breadcrumbPart of breadcrumb; let i = index">
                <a *ngIf="i != 0" [routerLink]="'/category/' + breadcrumbPart.urlComponent" data-cy="breadcrumb-link">{{ breadcrumbPart.name }}</a>
                <span *ngIf="i == 0">{{ breadcrumbPart.name }}</span>
                <span *ngIf="i < breadcrumb.length - 1" class="product-breadcrumb-separator"> / </span>
            </span>
        </div>
    </header>

    <app-product-listing class="fx-row" [product]="product"></app-product-listing>

    <mat-tab-group mat-align-tabs="center" [dynamicHeight]="true" [disableRipple]="true" data-cy="mat-tab-group">
        <mat-tab *ngIf="(product?.marketingInfo || premium_content || []).length > 0" label="About">
            <div class="fx-col-container">
                <div
                    *ngIf="get(product, 'marketingInfo', '')"
                    id="product-marketing-information-wide"
                    class="product-tab-content marketing-info px-3"
                    [innerHTML]="product.marketingInfo | safe: 'html'"
                ></div>
            </div>
            <div *ngIf="premium_content">
                <div *ngFor="let content of premium_content">
                    <!-- cms partial category top goes here -->
                    <app-cms-partial-category-top [data]="{ rows: content?.product_page_partial_rows }"></app-cms-partial-category-top>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="product.specifications" label="Specifications">
            <div class="fx-col-container">
                <div
                    *ngIf="product.specifications"
                    id="product-specifications-wide"
                    class="product-tab-content px-2"
                    [innerHTML]="product.specifications | safe: 'html'"
                ></div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div id="product-marketingInfo-specifications" class="container-padless" data-cy="marketing-info-narrow">
        <div class="menu-level1">
            <div
                *ngIf="(product?.marketingInfo || premium_content || []).length > 0"
                (click)="toggleMenu(0)"
                class="title fx-row fx-row-h-space-between fx-row-v-center"
                data-cy="product-about-btn-narrow"
            >
                <h3 class="about">About</h3>
                <i-feather name="plus-circle" class="plus color-green"></i-feather>
                <i-feather name="minus-circle" class="minus color-green"> </i-feather>
            </div>
            <div class="menu-level2" id="product-marketing-information-narrow" data-cy="product-about-body">
                <div
                    class="marketing-info"
                    [innerHTML]="get(product, 'marketingInfo', '') | safe: 'html'"
                    *ngIf="get(product, 'marketingInfo', '') !== null"
                ></div>
                <div *ngIf="premium_content">
                    <div *ngFor="let content of premium_content">
                        <!-- cms partial category top goes here -->
                        <app-cms-partial-category-top [data]="{ rows: content?.product_page_partial_rows }"></app-cms-partial-category-top>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-level1">
            <div
                *ngIf="product.specifications"
                (click)="toggleMenu(1)"
                class="title fx-row fx-row-h-space-between fx-row-v-center mb-0"
                data-cy="product-spec-btn-narrow"
            >
                <h3 class="specifications">Specifications</h3>
                <i-feather name="plus-circle" class="plus color-green"></i-feather>
                <i-feather name="minus-circle" class="minus color-green"></i-feather>
            </div>
            <div
                class="menu-level2"
                id="product-specifications-narrow"
                [innerHTML]="product.specifications | safe: 'html'"
                data-cy="product-spec-body"
            ></div>
        </div>
    </div>

    <div *ngIf="recommendationSlides" id="product-recommendations" data-cy="product-recommendations-parent" class="mt-4 mb-4 px-2 fx-col-container">
        <!-- <h3>Customers also viewed</h3> -->
        <mod-swiper
            *ngIf="recommendationSlides?.length > 0"
            class="normal-swiper-nav"
            [slidesPerView]="slidesPerView"
            swiperTitle="Customers also viewed"
            [spaceBetween]="spaceBetween"
            [breakpoints]="breakpoints"
            [loop]="false"
            containerClass="recommended-slides-container"
            data-cy="product-recommendations-container"
        >
            <product-recommended
                *ngFor="let slide of recommendationSlides"
                class="swiper-slide"
                [heading]="slide?.heading"
                [imageUrl]="slide?.imageUrl"
                [linkUrl]="slide?.linkUrl"
                data-cy="product-recommendation-item"
            >
            </product-recommended>
        </mod-swiper>
    </div>

    <div *ngIf="recentlyViewedSlides" id="product-recently-viewed" class="mt-4 mb-4 px-2 fx-col-container">
        <!-- <h3>Recently viewed</h3> -->
        <mod-swiper
            *ngIf="recentlyViewedSlides?.length > 0"
            class="normal-swiper-nav"
            [slidesPerView]="slidesPerView"
            swiperTitle="Recently viewed"
            [spaceBetween]="spaceBetween"
            [breakpoints]="breakpoints"
            [loop]="false"
            containerClass="recently-viewed-slides-container"
        >
            <product-recommended
                *ngFor="let recent of recentlyViewedSlides"
                class="swiper-slide"
                [heading]="recent?.heading"
                [imageUrl]="recent?.imageUrl"
                [linkUrl]="recent?.linkUrl"
            >
            </product-recommended>
        </mod-swiper>
    </div>
</div>
