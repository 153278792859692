import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { get } from 'lodash';

import { isBrowser } from 'lib/tools';
import { SwiperComponent } from 'lib/components/swiper/swiper.component';
import { SwiperSlide } from 'lib/classes/swiper-slide.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'quotes.component';

@Component({
    selector: 'app-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['./quotes.component.scss'],
})
export class QuotesComponent extends SwiperComponent implements OnChanges, OnInit {
    // Properties: public
    @Input() data: any = [];
    @Input() name: string = '';
    @Input() slideWidth: string = 'auto';
    isBrowser: boolean = isBrowser();
    slides: [] = [];
    getWebpImg: (src: string) => string;

    constructor(webpService: WebpService) {
        super({});
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    ngOnInit() {
        try {
            const processedSlides = this.convertQuotesForSwiper();
            if (this.mode == 'mobile') {
                this.mobileSlides = processedSlides;
            } else {
                this.slides = processedSlides;
            }
            super.ngOnInit();
        } catch (err) {
            console.error(...new ExxComError(621034, scriptName, err).stamp());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            changes.slides = this.convertQuotesForSwiper(this.data);
            changes.slides.currentValue = changes.slides;
            super.ngOnChanges(changes);
        } catch (err) {
            console.error(...new ExxComError(621035, scriptName, err).stamp());
        }
    }

    convertQuotesForSwiper: Function = () => {
        try {
            const newQuotes = this.data.map((quote) => {
                return new SwiperSlide({
                    heading: get(quote, 'person', ''),
                    secondHeading: get(quote, 'company', ''),
                    paragraph: get(quote, 'quote_copy', ''),
                    imageUrl: get(quote, 'imageUrl', '') || 'https://assets.exxactcorp.com/img/exx/misc/Quote.svg',
                });
            });
            return newQuotes;
        } catch (err) {
            console.error(...new ExxComError(621036, scriptName, err).stamp());
        }
    };

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
