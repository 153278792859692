<div id="cms-careers">
  <section id="cms-careers-row" class="fx-row-container fx-row-v-center">
    <div class="col-md-6 col-xs-12">
      <h3>{{ get(entries, "header", "") }}</h3>
      <p>{{ get(entries, "paragraph", "") }}</p>
    </div>
    <div class="col-md-6 col-xs-12">
      <img [src]="getWebpImg(entries?.image?.href)" [alt]="entries?.image?.title" />
    </div>
  </section>

  <div id="grnhse_app"></div>
</div>
