import { Component, OnInit } from '@angular/core';

import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { CmsService } from 'lib/services/cms.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-reference-architecture.component';

@Component({
    selector: 'app-cms-reference-architecture',
    templateUrl: './cms-reference-architecture.component.html',
    styleUrls: ['./cms-reference-architecture.component.scss'],
})
export class CmsReferenceArchitectureComponent extends ExxComComponentClass implements OnInit {
    // Dependencies
    cmsService: CmsService;

    // Properties: public
    entry: any = [];
    getWebpImg: (src: string) => string;

    constructor(c: CmsService, w: WebpService) {
        super({
            dependencies: {
                cmsService: c,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            const entries = await this.cmsService.getEntries('reference_architecture', { references: ['reference_architecture_pages'] });
            if (entries && entries.length >= 1) {
                this.entry = entries[0];
            }
        } catch (err) {
            console.error(...new ExxComError(456789, scriptName, err).stamp());
        }
    }
}
