<section *ngIf="get(coloredBlockRow, 'colored_block.colored_block', false)" id="colored-block-section">
    <div *ngFor="let row of get(coloredBlockRow, 'colored_block.colored_block', [])">
        <div
            class="container d-flex justify-content-center color-block colored-block container-fluid"
            [ngStyle]="{
                'background-color': get(row, 'exxcom_color_picker', ''),
                'background-repeat': 'no-repeat'
            }"
        >
            <div class="grid partial-grid justify-content-between">
                <div class="g-col-12 g-col-md-6 order-md-2 p-0 img-block-container">
                    <img [src]="getWebpImg(get(row, 'background_design_link.href', ''))" class="block-img-bkg" />
                    <img [src]="getWebpImg(get(row, 'image.href', ''))" class="block-img" />
                </div>
                <div class="g-col-12 g-col-md-6 order-md-1 fx-col-v-center text-content-padding">
                    <h5 class="color-block-label mb-base">
                        {{ get(row, 'label', '') }}
                    </h5>
                    <h2 class="mb-3">{{ get(row, 'headline', '') }}</h2>
                    <div [innerHtml]="get(row, 'copy', '') | safe: 'html'" class="copy-content"></div>
                    <!-- Marketo Form Button -->
                    <ng-container *ngIf="get(row, 'marketo_form_selection.form_selection', '') !== 'no_form'">
                        <button-component
                            data-cy="marketo-form"
                            *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'default_form'"
                            (click)="
                                bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                                    title: dataTitle,
                                    url: dataUrl
                                })
                            "
                            [buttonOptions]="{
                                text: get(row, 'link.title', ''),
                                type: 'tertiary',
                                iconPosition: 'right',
                                iconName: 'chevron-right',
                                class: 'mt-3 '
                            }"
                        ></button-component>
                        <button-component
                            data-cy="marketo-form"
                            *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'other_form'"
                            (click)="
                                bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form_selection.marketo_forms.id', ''), 'edit', {
                                    title: dataTitle,
                                    url: dataUrl
                                })
                            "
                            [buttonOptions]="{
                                text: get(row, 'link.title', ''),
                                type: 'tertiary',
                                iconPosition: 'right',
                                iconName: 'chevron-right',
                                class: 'mt-3 '
                            }"
                        ></button-component>
                    </ng-container>
                    <!-- RouterLink Button -->
                    <button-component
                        *ngIf="get(row, 'link.routerLink', '') !== '' && get(row, 'marketo_form_selection.form_selection', '') === 'no_form'"
                        (click)="buttonLinkRouter(get(row, 'link.routerLink', '/'))"
                        [buttonOptions]="{
                            text: get(row, 'link.title', ''),
                            type: 'tertiary',
                            iconPosition: 'right',
                            iconName: 'chevron-right',
                            class: 'mt-3 '
                        }"
                    ></button-component>
                </div>
            </div>
        </div>
    </div>
</section>
