import { OnInit, Directive } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { calculateReadingTime } from 'lib/tools';

const scriptName = 'cms-blog-homepage-component.class';

@Directive()
export class CmsBlogHomepageComponentClass extends CmsBlogComponentClass implements OnInit {
    calculateReadingTime: (entry: any) => string;

    // Dependencies

    cmsService: CmsService;

    // Properties: public

    activeCategoryName: string;
    featureBlog: any;
    formId: string = '';

    constructor({ dependencies }) {
        super({ dependencies });
        this.type = 'homepage';
        this.calculateReadingTime = calculateReadingTime;
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage(`${this.environment.siteAbbr}_blog_homepage`, {
                references: ['feature_blog', 'reference', 'feature_blog.blog_category'],
            });
            this.initMetaData();
            this.initDefaultCategory();
            this.featureBlog = get(this.entry, 'feature_blog[0]') || {};
        } catch (err) {
            console.error(...new ExxComError(550993, scriptName, err).stamp());
        }
    }

    private initDefaultCategory() {
        try {
            if (get(this.entry, 'reference.length') == 0) {
                return;
            }
            this.activeCategoryName = get(this, 'entry.reference[0].title', '');
        } catch (err) {
            console.error(...new ExxComError(809991, scriptName, err).stamp());
        }
    }

    onChosenTitle(title: string) {
        try {
            this.activeCategoryName = title;
        } catch (err) {
            console.error(...new ExxComError(809919, scriptName, err).stamp());
        }
    }
}
