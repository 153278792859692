import { Inject, Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { contains, eachRecursive, isBrowser, isDevServer, queryParams } from 'lib/tools';
import { each, get, isEmpty } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { RouterService } from 'lib/services/router.service';
import { MetaService } from './meta.service';

const scriptName = 'cms.service';
const stateKeys = {};

let apiService: ApiService;
let metaService: MetaService;
let environment: any;
let routerService: RouterService;
let transferState: TransferState;

@Injectable()
export class CmsService {
    cache: any = {};

    constructor(@Inject('environment') e: any, a: ApiService, m: MetaService, r: RouterService, t: TransferState) {
        try {
            metaService = m;
            environment = e;
            apiService = a;
            routerService = r;
            transferState = t;
        } catch (err) {
            console.error(...new ExxComError(530394, scriptName, err).stamp());
        }
    }

    // CORE

    private getUrl = () =>
        routerService.url.fullPath.includes('category') ? routerService.url.fullPath.split('category')[1] : `/${routerService.url.parts.join('/')}`;

    private async request({
        contentTypeId,
        entryId,
        query,
        limit,
        skip,
        sort,
        references,
        fields,
    }: {
        contentTypeId: string;
        entryId?: string;
        query?: object;
        limit?: number;
        skip?: number;
        sort?: any;
        references?: string[];
        fields?: string[];
    }) {
        try {
            let stateKey: any;
            let transferredRes: any;

            const request = async () => {
                try {
                    const url = [
                        environment.contentstack.baseUrl,
                        `/content_types/${contentTypeId}`,
                        `/entries${entryId ? '/' + entryId : ''}`,
                        `?environment=${environment.contentstack[environment.envAbbr].name}`,
                        '&locale=en-us',
                    ];
                    if (query) {
                        url.push(`&query=${JSON.stringify(query)}`);
                    }
                    if (limit) {
                        url.push(`&limit=${limit}`);
                    }
                    if (skip) {
                        url.push(`&skip=${skip}`);
                    }
                    if (sort && sort.asc) {
                        url.push(`&asc=${sort.asc}`);
                    }
                    if (sort && sort.desc) {
                        url.push(`&desc=${sort.desc}`);
                    }
                    if (references && references.length > 0) {
                        references.forEach((reference: string) => url.push(`&include[]=${reference}`));
                    }
                    if (fields && fields.length > 0) {
                        fields.forEach((field: string) => url.push(`&only[BASE][]=${field}`));
                    }
                    const res = await apiService.get(url.join(''), {
                        useFullUrl: true,
                        httpOptions: {
                            headers: {
                                access_token: environment.contentstack[environment.envAbbr].access_token,
                                api_key: environment.contentstack[environment.envAbbr].api_key,
                            },
                        },
                    });
                    if (!res.success) {
                        throw res;
                    }
                    parseUrls(res.entries);
                    return res;
                } catch (err) {
                    return err;
                }
            };

            const parseUrls = (entries: any[]) => {
                try {
                    if (isEmpty(entries)) {
                        return;
                    }
                    const urlKeys = ['url', 'href']; // If other fields are used for URLs in Contentstack, then those need to be added here
                    eachRecursive(entries, (v: any, k: string, o: any) => {
                        if (!contains(urlKeys, k)) {
                            return;
                        }
                        const urlParts = v ? decodeURIComponent(v).split('?') : [];
                        const urlComponent = urlParts[0];
                        const params = urlParts[1];
                        o.routerLink = urlComponent
                            ? urlComponent.indexOf('http') == 0 || urlComponent.indexOf('/') == 0 // Full URL or already has a leading slash
                                ? urlComponent
                                : `/${urlComponent}`
                            : '';
                        if (o.routerLink.indexOf('.jpg') !== -1 || o.routerLink.indexOf('.jpeg') !== -1 || o.routerLink.indexOf('.png') !== -1) {
                            const dotIndex = o.routerLink.lastIndexOf('.');
                            o.webp = o.routerLink.slice(0, dotIndex) + '.webp';
                        }
                        o.queryParams = !isEmpty(params) ? queryParams.get(params) : {};
                        each(o.queryParams, (v: string, k: string) => (v = decodeURIComponent(v)));
                    });
                } catch (err) {
                    console.error(...new ExxComError(602993, scriptName, err).stamp());
                }
            };

            const getStateKey = () => {
                const stateKeyName = entryId ? entryId : contentTypeId ? contentTypeId : null;
                if (!stateKeyName) {
                    throw new Error('"stateKeyName" is required.');
                }
                let stateKey = stateKeys[stateKeyName];
                if (!stateKey) {
                    stateKey = makeStateKey(stateKeyName);
                }
                return stateKey;
            };

            if (isDevServer() || !transferredRes) {
                // The dev server does not transfer state
                const res = await request();
                if (!res.success) {
                    throw res;
                }
                return res;
            } else {
                stateKey = getStateKey();

                transferredRes = transferState.get(stateKey, !isBrowser() ? 'firstLoad_server' : 'firstLoad_browser');

                if (transferredRes == 'firstLoad_server' || transferredRes == 'firstLoad_browser' || transferredRes == 'subsequentLoads_browser') {
                    const res = await request();
                    if (!res.success) {
                        throw res;
                    }

                    if (transferredRes == 'firstLoad_server') {
                        transferState.set(stateKey, res);
                    } else if (transferredRes == 'firstLoad_browser') {
                        transferState.set(stateKey, 'subsequentLoads_browser');
                    }
                    return res;
                } else {
                    // secondLoad_browser
                    transferState.set(stateKey, 'subsequentLoads_browser');
                    return transferredRes;
                }
            }
        } catch (err) {
            console.error(...new ExxComError(482773, scriptName, err).stamp());
        }
    }

    async getEntries(
        contentTypeId: string,
        {
            references,
            fields,
            url,
            query,
            sort,
            skip,
            searchParams,
        }: {
            references?: string[];
            fields?: string[];
            url?: string;
            query?: any;
            sort?: any;
            skip?: any;
            searchParams?: any;
        } = {}
    ) {
        try {
            if (url?.includes('#_heatmap')) {
                url = url.split('#_heatmap')[0];
            }

            url = this.processSearchUrl(url, searchParams);
            const req: any = { contentTypeId };
            if (references) {
                req.references = references;
            }
            if (fields) {
                req.fields = fields;
            }
            if (query) {
                req.query = query;
            } else if (url) {
                req.query = { url };
            }
            if (sort) {
                req.sort = sort;
            }
            if (skip) {
                req.skip = skip;
            }
            const res = await this.request(req);
            return res.entries || [];
        } catch (err) {
            console.error(...new ExxComError(298121, scriptName, err).stamp());
        }
    }

    async getEntry(contentTypeId: string, { references, fields }: { references?: string[]; fields?: string[] } = {}) {
        try {
            const res = await this.getEntries(contentTypeId, {
                references,
                fields,
            });
            if (get(res[0], 'seo_meta_data')) {
                metaService.add(res[0].seo_meta_data);
            }
            return res[0] || {};
        } catch (err) {
            console.error(...new ExxComError(900101, scriptName, err).stamp());
        }
    }

    // PAGES

    async getPage(
        contentTypeId: string,
        { references, fields, searchParams }: { references?: string[]; fields?: string[]; searchParams?: any } = {}
    ) {
        try {
            const url = this.getUrl();
            const res = await this.getEntries(contentTypeId, {
                url,
                references,
                fields,
                searchParams,
            });
            if (get(res[0], 'seo_meta_data')) {
                metaService.add(res[0].seo_meta_data);
            }
            return res[0] || {};
        } catch (err) {
            console.error(...new ExxComError(629834, scriptName, err).stamp());
        }
    }

    // PARTIALS

    async getPartials(contentTypeId: string, references?: string[]) {
        try {
            const url = this.getUrl();
            return this.getEntries(contentTypeId, { references, url });
        } catch (err) {
            console.error(...new ExxComError(729834, scriptName, err).stamp());
        }
    }

    // BLOG

    async getBlogCategories(contentTypeId: string) {
        try {
            const url = contentTypeId != `${environment.siteAbbr}_blog_category` ? routerService.url.current : null;
            return await this.getEntries(contentTypeId, { url });
        } catch (err) {
            console.error(...new ExxComError(892873, scriptName, err).stamp());
        }
    }

    async getBlogPost(url: string) {
        try {
            const query = { url: url };
            const req = {
                contentTypeId: `${environment.siteAbbr}_blog_post`,
                references: [
                    'blog_category',
                    'blog_content_v3.section.group.tables_optional.table_reference',
                    'blog_content_v3.section.group.tables_optional.table_reference.new_table_format_2.table_group.tables.table_reference',
                    'blog_content_v3.section.group.ad_block_reference',
                ],
                query: query,
            };
            const res = await this.request(req);
            return (res.entries && res.entries[0]) || {};
        } catch (err) {
            console.error(...new ExxComError(902873, scriptName, err).stamp());
        }
    }

    async getBlogPosts(limit?: number, skip?: number) {
        try {
            return await this.searchBlogPosts({}, limit, skip);
        } catch (err) {
            console.error(...new ExxComError(310992, scriptName, err).stamp());
        }
    }

    async getBlogPostsByCategory(name: string, limit?: number, skip?: number) {
        try {
            const req = {
                contentTypeId: `${environment.siteAbbr}_blog_post`,
                query: { blog_category: { $in_query: { title: name } } },
                references: ['blog_category'],
                limit: limit ? limit : 18,
                skip: skip ? skip : 0,
                sort: { desc: 'date' },
            };
            const res = await this.request(req);

            return res.entries;
        } catch (err) {
            console.error(...new ExxComError(482781, scriptName, err).stamp());
        }
    }

    async searchBlogPosts(query: object, limit?: number, skip?: number, isKeyUpEvent?: boolean) {
        try {
            const req = {
                contentTypeId: `${environment.siteAbbr}_blog_post`,
                query: query,
                references: ['blog_category'],
                limit: limit ? limit : 5,
                skip: skip ? skip : 0,
                sort: { desc: 'date' },
            };
            const res = await this.request(req);

            if (isKeyUpEvent !== true && environment.siteAbbr == 'exx') {
                await this.checkBlogCount(res.entries);
            }

            return res.entries;
        } catch (err) {
            console.error(...new ExxComError(539876, scriptName, err).stamp());
        }
    }
    /**
     * @function checkBlogCount
     * @param entries is the entries from content stack for pagination
     * @purpose to track the uid's of each blog and to know the total count of the blogposts for pagination.
     */
    async checkBlogCount(entries: any) {
        const entryList = entries;
        const existingBlogs = await this.findBlog();
        const existingBlogEntries = existingBlogs?.data;
        // Takes entries from content stack and compares them to documents in db

        for (let i = 0; i < entryList.length; i++) {
            if (existingBlogEntries?.length === 0) {
                this.createBlog({
                    uid: entryList[i].uid,
                    category: entryList[i].blog_category[0].title,
                    title: entryList[i].title,
                });
                existingBlogEntries.push({
                    uid: entryList[i].uid,
                    category: entryList[i].blog_category[0].title,
                    title: entryList[i].title,
                });
            }
            let matchingEntry = false;
            for (let j = 0; j < existingBlogEntries.length; j++) {
                if (entryList[i].uid == existingBlogEntries[j].uid) {
                    matchingEntry = true;
                    break;
                }
            }
            if (matchingEntry == false) {
                this.createBlog({
                    uid: entryList[i].uid,
                    category: entryList[i].blog_category[0].title,
                    title: entryList[i].title,
                });
            }
        }
    }
    /**
     * @function createBlog
     * @param field Blog entry data
     * @purpose creates blog entry in database with basic information about the blog (uid, category, title)
     * @returns
     */
    async createBlog(field: Object) {
        try {
            return apiService.post('blog/entry/create', field);
        } catch (err) {
            console.error(...new ExxComError(111119, scriptName, err).stamp());
        }
    }
    /**
     * @function findBlog
     * @returns the blogTracker object
     * @purpose creates a blogTracker object if there is not one, otherwise will return the blogTracker Object
     */
    async findBlog() {
        try {
            const blogRes = await apiService.get('blog/entry/find');
            return blogRes;
        } catch (err) {
            console.error(...new ExxComError(111119, scriptName, err).stamp());
        }
    }

    async searchBlogPostsByTitle(title: string, limit?: number, skip?: number) {
        try {
            const query = {
                $or: [{ title: { $regex: title, $options: 'i' } }, { tags: { $regex: title, $options: 'i' } }],
            };
            return await this.searchBlogPosts(query, limit, skip, true);
        } catch (err) {
            console.error(...new ExxComError(692883, scriptName, err).stamp());
        }
    }

    async searchBlogPostsByTag(tag: string, limit?: number, skip?: number) {
        try {
            const query = { tags: tag };
            return await this.searchBlogPosts(query, limit, skip, true);
        } catch (err) {
            console.error(...new ExxComError(430200, scriptName, err).stamp());
        }
    }

    // CACHE

    /**
     * @function initCache
     * @param {String} contentTypeId - A Contentstack content type ID
     * @param {Array[]} [references] - A list of references to be populated when retrieving entries
     * @returns {Promise<Object[]>} - A cache entry, which is a list of CMS entries
     * @description Initializes JavaScript-based local caching of CMS data.
     * initCache returns the data that was retreived from Contentstack, so it may
     * not be necessary to call getCached. However, if getCached is used instead
     * of the data returned from initCache, initCache still has to be called
     * before getCached.
     */
    async initCache({ contentTypeId, references, searchParams }: { contentTypeId: string; references?: string[]; searchParams?: any }): Promise<any> {
        try {
            const url = this.getUrl();
            const entry = await this.getEntries(contentTypeId, {
                references,
                url: this.processSearchUrl(url, searchParams),
            });
            return entry;
        } catch (err) {
            console.error(...new ExxComError(699884, scriptName, err).stamp());
        }
    }

    /**
     * @function getCached
     * @param {String} contentTypeId - A Contentstack content type ID
     * @returns {Object[]} - A cache entry, which is a list of CMS entries
     * @description Retrieves data that was cached previously via initCache.
     * initCache must be called before calling getCached.
     */
    getCached(contentTypeId: string): any {
        try {
            if (!this.isCached(contentTypeId)) {
                throw new Error('Cache must be initialized with initCache before data is retrieved.');
            }
            const cached = this.cache[this.getCacheKey(contentTypeId)];
            return cached;
        } catch (err) {
            console.error(...new ExxComError(699884, scriptName, err).stamp());
        }
    }

    /**
     * @function getCacheKey
     * @param {String} contentTypeId - A Contentstack content type ID
     * @description Generates a cache key.
     */
    private getCacheKey(contentTypeId: string): string {
        try {
            return `${contentTypeId}__${this.getUrl()}`;
        } catch (err) {
            console.error(...new ExxComError(501988, scriptName, err).stamp());
        }
    }

    /**
     * @function isCached
     * @param {String} contentTypeId - A Contentstack content type ID
     * @description Accesses cache to check if we have data stored.
     */
    private isCached(contentTypeId: string): boolean {
        try {
            return !isEmpty(get(this.cache, this.getCacheKey(contentTypeId)));
        } catch (err) {
            console.error(...new ExxComError(920392, scriptName, err).stamp());
        }
    }

    /**
     * @function processSearchUrl
     * @param {String} url - A Contentstack url for an entry
     * @description Adds sarch params to url, else returns the url
     */
    private processSearchUrl(url: string, searchParams?: any) {
        if (url == '/search' && searchParams) {
            const { q } = searchParams;
            if (q) {
                url = `/search?q=${q.split('%20').join('-')}`;
            }
        }
        return url;
    }
    /**
     * @function checkEntryExistence
     * @param {Object} entry - A contentstack entry - preferably an entry that populates the entire page, not a part of it.
     * @description The purpose of this function is to take in an entry object from content stack. If the entry is empty, we 404.
     * otherwise we return the entry for its intended usage. ONLY use with pages that rely on a single entry. Calling this function
     * on a single entry (such as a single partial in a large collection of them on a page), we will be 404ing the page when there
     * IS FOUND CONTENT on the page.
     * The intention of this function is simply to 404 a page that is dependent on a single entry if it should fail.
     */
    checkEntryExistence(entry: Object) {
        if (isEmpty(entry)) {
            routerService.navigate(['/404']);
        } else {
            return entry;
        }
    }

    async getAllCategoryPages() {
        try {
            const pages = await apiService.get('categorypage');
            return pages;
        } catch (err) {
            console.error(...new ExxComError(920395, scriptName, err).stamp());
        }
    }

    async getAllMarketoforms() {
        try {
            const pages = await apiService.get('marketoform');
            return pages;
        } catch (err) {
            console.error(...new ExxComError(920396, scriptName, err).stamp());
        }
    }
}
