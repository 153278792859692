import { Component, Input, ViewEncapsulation } from '@angular/core';

type featuredContentType = {
    featuredContentType: string;
    featuredContentLink: string;
    featuredContentImage: string;
    featuredContentTitle: string;
    featuredContentCategoryTitle: string;
    featuredContentArticle: string;
    featuredContentDate: string;
    featuredContentReadTime: string;
};

@Component({
    selector: 'exx-featured-card',
    templateUrl: './exx-featured-card.component.html',
    styleUrls: ['./exx-featured-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExxFeaturedCardComponent {
    @Input() featuredContentData: featuredContentType;

    constructor() {}
}
