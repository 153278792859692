<section
    [ngStyle]="{
        'background-color': get(tableRow, 'exxcom_color_picker', ''),
        'background-repeat': 'no-repeat',
        'margin-bottom': get(tableRow, 'exxcom_color_picker', '').length > 0 ? '24px' : ''
    }"
    class="non-modal-table-styles"
>
    <div *ngIf="get(tableRow, 'old_or_new_table', '') != 'new_table'">
        <div>
            <div class="container-padless d-flex flex-column align-items-center">
                <div class="category-top-title d-flex justify-content-center pb-2">
                    <h2 *ngIf="get(tableRow, 'headline', '')" class="col-md-7 text-center">
                        {{ get(tableRow, 'headline', '') }}
                    </h2>
                </div>
                <div class="px-2" id="tables" [innerHTML]="get(tableRow, 'table_content', '') | safe: 'html'"></div>
            </div>
        </div>
    </div>

    <div class="container new-table" *ngIf="get(tableRow, 'old_or_new_table', '') == 'new_table'">
        <ng-container *ngIf="activatePreview; then tablePreview"></ng-container>
        <div [class.hidden]="isTableOpen || activatePreview" class="new-table-opacity">
            <ng-container *ngIf="tableType == 'tabbed_table'; then tabbedTable; else partTwo"></ng-container>
            <ng-template #partTwo>
                <ng-container *ngIf="tableType == 'stacked_table'; then stackedTable; else tabbedAndStackedTable"></ng-container>
            </ng-template>
        </div>
    </div>
</section>
<ng-template #tablePreview>
    <div
        id="preview-table-container"
        class="d-flex flex-column align-items-center"
        [class.invalid-preview]="tabbedAndStackedLength < 2 && tableType == 'tabbed_and_stacked'"
    >
        <h2>{{ get(tableRow, 'headline', '') }}</h2>
        <button-component
            class="preview-button"
            (click)="openTable(tableTypeRef); isTableOpen = true"
            [buttonOptions]="{
                text: get(tableRow, 'button_text', ''),
                type: 'primary',
                iconPosition: 'right',
                iconName: 'chevron-right'
            }"
        ></button-component>
        <div class="preview-table">
            <img src="https://resources.exxactcorp.com/resources/exx/assets/images/table_preview.svg" />
        </div>
        <div class="preview-table-line"></div>
    </div>
</ng-template>

<ng-template #stackedTable>
    <div>
        <div class="d-flex justify-content-center headline" *ngIf="!isTableOpen && get(tableRow, 'headline', '').length > 0">
            <h2>{{ get(tableRow, 'headline', '') }}</h2>
        </div>
        <div class="modal-container">
            <div [class.scroll-container]="activatePreview">
                <span
                    class="d-flex align-items-center close-icon"
                    aria-label="Close"
                    (click)="isTableOpen = false; bsModalRef.hide()"
                    *ngIf="isTableOpen"
                >
                    <i-feather name="x"></i-feather>
                </span>

                <div class="two-table-container" [class.stacked]="tableType == 'stacked_table'" [class.tabbed]="tableType == 'tabbed_table'">
                    <table>
                        <thead class="main-table-head">
                            <tr id="master-header">
                                <ng-container
                                    *ngFor="let masterHeaderColumn of get(tableRow, 'new_table_format_2.table_group[0].master_header.rows[0]')"
                                >
                                    <th *ngIf="masterHeaderColumn.length != 0">
                                        <div>
                                            <h6>{{ masterHeaderColumn }}</h6>
                                        </div>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tr class="empty-row"></tr>

                        <ng-container *ngFor="let table of get(tableRow, 'new_table_format_2.table_group[0].tables'); let last = last">
                            <tr class="subtab">
                                <th [attr.colspan]="30">
                                    {{ table.table_reference[0].tab_name }}
                                </th>
                            </tr>

                            <ng-container *ngFor="let tableRow of table.table_reference[0].table.rows; let i = index" class="content">
                                <tr>
                                    <ng-container *ngFor="let tableCell of tableRow">
                                        <td *ngIf="tableCell.length > 0" [innerHTML]="tableCell | safe: 'html'"></td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                            <tr class="empty-row" *ngIf="!last">
                                <th [attr.colspan]="30">&nbsp;</th>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div
                    *ngIf="get(tableRow, 'new_table_format_2.table_group[0].footnote').length > 0"
                    class="table-footnote"
                    [innerHTML]="get(tableRow, 'new_table_format_2.table_group[0].footnote')"
                ></div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tabbedTable>
    <div class="d-flex justify-content-center headline" *ngIf="!isTableOpen && get(tableRow, 'headline', '').length > 0">
        <h2>{{ get(tableRow, 'headline', '') }}</h2>
    </div>
    <div class="modal-container">
        <div [class.scroll-container]="activatePreview">
            <span
                class="d-flex align-items-center close-icon"
                aria-label="Close"
                (click)="isTableOpen = false; bsModalRef.hide()"
                *ngIf="isTableOpen"
            >
                <i-feather name="x"></i-feather>
            </span>

            <div id="tab-container" class="d-flex flex-row" appDragToScroll>
                <div
                    *ngFor="let tab of get(tableRow, 'new_table_format_2.table_group[0].tables'); let i = index"
                    ngClass="{{ tableIdentifier + '-tab' + ' tab' }}"
                    [class.narrow]="isNarrow"
                    [class.active-tab]="i == currentTableIndex"
                    (click)="setTable(i)"
                >
                    <div class="d-flex flex-column align-items-center">
                        <span class="tab-text"> {{ tab.table_reference[0]?.tab_name }}</span>
                        <div class="tab-underline" *ngIf="isNarrow || (!isNarrow && tabWidthExceeded) || isBlogPage"></div>
                    </div>
                </div>
            </div>
            <div id="tab-line" *ngIf="!isNarrow && !tabWidthExceeded && !isBlogPage">
                <span class="tab-indicator" [style.transform]="transformAmount" [style.width]="activeTabWidthString"></span>
            </div>

            <div class="two-table-container" [class.stacked]="tableType == 'stacked_table'" [class.tabbed]="tableType == 'tabbed_table'">
                <table>
                    <thead class="main-table-head">
                        <tr id="master-header">
                            <ng-container
                                *ngFor="
                                    let masterHeaderColumn of get(
                                        tableRow,
                                        'new_table_format_2.table_group[0].tables[' + currentTableIndex + '].table_reference[0].table_header.rows[0]'
                                    )
                                "
                            >
                                <th *ngIf="masterHeaderColumn.length != 0">
                                    <div>
                                        <h6>{{ masterHeaderColumn }}</h6>
                                    </div>
                                </th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let table of activeTable; let i = index; let last = last">
                            <tr>
                                <ng-container *ngFor="let tableCell of table">
                                    <td *ngIf="tableCell.length != 0" [innerHTML]="tableCell | safe: 'html'"></td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div
                *ngIf="get(tableRow, 'new_table_format_2.table_group[0].footnote')"
                class="table-footnote"
                [innerHTML]="get(tableRow, 'new_table_format_2.table_group[0].footnote')"
            ></div>
        </div>
    </div>
</ng-template>

<ng-template #tabbedAndStackedTable>
    <div [class.invalid-table]="tabbedAndStackedLength < 2 && tableType == 'tabbed_and_stacked'">
        <div class="d-flex justify-content-center headline" *ngIf="!isTableOpen && get(tableRow, 'headline', '').length > 0">
            <h2>{{ get(tableRow, 'headline', '') }}</h2>
        </div>
        <div class="modal-container">
            <div [class.scroll-container]="activatePreview">
                <span
                    class="d-flex align-items-center close-icon"
                    aria-label="Close"
                    (click)="isTableOpen = false; bsModalRef.hide()"
                    *ngIf="isTableOpen"
                >
                    <i-feather name="x"></i-feather>
                </span>
                <div id="tab-container" class="d-flex flex-row" appDragToScroll>
                    <div
                        *ngFor="let tab of get(tableRow, 'new_table_format_2.table_group'); let i = index"
                        (click)="setTable(i)"
                        ngClass="{{ tableIdentifier + '-tab' + ' tab' }}"
                        [class.narrow]="isNarrow"
                        [class.active-tab]="i == currentTableIndex"
                    >
                        <div class="d-flex flex-column align-items-center">
                            <span class="tab-text"> {{ tab.tabbed_and_stacked_tab_name }}</span>
                            <div class="tab-underline" *ngIf="isNarrow || (!isNarrow && tabWidthExceeded) || isBlogPage"></div>
                        </div>
                    </div>
                </div>
                <div id="tab-line" *ngIf="!isNarrow && !tabWidthExceeded && !isBlogPage">
                    <span class="tab-indicator" [style.transform]="transformAmount" [style.width]="activeTabWidthString"></span>
                </div>
                <div class="two-table-container" [class.stacked]="tableType == 'stacked_table'" [class.tabbed]="tableType == 'tabbed_table'">
                    <table>
                        <thead class="main-table-head">
                            <tr id="master-header">
                                <ng-container
                                    *ngFor="
                                        let masterHeaderColumn of get(
                                            tableRow,
                                            'new_table_format_2.table_group[' + currentTableIndex + '].master_header.rows[0]'
                                        )
                                    "
                                >
                                    <th>
                                        <div>
                                            <h6>{{ masterHeaderColumn }}</h6>
                                        </div>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="empty-row-small"></tr>
                            <ng-container *ngFor="let table of activeTable; let last = last">
                                <tr class="subtab">
                                    <th [attr.colspan]="30">
                                        {{ table.table_reference[0].tab_name }}
                                    </th>
                                </tr>

                                <ng-container *ngFor="let tableRow of table.table_reference[0].table.rows; let i = index" class="content">
                                    <tr>
                                        <td *ngFor="let tableCell of tableRow" [innerHTML]="tableCell | safe: 'html'"></td>
                                    </tr>
                                </ng-container>
                                <tr class="empty-row" *ngIf="!last">
                                    <th [attr.colspan]="30">&nbsp;</th>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div
                    class="table-footnote"
                    innerHTML="{{ get(tableRow, 'new_table_format_2.table_group[' + currentTableIndex + '].footnote', '') }}"
                ></div>
            </div>
        </div>
    </div>
</ng-template>
