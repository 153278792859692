<div id="cms-blog-homepage" data-cy="cms-blog-homepage">
    <section id="cms-blog-hompage-featured" class="fx-row-container fx-wrap fx-row-h-left" data-cy="cms-blog-homepage-featured">
        <h4 class="bold">Featured Post</h4>

        <div class="feature-post-container">
            <div class="image-container fx-col col-sm-6 p-0">
                <img
                    *ngIf="!featureBlog?.feature_image?.url"
                    class="cms-blog-category-card-img"
                    alt="Blog MissingImage"
                    [src]="
                        getWebpImg(
                            'https://images.contentstack.io/v3/assets/bltb654d1b96a72ddc4/blteba795fc63fd91c1/5e56f34b9437900dbf81e21b/Blog-MissingImage.jpg'
                        )
                    "
                />
                <img
                    *ngIf="featureBlog?.feature_image?.url"
                    [src]="getWebpImg(featureBlog.feature_image.url)"
                    [alt]="featureBlog.feature_image.title"
                    data-cy="cms-blog-homepage-featured-img"
                />
            </div>

            <div id="cms-blog-hompage-featured-review" class="fx-col fx-col-v-center col-sm-6">
                <div>
                    <div id="cms-blog-hompage-featured-category">
                        {{ get(featureBlog, 'blog_category[0].title', '') }}
                    </div>
                    <a [routerLink]="featureBlog?.url">
                        <h3>{{ featureBlog?.title }}</h3>
                    </a>
                </div>
                <div class="date-time d-flex flex-row justify-content-between">
                    <span>{{ reformatDate(featureBlog) }}</span>
                    <div class="fx-row fx-row-v-center reading-time">
                        <mat-icon *ngIf="featureBlog?.article" svgIcon="clock" class="pr-1"></mat-icon>
                        <span>{{ featureBlog | readTimeBlog }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-cms-blog-category [name]="activeCategoryName" [showHeader]="false"></app-cms-blog-category>
</div>
