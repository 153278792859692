import { environment as env } from './shared_env';

export const environment = {
    ...env,
    production: false,
    envAbbr: 'sb',
    backendApiUrl: 'http://localhost:65220',
    clientUrl: 'http://localhost:65221',
    testEnv: true,
};
