<section
  *ngIf="!isEmpty(get(fullWidthImageAndTextRow.image_and_text, 'full_width_image_and_text', ''))"
  class="full-width-image-and-text p-0"
>
  <div
    *ngFor="
      let full_width_image_and_text of get(fullWidthImageAndTextRow.image_and_text, 'full_width_image_and_text', '')
    "
    [ngStyle]="{
      'background-color': get(fullWidthImageAndTextRow.image_and_text, 'exxcom_color_picker', '#FFF'),
      'background-repeat': 'no-repeat'
    }"
  >
    <div
      class="grid container-full-width"
      [class.full-width-image-and-right-text]="full_width_image_and_text.style === 'Image on the left'"
      [class.full-width-image-and-left-text]="full_width_image_and_text.style === 'Image on the right'"
    >
      <div
        class="g-col-lg-6 g-col-12 px-0 image-section"
        [class.order-1]="full_width_image_and_text.style === 'Image on the right' && !isNarrow"
        [class.order-0]="full_width_image_and_text.style === 'Image on the left' && !isNarrow"
        [ngStyle]="{
          'background-image': 'url(' + get(full_width_image_and_text, 'image.href', '') + ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }"
      >
        <img [src]="get(full_width_image_and_text, 'image.href', '')" />
      </div>
      <div
        class="g-col-lg-6 g-col-12 text-section d-flex flex-column justify-content-center"
        [class.order-0]="full_width_image_and_text.style === 'Image on the right' && !isNarrow"
        [class.order-1]="full_width_image_and_text.style === 'Image on the left' && !isNarrow"
      >
        <h5>{{ get(full_width_image_and_text, "label", "") }}</h5>
        <h2>{{ get(full_width_image_and_text, "header", "") }}</h2>

        <div
          *ngIf="get(full_width_image_and_text, 'text', '')"
          [innerHTML]="get(full_width_image_and_text, 'text', '') | safe: 'html'"
          class="full-width-image-and-text-injected-content"
        ></div>

        <div class="d-flex flex-row" *ngIf="get(full_width_image_and_text, 'link.title', '')">
          <!-- Marketo Button -->

          <ng-container
            *ngIf="get(full_width_image_and_text, 'marketo_form_selection.form_selection', '') !== 'no_form'"
          >
            <button
              *ngIf="get(full_width_image_and_text, 'marketo_form_selection.form_selection', '') == 'default_form'"
              class="button-primary mt-2"
              data-cy="marketo-form"
              (click)="
                bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                  title: dataTitle,
                  url: dataUrl
                })
              "
            >
              {{ get(full_width_image_and_text, "link.title", "") }}
              <mat-icon svgIcon="chevron_right"></mat-icon>
            </button>
            <button
              *ngIf="get(full_width_image_and_text, 'marketo_form_selection.form_selection', '') == 'other_form'"
              class="button-primary mt-2"
              data-cy="marketo-form"
              (click)="
                bootstrapMarketoService.openBootstrapMarketoDialog(
                  get(full_width_image_and_text, 'marketo_form_selection.marketo_forms.id', ''),
                  'edit',
                  {
                    title: dataTitle,
                    url: dataUrl
                  }
                )
              "
            >
              {{ get(full_width_image_and_text, "link.title", "") }}
              <mat-icon svgIcon="chevron_right"></mat-icon>
            </button>
          </ng-container>

          <!-- RouterLink Button -->
          <button
            *ngIf="
              get(full_width_image_and_text, 'link.routerLink', '') !== '' &&
              get(full_width_image_and_text, 'marketo_form_selection.form_selection', '') == 'no_form'
            "
            class="button-primary"
            (click)="buttonLinkRouter(get(full_width_image_and_text, 'link.routerLink', '/'))"
          >
            {{ get(full_width_image_and_text, "link.title", "") }}
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<hr
  *ngIf="
    get(fullWidthImageAndTextRow, 'image_and_text.split_line', '') &&
    !isEmpty(get(fullWidthImageAndTextRow.image_and_text, 'full_width_image_and_text', ''))
  "
  class="m-0"
/>
