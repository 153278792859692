import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';

@Component({
    selector: 'app-configurator-dialog',
    templateUrl: './configurator-dialog.component.html',
    styleUrls: ['./configurator-dialog.component.scss'],
})
export class ConfiguratorDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }

    ngOnInit() {}
}
