import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';

@Component({
    selector: 'emli-table-inner-content',
    templateUrl: './emli-table-inner-content.component.html',
    styleUrls: ['./emli-table-inner-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmliTableInnerContentComponent extends ExxComComponentClass implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter<any>();
    constructor(breakpointObserver: BreakpointObserver) {
        super({ dependencies: { breakpointObserver } });
    }

    ngOnInit() {}

    closeFunction = () => {
        this.close.emit();
    };
}
