<div id="footer">
    <div class="footer-container">
        <div *ngIf="!isNarrow" data-cy="exx-footer-desktop">
            <section id="footer-row-1" class="accordion">
                <div class="logo">
                    <img width="80px" src="https://images.exxactcorp.com/img/exx/misc/x-emblem.svg" alt="logo" />
                </div>
                <div class="footer-menu-header" data-cy="exx-footer-menu-header" *ngFor="let group of entry.sections; index as i">
                    <div class="copy">
                        <span>{{ group.section_title }}</span>
                    </div>
                    <div>
                        <div class="footer-menu-links" *ngFor="let subsection of group.group">
                            <a data-cy="footer-internal-link-desktop" *ngIf="!subsection.is_external_link" [routerLink]="subsection.link.href">{{
                                subsection.link?.title
                            }}</a>

                            <a
                                data-cy="footer-external-link-desktop"
                                *ngIf="subsection.is_external_link"
                                [href]="get(subsection, 'link.href', '/')"
                                >{{ subsection.link?.title }}</a
                            >
                        </div>
                    </div>
                </div>
                <div class="social-links">
                    <div class="fx-row fx-row-v-center fx-row-h-left">
                        <a id="linkedin-icon" href="https://www.linkedin.com/company/exxactcorp" target="_blank">
                            <mat-icon svgIcon="linkedin"></mat-icon>
                        </a>
                        <a id="twitter-icon" href="https://twitter.com/exxactcorp" target="_blank" rel="noreferrer">
                            <mat-icon svgIcon="x-logo"></mat-icon>
                        </a>
                        <a id="facebook-icon" href="https://www.facebook.com/exxactcorp" target="_blank" rel="noreferrer">
                            <mat-icon svgIcon="facebook"></mat-icon>
                        </a>
                    </div>
                    <div id="sign-up-desk-container">
                        <div id="mobile-sign-up-row">
                            <div class="fx-col">
                                <div class="sign-up-desk-icon">
                                    <i-feather name="mail"></i-feather>
                                </div>
                                <span>Sign up for our newsletter.</span>
                                <button-component
                                    class="button-signup"
                                    (click)="bootstrapMarketoService.openBootstrapMarketoDialog(marketoFormId, 'edit')"
                                    [buttonOptions]="{
                                        text: 'Sign up',
                                        iconPosition: 'right',
                                        iconName: 'chevron-right',
                                        type: 'primary',
                                        unsetWidth: true
                                    }"
                                ></button-component>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div *ngIf="isNarrow" data-cy="exx-footer-mobile">
            <section id="footer-row-1" class="accordion">
                <div class="logo">
                    <img width="80px" src="https://images.exxactcorp.com/img/exx/misc/x-emblem.svg" alt="logo" />
                </div>
                <div class="footer-menu-header accordion-item" *ngFor="let group of entry.sections; index as i">
                    <div class="accordion-header" id="{{ 'heading-' + i }}">
                        <div
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse-' + i"
                            aria-expanded="false"
                            [attr.aria-controls]="'collapse-' + i"
                        >
                            <span>{{ group.section_title }}</span>
                            <div class="icon-container">
                                <mat-icon class="mat-icon plus-icon" svgIcon="plus" role="img" aria-hidden="true"></mat-icon>
                            </div>
                        </div>
                    </div>

                    <div id="{{ 'collapse-' + i }}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading-' + i">
                        <div class="accordion-body">
                            <ng-container *ngFor="let subsection of group.group">
                                <a data-cy="footer-internal-link-mobile" *ngIf="!subsection.is_external_link" [routerLink]="subsection.link.href">{{
                                    subsection.link?.title
                                }}</a>

                                <a
                                    data-cy="footer-external-link-mobile"
                                    *ngIf="subsection.is_external_link"
                                    [href]="get(subsection, 'link.href', '/')"
                                    >{{ subsection.link?.title }}</a
                                >
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="social-links">
                    <div class="fx-row fx-row-v-center fx-row-h-left">
                        <a id="linkedin-icon" href="https://www.linkedin.com/company/exxactcorp" target="_blank">
                            <mat-icon svgIcon="linkedin"></mat-icon>
                        </a>
                        <a id="twitter-icon" href="https://twitter.com/exxactcorp" target="_blank" rel="noreferrer">
                            <mat-icon svgIcon="x-logo"></mat-icon>
                        </a>
                        <a id="facebook-icon" href="https://www.facebook.com/exxactcorp" target="_blank" rel="noreferrer">
                            <mat-icon svgIcon="facebook"></mat-icon>
                        </a>
                    </div>
                </div>
            </section>
        </div>

        <div id="sign-up-mobile-container" (click)="bootstrapMarketoService.openBootstrapMarketoDialog(marketoFormId, 'edit')">
            <div id="mobile-sign-up-row">
                <div class="fx-row fx-row-h-center fx-row-v-center pb-1">
                    <i-feather class="sign-up-desk-icon" name="mail"></i-feather>
                    <span class="sign">Sign up for our newsletter.</span>
                    <i-feather name="chevron-right" class="chevron-blue"></i-feather>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-container">
        <div class="footer-bottom">
            <div></div>
            <div class="privacy-policy">
                <span class="copy">
                    © {{ currentYear }} Exxact Corporation
                    <span class="vertical-bar mx-1">|</span>
                    <a routerLink="/Privacy" class="link-gray desk-privacy">Privacy</a>
                    <span class="vertical-bar mx-1">|</span>
                    <a id="termly-consent-preferences" href="#" class="link-gray" onclick="window.displayPreferenceModal();return false;"
                        >Consent Preferences</a
                    >
                    <span class="vertical-bar mx-1">|</span>
                    <a routerLink="/Cookies" class="link-gray">Cookies</a>
                </span>
            </div>
        </div>
    </div>
</div>
