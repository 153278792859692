/* eslint-disable max-len */
import { jsPDF } from 'jspdf';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, Inject, OnInit, Renderer2, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfigurationProductData } from 'lib/services/configuration-product/configuration-product.interface';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { SavedConfigurationService } from 'lib/services/saved-configuration/saved-configuration.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { ConfigurationData, OptimizedConfigurations } from 'lib/services/configuration/configuration.interface';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { floor, get, groupBy, indexOf, min, size, cloneDeep } from 'lodash';
import { isBrowser } from 'lib/tools';
import { MarketoDialogComponent } from 'lib/components/marketo-dialog/marketo-dialog.component';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { MetaService } from 'lib/services/meta.service';
import { MarketoService } from 'lib/services/marketo.service';
import { ToastrService } from 'ngx-toastr';
import { ConfiguratorErrorComponent } from 'lib/components/configurator-error/configurator-error.component';
import { ConfiguratorSharedRuleService } from 'lib/services/configuration/configurator-shared-rule.service';
import { ConfigurationDataV2 } from 'lib/services/configuration/configuration-v2.interface';

const scriptName = 'configurator.component';

interface SharedRule {
    _id?: string;
    configuration: string;
    categories: Set<string>;
    name: string;
    rule?: object;
    isSaved?: boolean;
    delete?: boolean;
}

@Component({
    selector: 'app-configurator',
    templateUrl: './configurator.component.html',
    styleUrls: ['./configurator.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfiguratorComponent extends ExxComComponentClass implements OnInit {
    @HostListener('window:scroll', [])
    @HostListener('touchmove', [])
    @HostListener('touchstart', [])
    onTouchMove() {
        if (!this.isBrowser || !this.isNarrow) {
            return;
        }
        // In chrome and some browser scroll is given to body tag

        const scrollPos: number = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        const footerPos = document.documentElement.scrollHeight - 676; // 676 accounts for footer height in mobile
        this.isVisible = scrollPos < footerPos;
    }

    getWebpImg: (src: string) => string;
    categories: FormGroup;
    categoryMap: Map<string, Object> = new Map();
    data: any = {};
    environment: any;
    configFormattedData: any;
    productsForm: FormGroup;
    maxForm: FormGroup;
    minForm: FormGroup;
    modalRef: BsModalRef;
    setsOfForm: FormGroup;
    formId: string;
    isBrowser: boolean = isBrowser();
    isSaving: boolean = false;
    isVisible: boolean = false;
    filteredProducts: ConfigurationProductData[] = [];
    errors: Object = {};
    modalService: BsModalService;
    configuration: ConfigurationData = {};
    activeReference: string = 'good';
    selectedPane = false;
    configurationUrlData;
    contentDataURL: string = '';
    categoryKeys: string[] = [
        'Platform',
        'CPU',
        'CPU Heatsink',
        'Memory',
        'M.2 Drive',
        'U.2/U.3 NVME Drive',
        'Hard Drive / SSD',
        'Optical Drive',
        '5.25” Bay',
        'GPU',
        'NVIDIA NVLink',
        'Video Devices',
        'Controller Card',
        'Battery Backup',
        'Network Card ',
        'Network Cable',
        'I/O Module – Networking',
        'PCI-Express Storage Card',
        'Sound Cards',
        'Trusted Platform Module (TPM)',
        'Riser Card',
        'Power Supply',
        'Cables',
        'Mounting Rails',
        'OS',
        'Software',
        'Warranty',
        'Monitor',
        'Peripherals',
        'Power Protection',
        'NAS',
    ];
    driveKeys: string[] = ['M.2 Drive', 'U.2/U.3 NVME Drive', 'Hard Drive / SSD'];

    // categoryDescriptions = new Map([
    //   ['Platform', ''],
    //   ['CPU', 'Processors increase the efficiency of your system by processing and delegating tasks from applications.'],
    //   ['CPU Heatsink', ''],
    //   ['Memory', 'More memory increases the size and quantity of high frequency cached files your system has access to.'],
    //   ['M.2 Drive', ''],
    //   ['U.2/U.3 NVME Drive', 'Small NVMe solid state drive with fast access over PCIe.'],
    //   ['Hard Drive / SSD', 'Accelerate applications and boost game performance.'],
    //   ['Optical Drive', ''],
    //   ['5.25” Bay', ''],
    //   ['GPU', 'Accelerate applications and boost game performance.'],
    //   ['NVIDIA NVLink', 'Faster communication between NVIDIA GPUs. Select the pair of GPUs you want to have NVLinks.'],
    //   ['Controller Card', ''],
    //   ['Battery Backup', ''],
    //   ['Network Card ', ''],
    //   ['I/O Module – Networking', ''],
    //   ['PCI-Express Storage Card', ''],
    //   ['Trusted Platform Module (TPM)', ''],
    //   ['Riser Card', ''],
    //   ['Power Supply', ''],
    //   ['Cables', ''],
    //   ['Mounting Rails', ''],
    //   ['OS', 'Select your operating system and a hard drive / SSD'],
    //   ['Software', ''],
    //   ['Warranty', ''],
    //   ['Monitor', ''],
    //   ['Peripherals', ''],
    //   ['Power Protection', '']
    // ]);

    renderedCategories: string[];
    optimizedConfigurations: OptimizedConfigurations = {
        good: { selectedSpecs: {} },
        better: { selectedSpecs: {} },
        best: { selectedSpecs: {} },
    };
    // populate something similar to "name" array to have names of product categories
    names: string[] = [];
    specTotals = {
        wattage: null,
        btu: null,
        memory: null,
    };
    gpuData = {
        isSupported: null,
        totalLinks: 0,
        nvLinkMessage: null,
        maxSlots: null,
        slotWidth: null,
        slotLimits: null,
        availableSlots: 0,
    };

    minTotalMemory = null;

    sharedRules: SharedRule[] = [];
    sharedRulesErrors: {
        type: string;
        categories: Set<string>;
    }[] = [];
    // Temporary solution to implementing product incompatibilities (For Windows + Additional software)
    // Eventually this should be replaced with something more scalable, such as adding an "incompatibility" field to the product data
    softwareDisabled = false;
    osDisabled = false; // Prevent user from selecting OS if no drives are selected
    networkingData = {
        activeNetworkCard: null,
        activeConnectorType: '',
        activeCableProductLines: [],
        numPorts: [], // This is in an array format to match how mat-select uses forEach to construct the UI
    };

    bottomTabToggled = true;

    // rightFormActive is used to hide the right pane marketo form before loading a new form via 'download pdf' button
    //  for some reason having two forms active at once causes some bugs/issues
    rightFormActive = true;
    // doneLoading is used to hide the right pane during initial load, which helps prevent the visual bug of the page
    //  showing both panels at once
    doneLoading = false;
    delayedDoneLoading = false;

    currentDate = new Date(Date.now()).toLocaleString('en');

    variantWhitelist = ['ANS', 'SCH', 'CPS'];

    private xsBreakpointObserver: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        private formBuilder: FormBuilder,
        private configuratorService: ConfiguratorService,
        private sharedRuleService: ConfiguratorSharedRuleService,
        private savedConfigurationService: SavedConfigurationService,
        private webstoreProductService: WebstoreProductService,
        private dialog: MatDialog,
        private routerService: RouterService,
        private marketoService: MarketoService,
        private metaService: MetaService,
        modalService: BsModalService,
        private toastr: ToastrService,
        webpService: WebpService,
        private renderer: Renderer2
    ) {
        super({
            dependencies: {
                breakpointObserver,
                dialog,
                routerService,
                marketoService,
                metaService,
                environment,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
        this.modalService = modalService;
        window.onbeforeunload = () => {
            this.doneLoading = false;
        };
    }

    async ngOnInit() {
        this.addFixedClasses();
        this.sendBreadcrumb();
        this.initForm();
        await this.initConfiguration().then(() => {
            this.doneLoading = true;
            setTimeout(() => {
                this.delayedDoneLoading = true;
            }, 500);
        });
        if (this.isBrowser && this.isNarrow) {
            this.isVisible = true;
        }
    }

    ngOnDestroy() {
        const categories = Object.keys(this.errors);
        categories.forEach((category) => {
            if (this.errors[category]) {
                this.toastr.clear(this.errors[category]);
            }
        });
        this.removeFixedClasses();
    }

    getBaseUrl() {
        if (this.routerService.baseUrl == 'http://localhost:8080') return 'http://localhost:4200';
        else return this.routerService.baseUrl;
    }

    private async sendBreadcrumb() {
        try {
            if (!this.isBrowser || !localStorage) {
                return;
            }
            await this.marketoService.init();

            const breadcrumb = localStorage.getItem('breadcrumb');
            if (breadcrumb && breadcrumb.includes(this.routerService.url.parts[0])) {
                this.marketoService.sendConfigurator(breadcrumb + '/configurator');
            } else {
                this.marketoService.sendConfigurator(breadcrumb + this.routerService.url.parts.join(','));
            }
        } catch (err) {
            console.error(...new ExxComError(923842, scriptName, err).stamp());
        }
    }

    private addFixedClasses() {
        const headerElement = document.getElementById('header');
        const footerElement = document.getElementById('footer');
        if (headerElement) {
            this.renderer.addClass(headerElement, 'fixed-header');
        }
        if (footerElement) {
            this.renderer.addClass(footerElement, 'fixed-footer');
        }
    }

    private removeFixedClasses() {
        const headerElement = document.getElementById('header');
        const footerElement = document.getElementById('footer');
        if (headerElement) {
            this.renderer.removeClass(headerElement, 'fixed-header');
        }
        if (footerElement) {
            this.renderer.removeClass(footerElement, 'fixed-footer');
        }
    }

    private initForm() {
        try {
            this.productsForm = this.formBuilder.group({
                productCategory: ['', { validators: [Validators.required] }],
                productName: [''],
                productCost: ['', { validators: [Validators.required] }],
                productQuantity: ['', { validators: [Validators.required] }],
            });
            this.categories = this.formBuilder.group({
                categoryName: [''],
                maxQuantity: [''],
                minQuantity: [''],
                setsOf: [''],
            });
            this.maxForm = this.formBuilder.group({
                maxQuantity: [''],
            });
            this.minForm = this.formBuilder.group({
                minQuantity: [''],
            });
            this.setsOfForm = this.formBuilder.group({
                setsOf: [''],
            });
        } catch (err) {
            console.error(...new ExxComError(116422, scriptName, err).stamp());
        }
    }

    /**
     * @function hasErrorCategory
     * @param {category}
     * category - the category we are checking for errors
     * @description this function will cause an error if a category has less selected products than the minimum
     * and is a required category
     */
    hasErrorCategory(category: string) {
        if (this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category]) {
            let total = 0;
            this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].forEach((element) => {
                total += element.quantity;
            });
            const containsError =
                total < this.configuration.category[category].minQuantity &&
                this.configuration.category[category] &&
                this.configuration.category[category].isRequired;
            return containsError;
        }
    }

    /**
     * @function hasErrorSelectMultiple
     * @param {category}
     * category - the category we are checking for errors
     * @description this function will cause an error if a category has more selected products than the max
     * and is allowed
     */
    hasErrorSelectMultiple(category: string) {
        if (this.configuration.category[category]) {
            let total = 0;
            this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].forEach((element) => {
                total += element.quantity;
            });
            const containsError = total > this.configuration.category[category].maxQuantity;
            return containsError;
        }
    }

    hasErrorGpuLimits(category) {
        const error = {
            hasError: false,
            msg: '',
        };

        if (
            (category == 'GPU' || (category == 'Network Card ' && this.configuration.category['Network Card ']?.sharedSlots)) &&
            this.configuration.maxGPUSlots
        ) {
            if (this.gpuData.availableSlots < 0) {
                error.hasError = true;
                error.msg = 'Too many GPUs';
                if (this.configuration.category['Network Card ']?.sharedSlots) error.msg += ' / Network Cards ';
                error.msg += ' selected for this system';
            }
        }
        return error;
    }

    /**
     * @function hasErrorMinMaxSharedRule
     * @param {category}
     * @description sets the error flag for minmax by comparing total category Set quantity to max qty
     */
    hasErrorMinMaxSharedRule(category: string) {
        const rules = this.getSharedRules(category, 'min/max');
        if (rules.length) {
            rules.forEach((rule: any) => {
                if (rule.name === 'min/max') {
                    const categories = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs;
                    const min = rule.rule.minQuantity;
                    const max = rule.rule.maxQuantity;
                    let total = 0;

                    rule.categories.forEach((category) => {
                        categories[category].forEach((product) => {
                            total += product.quantity;
                        });
                    });

                    if (total > max || total < min) {
                        // need to check if the error doesnt already exist before adding it
                        if (!this.checkErrorSharedRule(category, 'min/max')) {
                            this.sharedRulesErrors.push({
                                type: 'min/max',
                                categories: rule.categories,
                            });
                        }
                    } else {
                        // remove the error since the condition is no longer met
                        this.sharedRulesErrors.forEach((error, index) => {
                            if (error.type === 'min/max' && error.categories === rule.categories) {
                                this.sharedRulesErrors.splice(index, 1);
                            }
                        });
                    }
                }
            });
        }
    }

    /**
     * @private
     * @function createToast
     * @description creates a toast using the ConfiguratorErrorComponent and adds its id to the errors class object
     * @param {string} message
     * @param {string} category
     */
    private createToast(message: string, category: string) {
        const toast = this.toastr.show(message, null, {
            toastComponent: ConfiguratorErrorComponent,
            disableTimeOut: true,
            tapToDismiss: false,
        });
        toast.toastRef.componentInstance.category = category;
        this.errors[category] = toast.toastId;
    }

    /**
     * @function checkError
     * @description checks if theres an error based on quantity in a category
     * @param {string} category
     * @returns {boolean} containsError
     */
    checkError(category: string) {
        const hasErrorCategory = this.hasErrorCategory(category);
        const hasErrorSelectMultiple = this.hasErrorSelectMultiple(category);
        const hasErrorGpuLimits = this.hasErrorGpuLimits(category);
        const hasErrorSharedRules = this.checkErrorSharedRule(category, 'min/max');
        const containsError = hasErrorCategory || hasErrorSelectMultiple || hasErrorGpuLimits.hasError;
        const errorExists = category in this.errors;
        const slotErrorExists =
            (category == 'GPU' || (category == 'Network Card ' && this.configuration.category['Network Card ']?.sharedSlots)) &&
            'Slot Limits' in this.errors;

        // If an error is detected
        if ((hasErrorCategory || hasErrorSelectMultiple || hasErrorGpuLimits.hasError || hasErrorSharedRules) && !errorExists && !slotErrorExists) {
            const maxQuantity = this.configuration.category[category].maxQuantity;
            let total = 0;
            this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].forEach((element) => {
                total += element.quantity;
            });
            let message = '';
            if (hasErrorCategory) message = `${category} must have at least one selected`;
            else if (hasErrorSelectMultiple) message = `${category} max qty ${maxQuantity} (${total} selected)`;
            else if (hasErrorGpuLimits.hasError) message = hasErrorGpuLimits.msg;
            else if (hasErrorSharedRules) message = `${category} has a shared quantity error`;

            if (hasErrorGpuLimits.hasError) this.createToast(message, 'Slot Limits');
            else this.createToast(message, category);
            // Else clear all errors
        } else if (
            !(hasErrorCategory || hasErrorSelectMultiple || hasErrorGpuLimits.hasError || hasErrorSharedRules) &&
            (errorExists || slotErrorExists)
        ) {
            this.toastr.clear(this.errors[category]);
            delete this.errors[category];
            if (slotErrorExists) {
                this.toastr.clear(this.errors['Slot Limits']);
                delete this.errors['Slot Limits'];
            }
            const formErrors = Object.keys(this.errors);
            if (formErrors.length === 1 && formErrors[0] === 'hasErrors') {
                this.toastr.clear(this.errors['hasErrors']);
                delete this.errors['hasErrors'];
            }
        }

        return containsError;
    }

    /**
     * @function checkErrorSharedRule
     * @param {category, type}
     * @description checks the sharedRulesErrors array to see if the error of 'type' already exists on 'category'
     */
    checkErrorSharedRule(category: string, type: string): boolean {
        let errorFound = false;
        if (this.sharedRulesErrors.length) {
            this.sharedRulesErrors.forEach((error) => {
                if (error.type === type && error.categories.has(category)) {
                    errorFound = true;
                }
            });
        }
        return errorFound;
    }

    /**
     * @function openPdfGatedBootstrapMarketoDialog
     * @param {marketoFormId}
     * marketoFormId - Marketo form id to load from API
     * @description load the marketo form which customer submits config with
     */
    async openPdfGatedBootstrapMarketoDialog() {
        try {
            this.rightFormActive = false;

            const uniqueUrlData = await this.savedConfigurationService.createSavedConfiguration(
                this.configuration._id,
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs
            );

            const initialState = {
                data: {
                    action: 'display',
                    marketoFormId: 'mktoForm_2612',
                    configuratorSystemPrice: this.configuration.optimizedConfigurations[this.activeReference].priceTotal,
                    configuratorData: this.configFormattedData,
                    saveConfiguratorInformation: uniqueUrlData.data.url,
                },
            };

            this.modalRef = this.modalService.show(MarketoDialogComponent, {
                animated: true,
                class: 'modal-marketo-form modal-dialog-centered',
                initialState,
            });
            this.modalRef.content.closeBtnName = 'Close';

            interface EmitterReturnValue {
                success: boolean;
                customerData: Object;
            }

            this.modalRef.content.formSubmittedEmitter.subscribe(async (event: EmitterReturnValue) => {
                if (event.success) {
                    const savedConfig = await this.savedConfigurationService.addCustomerDataToSavedConfiguration(
                        uniqueUrlData.data,
                        event.customerData
                    );
                    this.savedConfigurationService.saveSavedConfiguration(savedConfig);
                    this.savePdf();
                }
            });

            this.modalRef.content.reloadEventEmitter.subscribe(() => {
                this.doneLoading = false;
                this.delayedDoneLoading = false;
            });

            this.modalRef.onHide.subscribe(() => {
                this.rightFormActive = true;
            });
        } catch (err) {
            console.error(...new ExxComError(969695, scriptName, err).stamp());
        }
    }

    /**
     * @function initConfiguration
     * @param {}
     * @description initialize the configuration page, search by MPN in exxcom db,
     * if the configurator doesn't exist we create a new one, also load all products
     * to be parsed into variable "allProducts" so we can add from that list later
     */
    async initConfiguration() {
        try {
            this.formId = 'mktoForm_2347';
            this.configuration.mpn = this.configuration.name = this.routerService.url.current.replace('/configurator', '');
            const product = this.webstoreProductService.getProductByMpnComponentConfig(this.configuration.mpn);
            let configDescription = this.configuration.mpn.replace('/', '');
            if (!this.variantWhitelist.includes(this.configuration.mpn.split('-')[2])) {
                this.configuration.mpn = this.configuration.mpn.split('-')[0] + '-' + this.configuration.mpn.split('-')[1];
            }
            this.configuration.mpn = this.configuration.mpn.split('?')[0];
            this.configuratorService.getConfigurationByUrlComponent('exx', this.configuration.mpn, null, null, null, true).then((config) => {
                if (!config || !config[0]?.isActive) {
                    this.routerService.router.navigateByUrl('/');
                }

                const configurationV2: ConfigurationDataV2 = cloneDeep(config[0]);
                this.configuration = this.convertConfigV2ToV1(configurationV2);

                this.gpuData.maxSlots = this.configuration.maxGPUSlots;
                this.gpuData.slotWidth = this.configuration.slotWidth ? this.configuration.slotWidth : 2;
                this.minTotalMemory = this.configuration.minTotalMemory ? this.configuration.minTotalMemory : null;
                this.initGpuLimits();

                // This is to help the reference bombs calculate their individual totals
                const refKeys = ['best', 'better', 'good'];
                refKeys.forEach((refKey) => {
                    this.selectReference(refKey);
                });

                this.data = this.getSelectedSpecs(this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs);
                if (this.configuration.type) {
                    configDescription = this.configuration.type;
                }

                this.initSpecs();
                this.calculateMarkupPercent(this.configuration.markupPercent);
                this.maintainReferenceBombs();
                this.maintainReferenceProducts();
                this.sortSelectedSpecs();

                this.sharedRuleService.findSharedRule(this.configuration._id).then((res) => {
                    this.sharedRules = this.parseRuleResponse(res.data);
                });

                // if the ?state= param is present, attempt to find Saved_Configuration in db
                if (this.routerService.url.params && this.routerService.url.params.state) {
                    this.savedConfigurationService.findSavedConfiguration(config[0]._id, this.routerService.url.params.state).then((res) => {
                        if (res.data.length) {
                            this.configuration.optimizedConfigurations['good'].selectedSpecs = res.data[0].selectedSpecs;
                            this.selectReference('good');
                            this.savedConfigurationService
                                .createSavedConfiguration(
                                    this.configuration._id,
                                    this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs
                                )
                                .then((result) => {
                                    this.configurationUrlData = result.data;
                                });
                        }
                    });
                }
            });

            product.then((prod) => {
                if (prod) {
                    this.metaService.add({
                        title: ` ${prod.description} | Configurator | ${this.environment.siteName}`,
                        description: `Customize your ${configDescription} with our online configuration tool. Inquire today and find out more regarding EDU and government discounts from Exxact.`,
                    });
                }
            });
        } catch (err) {
            console.error(...new ExxComError(116423, scriptName, err).stamp());
        }
    }

    private convertConfigV2ToV1(config: ConfigurationDataV2): ConfigurationData {
        const configProductMap: Map<string, Object> = new Map();

        const configV1: ConfigurationData = {
            _id: config._id,
            mpn: config.mpn,
            name: config.name,
            imgUrl: config.imgUrl,
            markupPercent: config.markupPercent,
            markupAmount: config.markupAmount,
            notes: config.notes,
            maxGPUSlots: config.maxGPUSlots,
            slotWidth: config.slotWidth,
            minTotalMemory: config.minTotalMemory,
            categoryNames: this.categoryKeys,
            category: {},
            products: {},
            optimizedConfigurations: {
                good: {
                    selectedSpecs: {},
                    category: {},
                    costTotal: config.optimizedConfigurations.good.costTotal,
                    priceTotal: config.optimizedConfigurations.good.priceTotal,
                    products: {},
                    title: config.optimizedConfigurations.good.title,
                },
                better: {
                    selectedSpecs: {},
                    category: {},
                    costTotal: config.optimizedConfigurations.better.costTotal,
                    priceTotal: config.optimizedConfigurations.better.priceTotal,
                    products: {},
                    title: config.optimizedConfigurations.better.title,
                },
                best: {
                    selectedSpecs: {},
                    category: {},
                    costTotal: config.optimizedConfigurations.best.costTotal,
                    priceTotal: config.optimizedConfigurations.best.priceTotal,
                    products: {},
                    title: config.optimizedConfigurations.best.title,
                },
            },
        };

        // Construct category map
        config.categories.forEach((item: any) => {
            this.categoryMap.set(item.category, item);
        });

        // Construct product map
        config.products.forEach((product: any) => {
            configProductMap.set(product._id, product);
        });

        // No, thanks.
        const noThanksProduct = {
            name: 'No, thanks.',
            type: '',
            quantity: 1,
            cost: 0,
            price: 0,
            productLine: '',
        };

        configProductMap.set('61049d1a6c78f86de9ff939d', noThanksProduct);

        // Construct category
        config.categories.forEach((category) => {
            configV1.category[category.category] = {
                setsOf: category.setsOf,
                productLineKeys: category.productLines,
                isRequired: category.isRequired,
                isMultiple: category.isMultiple,
                minQuantity: category.minQuantity,
                maxQuantity: category.maxQuantity,
                note: category.note,
                sharedSlots: category.sharedSlots,
            };

            // options
            configV1.category[category.category].options = Array.from(
                { length: configV1.category[category.category].maxQuantity },
                (_, i) => i + 1
            ).filter((e) => {
                if (configV1.category[category.category].setsOf) {
                    return e % configV1.category[category.category].setsOf == 0 && e >= configV1.category[category.category].minQuantity;
                } else return e >= configV1.category[category.category].minQuantity;
            });

            // productLines && products
            category.products.forEach((product: any) => {
                const tempProduct: any = cloneDeep(configProductMap.get(product));
                // Right now theres a bug where if the productLine is an empty string,
                //  it may not render properly in the ui. The following line is a temporary fix,
                //  but a long-term solution should involve changing the configuration schemas to
                //  default to an empty string instead of 'null', then reflecting that in the ui template.
                if (tempProduct.productLine == '') tempProduct.productLine = null;

                // product lines
                if (!configV1.category[category.category].productLines) configV1.category[category.category].productLines = {};
                if (!configV1.category[category.category].productLines[tempProduct.productLine]) {
                    configV1.category[category.category].productLines[tempProduct.productLine] = [];
                }

                configV1.category[category.category].productLines[tempProduct.productLine].push(cloneDeep(tempProduct));

                // products
                if (!configV1.products[category.category]) configV1.products[category.category] = [];
                configV1.products[category.category].push(cloneDeep(tempProduct));
            });
        });

        // Construct optimizedConfigurations
        const optimizedKeys = ['good', 'better', 'best'];
        optimizedKeys.forEach((key) => {
            config.optimizedConfigurations[key].selectedSpecs.forEach((category) => {
                // opt categories
                configV1.optimizedConfigurations[key].category[category.category] = cloneDeep(configV1.category[category.category]);

                // for (const line in configV1.optimizedConfigurations[key].category[category.category].productLines){
                //   if (Object.prototype.hasOwnProperty.call(configV1.optimizedConfigurations[key].category[category.category].productLines, line)){
                //     configV1.optimizedConfigurations[key].category[category.category].productLines[line].forEach((product) => {
                //       product.quantity = this.categoryMap.get(product.type)['setsOf']; // this gets overwritten in maintainreferenceproducts, but it shouldnt
                //     });
                //   }
                // }

                // opt categories 'no thanks'
                if (configV1.optimizedConfigurations[key].category[category.category].minQuantity == 0) {
                    configV1.optimizedConfigurations[key].category[category.category].noThanks = configProductMap.get('61049d1a6c78f86de9ff939d');
                }

                // opt products
                configV1.optimizedConfigurations[key].products[category.category] = cloneDeep(configV1.products[category.category]);

                // selected specs
                if (!configV1.optimizedConfigurations[key].selectedSpecs[category.category]) {
                    configV1.optimizedConfigurations[key].selectedSpecs[category.category] = [];
                }

                category.products.forEach((product) => {
                    const tempObj = cloneDeep(
                        Object.assign(configProductMap.get(product._id), {
                            quantity: product.quantity,
                        })
                    );
                    configV1.optimizedConfigurations[key].selectedSpecs[category.category].push(cloneDeep(tempObj));
                });
            });
        });
        return configV1;
    }

    /**
     * @function maintainReferenceProducts
     * @param {ref} ref- value that can be "good", "better", "best", changes active
     * @description maintains product lines for added products and sorts them into the correct place, currently alphabetical order
     * for "featured solutions", this is separate from "updateProductLineKeys" because they need to be ran at different places
     * and slightly different logic
     */
    maintainReferenceProducts() {
        const configKeys = Object.keys(this.configuration.optimizedConfigurations);
        this.configuration.categoryNames.forEach((element) => {
            for (const ref of configKeys) {
                if (!this.configuration.optimizedConfigurations[ref].category[element]) {
                    return;
                }
                this.configuration.optimizedConfigurations[ref].category[element].productLineKeys = Object.keys(
                    groupBy(this.configuration.optimizedConfigurations[ref].products[element], 'productLine')
                );
                this.configuration.optimizedConfigurations[ref].category[element].productLineKeys.sort((a, b) => {
                    return a.length - b.length;
                });
                this.configuration.optimizedConfigurations[ref].category[element].productLines = groupBy(
                    this.configuration.optimizedConfigurations[ref].products[element],
                    'productLine'
                );

                // this is to account for an edge case where a category is not selected in a reference bomb,
                // and it does not default to a quantity set of 1.
                // essentially, the Object.keys method above loses the proper product quantities,
                // (the quantity is correct in optimizedConfig.category, but not .products)
                // and too much of the configurator depends on this function for us to be able to change it easily
                // for (const line in this.configuration.optimizedConfigurations[ref].category[element].productLines){
                //   if (Object.prototype.hasOwnProperty.call(this.configuration.optimizedConfigurations[ref].category[element].productLines, line)){
                //     this.configuration.optimizedConfigurations[ref].category[element].productLines[line].forEach((product) => {
                //       if (this.categoryMap.get(product.type)['setsOf']){
                //         product.quantity = this.categoryMap.get(product.type)['setsOf'];
                //       }
                //     });
                //   }
                // }
            }
        });
    }

    /**
     * @function addProductFromList
     * @param { product }
     * product - product in listing to be added
     * @description add product to current configuration if it's not already there
     */
    async addProductFromList(product: ConfigurationProductData) {
        try {
            if (!product) {
                return;
            }
            await this.createCategory(product.type, 1, 1);
            const itemExists = this.configuration.products[product.type].find((element) => {
                if (product.name == element.name) {
                    return element;
                }
            });
            if (!itemExists) {
                this.configuration.products[product.type].push(product);
            }
        } catch (err) {
            console.error(...new ExxComError(996438, scriptName, err).stamp());
        }
    }

    selectNoThanks(category) {
        this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category] = [];
        this.hasErrorMinMaxSharedRule(category);
        if (this.driveKeys.includes(category)) this.checkForNoDrivesSelected();
        if (category === 'OS') this.checkForIncompatibleSoftware();
        this.maintainReferenceBombs();
        this.sortSelectedSpecs();
        if (category == 'GPU' || (category == 'Network Card ' && this.configuration.category['Network Card ']?.sharedSlots)) {
            this.getGpuLimitsFromSelected();
            this.checkError(category);
        }
        if (category == 'Network Card ') {
            this.updateNetworkingData(null);
        }
    }

    /**
     * @function createCategory
     * @param {value: string, minQuantity, maxQuantity}
     * value - form data when creating a category
     * minQuantity - minimum units for each product in the category
     * maxQuantity - maximum units for each product in the category
     * @description checks if the category exists somewhere in the database, if it
     * does, returns and doesn't create.  Categories that already exist must be
     * added from the list of categories displayed on the configuration.  If category
     * doesn't exist, creates it on configuration and initializes values.
     */
    async createCategory(value: string, minQuantity: number, maxQuantity: number) {
        try {
            if (this.configuration.categoryNames.includes(`${value}`)) {
                return;
            }
            this.configuration.products[`${value}`] = [];
            this.configuration.category[`${value}`] = {};
            this.configuration.category[value].minQuantity = minQuantity;
            this.configuration.category[value].maxQuantity = maxQuantity;
            this.configuration.category[value].options = Array.from({ length: maxQuantity }, (_, i) => i + 1);

            this.configuration.categoryNames.push(value);
            const keys = Object.keys(this.configuration.optimizedConfigurations);
            for (const key of keys) {
                this.configuration.optimizedConfigurations[key].selectedSpecs[value] = [];
            }
            await this.maintainReferenceBombs();
        } catch (err) {
            console.error(...new ExxComError(996436, scriptName, err).stamp());
        }
    }

    /**
     * @function initSpecs
     * @param {}
     * @description this function is called to make sure the good, better, best specs
     * are initialized with values so when they are parsed we don't get an undefined
     * error
     */
    async initSpecs() {
        try {
            if (!this.configuration.optimizedConfigurations) {
                return;
            }
            const keys = Object.keys(this.configuration.optimizedConfigurations);
            for (const key of keys) {
                this.configuration.categoryNames.forEach((element) => {
                    if (!this.configuration.optimizedConfigurations[key].selectedSpecs[element]) {
                        this.configuration.optimizedConfigurations[key].selectedSpecs[element] = [];
                    }
                });
            }
        } catch (err) {
            console.error(...new ExxComError(116425, scriptName, err).stamp());
        }
    }

    /**
     * @function calculateMarkupAmount
     * @param {markupAmount} markupAmount - the amount we want to markup the build by
     * @description this function is used to mark up the prices of products on a
     * sliding scale based on amount, only percentage or amount will be used, not both
     */
    async calculateMarkupAmount(markupAmount: number) {
        try {
            this.configuration.markupPercent = 0;
            this.configuration.markupAmount = markupAmount;

            const keys = Object.keys(this.configuration.products);
            const configKeys = Object.keys(this.configuration.optimizedConfigurations);
            const productMarkup = markupAmount / size(keys);

            for (const key of keys) {
                this.configuration.products[key].forEach((element) => {
                    element.price = (element.cost + productMarkup).toFixed(2);
                    element.price = parseFloat(element.price);
                });
            }

            for (const config of configKeys) {
                for (const key of keys) {
                    if (!this.configuration.optimizedConfigurations[config].selectedSpecs[key].price) {
                        continue;
                    }
                    this.configuration.optimizedConfigurations[config].selectedSpecs[key].price = (
                        this.configuration.optimizedConfigurations[config].selectedSpecs[key].price + productMarkup
                    ).toFixed(2);
                    this.configuration.optimizedConfigurations[config].selectedSpecs[key].price = parseFloat(
                        this.configuration.optimizedConfigurations[config].selectedSpecs[key].price
                    );
                }
            }

            await this.maintainReferenceBombs();
        } catch (err) {
            console.error(...new ExxComError(116427, scriptName, err).stamp());
        }
    }

    /**
     * @function calculateMarkupPercent
     * @param {markupPercent} markupPercent- percentage to mark up all products, .1 = 10%
     * @description this function is called to make sure the good, better, best specs
     * are initialized with values so when they are parsed we don't get an undefined
     * error
     */
    async calculateMarkupPercent(markupPercent: number) {
        try {
            this.configuration.markupPercent = markupPercent;
            this.configuration.markupAmount = 0;
            const keys = Object.keys(this.configuration.products);
            const configKeys = Object.keys(this.configuration.optimizedConfigurations);

            for (const key of keys) {
                this.configuration.products[key].forEach((element) => {
                    element.price = (element.cost * (1 + this.configuration.markupPercent / 100)).toFixed(2);
                    element.price = parseFloat(element.price);
                });
            }

            for (const config of configKeys) {
                for (const key of keys) {
                    for (const element of this.configuration.optimizedConfigurations[config].products[key]) {
                        element.price = (element.cost * (1 + this.configuration.markupPercent / 100)).toFixed(2);
                        element.price = parseFloat(element.price);
                    }
                    this.configuration.optimizedConfigurations[config].selectedSpecs[key].forEach((element, index, arr) => {
                        element.price = (element.cost * (1 + this.configuration.markupPercent / 100)).toFixed(2);
                        element.price = parseFloat(element.price);
                    });
                }
            }

            await this.maintainReferenceBombs();
        } catch (err) {
            console.error(...new ExxComError(996428, scriptName, err).stamp());
        }
    }

    /**
     * @function calcDiff
     * @param {productCategory,product}
     * productCategory- category we're comparing prices in
     * product - the current product we are comparing the selected product
     * @description function used to calculate the difference in costs for each
     * product in each category.
     */
    calcDiff(productCategory, product) {
        try {
            if (product.name == 'No, thanks.') {
                let sum = 0;
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].forEach((element) => {
                    sum += element.price;
                });
                if (sum == 0 && this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].length == 0) {
                    return 'Included';
                } else {
                    return sum == 0 ? 0 : '-' + this.formatNumbers(sum);
                }
            }
            let diff = product.price;
            const productSelected = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].find(
                function (element) {
                    return element._id == product._id;
                }
            );
            if (
                !this.configuration.category[productCategory].isMultiple &&
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory][0]
            ) {
                diff = product.price - this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory][0].price;
                diff = diff.toFixed(2);
            } else {
                if (productSelected) {
                    diff = (-product.price).toFixed(2);
                } else {
                    diff = product.price.toFixed(2);
                }
            }
            if (parseFloat(diff) == 0) {
                if (!productSelected) {
                    return 0;
                } else return 'Included';
            }
            if (parseFloat(diff) > 0) {
                return '+' + this.formatNumbers(diff);
            }
            return '-' + this.formatNumbers(-diff);
        } catch (err) {
            console.error(...new ExxComError(996429, scriptName, err).stamp());
        }
    }

    /**
     * @function selectReference
     * @param {ref} ref- value that can be "good", "better", "best", changes active
     * @description select good, better, best configs and update selection on config
     */
    async selectReference(ref) {
        try {
            this.activeReference = ref;
            this.configuration.activeReference = ref;
            this.updateGpuData('GPU', this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs);
            this.maintainReferenceBombs();
            this.data = this.getSelectedSpecs(this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs);
            this.getSelectedProductQuantity();
            this.checkForIncompatibleSoftware();
            this.checkForNoDrivesSelected();
            this.sortSelectedSpecs();
        } catch (err) {
            console.error(...new ExxComError(116430, scriptName, err).stamp());
        }
    }

    /**
     * @function selectProduct
     * @param {productCategory,product} productCategory- category to parse in
     * product- product to be set to selected
     * @description select product and set it to active then call maintainReferenceBombs()
     * to update the pricing/cost totals
     */
    selectProduct(productCategory, product) {
        try {
            // de-select a product
            if (product.name == 'No, thanks.') {
                if (this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory][0].name != product.name) {
                    this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory] = [product];
                    this.hasErrorMinMaxSharedRule(productCategory);
                    this.maintainReferenceBombs();
                    this.sortSelectedSpecs();
                    return;
                } else {
                    this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory] = [];
                    this.hasErrorMinMaxSharedRule(productCategory);
                    this.maintainReferenceBombs();
                    this.sortSelectedSpecs();
                    return;
                }
            }
            const selectedExists = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].filter(
                (element) => {
                    return product._id == element._id;
                }
            );
            // Product de-selection based on re-clicking active product
            if (selectedExists.length > 0) {
                const productExists = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].find(
                    (element) => {
                        return element._id == product._id;
                    }
                );
                const index = indexOf(this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory], productExists);
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].splice(index, 1);
                this.hasErrorMinMaxSharedRule(productCategory);
                this.maintainReferenceBombs();
                if (productCategory == 'GPU' || (productCategory == 'Network Card ' && this.configuration.category['Network Card ']?.sharedSlots)) {
                    this.getGpuLimitsFromSelected();
                    this.checkError(productCategory);
                }
                if (productCategory == 'OS') this.checkForIncompatibleSoftware();
                if (this.driveKeys.includes(productCategory)) this.checkForNoDrivesSelected();
                if (productCategory == 'Network Card ') {
                    this.updateNetworkingData(null);
                }
                this.sortSelectedSpecs();
                return;
            }
            if (!this.configuration.category[productCategory].isMultiple) {
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory] = [product];
            } else {
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[productCategory].push(product);
            }
            this.hasErrorMinMaxSharedRule(productCategory);
            this.maintainReferenceBombs();
            if (productCategory == 'GPU' || (productCategory == 'Network Card ' && this.configuration.category['Network Card ']?.sharedSlots)) {
                this.getGpuLimitsFromSelected();
                this.checkError(productCategory);
            }
            if (productCategory == 'OS') this.checkForIncompatibleSoftware();
            if (this.driveKeys.includes(productCategory)) this.checkForNoDrivesSelected();
            if (productCategory == 'Network Card ') {
                this.updateNetworkingData(product);
            }
            this.sortSelectedSpecs();
        } catch (err) {
            console.error(...new ExxComError(996431, scriptName, err).stamp());
        }
    }

    /**
     * @function includesProducts
     * @param {Category, product}
     * @description checks to see if a product is selected or not
     */
    includesProducts(category: string, product: ConfigurationProductData) {
        try {
            const productActive = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].filter((element) => {
                return element._id == product._id;
            });
            return productActive.length > 0 ? true : false;
        } catch (err) {
            console.error(...new ExxComError(995760, scriptName, err).stamp());
        }
    }

    parseFloat(num) {
        return parseFloat(num);
    }

    /**
     * @function formatNumbers
     * @param {numbers}
     * @description takes a number and adds appropriate commas
     */
    formatNumbers(number: string | number) {
        try {
            if (number == null) {
                return;
            }
            if (typeof number != 'string') {
                number = number.toFixed(2);
            }
            return '$' + number.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // adds a comma after every third number backwards
        } catch (err) {
            console.error(...new ExxComError(934260, scriptName, err).stamp());
        }
    }

    /**
     * @function maintainReferenceBombs
     * @param {}
     * @description use this function to set default prices for bombs and update
     * prices once an active selection or pricing has changed
     */
    async maintainReferenceBombs() {
        try {
            const good = this.configuration.optimizedConfigurations.good.selectedSpecs;
            this.configuration.optimizedConfigurations.good.costTotal = this.formatNumbers(this.getTotalCost(good).toFixed(2));
            this.configuration.optimizedConfigurations.good.priceTotal = this.formatNumbers(this.getTotalPrice(good).toFixed(2));

            const better = this.configuration.optimizedConfigurations.better.selectedSpecs;
            this.configuration.optimizedConfigurations.better.costTotal = this.formatNumbers(this.getTotalCost(better).toFixed(2));
            this.configuration.optimizedConfigurations.better.priceTotal = this.formatNumbers(this.getTotalPrice(better).toFixed(2));

            const best = this.configuration.optimizedConfigurations.best.selectedSpecs;
            this.configuration.optimizedConfigurations.best.costTotal = this.formatNumbers(this.getTotalCost(best).toFixed(2));
            this.configuration.optimizedConfigurations.best.priceTotal = this.formatNumbers(this.getTotalPrice(best).toFixed(2));
            // let cost = parseFloat(this.configuration.optimizedConfigurations[this.activeReference].costTotal);
            // let price = parseFloat(this.configuration.optimizedConfigurations[this.activeReference].priceTotal);
            this.getSpecTotals();
        } catch (err) {
            console.error(...new ExxComError(996432, scriptName, err).stamp());
        }
    }

    /**
     * @function getTotalCost
     * @param {selection} selection- good, better, best value to be parsed through
     * @description goes through the selected products and adds up total cost
     */
    getTotalCost(selection: any) {
        try {
            let total = 0;
            const keys = Object.keys(selection);
            for (const key of keys) {
                selection[key].forEach((element) => {
                    total += element.cost;
                });
            }
            return total;
        } catch (err) {
            console.error(...new ExxComError(996433, scriptName, err).stamp());
        }
    }

    /**
     * @function getTotalPrice
     * @param {selection} selection- good, better, best value to be parsed through
     * @description goes through the selected products and adds up total price
     */
    getTotalPrice(selection: any) {
        try {
            let total = 0;
            const keys = Object.keys(selection);
            for (const key of keys) {
                selection[key].forEach((element) => {
                    total += element.price;
                });
            }
            return total;
        } catch (err) {
            console.error(...new ExxComError(996434, scriptName, err).stamp());
        }
    }

    /**
     * @function getSpecTotals
     * @param {}
     * @description goes through selection for activeReference and adds up specs (watts, btu/hr, memory)
     */
    async getSpecTotals() {
        try {
            const { selectedSpecs } = this.configuration.optimizedConfigurations[this.activeReference];
            this.specTotals.wattage = await this.getTotalWattage(selectedSpecs);
            this.specTotals.btu = this.calculateBTU(this.specTotals.wattage);
            this.specTotals.memory = this.getTotalMemory(selectedSpecs);
        } catch (err) {
            console.error(...new ExxComError(996445, scriptName, err).stamp());
        }
    }

    /**
     * @function getTotalWattage
     * @param {selection} selection- good, better, best value to be parsed through
     * @description goes through the selected products and adds up total wattage
     */
    async getTotalWattage(selection: any) {
        try {
            let totalWattage = 0;
            const keys = Object.keys(selection);
            for (const key of keys) {
                selection[key].forEach((element) => {
                    if (element.wattage) totalWattage += element.wattage * element.quantity;
                });
            }
            return totalWattage;
        } catch (err) {
            console.error(...new ExxComError(996444, scriptName, err).stamp());
        }
    }

    /**
     * @function calculateBTU
     * @param {watts} watts- total wattage to be used in BTU/hr conversion
     * @description converts total wattage to BTU/hr
     */
    calculateBTU(watts: number) {
        return parseFloat((watts * 3.412141633).toFixed(2));
    }

    /**
     * @function getTotalMemory
     * @param {selection} selection- good, better, best value to be parsed through
     * @description goes through the selected products and adds up total memory
     */
    getTotalMemory(selection: any) {
        try {
            let totalMemory = 0;
            selection['Memory'].forEach((memorySelection) => {
                if (memorySelection.memory) totalMemory += memorySelection.memory * memorySelection.quantity;
            });
            return totalMemory;
        } catch (err) {
            console.error(...new ExxComError(996446, scriptName, err).stamp());
        }
    }

    /**
     * @function updategpuData
     * @param {category, selection}
     * category - enables functionality only on GPU category
     * selection - good, better, best value to be parsed through
     * @description goes through selection to determine total nvlinks required (if applicable)
     */
    updateGpuData(category, selection: any) {
        try {
            if (category == 'GPU') {
                // Reset gpuData
                this.gpuData.isSupported = false;
                this.gpuData.totalLinks = 0;
                this.gpuData.nvLinkMessage = null;

                // Calculate for nvlink requirements
                selection['GPU'].forEach((gpuSelection) => {
                    if (gpuSelection.nvLinkSupported) {
                        this.gpuData.isSupported = true;
                        this.gpuData.totalLinks += floor(gpuSelection.quantity / 2) * gpuSelection.nvLinksPerPair;
                        if (gpuSelection.quantity % 2 != 0) {
                            this.gpuData.nvLinkMessage = 'One GPU will not have NVLink';
                        }
                    }
                });

                if (!this.gpuData.isSupported) {
                    this.gpuData.nvLinkMessage = 'NVLink not supported by selected GPU';
                    this.selectNoThanks('NVIDIA NVLink');
                } else if (this.gpuData.totalLinks == 0) {
                    this.gpuData.nvLinkMessage = 'NVLink requires at least 1 GPU pair';
                    this.selectNoThanks('NVIDIA NVLink');
                }

                if (this.gpuData.totalLinks) {
                    this.updateProductQuantity(
                        this.gpuData.totalLinks,
                        'NVIDIA NVLink',
                        this.configuration.optimizedConfigurations[this.activeReference].category['NVIDIA NVLink'].productLines[null][0],
                        false
                    );
                }
            }
        } catch (err) {
            console.error(...new ExxComError(996447, scriptName, err).stamp());
        }
    }

    calcMaxGPUQty(slotSpace: any, gpuData: any) {
        return min([
            floor((gpuData.maxSlots * gpuData.slotWidth) / slotSpace),
            gpuData.maxSlots,
            slotSpace > gpuData.slotWidth ? floor(gpuData.maxSlots / (slotSpace - (gpuData.slotWidth + 1))) : null,
            this.configuration.category['GPU'].maxQuantity,
        ]);
    }

    /**
     * @function initGpuLimits
     * @description sets the individual GPU limits for each type of gpu slotspace taken
     */
    initGpuLimits() {
        try {
            this.gpuData.slotLimits = [
                {
                    name: 'Single Slot',
                    max: this.calcMaxGPUQty(1, this.gpuData),
                    current: 0,
                    space: 1,
                },
                {
                    name: 'Double Slot',
                    max: this.calcMaxGPUQty(2, this.gpuData),
                    current: 0,
                    space: 2,
                },
                {
                    name: 'Triple Slot',
                    max: this.calcMaxGPUQty(3, this.gpuData),
                    current: 0,
                    space: 3,
                },
                {
                    name: 'Quadruple Slot',
                    max: this.calcMaxGPUQty(4, this.gpuData),
                    current: 0,
                    space: 4,
                },
            ];
        } catch (err) {
            console.error(...new ExxComError(996448, scriptName, err).stamp());
        }
    }

    getGpuLimitsFromSelected() {
        this.resetGpuLimits();
        const { selectedSpecs } = this.configuration.optimizedConfigurations[this.activeReference];
        selectedSpecs['GPU'].forEach((GPU) => {
            this.updateGpuLimits(GPU.slotSpace, true, GPU.quantity);
        });

        if (this.configuration.category['Network Card ']?.sharedSlots) {
            selectedSpecs['Network Card '].forEach((networkCard) => {
                this.updateGpuLimits(1, true, networkCard.quantity);
            });
        }
        this.updateAvailableGpuSlots();
    }

    /**
     * @function updateGpuLimits
     * @description updates the individual GPU limits for each type of gpu slotspace taken
     * @param { slotSpace, toIncrease, quantity }
     */
    updateGpuLimits(slotSpace, toIncrease, quantity) {
        if (this.gpuData.slotLimits && slotSpace) {
            if (toIncrease) this.gpuData.slotLimits[slotSpace - 1].current += quantity;
            else this.gpuData.slotLimits[slotSpace - 1].current -= quantity;
        }
    }

    /**
     * @function resetGpuLimits
     * @description resets the individual GPU limits for each type of gpu slotspace taken
     */
    resetGpuLimits() {
        if (this.gpuData.slotLimits) {
            this.gpuData.slotLimits.forEach((slotType) => {
                slotType.current = 0;
            });
        }
    }

    /**
     * @function updateAvailableGpuSlots
     * @description keeps track of available gpu slots. To keep track of how many of each gpu we can still
     * add, we utilize the values inside gpuData.slotLimits in combination with slotsAvailable, and slotWidth
     */
    updateAvailableGpuSlots() {
        try {
            // First we get the effective number of slots by multiplying total slots and the spacing between them
            let availableSlots = this.gpuData.maxSlots * this.gpuData.slotWidth;

            // Then we take away from the available slots based on how many of each gpu we have selected
            // Note that if the slot of the gpu is smaller than the width, we consider the slotSpace to be
            //  equal to the slotWidth to remove one slot entirely
            this.gpuData.slotLimits.forEach((slotType) => {
                if (slotType.space < this.gpuData.slotWidth) {
                    availableSlots -= slotType.current * this.gpuData.slotWidth;
                } else {
                    availableSlots -= slotType.current * slotType.space;
                }
            });

            this.gpuData.availableSlots = availableSlots;
        } catch (err) {
            console.error(...new ExxComError(996458, scriptName, err).stamp());
        }
    }

    /**
     * @function updateNetworkCableData
     * @description keeps track of which network cables and product lines are active for a given network card
     */
    updateNetworkingData(product) {
        this.networkingData.activeCableProductLines = [];

        if (product) {
            if (this.configuration.products['Network Cable']) this.updateProductQuantity(1, 'Network Cable', product, false);
            if (this.networkingData.activeConnectorType !== product.connectorType) this.selectNoThanks('Network Cable');
            this.networkingData.activeNetworkCard = cloneDeep(product);
            this.networkingData.numPorts = Array.from({ length: this.networkingData.activeNetworkCard.numPorts }, (_, i) => i + 1); // Will need to update this logic to include OCP cards in the future
            this.networkingData.activeConnectorType = product.connectorType;

            this.configuration.products['Network Cable'].forEach((cable) => {
                if (
                    cable.connectorType == this.networkingData.activeConnectorType &&
                    !this.networkingData.activeCableProductLines.includes(cable.productLine)
                ) {
                    this.networkingData.activeCableProductLines.push(cable.productLine);
                }
            });
        } else {
            this.networkingData.activeNetworkCard = null;
            this.networkingData.activeConnectorType = null;
            this.selectNoThanks('Network Cable');
        }
    }

    /**
     * @param category @function hideNetworkCables
     * @description determines if we should hide the network cable category based on available cables
     */
    hideNetworkCables(category) {
        if (category == 'Network Cable' && !this.networkingData.activeNetworkCard) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * @param memoryProduct needed to extract memory values
     * @description determines the available quantity selectors for memory based on category inputs and minTotalMemory value
     */
    calculateMemoryQuantity(memoryProduct) {
        const minQty = this.configuration.category['Memory'].minQuantity;
        const maxQty = this.configuration.category['Memory'].maxQuantity;
        const setsOf = this.configuration.category['Memory'].setsOf;

        const minimumMem = this.minTotalMemory / memoryProduct.memory;

        const result = [];
        for (let i = minQty; i <= maxQty; i += setsOf) {
            if (i >= minimumMem) result.push(i);
        }

        return result;
    }

    /**
     * @function getSelectedSpecs
     * @param {selection} selection- good, better, best value to be parsed through
     * @description goes through the selected products and saves category/product
     */
    getSelectedSpecs(selection: any) {
        try {
            const selectedProducts = [];
            const keys = Object.keys(selection);
            for (const key of keys) {
                selection[key].forEach((element) => {
                    selectedProducts.push({
                        category: key,
                        product: {
                            _id: element._id,
                            name: element.name,
                            quantity: element.quantity,
                        },
                    });
                });
                if (
                    this.configuration.optimizedConfigurations[this.activeReference].category[key] &&
                    this.configuration.optimizedConfigurations[this.activeReference].category[key].noThanks &&
                    this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[key].length == 0
                ) {
                    selectedProducts.push({
                        category: key,
                        product: { name: '*None', quantity: '--' },
                    });
                }
            }

            selectedProducts.sort((a, b) => {
                return this.categoryKeys.indexOf(a.category) - this.categoryKeys.indexOf(b.category);
            });
            const tempData = {
                title: this.configuration.mpn,
                products: {},
            };
            tempData.products = selectedProducts;
            this.configFormattedData = JSON.stringify(tempData);
            return selectedProducts;
        } catch (err) {
            console.error(...new ExxComError(116434, scriptName, err).stamp());
        }
    } /**
     * @function sortSelectedSpecs
     * @param {}
     * @description goes through the selected products and saves category/product
     */

    sortSelectedSpecs() {
        this.renderedCategories = Object.keys(this.configuration.optimizedConfigurations[this.activeReference]['category']);
        this.renderedCategories.sort((a: string, b: string) => {
            return this.categoryKeys.indexOf(a) - this.categoryKeys.indexOf(b);
        });
    }

    /**
     * @function checkIsRequired
     * @param {}
     * @description check for required category selections
     */
    async checkIsRequired() {
        try {
            for (const category in this.configuration.category) {
                if (this.configuration.category[category].isRequired) {
                    if (this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category]) {
                        let total = 0;
                        this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].forEach((element) => {
                            total += element.quantity;
                        });
                        if (total < this.configuration.category[category].minQuantity) {
                            document.getElementById(category).scrollIntoView({
                                block: 'center',
                                behavior: 'smooth',
                            });
                            return true;
                        }
                    }
                }
            }
            return false;
        } catch (err) {
            console.error(...new ExxComError(920312, scriptName, err).stamp());
        }
    }

    /**
     * @function checkMultipleProducts
     * @param {}
     * @description checks to see if any selected products in categories violate the maxQuantity
     */
    async checkMultipleProducts() {
        try {
            for (const category in this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs) {
                if (Object.prototype.hasOwnProperty.call(this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs, category)) {
                    let total = 0;
                    this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].forEach((product) => {
                        total += product.quantity;
                    });
                    if (!this.configuration.category[category]) {
                        continue;
                    }
                    if (total > this.configuration.category[category].maxQuantity) {
                        return true;
                    }
                }
            }
            return false;
        } catch (err) {
            console.error(...new ExxComError(996550, scriptName, err).stamp());
        }
    }

    /**
     * @function saveReferenceSpecs
     * @param {}
     * @description this function is ran after the save button is clicked when editing
     * a featured solution.  Checks to make sure no *required* categories have missing
     * parts selected and makes sure there are not too many parts selected on a category.
     * If no errors, we clone the cachedConfiguration back onto our main configuration,
     * still have to publish to save to DB.
     */
    async saveReferenceSpecs() {
        try {
            this.isSaving = true;
            const missingSpecs = await this.checkIsRequired();
            if (missingSpecs) {
                return false;
            }
            const tooManyProductsSelected = await this.checkMultipleProducts();
            if (tooManyProductsSelected) {
                return false;
            }
            this.isSaving = false;
            this.data = this.getSelectedSpecs(this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs);
            return true;
        } catch (err) {
            console.error(...new ExxComError(903212, scriptName, err).stamp());
        }
    }

    checkFormErrors() {
        const formErrors = Object.keys(this.errors);
        if (formErrors.length > 0) {
            if (formErrors.length === 1 && formErrors[0] === 'hasErrors') {
                this.toastr.clear(this.errors['hasErrors']);
                delete this.errors['hasErrors'];
                return false;
            }
            if (!this.errors['hasErrors']) {
                this.createToast('Categories contain errors', 'hasErrors');
            }
            return true;
        }
        return false;
    }

    /**
     * @function saveSelectedData
     * @param {}
     * @description save configuration data to a pdf and or update contentDataUrl to
     * latest selected specs
     */
    async saveSelectedData(savePdf: boolean) {
        try {
            if (!isBrowser()) {
                return;
            }
            if (this.checkFormErrors()) {
                return;
            }
            const res = this.saveReferenceSpecs();
            res.then((data) => {
                if (!data) return;
            });
            if (savePdf) {
                this.openPdfGatedBootstrapMarketoDialog();
            } else {
                this.savedConfigurationService
                    .createSavedConfiguration(this.configuration._id, this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs)
                    .then((result) => {
                        this.configurationUrlData = result.data;
                    });
                this.rightFormActive = true;
                this.selectedPane = !this.selectedPane;
            }
        } catch (err) {
            console.error(...new ExxComError(116437, scriptName, err).stamp());
        }
    }

    async saveConfiguration(customerData: object) {
        const savedConfig = await this.savedConfigurationService.addCustomerDataToSavedConfiguration(this.configurationUrlData, customerData);
        this.savedConfigurationService.saveSavedConfiguration(savedConfig);
    }

    async savePdf() {
        this.currentDate = new Date(Date.now()).toLocaleString('en');
        const data = document.querySelector('.hidden-specs-marketo') as HTMLElement;
        const categoryContainer = document.querySelector('#category-container') as HTMLElement;
        data.style.display = 'flex';
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: 'letter',
            hotfixes: ['px_scaling'],
        });

        const marginX = 48;
        const marginY = 42;

        const width = pdf.internal.pageSize.getWidth() - marginX * 2;
        const height = pdf.internal.pageSize.getHeight() - marginY * 2;

        data.style.width = `${width}px`;
        data.style.height = `${height}px`;

        // manually break into a 2nd page if the category container is taller than 500px
        if (categoryContainer.offsetHeight > 500) data.style.height = `${height * 2}px`;

        pdf.html(data, {
            autoPaging: 'text',
            callback: (doc) => {
                if (categoryContainer.offsetHeight <= 500) doc.deletePage(2);
                else doc.deletePage(3);
                const date = new Date().toLocaleDateString();
                doc.save(`${date}-${get(this.configuration, 'mpn', '')}`);
                data.style.display = 'none';
            },
            margin: [marginY, marginX, marginY, marginX],
        });
    }

    /**
     * @function updateProductQuantity
     * @param { num, category, product }
     * num - selected amount of units for a product
     * category - the category where quantity is being updated
     * product - the product to be updated
     * isInit - determines if this is the initialization call of the function
     * @description since quantity can go up or down, we need to take a ratio of
     * selected num/previous quantity to scale the costs and prices accordingly
     */
    updateProductQuantity(num, category, product, isInit) {
        try {
            const ratio = num / product.quantity;
            const categoryProducts = this.configuration.optimizedConfigurations[this.activeReference].products[category];
            const targetProductItem = categoryProducts.find((targetProduct) => {
                return targetProduct._id == product._id;
            });

            if (this.configuration.category[category].isMultiple || (category == 'Memory' && !isInit)) {
                targetProductItem.quantity = num;
                targetProductItem.price *= ratio;
                targetProductItem.cost *= ratio;
            } else if (category == 'Memory' && isInit) {
                categoryProducts.forEach((productItem) => {
                    // Need to determine lowest viable qty for memory, then adjust selected qty based on that
                    const memoryQty = this.calculateMemoryQuantity(productItem);
                    const quantity = memoryQty.includes(num) ? num : memoryQty[0];
                    let selfRatio = quantity / productItem.quantity;
                    productItem.quantity = quantity;
                    productItem.price *= selfRatio;
                    productItem.cost *= selfRatio;
                });
            } else {
                categoryProducts.forEach((productItem) => {
                    let selfRatio = num / productItem.quantity;
                    // Since GPU can have variable quantity options, we need to check if the quantity is available
                    // and prevent the user from selecting more than the max quantity
                    if (category !== 'GPU') {
                        productItem.quantity = num;
                    } else {
                        const maxGPUQty = this.calcMaxGPUQty(productItem.slotSpace, this.gpuData);
                        if (maxGPUQty && num > maxGPUQty) {
                            selfRatio = maxGPUQty / productItem.quantity;
                            productItem.quantity = maxGPUQty;
                        } else {
                            productItem.quantity = num;
                        }
                    }

                    productItem.price *= selfRatio;
                    productItem.cost *= selfRatio;
                });
            }

            // following is used for checking whether or not the product is already selected
            const selectedProduct = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category].find((e) => {
                return e._id == product._id;
            });

            // if it isn't already selected, put product through selectProduct()
            // else, we are just updating the quantity & totals
            // we also avoid auto selecting NVLink items since that quantity is updated via GPU quantity updates
            if (!selectedProduct && category != 'NVIDIA NVLink') {
                this.selectProduct(category, product);
            } else {
                const refLoc = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category];
                this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs[category][indexOf(refLoc, selectedProduct)] =
                    targetProductItem;
            }
            if (category == 'GPU' || (category == 'Network Card ' && this.configuration.category['Network Card ']?.sharedSlots)) {
                this.getGpuLimitsFromSelected();
                this.checkError(category);
            }
            this.hasErrorMinMaxSharedRule(category);
            this.maintainReferenceBombs();
        } catch (err) {
            console.error(...new ExxComError(996441, scriptName, err).stamp());
        }
    }

    /**
     * @function updatedSelectedProductQuantity
     * @desc directly updates the quantity of a product in the optimized configurations if it is in selectedSpecs
     */
    getSelectedProductQuantity() {
        try {
            const products = this.configuration.optimizedConfigurations[this.activeReference].products;
            const selectedSpecs = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs;

            if (products) {
                Object.keys(products).forEach((categoryKey) => {
                    if (products[categoryKey]) {
                        products[categoryKey].forEach((product) => {
                            Object.keys(selectedSpecs).forEach((categoryKey) => {
                                selectedSpecs[categoryKey].forEach((selectedProduct) => {
                                    if (selectedProduct._id == product._id) {
                                        this.updateProductQuantity(selectedProduct.quantity, categoryKey, product, true);
                                    }
                                });
                            });
                        });
                    }
                });
            }
        } catch (err) {
            console.error(...new ExxComError(996500, scriptName, err).stamp());
        }
    }

    showDropDown(category: string) {
        try {
            return this.configuration.category[category].minQuantity || this.configuration.category[category].maxQuantity;
        } catch (err) {
            console.error(...new ExxComError(236441, scriptName, err).stamp());
        }
    }

    openModal(templateRef: TemplateRef<any>) {
        this.modalRef = this.modalService.show(templateRef, {
            class: 'modal-xl modal-dialog-scrollable',
        });
    }

    closeModal() {
        this.modalRef.hide();
    }

    parseRuleResponse(result: any[]) {
        for (let i = 0; i < result.length; i++) {
            result[i].categories = new Set(result[i].categories);
        }
        return result;
    }

    /**
     * @function getSharedRules
     * @desc get all shared rules in given category
     * @param category
     * @returns
     */
    getSharedRules(category: string, name: string) {
        return this.sharedRules.filter((rule) => {
            return rule.categories.has(category) && rule.name == name && !rule.delete;
        });
    }

    /**
     * @function hasSharedRule
     * @desc returns true if the pass in category has a rule associated with it
     * @param category
     */
    hasSharedRule(category: string, type: string) {
        let hasRule = false;
        this.sharedRules.forEach((sharedRule) => {
            if (sharedRule.categories.has(category) && sharedRule.name === type && !sharedRule.delete) {
                hasRule = true;
            }
        });
        return hasRule;
    }

    getAnchoredSharedRule(category: string, name: string) {
        let targetRule = null;
        this.sharedRules.forEach((rule) => {
            if (Array.from(rule.categories)[0] == category && rule.name == name && !rule.delete) {
                targetRule = rule;
            }
        });
        return targetRule;
    }

    isSharedRuleAnchor(category: string, rule: string) {
        let targetRule: any = this.getSharedRules(category, 'min/max');
        targetRule = targetRule[0];

        if (Array.from(targetRule.categories)[0] == category) {
            return true;
        } else {
            return false;
        }
    }

    getUsedCategoriesForSharedRuleAnchor(category: string, name: string) {
        const anchor: any = this.getAnchoredSharedRule(category, name);
        if (anchor) return Array.from(anchor.categories);
        else return [];
    }

    combineSharedRuleCategoryLabels(category: string, rule: string) {
        if (this.isSharedRuleAnchor(category, 'min/max')) {
            // ideally this should return a single rule, but if there are multiple rules, we will use the first one
            // in the future it may be possible for multiple of the same rule to exist for a single category
            let targetRule: any = this.getSharedRules(category, 'min/max');
            targetRule = targetRule[0];

            let label = '';

            targetRule.categories.forEach((category) => {
                label += category + ' / ';
            });

            return label.slice(0, -3);
        } else {
            return null;
        }
    }
    /**
     * @function checkForIncompatibleSoftware
     * @desc if Windows 10 is selected from OS, disables ability to select any additional software
     */
    // Temporary fix for Windows 10 software incompatibility, eventually we will replace this with a scalable solution
    checkForIncompatibleSoftware() {
        try {
            const selectedOS = this.configuration.optimizedConfigurations[this.activeReference].selectedSpecs['OS'];

            if (!selectedOS?.length) {
                this.softwareDisabled = true;
                this.selectNoThanks('Software');
            } else if (selectedOS[0]?._id == '61049e086c78f86de9ff93a0' || this.osDisabled) {
                this.softwareDisabled = true;
                this.selectNoThanks('Software');
            } else this.softwareDisabled = false;
        } catch (err) {
            console.error(...new ExxComError(996501, scriptName, err).stamp());
        }
    }

    checkForNoDrivesSelected() {
        try {
            // For current reference
            // Check if an M.2, U.2, U.3, Hard Drive, or SSD is selected (drive keys)
            // if none are selected, set osDisabled to true
            const currentRef = this.configuration.optimizedConfigurations[this.activeReference];
            let drivesPopulated = false;
            this.driveKeys.forEach((category) => {
                if (currentRef?.selectedSpecs[category]?.length) {
                    drivesPopulated = true;
                }
            });

            this.osDisabled = !drivesPopulated;
            if (this.osDisabled) {
                this.selectNoThanks('OS');
                this.selectNoThanks('Software');
            }
        } catch (err) {
            console.error(...new ExxComError(996502, scriptName, err).stamp());
        }
    }
}
