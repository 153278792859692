<section>
  <app-repository-banner
    *ngIf="entry"
    [entry]="entry?.repository_reference[0]?.repository_banner"
  ></app-repository-banner>

  <div id="cards-section" *ngIf="!isEmpty(get(entry, 'repository_reference[0].repository_category.group', []))">
    <div class="container card-grid">
      <app-repository-card
        *ngFor="let card of get(entry, 'repository_reference[0].repository_category.group', [])"
        [entry]="card"
        class="repository-card"
      ></app-repository-card>
    </div>
  </div>
</section>
