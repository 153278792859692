import { Component, OnInit } from '@angular/core';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser, scrollToTop } from 'lib/tools';
import { currentYear } from 'lib/tools';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RouterService } from 'lib/services/router.service';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';

const scriptName = 'footer.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends ExxComComponentClass implements OnInit {
    entry: any = {};
    currentYear: number = currentYear();
    marketoFormId: string = '1125';
    constructor(
        private cmsService: CmsService,
        private routerService: RouterService,
        breakpointObserver: BreakpointObserver,
        public bootstrapMarketoService: BootstrapMarketoService
    ) {
        super({
            dependencies: {
                breakpointObserver: breakpointObserver,
            },
        });
    }

    async ngOnInit() {
        if (!isBrowser()) {
            return;
        }
        this.entry = await this.cmsService.getEntry('footer');
    }

    scrollToTop() {
        try {
            if (!isBrowser()) {
                return;
            }
            const body = document.body.scrollTop ? document.body : document.documentElement;
            scrollToTop(body, 200);
        } catch (err) {
            console.error(...new ExxComError(943992, scriptName, err).stamp());
        }
    }

    showSslVerification() {
        try {
            if (!isBrowser()) {
                return;
            }
            const url = 'https://seal.godaddy.com/verifySeal?sealID=f00IwKbVh7Etz1Hs8JGfeV7Y9iDnh3q7MlZL5XGYm5proZYXCWc1tun5BKJY';
            const name = '_blank';
            const specs = ['menubar=no', 'location=no', 'scrollbars=no', 'status=no', 'toolbar=no', 'width=610', 'height=610'].join(', ');
            window.open(url, name, specs);
        } catch (err) {
            console.error(...new ExxComError(904395, scriptName, err).stamp());
        }
    }
}
