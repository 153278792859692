<div
  [class.related-content-post-tile]="
    relatedContentTileData?.isRelatedContent === true && relatedContentTileData?.narrowOnlyDesign !== true
  "
  [class.related-post-tile]="!relatedContentTileData?.isRelatedContent === true"
  [class.related-content-post-tile-narrow-only]="
    relatedContentTileData?.isRelatedContent === true && relatedContentTileData?.narrowOnlyDesign === true
  "
  id="resource-card"
>
  <a [href]="relatedContentTileData?.link">
    <div class="related-post-img-container">
      <img
        *ngIf="!relatedContentTileData?.image"
        class="related-post-tile-img"
        [src]="
          getWebpImg(
            'https://images.contentstack.io/v3/assets/bltb654d1b96a72ddc4/blteba795fc63fd91c1/5e56f34b9437900dbf81e21b/Blog-MissingImage.jpg'
          )
        "
        alt="no-image"
      />
      <img
        *ngIf="relatedContentTileData?.image"
        class="related-post-tile-img"
        [src]="getWebpImg(relatedContentTileData?.image)"
        [alt]="relatedContentTileData?.imageTitle"
      />
    </div>
    <div class="related-post-img-desc fx-col">
      <h5 *ngIf="relatedContentTileData?.categoryTitle" class="label">
        {{ relatedContentTileData?.categoryTitle }}
      </h5>
      <h4 *ngIf="relatedContentTileData?.title" class="title">{{ relatedContentTileData?.title }}</h4>

      <div *ngIf="relatedContentTileData.date" class="fx-row fx-row-v-center fx-row-h-space-between mt-1 publish-desc">
        <span>{{ relatedContentTileData?.date | date : "longDate" }}</span>
        <div
          class="fx-row fx-row-v-center read-time"
          [class.hide-time-narrow]="relatedContentTileData?.hideTimeNarrow === true"
        >
          <mat-icon class="pr-1" svgIcon="clock"></mat-icon>
          <span *ngIf="relatedContentTileData?.readTime">{{ relatedContentTileData?.readTime | readTime }}</span>
          <span *ngIf="relatedContentTileData?.entry">{{ relatedContentTileData?.entry | readTimeBlog }}</span>
        </div>
      </div>
    </div>
  </a>
</div>
