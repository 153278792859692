import { Pipe, PipeTransform } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'read-time-blog-pipe';

@Pipe({
    name: 'readTimeBlog',
})
export class readTimePipeBlog implements PipeTransform {
    transform(entryArticle: any) {
        try {
            if (!entryArticle) {
                return '';
            }

            let article: string = '';
            if (entryArticle?.blog_content_v3?.new_or_old_blog == 'new_blog') {
                const articleNewGroup = entryArticle?.blog_content_v3?.section?.group;
                articleNewGroup.forEach((section) => {
                    if (section?.rich_text_editor.length > 0) {
                        article += section?.rich_text_editor;
                        article += section?.section_title;
                    }
                });
            } else {
                article = entryArticle?.article;
            }

            return this.findTime(article);

            // formula and constansts suggested in this article:
            // https://betterprogramming.pub/how-to-estimate-any-articles-read-time-2a8403f0bd79
        } catch (err) {
            console.error(...new ExxComError(662312, scriptName, err).stamp());
            return '';
        }
    }

    findTime(article: string) {
        const articleLength = article?.replace(/[^0-9a-z]/gi, '').length;
        const wordsPerMinute = 200;
        const avgWordLen = 5;
        const min = Math.round(articleLength / (avgWordLen * wordsPerMinute));
        return `${min} min read`;
    }
}
