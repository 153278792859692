import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'cms-account-application.component';

@Component({
    selector: 'app-cms-account-application',
    templateUrl: './cms-account-application.component.html',
    styleUrls: ['./cms-account-application.component.scss'],
})
export class CmsAccountApplicationComponent extends ExxComComponentClass implements OnInit {
    // Dependencies
    cmsService: CmsService;

    // Properties: public
    entry: any;

    constructor(c: CmsService) {
        super({
            dependencies: {
                cmsService: c,
            },
        });
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('account_application', {
                references: ['repository_reference'],
            });
        } catch (err) {
            console.error(...new ExxComError(444993, scriptName, err).stamp());
        }
    }
}
