import { APP_INITIALIZER, Injector } from '@angular/core';

import { CategoryComponent } from './category/category.component';
import { CategoryService } from 'lib/services/category/category.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { GtmService } from 'lib/services/google/gtm.service';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'app.initializers';

export const ServicesInitializer = {
    provide: APP_INITIALIZER,
    deps: [Injector],
    multi: true,
    useFactory: function initServices(injector: Injector): () => Promise<any> {
        return async () => {
            try {
                injector.get(RouterService).init(); // Has to be called first because it is used in many services
                injector.get(ConfiguratorService);
                const asyncInitializers = [
                    // The functions in each array element are all called at the same time
                    (async () => {
                        await injector.get(CategoryService).initRoutes(CategoryComponent);
                        await injector.get(WebpService).setWebpAvailable();
                        injector.get(MetaService).init(); // Has to be called after CategoryService
                        injector.get(GtmService).init(); // Has to be called after MetaService
                    })(),
                    injector.get(WebstoreProductService).init(),
                ];
                await Promise.all(asyncInitializers);
            } catch (err) {
                console.error(...new ExxComError(529838, scriptName, err).stamp());
            }
        };
    },
};
