import { Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { WebpService } from 'lib/services/webp.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-product-page-image-tile',
    templateUrl: './product-page-image-tile.component.html',
    styleUrls: ['./product-page-image-tile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductPageImageTileComponent extends ExxComComponentClass {
    @Input() productImages: string[] = [];
    @Input() slidesPerView: number = 4;
    @Input() widthPerSlideImage: number = 66;
    @Input() activeImage: string;
    @Input() modalTemplate: any;
    @Input() isModal: boolean = false;
    @Input() amount: number = 0;
    @Output() amountChange = new EventEmitter();

    @Input() translateAmount: number = 0;
    @Output() translateAmountChange = new EventEmitter();

    currentSlideCounter: number = 0;
    prevSlideCounter: number = 0;
    slidesPerViewSlider: number = 4;
    belowSlideLocation: number = 0;
    belowSlideTranslateAmount: number = 0;
    widthPerSwiperImage: number = 66;
    bsModalRef?: BsModalRef;
    isModalOpen?: boolean = false;
    currentImageIndex: number = 0;
    getWebpImg: (src: string) => string;

    constructor(
        private bsModalService: BsModalService,
        w: WebpService
    ) {
        super({});
        this.belowSlideTranslateAmount = this.widthPerSlideImage * this.slidesPerViewSlider;
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.bsModalService.show(template, {
            class: 'modal-xl product-preview-modal modal-dialog-centered',
        });
        this.isModalOpen = true;
    }
    slideChange(index?: number, next?: string) {
        if (next == 'right' && this.amount + 1 == this.slidesPerViewSlider) {
            const newTranslateAmount = this.translateAmount - this.belowSlideTranslateAmount;
            this.translateAmountChange.emit(newTranslateAmount);
        }
        if (next == 'right' && this.amount == this.productImages.length - 1) {
            this.translateAmountChange.emit(0);
            this.amountChange.emit(0);
        }
        if (next == 'left' && this.amount == this.slidesPerViewSlider) {
            const newTranslateAmount = this.translateAmount + this.belowSlideTranslateAmount;
            this.translateAmountChange.emit(newTranslateAmount);
        }
        if (index == 0) {
            this.amountChange.emit(0);

            return;
        } else if (index && !next) {
            this.amountChange.emit(index);

            return;
        }

        if ((this.amount == 0 && next == 'left') || (this.amount == this.productImages.length - 1 && next == 'right')) {
            return;
        }

        if (next) {
            if (next == 'right') {
                const newIndex = this.amount + 1;

                this.amountChange.emit(newIndex);
            } else {
                const newIndex = this.amount - 1;

                this.amountChange.emit(newIndex);
            }
        }
    }

    setImageIndex(value: number) {
        this.currentImageIndex = value;
    }
}
