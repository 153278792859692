import { Component, Inject, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { get } from 'lodash';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CmsService } from 'lib/services/cms.service';
import { calculateReadingTime } from 'lib/tools';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';
import { Location } from '@angular/common';
import { RouterService } from 'lib/services/router.service';
import { cmsMarketoIDsService } from 'lib/services/cmsMarketoIDs.service';

const scriptName = 'cms-partial-category-top.component';

@Component({
    selector: 'app-cms-partial-category-top',
    templateUrl: './cms-partial-category-top.component.html',
    styleUrls: ['./cms-partial-category-top.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsPartialCategoryTopComponent extends CmsPartialComponentClass implements OnChanges, OnInit {
    // Properties
    @Input() data: any = {};
    marketoSolutionsID: String;
    private xsBreakpointObserver: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
    adblockBackgroundURL: string = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/feature-backlink-bg.png';

    activeCategoryName: string = 'Blog';
    recommendedBlogPosts: any = [];
    // Dependencies

    dialog: MatDialog;
    calculateReadingTime: (entry: any) => string;
    getWebpImg: (src: string) => string;
    configuratorService: ConfiguratorService;
    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        cmsService: CmsService,
        public cmsMarketoIDsService: cmsMarketoIDsService,
        dialog: MatDialog,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService,
        configuratorService: ConfiguratorService,
        routerService: RouterService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                dialog,
                configuratorService,
                environment,
                cmsService,
                location,
                categoryService,
                cmsMarketoIDsService,
                routerService,
            },
        });
        this.xsBreakpointObserver.subscribe((size) => {
            if (size.matches) {
                this.adblockBackgroundURL = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/feature-backlink-bg-mobile.png';
            } else {
                this.adblockBackgroundURL = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/feature-backlink-bg.png';
            }
        });
        this.calculateReadingTime = calculateReadingTime;
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
    ngOnInit() {
        try {
            this.setFormIDs();
        } catch (err) {
            console.error(...new ExxComError(923241, scriptName, err).stamp());
        }
    }

    async ngOnChanges(changes: any) {
        try {
            if (!this.data && changes && changes.data && changes.data.currentValue) {
                this.data = changes.data.currentValue;
            }
            const rows = get(changes, 'data.currentValue.rows', []);
            rows.forEach((r) => {
                if (Object.keys(r)[0] === 'feature_solutions') {
                    r.feature_solutions.feature_solutions[0].feature_solutions.forEach(async (solution) => {
                        await this.configuratorExists(solution);
                    });
                }
            });
        } catch (err) {
            console.error(...new ExxComError(999441, scriptName, err).stamp());
        }
    }

    setFormIDs() {
        try {
            this.cmsMarketoIDsService.getFormIDs().then((res) => (this.marketoSolutionsID = res?.exx_solution_inquiry_id?.marketo_id));
        } catch (err) {
            console.error(...new ExxComError(992321, scriptName, err).stamp());
        }
    }

    async configuratorExists(solution: any) {
        try {
            if (solution.part_number != '') {
                let mpnSplit = solution.part_number.split('-');
                mpnSplit = mpnSplit.slice(0, 2);
                const mpn = mpnSplit.join('-');
                const config = await this.configuratorService.getConfigurationWithBasePrice('exx', mpn);
                if (config) {
                    solution.configuratorUrl = '/' + solution.part_number + '/configurator';
                    solution.configExists = config[0]?.isActive;
                    solution.price = get(config, '[0].basePrice', get(config, '[0].optimizedConfigurations.good.priceTotal', ''));
                }
            }
        } catch (err) {
            console.error(...new ExxComError(999442, scriptName, err).stamp());
        }
    }

    get categories() {
        return ['Blog', 'Case Studies', 'Whitepapers', 'eBooks'];
    }

    reformatDate(entry: any) {
        try {
            if (!entry || !entry.date) {
                return '';
            }
            return new Date(entry.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        } catch (err) {
            console.error(...new ExxComError(484999, scriptName, err).stamp());
        }
    }

    getFullWidthBlockSrc(srcObj: Object) {
        let src = '';
        if (this.isNarrow) {
            src = get(srcObj, 'full_width_block[0].image_mobile.href');
            src = src ? src : get(srcObj, 'full_width_block[0].image_desktop.href');
        } else {
            src = get(srcObj, 'full_width_block[0].image_desktop.href');
        }
        return src;
    }
}
