import { Pipe, PipeTransform } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'trim-pipe';
@Pipe({ name: 'trim' })
export class TrimPipe implements PipeTransform {
    transform(value: any) {
        try {
            if (!value) {
                return '';
            }

            return value.replace(/\s*/g, '');
        } catch (err) {
            console.error(...new ExxComError(112312, scriptName, err).stamp());
            return '';
        }
    }
}
