// route: 'title'
export const appPageTitles = {
    '': 'Exxact Corporation | Deep Learning, HPC, AV, Distribution & More',
    'About-Us': 'About Us | Exxact Corporation',
    'Case-Studies': 'Case Studies | Exxact Corporation',
    'Contact-Us': 'Contact Us | Exxact Corporation',
    eBooks: 'eBooks | Exxact Corporation',
    'News-Events': 'News & Events | Exxact Corporation',
    'Reference-Architecture': 'Reference Architecture | Exxact Corporation',
    'Resource-Center': 'Resource Center | Exxact Corporation',
    'Return-Policy': 'Return Policy | Exxact Corporation',
    'RMA-Request': 'RMA Request | Exxact Corporation',
    'Service-Support': 'Service Support | Exxact Corporation',
    'Supported-Software': 'Supported Software | Exxact Corporation',
    Whitepapers: 'Whitepapers | Exxact Corporation',
};
