import { Component, Input } from '@angular/core';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { WebpService } from 'lib/services/webp.service';

@Component({
    selector: 'app-product-tile-rows',
    templateUrl: './product-tile-rows.component.html',
    styleUrls: ['./product-tile-rows.component.scss'],
})
export class ProductTileRowsComponent extends ExxComComponentClass {
    @Input() tiles;
    getWebpImg: (src: string) => string;

    constructor(webpService: WebpService) {
        super({ dependencies: { webpService } });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
