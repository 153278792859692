import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-about-us.component';

@Component({
    selector: 'app-cms-about-us',
    templateUrl: './cms-about-us.component.html',
    styleUrls: ['./cms-about-us.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsAboutUsComponent implements OnInit {
    entry: any = {};
    getWebpImg: (src: string) => string;

    constructor(
        private cmsService: CmsService,
        private metaService: MetaService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('about_us');
        } catch (err) {
            console.error(...new ExxComError(888334, scriptName, err).stamp());
        }
    }
}
