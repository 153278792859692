import { Input, OnChanges, OnDestroy, OnInit, Directive, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { contains, isBrowser, wait } from 'lib/tools';
import { calculateReadingTime } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { BlogCountService } from 'lib/services/blog-count.service';
import { Subscription } from 'rxjs';

const scriptName = 'cms-blog-category-component.class';

@Directive()
export class CmsBlogCategoryComponentClass extends CmsBlogComponentClass implements OnInit, OnChanges, OnDestroy {
    // Inputs
    @ViewChild('infiniteScrollContainer') infiniteScrollContainer: ElementRef;
    @Input() name: string = 'Recent';
    @Input() blogInfo: any;
    search: string = '';
    tag: string = '';
    noBlogResults: boolean = true;
    queryLoadingFinished: boolean = false;
    // Dependencies
    blogCountService: BlogCountService;
    cmsService: CmsService;
    routerService: RouterService;
    dialog: MatDialog;
    infiniteScrollService: InfiniteScrollService;
    router: Router;

    // Properties: public

    activeCategoryName: string;
    featureBlog: any;
    formId: string = '';
    blogCategories: any;
    skipTotal: number = 0;
    skipFiltered: number = 0;
    pages: any = [];
    filteredPages: any = [];
    prevPage: number = 1;
    subscription: Subscription;
    itemCount: number = 0;

    calculateReadingTime: (entry: any) => string;

    constructor({ dependencies }) {
        super({ dependencies });
        if (this.environment.siteAbbr == 'exx') {
            this.subscription = this.blogCountService.blogInfoSource.subscribe((blogInfo) => {
                this.blogInfo = blogInfo;
            });
            this.extractURLParams();
            this.activeCategoryName = decodeURIComponent(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
            this.paginate(1);
        }

        this.type = 'category';
        this.calculateReadingTime = calculateReadingTime;
    }

    ngOnChanges(changes: any) {
        try {
            const name = get(changes, 'name');
            const previous = get(name, 'previousValue');
            const current = get(name, 'currentValue');
            if (!name || !previous || current == previous) {
                return;
            }
            this.entries = [];
            if (this.environment.siteAbbr == 'spc') {
                this.infiniteScrollService.unregister('blogCategory');
                this.initInfiniteScroll();
            }
        } catch (err) {
            console.error(...new ExxComError(634000, scriptName, err).stamp());
        }
    }
    /**
     *
     * @param blogData blogCountService object that contains count for each category
     * @sets the count of the blog category currently to itemCount
     */
    blogCategoryLength(blogData: any) {
        if (this.search !== '' || this.tag !== '') {
            return;
        }
        for (let i = 0; i < blogData.length; i++) {
            if (blogData[i][this.name]) {
                this.itemCount = blogData[i][this.name];
                break;
            }
        }
    }

    async ngOnInit() {
        try {
            this.name = this.getCategoryName(this.router.url) || this.name || '';
            this.initMetaData(this.name);
            await wait('100ms');

            if (this.environment.siteAbbr == 'exx') {
                this.blogCategories = await this.cmsService.getEntries('exx_blog_category', {});
            }
            if (this.environment.siteAbbr == 'spc') {
                this.initInfiniteScroll();
            }
        } catch (err) {
            console.error(...new ExxComError(410488, scriptName, err).stamp());
        }
    }

    ngOnDestroy(): void {
        if (this.environment.siteAbbr == 'exx') {
            this.subscription.unsubscribe();
        }

        if (this.environment.siteAbbr == 'spc') {
            this.infiniteScrollService.unregister('blogCategory');
            if (!isBrowser()) {
                return;
            }
            const infiniteScroll = document.getElementById('infinite-scroll-global-container');
            infiniteScroll.style.overflow = '';
        }
    }
    /**
   * This method fires when a new filter is selected (category name). It will clear the current entries, and pages so it can be
   * populated again with their respected data.
   @entries the current page of data
   @filteredPages the whole set of page data
   @skipFiltered query count to prevent calling the same data again
   @search query search
   @tag query search
   */
    changeFilteredEntries() {
        if (this.activeCategoryName === this.name) {
            return;
        }
        if (this.search !== '' || window.location.pathname !== '/blog') {
            this.router.navigateByUrl(`/blog/category/${this.name}`);
        }
        this.entries = [];
        this.filteredPages = [];
        this.skipFiltered = 0;
        this.search = '';
        this.tag = '';
        this.activeCategoryName = this.name;
        this.paginate(1);
    }

    /**
     * Purpose of this method is to extract the URL params to establish the state of the page. Sets search/tag values depending on the url
     */

    extractURLParams() {
        const path = window.location.pathname;
        if (contains(path, 'blog/result/input')) {
            this.search = decodeURIComponent(path.substring(path.lastIndexOf('/') + 1));
            this.tag == '';
        } else if (contains(path, 'blog/category')) {
            this.name = decodeURIComponent(path.substring(path.lastIndexOf('/') + 1));
            this.tag == '';
        } else if (contains(path, 'blog/result/tag')) {
            this.tag = decodeURIComponent(path.substring(path.lastIndexOf('/') + 1));
        }
    }
    /**
     * @docs purpose is to paginate data. This method is seperated into two approaches, filtering by a name, or querying all types.
     * When a user navigates to a new page, if they navigate within the page reserve (the amount of pages queried ahead of time) then the
     * entries for that page will be set to the already queried data.
     * If the data does not exist and they attempt to navigate to a page outside of the page reserve, extra queries will be made to compensate
     * When a query is made, we must add the amount we totaled to the skip or skipFiltered in order to prevent our API calls from querying
     * the same data again. If we query for 60 items, we add 60 to the skip total. That way when we query for another 60, we're querying
     * for the 60 after the 60 we just got.
     * @page page traveling to
     * @limit total amount to query
     * @pageReserve amount of pages of already queried data
     * @returns
     */
    public async paginate(page: number) {
        try {
            const scrollAnchor = document.getElementById('scroll-anchor');
            if (scrollAnchor) {
                scrollAnchor.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
            }
            const pageIndex = page - 1;
            if (!isBrowser()) {
                return;
            }
            const amountPerPage = 21;
            const limit = amountPerPage * 2;
            const pageReserve = limit / amountPerPage;
            const pageDiff = page - this.prevPage;
            // If search and tag is empty or undefined, We are on blog homepage and will use the regular getblogposts api
            if (contains(this.search, '*')) {
                this.search = '';
            }
            if (this.search === '' && this.tag === '') {
                if (this.isEmpty(this.pages[pageIndex]) && this.name === 'Recent') {
                    this.blogCategoryLength(this.blogInfo);
                    // If client page that is desired is outside of current pages queried, we make extra queries
                    if (pageDiff > pageReserve) {
                        this.queryLoadingFinished = false;
                        const pagesNeeded = pageDiff - pageReserve;
                        const extraQueries = Math.ceil((amountPerPage * pagesNeeded) / limit);
                        for (let i = 0; i <= extraQueries; i++) {
                            const result = await this.cmsService.getBlogPosts(limit, this.skipTotal);
                            while (result.length) {
                                this.pages.push(result.splice(0, amountPerPage));
                            }
                            this.skipTotal += limit;
                        }
                        // normal pagination if client clicks within page reserve
                    } else {
                        this.queryLoadingFinished = false;
                        const additionalPosts = await this.cmsService.getBlogPosts(limit, this.skipTotal);
                        this.skipTotal += limit;
                        if (additionalPosts.length !== 0) {
                            for (let i = this.pages.length; i < pageIndex + pageReserve; i++) {
                                this.pages[i] = additionalPosts.splice(0, amountPerPage);
                            }
                        }
                    }
                }
                // populates entry with selected category

                if (this.isEmpty(this.filteredPages[pageIndex]) && this.name !== 'Recent') {
                    this.blogCategoryLength(this.blogInfo);
                    // if client clicks outside of page reserve, make extra queries
                    if (pageDiff > pageReserve) {
                        this.queryLoadingFinished = false;
                        const pagesNeeded = pageDiff - pageReserve;
                        const extraQueries = Math.ceil((amountPerPage * pagesNeeded) / limit);

                        for (let i = 0; i < extraQueries; i++) {
                            const result = await this.cmsService.getBlogPostsByCategory(this.name, limit, this.skipTotal);
                            while (result.length) {
                                this.filteredPages.push(result.splice(0, amountPerPage));
                            }
                            this.skipFiltered += limit;
                        }
                        // normal pagination within page reserve
                    } else {
                        this.queryLoadingFinished = false;
                        const additionalPosts = await this.cmsService.getBlogPostsByCategory(this.name, limit, this.skipFiltered);
                        this.skipFiltered += limit;
                        if (additionalPosts.length !== 0) {
                            for (let i = this.filteredPages.length; i < pageIndex + pageReserve; i++) {
                                this.filteredPages[i] = additionalPosts.splice(0, amountPerPage);
                            }
                        }
                    }
                }
                // if search is not empty, we are paginating with the current search using the search api
                // We do not know how many items will be in the query, so we must query for all of them for a
                // complete pagination component (total pages needed is required)
            }
            if ((this.isEmpty(this.filteredPages) || this.search !== '' || this.tag !== '') && page == 1) {
                let totalItems = 0;
                let filterSearch = '';
                // determine if search is by tag or text input
                this.tag === '' ? (filterSearch = this.search) : (filterSearch = this.tag);
                let result = await this.cmsService.searchBlogPostsByTitle(filterSearch, limit / 2, this.skipFiltered);
                // Because we are querying until we run out of items, skipFiltered is set to the same as the page count for simplicities sake.
                this.skipFiltered += limit / 2;
                totalItems += result.length;

                while (result.length) {
                    this.filteredPages.push(result.splice(0, amountPerPage));
                    result = await this.cmsService.searchBlogPostsByTitle(filterSearch, limit / 2, this.skipFiltered);
                    totalItems += result.length;
                    this.skipFiltered += limit / 2;
                }
                this.itemCount = totalItems;
                this.queryLoadingFinished = true;
            }
            // determining how to populate entries based on search/filtering
            if (this.search === '' && this.tag === '') {
                this.name === 'Recent' ? (this.entries = [...this.pages[pageIndex]]) : (this.entries = [...this.filteredPages[pageIndex]]);
                this.noBlogResults = this.isEmpty(this.entries);
            } else if ((this.search !== '' && this.itemCount !== 0) || (this.tag !== '' && this.itemCount !== 0)) {
                this.entries = [...this.filteredPages[pageIndex]];
                this.noBlogResults = this.isEmpty(this.entries);
            } else {
                this.itemCount = 0;
                this.noBlogResults = true;

                return;
            }

            this.prevPage = page;
            this.queryLoadingFinished = true;
        } catch (err) {
            console.error(...new ExxComError(300201, scriptName, err).stamp());
        }
    }

    // onChosenTitle(title: string) {
    //   try {
    //     this.router.navigateByUrl(`/blog/${title}`);
    //   } catch (err){ console.error(...new ExxComError(231321, scriptName, err).stamp()); }
    // }

    private async initInfiniteScroll() {
        try {
            if (!isBrowser()) {
                return;
            }
            const limit = 18;
            const retriever = async (skip: number) => {
                const additionalPosts =
                    this.name == 'Recent'
                        ? await this.cmsService.getBlogPosts(limit, skip)
                        : await this.cmsService.getBlogPostsByCategory(this.name, limit, skip);
                this.entries = this.entries.concat(additionalPosts);
            };
            await retriever;
            const el = document.getElementById('infinite-scroll-global-container');
            this.infiniteScrollService.register({
                container: el,
                key: 'blogCategory',
                containerHeight: '100vh',
                limit: limit,
                retriever: retriever,
            });
        } catch (err) {
            console.error(...new ExxComError(300201, scriptName, err).stamp());
        }
    }
}
