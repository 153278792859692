import { Component, Inject, Input, OnChanges } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { Location } from '@angular/common';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { BreakpointObserver } from '@angular/cdk/layout';
import { get } from 'lodash';
import { getRelatedBlogPosts } from 'lib/tools';
import { CmsService } from 'lib/services/cms.service';

const scriptName = 'cms-partial-category-top-component-related-content.component';

@Component({
    selector: 'cms-partial-category-top-component-related-content',
    templateUrl: './related-content.component.html',
    styleUrls: ['./related-content.component.scss'],
})
export class RelatedContentComponent extends CmsPartialComponentClass implements OnChanges {
    @Input() relatedContentRow: any;
    getWebpImg: (src: string) => string;
    activeCategoryName: string = 'Blog';
    recommendedBlogPosts: any = [];

    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        cmsService: CmsService,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                cmsService,
                environment,
                routerService,
                location,
                categoryService,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
    async ngOnChanges(changes: any) {
        try {
            if (!this.relatedContentRow && changes && changes.relatedContentRow && changes.relatedContentRow.currentValue) {
                this.relatedContentRow = changes.relatedContentRow.currentValue;
            }
            const rows = get(changes, 'relatedContentRow.currentValue.rows', []);

            if (this.recommendedBlogPosts && this.recommendedBlogPosts.length === 0 && rows.length > 0) {
                let relatedContent = null;
                let blogTags = null;
                // go through category top rows and find related content
                rows.forEach((r) => {
                    if (Object.keys(r)[0] === 'related_content') {
                        relatedContent = get(r, 'related_content.related_content[0]', r);
                        blogTags = get(r, 'related_content.related_content[0].blog.blog_tags', r);
                    }
                });
                if (relatedContent) {
                    const posts = await getRelatedBlogPosts({
                        entry: relatedContent,
                        cmsService: this.cmsService,
                        environment: this.environment,
                        tags: blogTags,
                        limit: 100,
                    });
                    this.recommendedBlogPosts = posts;
                }
            }
        } catch (err) {
            console.error(...new ExxComError(999441, scriptName, err).stamp());
        }
    }
    onChosenTitle(title: string) {
        try {
            this.activeCategoryName = title;
        } catch (err) {
            console.error(...new ExxComError(484996, scriptName, err).stamp());
        }
    }
    get categories() {
        return ['Blog', 'Case Studies', 'Whitepapers', 'eBooks'];
    }

    getCategoryPosts(entry: any, categoryName?: string) {
        const chosenCategoryName = categoryName ? categoryName : this.activeCategoryName;

        try {
            if (chosenCategoryName == 'Blog') {
                return this.recommendedBlogPosts;
            } else if (chosenCategoryName == 'Case Studies') {
                return this.get(entry, 'related_content[0].case_studies.gated_content_case_studies') || [];
            } else if (chosenCategoryName == 'eBooks') {
                return this.get(entry, 'related_content[0].ebooks.gated_content_ebooks') || [];
            } else {
                return this.get(entry, 'related_content[0].whitepapers.gated_content_whitepapers') || [];
            }
        } catch (err) {
            console.error(...new ExxComError(484997, scriptName, err).stamp());
        }
    }
}
