import { Component, Input } from '@angular/core';
import { get } from 'lodash';

type dropDownDataType = {
    buttonTitle: String;
    listItems: String[];
    dropdownMenuID: String;
};

@Component({
    selector: 'exx-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
    @Input() dropdownWidth: number;
    @Input() dropdownData: dropDownDataType;
    constructor() {}

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
