import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { Location } from '@angular/common';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
@Component({
    selector: 'cms-partial-category-top-multiple-columns-v2',
    templateUrl: './multiple-columns-v2.component.html',
    styleUrls: ['./multiple-columns-v2.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MultipleColumnsComponentV2 extends CmsPartialComponentClass {
    @Input() multipleColumnsRow: any;
    @Input() solutionID: string;
    @Input() dataTitle: string;
    @Input() dataUrl: string;
    getWebpImg: (src: string) => string;

    constructor(
        @Inject('environment') environment: any,
        public bootstrapMarketoService: BootstrapMarketoService,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                environment,
                bootstrapMarketoService,
                routerService,
                location,
                categoryService,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
