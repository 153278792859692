import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';

@Component({
    selector: 'emli-table-modal',
    templateUrl: './emli-table-modal.component.html',
    styleUrls: ['./emli-table-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmliTableModalComponent extends ExxComComponentClass implements OnInit {
    constructor(breakpointObserver: BreakpointObserver) {
        super({ dependencies: { breakpointObserver } });
    }

    ngOnInit() {}
}
