<div id="cms-test-drive">
  <div *ngFor="let banner of get(entry, 'banner', [])">
    <!-- BANNER -->
    <section
      *ngIf="!isNarrow"
      class="cms-banner py-4"
      [ngStyle]="{
        'background-image': 'url(' + get(banner, 'background_image_wide.href', '') + ')'
      }"
    >
      <div class="fx-wrap fx-row-container fx-row-h-space-between hero-banner-text">
        <div class="col-sm-6 fx-col fx-col-v-center my-5">
          <h5 class="banner-label">{{ get(banner, "banner_label", "") }}</h5>

          <h1 class="banner-title" [ngClass]="get(banner, 'font_color_light_theme') ? 'font-light-theme' : ''">
            {{ get(banner, "banner_title", "") }}
          </h1>

          <p [ngClass]="get(banner, 'font_color_light_theme') ? 'font-light-theme banner-copy' : 'banner-copy'">
            {{ get(banner, "banner_copy", "") }}
          </p>

          <div class="fx-row">
            <button
              *ngIf="get(banner, 'button_text_select', false)"
              class="button-primary"
              (click)="bootstrapMarketoService.openBootstrapMarketoDialog(get(banner, 'marketo_forms.id', ''))"
              data-cy="exx-test-drive-button-desktop"
            >
              {{ get(banner, "button_text_select", "") }}
              <mat-icon class="mat-icon notranslate material-icons" role="img" aria-hidden="true"
                >chevron_right</mat-icon
              >
            </button>
          </div>
        </div>

        <div *ngIf="get(banner, 'badge', false)" class="test-drive-badge">
          <img [src]="getWebpImg(get(banner, 'badge', ''))" [alt]="get(banner, 'banner_title', '')" />
        </div>
      </div>
    </section>

    <section *ngIf="isNarrow" class="cms-banner fx-col pb-4">
      <div
        class="hero-banner"
        data-cy="test-drive-hero-banner"
        [ngStyle]="{
          'background-image': 'url(' + get(banner, 'background_image_wide.href', '') + ')
        '
        }"
      >
        <div *ngIf="get(banner, 'badge', false)" class="test-drive-badge">
          <img [src]="getWebpImg(get(banner, 'badge', ''))" [alt]="get(banner, 'banner_title', '')" />
        </div>
      </div>

      <div class="p-2">
        <h5 class="banner-label">{{ get(banner, "banner_label", "") }}</h5>
        <h1 class="banner-title" [ngClass]="get(banner, 'font_color_light_theme') ? 'font-color-light' : ''">
          {{ get(banner, "banner_title", "") }}
        </h1>
        <p [ngClass]="get(banner, 'font_color_light_theme') ? 'font-color-light banner-copy' : 'banner-copy'">
          {{ get(banner, "banner_copy", "") }}
        </p>
        <div class="fx-row">
          <button
            *ngIf="get(banner, 'button_text_select', false)"
            class="button-primary"
            (click)="bootstrapMarketoService.openBootstrapMarketoDialog(get(banner, 'marketo_forms.id', ''))"
            data-cy="exx-test-drive-button-mobile"
          >
            {{ get(banner, "button_text_select", "") }}
            <mat-icon class="mat-icon notranslate material-icons" role="img" aria-hidden="true">chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </div>

  <!-- PRODUCT-BLURB  -->
  <section
    *ngFor="let reference of get(entry, 'reference', []); let i = index"
    [class]="
      i % 2 != 0
        ? 'product-blurb fx-row-container fx-wrap fx-row-h-space-between py-4'
        : 'product-blurb fx-row-container fx-wrap fx-row-h-space-between reverse py-4'
    "
  >
    <div class="col-md-6 fx-col fx-col-v-center image-container">
      <img *ngIf="get(reference, 'image.href', '')" [src]="getWebpImg(reference.image.href)" alt="Partner Logos" />
    </div>
    <div class="col-md-5 fx-col fx-col-v-center">
      <h2>{{ get(reference, "header", "") }}</h2>
      <div [innerHTML]="get(reference, 'text', '') | safe: 'html'"></div>
      <button
        *ngIf="get(reference, 'link.title', false)"
        class="button-primary"
        (click)="bootstrapMarketoService.openBootstrapMarketoDialog(get(reference, 'marketo_form.id', ''))"
      >
        {{ reference.link.title }}
        <mat-icon class="mat-icon notranslate material-icons" role="img" aria-hidden="true">chevron_right</mat-icon>
      </button>
    </div>
  </section>

  <!-- QUOTE -->
  <section *ngIf="get(entry, 'quote[0].quotes', false)" id="test-drive-quote-section">
    <app-quotes
      name="quote-swiper"
      mode="single"
      [data]="entry.quote[0].quotes"
      selector="test-drive-quote-swiper"
      class="quote-swiper-wide"
    >
    </app-quotes>
  </section>
</div>
