<div class="use-cases">
    <h2 class="use-cases-header">{{ row.header }}</h2>
    <div class="use-cases-category">
        <div class="use-cases-category-item" *ngFor="let item of row?.category; let i = index" (click)="changeActiveCategory(i)">
            <img
                class="use-cases-category-item-image"
                [class.use-cases-category-item-image-highlighted]="activeCategory == i"
                [src]="item?.category_image?.href"
                [alt]="item?.category_image?.title"
            />
            <h5 class="use-cases-category-item-name" [class.use-cases-category-item-name-active]="activeCategory == i">
                {{ item?.category_name }}
            </h5>
        </div>
    </div>
    <div class="use-cases-solution">
        <div class="use-cases-solution-item" *ngFor="let item of activeSolutions">
            <button-component
                [buttonOptions]="{
                    text: item?.solution_name,
                    internalLink: item?.solution_link?.href,
                    iconPosition: 'right',
                    iconName: 'chevron-right',
                    type: 'tiny-use-case',
                    class: 'use-cases-solution-item-anchor'
                }"
            ></button-component>
        </div>
    </div>
</div>
