import { AfterContentInit, Component, Inject, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BreakpointObserver } from '@angular/cdk/layout';
import { v4 as uuidv4 } from 'uuid';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName: string = 'Table component';

@Component({
    selector: 'cms-partial-category-top-component-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TableComponent extends CmsPartialComponentClass implements AfterContentInit, OnInit {
    @Input() tableRow: any;
    @Input() grayBackgroundRows: any = [];
    @Input() isBlogPage: boolean = false;
    @ViewChild('stackedTable', { static: true }) stackedTable: TemplateRef<any>;
    @ViewChild('tabbedTable', { static: true }) tabbedTable: TemplateRef<any>;
    @ViewChild('tabbedAndStackedTable', { static: true })
    tabbedAndStackedTable: TemplateRef<any>;
    bsModalRef: BsModalRef;
    currentTableIndex: number = 0;
    getWebpImg: (src: string) => string;
    activeTabWidth: number;
    activeTabWidthString: string;
    tableIdentifier: string = uuidv4();
    isTableOpen: boolean = false;
    maxCount: number;
    widthArray: number[] = [];
    transformAmountNumber: number = 0;
    transformAmount: string;
    activeTable: Array<[]>;
    activatePreview: boolean = false;
    didLoadHappenYet: boolean = false;
    tableType: string;
    tableTypeRef: any;
    tabbedAndStackedLength: number;
    isNewTable: boolean = false;
    tabWidthExceeded: boolean = false;

    constructor(
        @Inject('environment') environment: any,
        routerService: RouterService,
        breakpointObserver: BreakpointObserver,
        bsModalRef: BsModalRef,
        bsModalService: BsModalService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                bsModalRef,
                breakpointObserver,
                bsModalService,
                environment,
                routerService,
                location,
                categoryService,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
    ngOnInit(): void {
        try {
            this.isNewTable = this.get(this.tableRow, 'old_or_new_table') == 'new_table';
            if (this.isNewTable) {
                this.tableType = this.get(this.tableRow, 'table_type');
                this.tabbedAndStackedLength = this.get(this.tableRow, 'new_table_format_2.table_group').length;
                this.setTableTypeRef();
                this.maxCount = this.get(this.tableRow, 'new_table_format_2.row_count_cut_off');
            }
        } catch (err) {
            console.error(...new ExxComError(786234, scriptName, err).stamp());
        }
    }
    ngAfterContentInit(): void {
        try {
            if (this.isNewTable) {
                this.setActiveTable(0);
                this.determineLength();
            }
        } catch (err) {
            console.error(...new ExxComError(972321, scriptName, err).stamp());
        }
    }

    /**
     * @function setTableTypeRef
     * @description Sets table type in order to render the correct template reference from modal
     */

    setTableTypeRef() {
        try {
            if (this.tableType == 'stacked_table') this.tableTypeRef = this.stackedTable;
            if (this.tableType == 'tabbed_table') this.tableTypeRef = this.tabbedTable;
            if (this.tableType == 'tabbed_and_stacked') this.tableTypeRef = this.tabbedAndStackedTable;
        } catch (err) {
            console.error(...new ExxComError(223344, scriptName, err).stamp());
        }
    }

    /**
     * @function determineLength
     * @description Depending on table type, this function will iterate through the table rows and count them accordingly. If they exceed
     * max count set in ngOnInit, we will set activatePreview as true. This will render a preview design rather than a large table,
     * avoiding a large table in the DOM.
     */
    determineLength() {
        try {
            if (this.tableType == 'tabbed_and_stacked' && this.tabbedAndStackedLength < 2) return;
            const tableArray = this.get(this.tableRow, 'new_table_format_2.table_group[0].tables');
            let largestLength = 0;
            if (this.tableType == 'tabbed_and_stacked') {
                const longerTableArray = this.get(this.tableRow, 'new_table_format_2.table_group');
                longerTableArray.map((table) => {
                    let tableGroupLargestLength = 0;
                    table.tables.map((tableGroup) => {
                        tableGroupLargestLength += tableGroup?.table_reference[0]?.table?.rows.length;
                    });

                    largestLength = Math.max(tableGroupLargestLength, largestLength);
                });
            } else if (this.tableType == 'stacked_table') {
                tableArray.map((table) => {
                    largestLength += table?.table_reference[0]?.table?.rows.length;
                });
            } else {
                tableArray.map((table) => {
                    largestLength = Math.max(table?.table_reference[0]?.table?.rows.length, largestLength);
                });
            }

            if (largestLength <= this.maxCount) {
                setTimeout(() => {
                    this.setWidths(this.activatePreview);
                }, 1000);
            } else {
                this.activatePreview = true;
            }
        } catch (err) {
            console.error(...new ExxComError(972312, scriptName, err).stamp());
        }
    }
    /**
     * @function setWidths
     * @description Depending on the type of table and if there is a preview, we wait for the table to render in the dom. We then
     * search for each tab in the dom. If there is a preview, we end up with tab duplicates based on modal rendering. We only need the last
     * half of the tabs since they're duplicates (so slice is used). We then iterate through the tabs to get their respective widths for
     * a tab width array. This is important because I do not know how much to translate the tab by when transitioning tabs unless we
     * know the width of the next tab.
     */
    setWidths(isPreview: boolean) {
        try {
            if (isPreview != true) {
                const allTabs = document.getElementsByClassName(`${this.tableIdentifier}-tab`);
                const tabsArray = Array.from(allTabs);

                tabsArray.map((tabsArray) => {
                    const width = tabsArray.getBoundingClientRect();
                    this.widthArray.push(width.width);
                });
            } else {
                const allTabs = Array.from(document.getElementsByClassName(`${this.tableIdentifier}-tab`));
                if (allTabs.length % 2 != 0) {
                    return;
                }
                const middle = allTabs.length / 2;
                const activeTabs = allTabs.slice(middle);
                activeTabs.map((tab) => {
                    const width = tab.getBoundingClientRect();
                    this.widthArray.push(width.width);
                });
            }
            let totalWidth = 0;
            this.widthArray.map((width) => {
                totalWidth += width;
            });

            if (totalWidth > 900) {
                this.tabWidthExceeded = true;
            }
            // Here we initialize the widths of all tabs so we can do calculations for transformations

            // change this function to accept an array of elements that we map over and spit out the widths
            this.activeTabWidth = this.widthArray[0];
            this.activeTabWidthString = `${this.activeTabWidth}px`;
        } catch (err) {
            console.error(...new ExxComError(231232, scriptName, err).stamp());
        }
    }
    /**
     * @function setTable
     * @description setTable is called when a tab is clicked. We primarily use this function to keep track of tab index
     * and to set the width of the tab based on the index.
     */
    setTable(index: number) {
        try {
            this.activeTabWidthString = `${this.widthArray[index]}px`;
            let transAmount = 0;
            for (let i = 0; i < index; i++) {
                transAmount += this.widthArray[i];
            }

            this.transformAmount = `translateX(${transAmount + index * 32}px)`;

            this.currentTableIndex = index;
            this.setActiveTable(this.currentTableIndex);
        } catch (err) {
            console.error(...new ExxComError(215231, scriptName, err).stamp());
            return '';
        }
    }
    /**
     * @function openTable
     * @description Opens the table based on the set tableref. We have to set a short time out to wait for the dom elements to load/render
     * before we can run setWidths to determine the tab widths that are going to exist within the table.
     */
    openTable(template: TemplateRef<any>) {
        try {
            if (this.isTableOpen) return;
            this.bsModalRef = this.bsModalService.show(template, {
                class: 'modal-dialog-centered new-table table-modal',
            });

            this.bsModalRef.onHide.subscribe(() => {
                this.isTableOpen = false;
            });
            if (!this.didLoadHappenYet) {
                setTimeout(() => {
                    this.setWidths(this.activatePreview);
                    this.didLoadHappenYet = true;
                }, 300);
            }
        } catch (err) {
            console.error(...new ExxComError(233422, scriptName, err).stamp());
            return '';
        }
    }
    /**
     * @function setActiveTable
     * @description setActiveTable takes the index passed in and sets the table array of rows based on the tableType
     */
    setActiveTable(index: number) {
        try {
            if (this.tableType == 'tabbed_and_stacked' && this.tabbedAndStackedLength < 2) return;
            if (this.get(this.tableRow, 'new_table_format_2.table_group').length > 1 && this.tableType == 'tabbed_and_stacked') {
                this.activeTable = this.get(this.tableRow, `new_table_format_2.table_group[${index}].tables`);
            } else {
                this.activeTable = this.get(this.tableRow, `new_table_format_2.table_group[0].tables[${index}].table_reference[0].table.rows`);
            }
        } catch (err) {
            console.error(...new ExxComError(111123, scriptName, err).stamp());
            return '';
        }
    }
}
