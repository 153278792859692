import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Input } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';

const scriptName = 'header.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() isResourcePage: boolean;
    isBrowser: boolean = isBrowser();

    isNarrow: boolean;
    menuActive: boolean;
    overlayVisible: boolean = false;
    overlayZindex: number = 100;
    searchActive: boolean;
    toggleSearch: boolean;

    constructor(private breakpointObserver: BreakpointObserver) {}

    async ngOnInit() {
        try {
            this.initBreakpointObserver();
        } catch (err) {
            console.error(...new ExxComError(392887, scriptName, err).stamp());
        }
    }

    private initBreakpointObserver() {
        try {
            this.breakpointObserver.observe(['only screen and (min-width: 1100px)']).subscribe((result) => {
                this.isNarrow = !result.matches;
                /**
                 * Everything closes when the breakpoint changes. The overlay is
                 * controlled in this component. Closing the individual menus is
                 * controlled in the child components.
                 */
                this.overlayVisible = false;
                this.menuActive = false;
                this.searchActive = false;
            });
        } catch (err) {
            console.error(...new ExxComError(320948, scriptName, err).stamp());
        }
    }

    private setOverlayVisibility() {
        try {
            this.overlayVisible = this.menuActive || this.searchActive;
        } catch (err) {
            console.error(...new ExxComError(103887, scriptName, err).stamp());
        }
    }

    onActiveChange(which: string, $event: boolean, zIndex: number) {
        try {
            this[`${which}Active`] = $event;
            this.overlayZindex = zIndex;
            this.setOverlayVisibility();
        } catch (err) {
            console.error(...new ExxComError(620958, scriptName, err).stamp());
        }
    }

    onToggleSearch($event: boolean) {
        try {
            this.toggleSearch = $event;
        } catch (err) {
            console.error(...new ExxComError(997773, scriptName, err).stamp());
        }
    }
}
