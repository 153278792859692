import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-customers.component';

@Component({
    selector: 'app-cms-customers',
    templateUrl: './cms-customers.component.html',
    styleUrls: ['./cms-customers.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsCustomersComponent extends ExxComComponentClass implements OnInit {
    // Dependencies
    cmsService: CmsService;
    breakpointObserver: BreakpointObserver;

    // Properties: public
    entry: any = [];
    backgroundBanner: string = this.isNarrow
        ? 'https://assets.exxactcorp.com/img/exx/cms/company/customers/exx-customers-mob.png'
        : 'https://assets.exxactcorp.com/img/exx/cms/company/customers/customer-bg.png';
    getWebpImg: (src: string) => string;

    constructor(b: BreakpointObserver, c: CmsService, w: WebpService) {
        super({
            dependencies: {
                breakpointObserver: b,
                cmsService: c,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('customers');
        } catch (err) {
            console.error(...new ExxComError(246662, scriptName, err).stamp());
        }
    }

    onResize() {
        this.backgroundBanner = this.isNarrow
            ? 'https://assets.exxactcorp.com/img/exx/cms/company/customers/exx-customers-mob.png'
            : 'https://assets.exxactcorp.com/img/exx/cms/company/customers/customer-bg.png';
    }
}
