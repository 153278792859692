import { Pipe, PipeTransform } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'read-time-blog-pipe';

@Pipe({
    name: 'readTime',
})
export class readTimePipe implements PipeTransform {
    transform(articleCharCount: number) {
        try {
            const avgWPM = 200;
            const avgWordLength = 5;
            const min = Math.round(articleCharCount / (avgWPM * avgWordLength));

            return `${min} min read`;
        } catch (err) {
            console.error(...new ExxComError(662312, scriptName, err).stamp());
            return '';
        }
    }
}
