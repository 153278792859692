<div id="header-menu">
    <nav id="header-menu-wide" class="header-row" (mouseover)="setIsActive(true)" (mouseout)="setIsActive(false)">
        <div class="header-menu-wide-level1 fx-row fx-row-h-center fx-row-v-center" data-cy="header-content-parent">
            <div *ngFor="let level1Category of menu" class="header-menu-wide-level1-item">
                <div class="header-menu-wide-level1-label header-menu-label fx-row fx-row-h-center fx-row-v-center">
                    {{ level1Category.name }}
                    <mat-icon>expand_more</mat-icon>
                </div>

                <div class="header-menu-wide-level2" [ngStyle]="{ 'height.px': level2MenuHeight, 'width.px': level2MenuWidth }">
                    <div class="py-2 level2-content">
                        <div
                            *ngFor="let level2Category of level1Category.categories"
                            class="header-menu-wide-level2-item"
                            (mouseenter)="mouseEnter(level1Category, level2Category)"
                            (mouseleave)="mouseLeave()"
                            data-cy="exx-mouseover-link-animate-level2"
                        >
                            <a
                                class="header-menu-wide-level2-label header-menu-label fx-row fx-row-h-space-between fx-row-v-center"
                                [routerLink]="getLinkUrl(level2Category.href)"
                            >
                                {{ level2Category.name }}
                                <div *ngIf="level2Category.categories" class="fx-row fx-row-h-space-between fx-row-v-center">
                                    <mat-icon class="pl-0">chevron_right</mat-icon>
                                </div>
                            </a>

                            <div *ngIf="level2Category.categories" class="py-2 header-menu-wide-level3">
                                <div
                                    *ngFor="let level3Category of level2Category.categories"
                                    class="header-menu-wide-level3-item"
                                    data-cy="exx-header-menu-wide-level3-item"
                                >
                                    <a class="header-menu-wide-level3-label header-menu-label" [routerLink]="getLinkUrl(level3Category.href)">
                                        {{ level3Category.name }}
                                    </a>
                                </div>
                                <div [class.divider]="level3MenuOpen"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <nav id="header-menu-narrow" (window:resize)="narrowMenu?.calculateHeight()">
        <div class="header-menu-narrow-level1" #headerMenuNarrowLevel1>
            <div
                id="header-menu-narrow-top"
                class="narrow-top header-menu-horizontal-padding fx-row fx-row-h-space-between fx-row-v-center col-sm-12"
            >
                <span *ngIf="narrowMenu?.currentLevel != 1" class="fx-row header-menu-back">
                    <button class="header-menu-img-button" (click)="returnToFirstLevel()">
                        <img src="https://assets.exxactcorp.com/img/exx/misc/back-nav-mobile.svg" alt="go back" />
                    </button>
                </span>
                <span class="header-back-text" *ngIf="narrowMenu?.currentLevel != 1">
                    {{ narrowMenu?.currentCategory?.name }}
                </span>
                <div *ngIf="narrowMenu?.currentLevel == 1" id="header-menu-greeting">
                    <span>Welcome</span>
                </div>
                <button class="header-menu-img-button" (click)="closeNarrowMenu()">
                    <img src="https://assets.exxactcorp.com/img/exx/misc/close-nav-mobile.svg" alt="close menu" />
                </button>
            </div>

            <div *ngFor="let level1Category of menu">
                <div
                    class="header-menu-label header-menu-horizontal-padding header-menu-narrow-navigate-next fx-row fx-row-h-space-between col-sm-12"
                    (click)="narrowMenu?.next($event.currentTarget, level1Category)"
                    data-cy="header-content-narrow-level1-item"
                >
                    {{ level1Category.name }}
                    <div class="header-menu-level1-icon-container" *ngIf="level1Category.categories">
                        <mat-icon class="header-menu-level1-icon">chevron_right</mat-icon>
                    </div>
                </div>

                <div class="header-menu-narrow-level2 col-sm-12" id="level-2-menu" #headerMenuNarrowLevel2>
                    <div *ngFor="let level2Category of level1Category.categories" data-cy="header-content-narrow-level2-item">
                        <a
                            *ngIf="!level2Category.categories"
                            class="header-menu-label pl-2 pr-1"
                            (click)="closeNarrowMenu()"
                            [routerLink]="getLinkUrl(level2Category.href)"
                        >
                            {{ level2Category.name }}
                        </a>

                        <div class="pl-2 pr-1">
                            <div
                                *ngIf="level2Category.categories"
                                [id]="level2Category.name"
                                class="header-menu-label fx-row fx-row-h-space-between"
                                (click)="showCategoryChildren($event.currentTarget, level2Category?.name)"
                            >
                                {{ level2Category.name }}
                                <div class="header-menu-category-toggle">
                                    <div class="header-menu-category-toggle-button">
                                        <div class="horizontal"></div>
                                        <div class="vertical"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="header-menu-category-children" data-cy="header-content-narrow-category-children">
                                <a class="header-menu-label all" (click)="closeNarrowMenu()" [routerLink]="getLinkUrl(level2Category.href)">
                                    All {{ level2Category.name }}
                                </a>

                                <div *ngFor="let level3Category of level2Category.categories">
                                    <a
                                        class="header-menu-label"
                                        (click)="selectCategory($event.currentTarget)"
                                        [routerLink]="getLinkUrl(level3Category.href)"
                                    >
                                        {{ level3Category.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="header-menu-narrow-spacer"></div>
                </div>
            </div>

            <div class="header-menu-narrow-spacer"></div>

            <div id="links-container">
                <button-component
                    class="mb-1 ml-40"
                    (click)="narrowMenu?.toggle()"
                    [buttonOptions]="{
                        text: 'Contact Sales',
                        type: 'primary',
                        iconPosition: 'right',
                        iconName: 'chevron-right',
                        internalLink: '/Contact-Us'
                    }"
                ></button-component>
                <a class="link col-sm-12 ml-2 pt-3" href="https://exxactcorporation.zendesk.com/hc/en-us?return_to=%2Fhc%2Frequests">
                    Get Support <mat-icon class="icon">chevron_right</mat-icon>
                </a>
                <a class="link col-sm-12 ml-2 pt-3" routerLink="/Partners" (click)="narrowMenu?.toggle()">
                    Partner with us <mat-icon class="icon">chevron_right</mat-icon>
                </a>
            </div>
        </div>

        <div id="header-menu-narrow-toggle" #headerMenuNarrowToggle (click)="narrowMenu?.toggle()">
            <div *ngIf="narrowMenu">
                <mat-icon [ngClass]="narrowMenu?.menuOpen ? 'hide' : ' '" svgIcon="menu"></mat-icon>
                <mat-icon [ngClass]="!narrowMenu?.menuOpen ? 'hide' : ' '" svgIcon="x"></mat-icon>
            </div>
        </div>
    </nav>
</div>
