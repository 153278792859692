import { Component, OnInit } from '@angular/core';
import { isBrowser } from 'lib/tools';
import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-careers.component';

@Component({
    selector: 'app-cms-careers',
    templateUrl: './cms-careers.component.html',
    styleUrls: ['./cms-careers.component.scss'],
})
export class CmsCareersComponent extends ExxComComponentClass implements OnInit {
    // Dependencies
    cmsService: CmsService;

    // Properties: public
    entries: any = [];
    getWebpImg: (src: string) => string;

    constructor(c: CmsService, w: WebpService) {
        super({
            dependencies: {
                cmsService: c,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entries = await this.cmsService.getPage('careers');
            // append script for greenhouse iFrame to the body as specified in documentation given
            if (!isBrowser()) {
                return;
            }
            const greenhouseIFrame = document.createElement('script');
            greenhouseIFrame.type = 'text/javascript';
            greenhouseIFrame.setAttribute('src', 'https://assets.exxactcorp.com/js/greenhouse.js');
            document.body.append(greenhouseIFrame);
        } catch (err) {
            console.error(...new ExxComError(384993, scriptName, err).stamp());
        }
    }
}
