<section id="page-not-found" class="fx-row fx-row-container fx-row-h-center fx-wrap">
  <div class="col-md-6 col-xs-12 fx-wrap not-found-content">
    <p class="not-found-subheader col-md-12 col-xs-12 py-2 px-2 ml-0">ERROR</p>

    <p class="big-404 col-md-12 col-xs-12 px-2 py-2">404</p>

    <div class="star-container col-md-12 col-xs-12 px-2 mt-4">
      <mat-icon *ngFor="let star of [1, 2, 3, 4, 5]" svgIcon="star" class="star"></mat-icon>
    </div>

    <p class="not-found-text col-md-12 col-xs-12">
      Not sure how we got here, but it was a really nice place to crash.
    </p>

    <a [routerLink]="'/'" class="col-md-12 col-xs-12 px-2 py-2" role="link">
      <button class="button button-primary ml-2">
        Return Home
        <mat-icon class="mat-icon notranslate material-icons" role="img" aria-hidden="true">chevron_right</mat-icon>
      </button>
    </a>
  </div>

  <img
    class="col-md-6 col-xs-12"
    [src]="getWebpImg('https://assets.exxactcorp.com/img/exx/misc/404-Exxact.png')"
    alt="404 Exxact"
  />
</section>
