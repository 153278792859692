<div id="cms-ref-arch" class="px-2">
  <div class="ref-arch-card-row fx-wrap fx-row-container fx-row-h-left mt-4">
    <div *ngFor="let entry of entry?.reference_architecture_pages" class="col-md-4 col-sm-6 mb-3 fx-col-h-center">
      <app-resource-card-related-card
        [entry]="entry"
        [relatedContentTileData]="{
          title: entry?.preview_title,
          categoryTitle: entry?.subheading,
          image: entry?.preview_image?.href,
          imageTitle: entry?.preview_image?.title,
          date: entry?.date,
          link: entry?.routerLink,
          entry: entry
        }"
      ></app-resource-card-related-card>
    </div>
  </div>
</div>
