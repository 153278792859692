import { Component, ElementRef, EventEmitter, OnInit, QueryList, ViewChild, ViewChildren, Input, Output } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { CategoryService } from 'lib/services/category/category.service';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { HeaderMenuComponent as HeaderMenu } from 'lib/components/header-menu.component.class';
import { HeaderMenuNarrow } from './header-menu-narrow.class';
import { isBrowser } from 'lib/tools';
import { isEmpty, size } from 'lodash';

const scriptName = 'header-menu.component';
export interface menuObject {
    name?: string;
    href?: string;
    categories?: [{ name?: string; href?: string }] | any[];
}
const MENU_STATE_KEY = makeStateKey<Array<object>>('menu');
@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent extends HeaderMenu implements OnInit {
    @Input('isValidLocalSessionData') isValidLocalSessionData: boolean = false;
    @Input('isNarrow') isNarrow: boolean = false;
    @Input() isResourcePage: boolean;
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('headerMenuNarrowToggle', { static: true })
    headerMenuNarrowToggle: any;
    @ViewChild('headerMenuNarrowLevel1', { static: true })
    headerMenuNarrowLevel1: any;
    @ViewChildren('headerMenuNarrowLevel2')
    headerMenuNarrowLevel2: QueryList<ElementRef>;
    @ViewChildren('headerMenuNarrowLevel3')
    headerMenuNarrowLevel3: QueryList<ElementRef>;

    currentNarrowMenuLevel: number = 0;
    isNarrowMenuOpen: boolean = false;
    level2LabelWidth: number = 0.5;
    level2MenuHeight: number | null;
    level2MenuWidth: number | null;
    level3MenuOpen: boolean = false;
    menu: menuObject[];
    narrowMenu: HeaderMenuNarrow;
    wasActive: boolean = false;
    services: menuObject = {
        name: 'Services',
        href: '/Services',
        categories: [],
    };
    resources: menuObject = {
        name: 'Resources',
        href: '/Resources',
        categories: [],
    };

    constructor(
        private apiService: ApiService,
        categoryService: CategoryService,
        private cmsService: CmsService,
        private transferState: TransferState
    ) {
        super(categoryService);
    }

    async ngOnInit() {
        this.getMenu();
        if (!isBrowser()) {
            return;
        }
        setTimeout(() => {
            this.narrowMenu = new HeaderMenuNarrow(this.isNarrow, {
                menuOpen: this.isNarrowMenuOpen,
                toggle: this.headerMenuNarrowToggle,
                level1: this.headerMenuNarrowLevel1,
                level2: this.headerMenuNarrowLevel2,
                level3: this.headerMenuNarrowLevel3,
                showChildren: this.showCategoryChildren,
            });
            if (!this.isResourcePage) {
                this.narrowMenu.init();
            }
        }, 500);
    }

    private getMenu() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve) => {
            try {
                this.menu = this.transferState.get(MENU_STATE_KEY, []);
                if (!isEmpty(this.menu)) {
                    return;
                }

                const res = await this.apiService.get('menu');
                if (!res.success) {
                    throw res;
                }
                this.menu = res.data;
                const header = await this.apiService.get('headermenu');

                header.data.services.level2menus.forEach((element) => {
                    this.services.categories.push({
                        name: element.title,
                        href: element.href,
                    });
                });
                header.data.resources.level2menus.forEach((element) => {
                    this.resources.categories.push({
                        name: element.title,
                        href: element.href,
                    });
                });

                this.menu.push(this.services, this.resources);

                this.transferState.set(MENU_STATE_KEY, this.menu);
                resolve();
            } catch (err) {
                if (err.statusText === 'Unknown Error') {
                    return;
                }
                console.error(...new ExxComError(529387, scriptName, err).stamp());
            }
        });
    }

    showCategoryChildren(target: HTMLElement, categoryId: string) {
        try {
            const categoryChildren = target.parentElement.children[1];
            const categoryToggleButton = target.children[0].children[0];
            const categoryLabel = target.parentElement.children[0];
            if (categoryChildren.classList.contains('show-category-children')) {
                categoryToggleButton.classList.remove('header-menu-narrow-collapse-icon');
                categoryChildren.classList.remove('show-category-children');
                categoryLabel.classList.remove('selected-category');
            } else {
                categoryToggleButton.classList.add('header-menu-narrow-collapse-icon');
                categoryChildren.classList.add('show-category-children');
                categoryLabel.classList.add('selected-category');
            }
        } catch (err) {
            if (err.statusText === 'Unkown Error') {
                return;
            }
            console.error(...new ExxComError(167382, scriptName, err).stamp());
        }
    }

    mouseEnter(level1Category: any, level2Category: any) {
        const dropdownWidth = 330;
        const level1Categories = size(level1Category.categories);
        const level2Categories = size(level2Category.categories);
        if (level2Categories) {
            this.level2MenuWidth = dropdownWidth * 2;
            this.level3MenuOpen = true;
        }
        // this.level2MenuHeight = level1Categories * (!level2Categories ? 42 : 44) + 15*2 + 2;
        if (level2Categories > level1Categories) {
            // 39 is the height of header-menu-wide-level2/3-label, 16 is the of padding of level div
            this.level2MenuHeight = level2Categories * 39 + 16 * 2;
        }
    }

    mouseLeave() {
        this.level2MenuHeight = null;
        this.level2MenuWidth = null;
        this.level3MenuOpen = false;
    }

    setIsActive(active: boolean) {
        if (active == this.wasActive) {
            return;
        }
        this.wasActive = active;
        this.isActiveChange.emit(active);
    }

    // collapses all open level 2 categories
    collapseCategories() {
        const openCategoryIcons = document.querySelectorAll('.header-menu-narrow-collapse-icon');
        const openCategories = document.querySelectorAll('.show-category-children');
        openCategoryIcons.forEach((icon) => {
            icon.classList.remove('header-menu-narrow-collapse-icon');
        });
        openCategories.forEach((category) => {
            category.classList.remove('show-category-children');
            category.parentElement.children[0].classList.remove('selected-category');
        });
    }

    selectCategory(target: HTMLElement) {
        this.collapseCategories();
        this.narrowMenu.close();
    }

    // returns to the first level from the second level of the narrow menu
    returnToFirstLevel() {
        if (this.narrowMenu) {
            this.collapseCategories();
            this.narrowMenu.prev();
        } else {
            return;
        }
    }

    closeNarrowMenu() {
        if (this.narrowMenu) {
            this.collapseCategories();
            this.narrowMenu.toggle();
        } else {
            return;
        }
    }
}
