import { Component, Input, OnInit } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { WebpService } from 'lib/services/webp.service';

@Component({
    selector: 'app-configurations-grid',
    templateUrl: './configurations-grid.component.html',
    styleUrls: ['./configurations-grid.component.scss'],
})
export class ConfigurationsGridComponent extends CmsPartialComponentClass implements OnInit {
    @Input() configurationsRow: any;
    getWebpImg: (src: string) => string;
    configuratorService: ConfiguratorService;

    constructor(webpService: WebpService, configuratorService: ConfiguratorService) {
        super({
            dependencies: {
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
        this.configuratorService = configuratorService;
    }

    async ngOnInit() {
        const solutions = [...this.configurationsRow];

        for (const solution of solutions[0].configurations.configuration) {
            const desiredMPN = solution.configuration_mpn;

            const solutionExxcom = await this.configuratorService.getConfigurationByUrlComponent('exx', desiredMPN, null, null, true, true);

            if (solutionExxcom[0] && solutionExxcom[0].sierraActive) {
                // Find the matched MPN in configurationsRow and add sierraActive to it
                const matchingSolution = this.configurationsRow[0].configurations.configuration.find(
                    (config) => config.configuration_mpn === desiredMPN
                );

                if (matchingSolution) {
                    matchingSolution.sierraActive = true;
                }
            }
        }
    }
}
