<div *ngIf="entry?.supported_software" id="cms-software">
  <div class="header gray-background">
    <div class="header-container-wide">
      <div class="fx-row fx-row-h-space-between px-2">
        <div class="header-container-left">
          <mat-icon svgIcon="settings" class="mr-1"></mat-icon>
          <h3 class="pt-1">Supported Software</h3>
        </div>
        <div *ngIf="(entry?.supported_software)[0]?.logo?.href" class="header-container-right">
          <img [src]="(entry?.supported_software)[0]?.logo?.href" [alt]="entry?.title" />
        </div>
      </div>
    </div>

    <div class="header-container-narrow">
      <div class="fx-row px-2">
        <div class="chevron-left" routerLink="/Supported-Software">
          <mat-icon>chevron_left</mat-icon>
        </div>
        <div *ngIf="(entry?.supported_software)[0]?.logo?.href" class="header-container-right fx-row fx-row-h-center">
          <img [src]="(entry?.supported_software)[0]?.logo?.href" [alt]="entry?.title" />
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="((entry?.supported_software)[0]?.category)[0]?.title"
    class="title-container fx-col fx-col-container px-2"
  >
    <h5>{{ ((entry?.supported_software)[0]?.category)[0]?.title }}</h5>
    <h1>{{ entry?.title }}</h1>
    <p>{{ entry?.short_description }}</p>
  </div>

  <!-- cms partial category top goes here -->
  <app-cms-partial-category-top
    *ngIf="entry?.category_top_partial_rows"
    [data]="{ rows: entry?.category_top_partial_rows }"
  ></app-cms-partial-category-top>
</div>
