<div class="enlargement-container">
    <div class="enlargement-positioning">
        <img
            class="enlargement-image"
            [src]="getWebpImg(image)"
            [alt]="altText"
            data-bs-toggle="modal"
            data-backdrop="true"
            [attr.data-bs-target]="'#imageEnlargement-' + uniqueId"
        />

        <div class="enlargement-gradient">
            <div class="maximize-icon"><i-feather name="maximize" class="maximize"></i-feather></div>
            <div class="image-enlargement-overlay"></div>
        </div>
    </div>
</div>

<div class="modal fade image-enlargement" [id]="'imageEnlargement-' + uniqueId" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <span class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i-feather name="x"></i-feather>
            </span>
            <div class="modal-body">
                <img class="enlargement-properties" [src]="getWebpImg(image)" [alt]="altText" />
            </div>
        </div>
    </div>
</div>
