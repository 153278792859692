<div id="cms-rma-request" class="fx-col-container fx-col-h-center">
  <section id="cms-rma-request-row-1" class="mt-4 p-2">
    <div class="fx-row-container-reverse-sm fx-row-v-center fx-wrap">
      <div class="col-sm-6">
        <img
          *ngIf="entry?.hero_banner_image?.href"
          [src]="entry.hero_banner_image.href"
          [alt]="entry.hero_banner_image.title"
        />
      </div>
      <div class="col-sm-6">
        <h5>{{ entry?.hero_banner_label }}</h5>
        <h1>{{ entry?.hero_banner_title }}</h1>
        <p>{{ entry?.hero_banner_copy }}</p>
      </div>
    </div>
  </section>

  <hr class="divider" />

  <section id="cms-rma-request-row-2" class="fx-wrap fx-row-container mb-4 p-2">
    <h2 class="col-xs-12 mb-4 text-center">{{ entry?.row_1_title }}</h2>

    <div *ngFor="let group of entry?.row_1" class="col-md-3 mb-3">
      <div class="card-1 text-center">
        <img
          *ngIf="group?.card_image?.href"
          [src]="group.card_image.href"
          class="mb-2"
          [alt]="group.card_image.title"
        />
        <h5>{{ group?.card_label }}</h5>
        <h3>{{ group?.card_title }}</h3>
        <p>{{ group?.card_copy }}</p>
      </div>
    </div>
  </section>

  <section id="cms-rma-request-row-3" class="fx-row-container fx-wrap mb-4 p-2">
    <div id="cms-rma-request-form-heading">
      <h2>Please fill out a request.</h2>
      <a class="link-purple mr-2" routerLink="/policy/Return-Policy">See Our Return Policy</a>
    </div>

    <form *ngIf="!formSubmitted" [formGroup]="form" class="form-1 fx-col mx-2" (ngSubmit)="onSubmit()">
      <h4 class="my-2">Your Info</h4>

      <div id="cms-rma-request-form-customer-info" class="fx-row fx-row-h-space-between fx-wrap">
        <div id="cms-rma-request-form-customer-info-left" class="col-sm-6">
          <label for="first">First Name</label>
          <input id="first" class="my-1" formControlName="first" #firstInput [class.error]="hasError(first)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(first)">
            <div class="form-error" [class.show]="hasErrorRequired(first)">First Name is required.</div>
          </div>

          <label for="last">Last Name</label>
          <input id="last" class="my-1" formControlName="last" #lastInput [class.error]="hasError(last)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(last)">
            <div class="form-error" [class.show]="hasErrorRequired(last)">Last Name is required.</div>
          </div>

          <label for="company">Company</label>
          <input id="company" class="my-1" formControlName="company" #companyInput [class.error]="hasError(company)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(company)">
            <div class="form-error" [class.show]="hasErrorRequired(company)">Company is required.</div>
          </div>

          <label for="phone">Phone</label>
          <input id="phone" class="my-1" formControlName="phone" #phoneInput [class.error]="hasError(phone)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(phone)">
            <div class="form-error" [class.show]="hasErrorRequired(phone)">Phone is required.</div>
            <div class="form-error" [class.show]="hasErrorPhoneInvalid(phone)">Invalid phone number.</div>
          </div>

          <label for="email">Email Address</label>
          <input id="email" class="my-1" formControlName="email" #emailInput [class.error]="hasError(email)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(email)">
            <div class="form-error" [class.show]="hasErrorRequired(email)">Email Address is required.</div>
            <div class="form-error" [class.show]="hasErrorInvalid(email)">Email Address is invalid.</div>
          </div>
        </div>

        <div id="cms-rma-request-form-customer-info-right" class="col-sm-6">
          <label for="address1">Address</label>
          <input
            id="address1"
            class="my-1"
            formControlName="address1"
            #address1Input
            [class.error]="hasError(address1)"
          />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(address1)">
            <div class="form-error" [class.show]="hasErrorRequired(address1)">Address is required.</div>
          </div>

          <label for="city">City</label>
          <input id="city" class="my-1" formControlName="city" #cityInput [class.error]="hasError(city)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(city)">
            <div class="form-error" [class.show]="hasErrorRequired(city)">City is required.</div>
          </div>

          <label for="state">State</label>
          <input id="state" class="my-1" formControlName="state" #stateInput [class.error]="hasError(state)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(state)">
            <div class="form-error" [class.show]="hasErrorRequired(state)">State is required.</div>
          </div>

          <label for="country">Country</label>
          <input id="country" class="my-1" formControlName="country" #countryInput [class.error]="hasError(country)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(country)">
            <div class="form-error" [class.show]="hasErrorRequired(country)">Country is required.</div>
          </div>

          <label for="zip">Zip Code</label>
          <input id="zip" class="my-1" maxlength="5" formControlName="zip" #zipInput [class.error]="hasError(zip)" />
          <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(zip)">
            <div class="form-error" [class.show]="hasErrorRequired(zip)">Zip Code is required.</div>
          </div>
        </div>
      </div>

      <h4 class="my-2">Item Info</h4>

      <div id="cms-rma-request-form-item-info" class="m-0" formArrayName="returnList">
        <div
          *ngFor="let row of returnList.controls; let i = index"
          class="cms-rma-request-form-item-info-row fx-row fx-row-v-top fx-wrap mb-2"
          [formGroupName]="i"
        >
          <div class="col-sm-2 p-0 pr-1" id="quantity">
            <label [for]="'quantity-' + i">Qty.</label>
            <input
              [id]="'quantity-' + i"
              class="my-1"
              type="number"
              min="0"
              oninput="this.value = 
            !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
              formControlName="quantity"
              #quantityInput
              [class.error]="hasError(quantity(i))"
            />
            <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(quantity(i))">
              <div class="form-error" [class.show]="hasErrorRequired(quantity(i))">Qty is required.</div>
            </div>
          </div>

          <div class="col-sm-3 p-0 pr-1">
            <label [for]="'order-number-' + i">Order #</label>
            <input
              [id]="'order-number-' + i"
              class="my-1"
              formControlName="orderNumber"
              #orderNumberInput
              [class.error]="hasError(orderNumber(i))"
            />
            <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(orderNumber(i))">
              <div class="form-error" [class.show]="hasErrorRequired(orderNumber(i))">Order Number is required.</div>
            </div>
          </div>

          <div class="col-sm-3 p-0 pr-1">
            <label [for]="'part-number-' + i">Part #</label>
            <input
              [id]="'part-number-' + i"
              class="my-1"
              formControlName="partNumber"
              #portNumberInput
              [class.error]="hasError(partNumber(i))"
            />
            <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(partNumber(i))">
              <div class="form-error" [class.show]="hasErrorRequired(partNumber(i))">Part Number is required.</div>
            </div>
          </div>

          <div class="col-sm-4 p-0 pr-1">
            <label [for]="'serial-number-' + i">Serial #</label>
            <input
              [id]="'serial-number-' + i"
              class="my-1"
              formControlName="serialNumber"
              #serialNumberInput
              [class.error]="hasError(serialNumber(i))"
            />
            <div class="form-error fx-col fx-col-v-center mb-2" [class.show]="hasError(serialNumber(i))">
              <div class="form-error" [class.show]="hasErrorRequired(serialNumber(i))">Serial Number is required.</div>
            </div>
          </div>

          <div class="cms-rma-request-form-item-info-row-select col-sm-2 p-0 pr-1">
            <label [for]="'request-type-' + i">Req. Type</label>
            <select
              [id]="'request-type-' + i"
              class="my-1"
              formControlName="requestType"
              #requestTypeInput
              [class.error]="hasError(requestType(i))"
            >
              <option value>Choose One</option>
              <option value="Refund">Refund</option>
              <option value="Replace">Replace</option>
            </select>
            <div class="form-error mb-2" [class.show]="hasError(requestType(i))">
              <div class="form-error" [class.show]="hasErrorRequired(requestType(i))">Type is required.</div>
            </div>
          </div>

          <div class="col-sm-6 p-0 pr-1">
            <label [for]="'reason-' + i">Reason for your return</label>
            <input
              [id]="'reason-' + i"
              class="my-1"
              formControlName="reason"
              #reasonInput
              [class.error]="hasError(reason(i))"
            />
            <div class="form-error fx-col fx-col-v-center p-0" [class.show]="hasError(reason(i))">
              <div class="form-error p-0" [class.show]="hasErrorRequired(reason(i))">Reason is required.</div>
            </div>
          </div>

          <div class="cms-rma-request-form-item-info-row-buttons col-sm-4 fx-row fx-wrap">
            <button class="link-purple" [disabled]="i == 0 && 2 > returnList.controls.length" (click)="removeRow(i)">
              Remove item
            </button>
          </div>
          <div>
            <button
              *ngIf="i == returnList.controls.length - 1"
              class="button-secondary button-base mb-3"
              (click)="addRow()"
            >
              Add another item
            </button>
          </div>
        </div>
      </div>

      <div class="form-messages">{{ messageService.locals.cms_rmaRequest }}</div>

      <button
        id="cms-rma-request-form-submit-button"
        class="button-primary button-base fx-col-push-left"
        type="submit"
        [disabled]="form.invalid"
      >
        <mat-spinner *ngIf="actionPending" diameter="20" class="white"></mat-spinner>
        <span [class.invisible]="actionPending">Submit your request</span>
      </button>
    </form>

    <hr *ngIf="formSubmitted" class="divider" />
    <h3 *ngIf="formSubmitted" id="cms-rma-request-success" class="font-color-yellow-300">
      Your info was submitted successfully. We will contact with you shortly!
    </h3>
  </section>
</div>
