import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebpService } from 'lib/services/webp.service';
import { isEmpty } from 'lodash';

const scriptName = 'cms-gated-content.component';

@Component({
    selector: 'app-cms-gated-content',
    templateUrl: './cms-gated-content.component.html',
    styleUrls: ['./cms-gated-content.component.scss'],
})
export class CmsGatedContentComponent implements OnInit {
    entry: any = [];
    entries: any = [];
    getWebpImg: (src: string) => string;

    constructor(
        private cmsService: CmsService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('gated_content_type');
            if (isEmpty(this.entry)) {
                return;
            }
            this.entries = await this.cmsService.getEntries('gated_content', {
                query: { type: { $in_query: { title: this.entry.title } } },
                references: ['type'],
                sort: { asc: 'title' },
            });

            if (this.entry.title == 'Case Studies' || this.entry.title == 'Whitepapers' || this.entry.title == 'eBooks') {
                this.sortByDateNewest();
            }
        } catch (err) {
            console.error(...new ExxComError(555122, scriptName, err).stamp());
        }
    }

    getText() {
        try {
            return this.entry.title == 'eBooks' ? 'View the eBook' : 'View the study';
        } catch (err) {
            console.error(...new ExxComError(555123, scriptName, err).stamp());
        }
    }

    getTags(tags: string[]) {
        try {
            return tags.join(', ');
        } catch (err) {
            console.error(...new ExxComError(555124, scriptName, err).stamp());
        }
    }

    sortByDateNewest() {
        this.entries.sort((entryA, entryB) => {
            const timestampA = Date.parse(entryA.date);
            const timestampB = Date.parse(entryB.date);
            return timestampB - timestampA;
        });
    }
}
