<section class="d-flex flex-column align-items-center justify-content-center" id="supported-software">
  <exx-featured-card [featuredContentData]="featuredContentSoftwareBlog"></exx-featured-card>

  <div id="cms-supported-sw" class="px-3 w-100">
    <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
      <div id="supported-software-downloads" class="d-flex flex-column">
        <h4 class="old">{{ selectedCategory === "All" ? "All Software" : selectedCategory }}</h4>
        <div class="d-flex flex-row align-items-center">
          <mat-icon id="preinstalled-icon" class="mr-1" svgIcon="download"></mat-icon>

          <p class="m-0">Preinstalled upon request</p>
        </div>
      </div>
      <div id="supported-software-search-and-filter" class="d-flex flex-row">
        <div id="search-icon-container" class="">
          <mat-icon id="supported-software-search-icon" svgIcon="nav-search"></mat-icon>
          <input
            id="cms-supported-software-search-input"
            #searchInput
            placeholder="Search Software"
            [(ngModel)]="searchInputValue"
            (ngModelChange)="filterCurrentSoftware($event)"
          />
          <div id="no-search-results" *ngIf="newEntries.length === 0" class="text-center d-flex align-items-center">
            <h5 class="m-0">No search results match your search query.</h5>
          </div>
        </div>

        <div id="cms-supported-sw-filter" #filterMenu>
          <!-- filter button -->
          <button
            id="cms-supported-sw-filter-button"
            [class]="
              filterMenuOpen
                ? 'fx-row fx-row-h-space-between fx-row-v-center open'
                : 'fx-row fx-row-h-space-between fx-row-v-center'
            "
            #filterMenuButton
            (click)="filterMenuOpen = !filterMenuOpen"
          >
            <span id="narrow-button-select" class="d-flex flex-row align-items-center"
              ><mat-icon svgIcon="filter-hopper"></mat-icon><mat-icon>expand_more</mat-icon></span
            >
            <span id="wide-button-select" class="d-flex flex-row justify-content-between align-items-center w-100">
              <div class="truncate-text">{{ selectedCategory === "All" ? "Category" : selectedCategory }}</div>
              <i-feather name="chevron-down"></i-feather>
            </span>
          </button>
          <!-- filter menu open -->
          <div *ngIf="filterMenuOpen" id="cms-supported-sw-filter-options" class="fx-col mat-elevation-z2">
            <!-- filter options -->
            <a
              *ngFor="let category of categories"
              class="cms-supported-sw-filter-menu-option"
              (click)="
                selectedCategory = category?.title; filterMenuOpen = !filterMenuOpen; categorySearch(selectedCategory)
              "
            >
              {{ category?.title }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <section class="fx-row-h-space-between">
      <!-- search input cards -->
      <div>
        <div
          *ngIf="searchInputValue !== '' || selectedCategory !== 'All'"
          class="d-flex flex-column justify-content-center align-items-center search-grid-container"
        >
          <div class="card-grid">
            <div
              *ngFor="let entry of newEntries"
              id="software-card"
              class="d-flex flex-row align-items-center"
              (click)="openCardModal(template, entry)"
            >
              <div class="software-card-image d-flex flex-row justify-content-center">
                <mat-icon
                  *ngIf="get(entry, 'preinstalled', '') === 'Yes'"
                  svgIcon="download"
                  id="preinstalled-badge"
                ></mat-icon>
                <img [src]="get(entry, 'logo.href', '')" />
              </div>
              <span class="divider-line"></span>
              <div class="software-card-text">
                <p class="software-card-category-title bold mb-base">{{ entry.category[0].title }}</p>
                <p
                  class="software-title mb-2 mt-base"
                  [class.truncate-text]="get(entry, 'software_page_link', '/').length > 1"
                >
                  {{ entry.title }}
                </p>
                <a
                  *ngIf="get(entry, 'software_page_link', '/').length > 1"
                  [routerLink]="get(entry, 'software_page_link', '/')"
                  class="software-learn-more link-blue d-flex align-items-center"
                >
                  <span>Learn More</span>
                  <mat-icon>chevron_right</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- no search input cards -->
      <div
        id="categorized-entries"
        class="d-flex flex-column justify-content-center align-items-center"
        *ngIf="searchInputValue === '' && selectedCategory === 'All'"
      >
        <div
          id="category-section"
          *ngFor="let entryCategory of categories"
          class="w-100 d-flex flex-column align-items-center"
        >
          <div *ngIf="entryCategory.title !== 'All'" class="w-100 category-title">
            <h4 class="old">{{ entryCategory.title }}</h4>
            <hr class="card-category-divider mb-3" />
          </div>
          <div class="card-grid">
            <ng-container *ngFor="let entry of newEntries">
              <div
                id="software-card"
                *ngIf="entry.category[0].title == entryCategory.title"
                class="d-flex flex-row align-items-center"
                (click)="openCardModal(template, entry)"
              >
                <div class="software-card-image d-flex flex-row justify-content-center">
                  <mat-icon
                    *ngIf="get(entry, 'preinstalled', '') === 'Yes'"
                    id="preinstalled-badge"
                    svgIcon="download"
                  ></mat-icon>
                  <img [src]="get(entry, 'logo.href', '')" />
                </div>
                <span class="divider-line"></span>
                <div class="software-card-text">
                  <p class="software-card-category-title bold mb-base">{{ entry.category[0].title }}</p>
                  <p
                    class="software-title mb-2 mt-base"
                    [class.has-learn-more]="get(entry, 'software_page_link', '/').length > 1"
                  >
                    {{ entry.title }}
                  </p>
                  <a
                    *ngIf="get(entry, 'software_page_link', '/').length > 1"
                    [routerLink]="get(entry, 'software_page_link', '/')"
                    class="software-learn-more link-blue d-flex align-items-center"
                  >
                    <span>Learn More</span>
                    <mat-icon>chevron_right</mat-icon>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>

<lib-overlay [isVisible]="filterMenuOpen || newEntries.length === 0" [zIndex]="1"></lib-overlay>

<ng-template #template>
  <div id="card-modal-container">
    <mat-icon svgIcon="close" class="close-modal-button" (click)="bsModalRef.hide()"></mat-icon>
    <div class="d-flex flex-row justify-content-center">
      <div id="card-image-container">
        <img [src]="get(cardEntryData, 'logo.href', '')" />
      </div>
    </div>
    <hr />
    <p [class.text-center]="isNarrow" class="mt-0 mb-1 card-category-title">{{ cardEntryData?.category[0]?.title }}</p>
    <h5 [class.text-center]="isNarrow" class="card-title mb-2">{{ cardEntryData?.title }}</h5>
    <p [class.text-center]="isNarrow" *ngIf="cardEntryData?.preinstalled === 'Yes'" class="mt-2 mb-3">
      <mat-icon id="preinstalled-badge" svgIcon="download"></mat-icon> Preinstallation upon request
    </p>
    <p class="test">{{ cardEntryData?.short_brief }}</p>
    <a
      *ngIf="get(cardEntryData, 'software_page_link', '/').length > 1"
      [routerLink]="get(cardEntryData, 'software_page_link', '/')"
      class="software-learn-more link-blue d-flex align-items-center"
    >
      <span>Learn More</span>
      <mat-icon>chevron_right</mat-icon>
    </a>
  </div>
</ng-template>
