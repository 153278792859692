<div id="cms-homepage">
    <div id="hero-banner">
        <div class="d-flex banner-contents-container container">
            <div class="d-flex tablet-container">
                <div class="banner-text d-flex flex-row">
                    <div class="d-flex flex-column title-lines">
                        <ng-container
                            *ngFor="
                                let titleLine of get(
                                    entry,
                                    'hero_banner_content.hero_banner.hero_banner_title_lines.hero_banner_line'
                                )
                            "
                            ><h1>{{ titleLine }}</h1></ng-container
                        >
                        <ng-container>
                            <h1 class="carousel-word" id="carousel"></h1>
                        </ng-container>
                    </div>
                </div>
                <div class="banner-image d-flex">
                    <img
                        id="hero-banner-image"
                        [src]="
                            entry?.hero_banner_content?.hero_banner
                                ?.hero_banner_background_image?.href
                        "
                    />
                </div>
            </div>
            <div
                class="mdf-container-parent"
                *ngIf="
                    !isEmpty(
                        get(
                            entry,
                            'hero_banner_content.hero_banner.mdf_references.mdf'
                        )
                    ) &&
                    get(
                        entry,
                        'hero_banner_content.hero_banner.mdf_references.mdf'
                    ).length <= 2
                "
            >
                <div class="mdf-container">
                    <ng-container
                        *ngFor="
                            let mdf of get(
                                entry,
                                'hero_banner_content.hero_banner.mdf_references.mdf'
                            )
                        "
                    >
                        <div
                            [class.mdf-full]="mdfWidth == 'full'"
                            [class.mdf-half]="mdfWidth == 'half'"
                            [style.background]="
                                mdf.banner_background_color + 'cc'
                            "
                            [routerLink]="mdf?.link_and_text?.routerLink"
                            [queryParams]="mdf?.link_and_text?.queryParams"
                        >
                            <div class="image-container">
                                <div class="images">
                                    <img
                                        [src]="
                                            get(
                                                mdf,
                                                'mdf_image.image_link_desktop.href'
                                            )
                                        "
                                        *ngIf="
                                            (mdfWidth == 'full' && !isNarrow) ||
                                            (mdfWidth == 'full' &&
                                                isTablet &&
                                                isNarrow)
                                        "
                                    />
                                    <img
                                        [src]="
                                            get(
                                                mdf,
                                                'mdf_image.image_link_mobile.href'
                                            )
                                        "
                                        *ngIf="
                                            (isNarrow && !isTablet) ||
                                            mdfWidth == 'half'
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                [ngClass]="
                                    get(mdf, 'text_color') == 'dark_text'
                                        ? 'text-container dark-text'
                                        : 'text-container light-text'
                                "
                            >
                                <div
                                    class="d-flex flex-column justify-content-between h-100"
                                >
                                    <h2>{{ mdf?.mdf_title_text }}</h2>
                                    <a
                                        class="link"
                                        [routerLink]="
                                            mdf?.link_and_text?.routerLink
                                        "
                                        [queryParams]="
                                            mdf?.link_and_text?.queryParams
                                        "
                                        ><span>{{
                                            mdf?.link_and_text?.title
                                        }}</span
                                        ><i-feather
                                            class="chevron-right"
                                            name="chevron-right"
                                        ></i-feather
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- rename groups to make more sense, change right before prod deployment -->
    <ng-container
        *ngFor="let homePageRow of get(entry, 'homepage_reordering.group', '')"
        class="spacing-class"
    >
        <div *ngIf="!isEmpty(homePageRow?.use_cases)" id="use-cases">
            <app-exx-use-cases
                [row]="homePageRow?.use_cases[0]"
            ></app-exx-use-cases>
        </div>

        <div
            *ngIf="!isEmpty(homePageRow?.product_tile_rows)"
            id="product-tile-rows"
            class="spacing-class"
        >
            <app-product-tile-rows
                [tiles]="homePageRow?.product_tile_rows[0]"
            ></app-product-tile-rows>
        </div>
        <!-- 56 top 48 bottom -->
        <div
            *ngIf="!isEmpty(homePageRow?.logos)"
            id="logos"
            class="spacing-class"
            [ngStyle]="{
                'background-color': get(
                    homePageRow,
                    'logos[0].background_color',
                    ''
                ),
                'background-repeat': 'no-repeat'
            }"
        >
            <cms-partial-category-top-logos
                [logosRow]="{ logos: { logos: homePageRow?.logos } }"
            ></cms-partial-category-top-logos>
        </div>
        <div
            *ngIf="!isEmpty(homePageRow?.homepage_configurations)"
            id="product-tile-rows"
            class="spacing-class"
        >
            <!-- <app-product-tile-rows [tiles]="homePageRow?.product_tile_rows[0]"></app-product-tile-rows> -->
            <app-configurations-grid
                [configurationsRow]="homePageRow?.homepage_configurations"
            ></app-configurations-grid>
        </div>
        <div *ngIf="!isEmpty(homePageRow?.cta_banner)" class="spacing-class">
            <cms-partial-category-top-lead-gen
                [leadGenRow]="{
                    lead_gen: { lead_gen: homePageRow?.cta_banner }
                }"
                [dataUrl]="'/'"
                [dataTitle]="'Homepage'"
            ></cms-partial-category-top-lead-gen>
        </div>
    </ng-container>
</div>
