<div
  *ngIf="!this.isBrowser"
  id="cms-quotes-partial"
  class="fx-row-container fx-row-h-space-between fx-wrap pt-2 pb-2 mb-4"
>
  <div class="col-md-1 col-sm-12 mt-1">
    <img [attr.src]="getWebpImg(get(slides, '[0].imageUrl', ''))" alt="Testimonial" />
  </div>
  <div class="quote-person fx fx-wrap pl-3 pr-2 col-md-11 col-sm-12">
    <p class="test-paragraph pb-2">{{ get(slides, "[0].paragraph", "") }}</p>
    <p class="testimonials-name">{{ get(slides, "[0].heading", "") }}</p>
    <h5 class="grayout">{{ get(slides, "[0].secondHeading", "") }}</h5>
  </div>
</div>

<div *ngIf="this.isBrowser" class="swiper swiper-single">
  <div id="swiper-quote-swiper-container" class="swiper-container">
    <div class="swiper-wrapper">
      <div *ngFor="let slide of slides" class="swiper-slide swiper-single-slide">
        <div
          id="cms-quotes-partial"
          [ngStyle]="{ width: this.slideWidth }"
          class="fx-row-container fx-row-h-space-between fx-wrap py-2"
        >
          <div [class]="this.slideWidth != 'auto' ? 'col-md-12 col-sm-12 mt-1' : 'col-md-1 col-sm-12 mt-1'">
            <img [attr.src]="getWebpImg(slide.imageUrl)" alt="Testimonial" />
          </div>
          <div class="quote-person fx fx-wrap pl-3 pr-2 col-md-11 col-sm-12">
            <p class="test-paragraph pb-2">{{ slide.paragraph }}</p>
            <p class="testimonials-name">{{ slide.heading }}</p>
            <h5 class="grayout">{{ slide.secondHeading }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="slides && slides.length > 1" class="swiper-pagination"></div>
  </div>
</div>
