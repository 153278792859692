<div *ngIf="!doneLoading" class="configurator-loading d-flex align-items-center justify-content-center">
    <mat-spinner diameter="25" color="accent"></mat-spinner>
</div>
<div id="scroll-div" *ngIf="doneLoading">
    <app-slide-panel *ngIf="configuration.optimizedConfigurations" [activePane]="selectedPane ? 'right' : 'left'" id="parent-panel">
        <div *ngIf="delayedDoneLoading" rightPane class="configurator-container gradient-background-left fx-wrap fx-row-h-center" id="rightPane">
            <div class="fx-row fx-wrap fx-row-container" id="contact-container">
                <div *ngIf="isNarrow" class="marketo-specs col-lg-2_5 col-xs-12" id="narrow-data">
                    <div class="fx-col-v-space-between">
                        <div>
                            <div class="fx-col fx-col-h-center">
                                <h2 *ngIf="configuration?.type" class="mt-5">{{ configuration?.type }}</h2>
                                <h5 *ngIf="configuration?.mpn" class="mt-3 bold font-lg">{{ configuration?.mpn }}</h5>
                                <div class="img-marketo pt-4">
                                    <img class="narrow-solution-img" [src]="getWebpImg(configuration?.imgUrl)" alt="Solution image" />
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mb-base mt-4">
                                <p class="m-0 d-flex align-items-end lh-1 font-sm">Configured price</p>
                                <h2 class="m-0 font-xl">{{ configuration.optimizedConfigurations[activeReference].priceTotal }}</h2>
                            </div>
                            <div class="d-flex justify-content-between mb-base">
                                <p class="m-0 font-sm">Shipping & Sales Tax</p>
                                <p class="m-0 font-sm">TBD after system validation</p>
                            </div>
                            <div class="d-flex justify-content-between pb-2 mb-2 mt-3 edit-btn-wrapper">
                                <p class="m-0 bold font-md">Review your order</p>
                                <button class="fx-row save fx-row-v-center edit-btn" (click)="selectedPane = !selectedPane">
                                    <p class="m-0 bold edit-config">Edit</p>
                                </button>
                            </div>
                            <div></div>
                            <div class="review-save">
                                <div *ngIf="data[0]" id="show-specs-marketo" class="fx-col">
                                    <div *ngFor="let category of renderedCategories" class="fx-col light-bkg-padding">
                                        <div class="col-xs-12 col-sm-12 p-0">
                                            <p class="green350 bold font-md mb-1">{{ category }}</p>
                                        </div>
                                        <div *ngFor="let product of configuration.optimizedConfigurations[activeReference].selectedSpecs[category]">
                                            <div class="col-xs-12 col-sm-12 p-0 product fx-row">
                                                <p *ngIf="product?.quantity >= 1" class="fx-col-h-left mr-3">{{ product?.quantity }}x</p>
                                                <span class="fx-col pr-0">
                                                    <ng-container *ngFor="let line of product.name.split(';'); let last = last">
                                                        <p [class.mb-halfbase]="!last">{{ line }}</p>
                                                    </ng-container>
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="
                                                configuration?.optimizedConfigurations[activeReference].category[category]?.noThanks &&
                                                configuration.optimizedConfigurations[activeReference].selectedSpecs[category].length == 0
                                            "
                                            class="col-xs-12 col-sm-12 p-0 product fx-row"
                                        >
                                            <div class="fx-col-h-left mr-3">
                                                <p class="text-nowrap">--</p>
                                            </div>
                                            <div class="col-xs-12 col-sm-12 p-0 product fx-row">
                                                <p class="fx-col-h-left">*None</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Memory, Watts, BTU Totals on confirmation page (right pane)-->
                        <!-- Narrow -->
                        <div
                            class="spec-totals d-flex flex-col justify-content-between"
                            *ngIf="specTotals?.memory || specTotals?.wattage || specTotals?.btu"
                        >
                            <span class="d-flex flex-row align-items-center">
                                <mat-icon svgIcon="ram"></mat-icon>
                                {{ specTotals?.memory }}
                                <p class="spec-totals-unit mt-0 lh-base">GB Memory</p>
                            </span>
                            <span class="d-flex flex-row align-items-center">
                                <mat-icon svgIcon="lightning"></mat-icon>
                                {{ specTotals?.wattage }}
                                <p class="spec-totals-unit mt-0 lh-base">Watts</p>
                            </span>
                            <span class="d-flex flex-row align-items-center">
                                <mat-icon svgIcon="fire"></mat-icon>
                                {{ isNarrow ? specTotals?.btu.toFixed(0) : specTotals?.btu }}
                                <p class="spec-totals-unit mt-0 lh-base">BTU/Hr</p>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-xs-12" id="configure-system-container">
                    <lib-marketo-form
                        class="configure-system col-md-12 col-xs-12"
                        *ngIf="configuration.optimizedConfigurations && rightFormActive"
                        [formId]="formId"
                        [configuratorSystemPrice]="configuration.optimizedConfigurations[activeReference].priceTotal"
                        [configuratorData]="configFormattedData"
                        [saveConfiguratorInformation]="configurationUrlData?.url"
                        (formSubmittedEmitter)="saveConfiguration($event)"
                    ></lib-marketo-form>
                </div>
                <div *ngIf="!isNarrow" id="marketo-specs-selector-wide" class="marketo-specs col-md-7 col-sm-12">
                    <div class="fx-col-v-space-between config-spec-container">
                        <div class="grid fx-row-h-space-between marketo-specs-title pb-2">
                            <h2 class="m-0 g-col-12" *ngIf="configuration?.type">{{ configuration.type }}</h2>
                            <h5 class="m-0 g-col-12 font-xl mb-3" *ngIf="configuration?.mpn">{{ configuration.mpn }}</h5>
                            <p class="m-0 g-col-6 align-self-end font-sm">Configured price</p>
                            <h2 class="m-0 g-col-6 justify-self-end">
                                {{ configuration.optimizedConfigurations[activeReference].priceTotal }}
                            </h2>
                            <p class="m-0 g-col-6 font-sm">Shipping & Sales Tax</p>
                            <p class="m-0 g-col-6 font-sm justify-self-end white">TBD after system validation</p>
                            <hr class="mb-4 g-col-12" />
                            <h4 class="m-0 g-col-6 font-lg">Review your order</h4>
                            <div class="g-col-6 justify-self-end">
                                <!-- <a class="fx-row save config-button fx-row-v-center" (click)="selectedPane = !selectedPane"> Edit configuration </a> -->
                                <button-component
                                    (click)="selectedPane = !selectedPane"
                                    [buttonOptions]="{
                                        text: 'Edit configuration',
                                        type: 'tiny-primary'
                                    }"
                                ></button-component>
                            </div>
                        </div>
                        <div class="review-save">
                            <div id="show-specs-marketo" class="fx-col">
                                <div *ngFor="let category of renderedCategories" class="fx-row light-bkg-padding">
                                    <div class="fx-col col-xs-12 p-0">
                                        <div class="fx-row col-sm-12 p-0">
                                            <div class="col-sm-2 p-0">
                                                <p class="category font-md bold">{{ category }}</p>
                                            </div>
                                            <div class="col-sm-12 p-0">
                                                <div
                                                    *ngFor="
                                                        let product of configuration.optimizedConfigurations[activeReference].selectedSpecs[category]
                                                    "
                                                >
                                                    <div class="fx-row">
                                                        <div class="col-sm-1 p-0">
                                                            <p class="text-center">{{ product?.quantity }}x</p>
                                                        </div>
                                                        <div class="col-sm-11 product fx-row pr-0">
                                                            <span class="fx-col pr-0">
                                                                <ng-container *ngFor="let line of product.name.split(';'); let last = last">
                                                                    <p [class.mb-halfbase]="!last">{{ line }}</p>
                                                                </ng-container>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        configuration?.optimizedConfigurations[activeReference].category[category]?.noThanks &&
                                                        configuration.optimizedConfigurations[activeReference].selectedSpecs[category].length == 0
                                                    "
                                                    class="fx-row"
                                                >
                                                    <div class="col-sm-1 p-0">
                                                        <p class="text-center">--</p>
                                                    </div>
                                                    <div class="col-sm-11 product fx-row pr-0">
                                                        <p class="fx-col-h-left">*None</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Memory, Watts, BTU Totals on confirmation page (right pane)-->
                        <!-- Desktop -->
                        <div
                            class="spec-totals d-flex flex-col justify-content-between"
                            *ngIf="specTotals?.memory || specTotals?.wattage || specTotals?.btu"
                        >
                            <span class="d-flex flex-row align-items-center">
                                <mat-icon svgIcon="ram"></mat-icon>
                                {{ specTotals?.memory }}
                                <p class="spec-totals-unit m-0">GB Memory</p>
                            </span>
                            <span class="d-flex flex-row align-items-center">
                                <mat-icon svgIcon="lightning"></mat-icon>
                                {{ specTotals?.wattage }}
                                <p class="spec-totals-unit m-0">Watts</p>
                            </span>
                            <span class="d-flex flex-row align-items-center">
                                <mat-icon svgIcon="fire"></mat-icon>
                                {{ specTotals?.btu }}
                                <p class="spec-totals-unit m-0">BTU/Hr</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden-specs-marketo" id="wide-data">
                <span>
                    <span class="top-wrapper d-flex fx-row fx-row-h-space-between">
                        <div class="d-flex fx-col fx-col-h-flex-start">
                            <img class="exxact-logo mb-1" [src]="getBaseUrl() + '/assets/images/exxact_logo.jpg'" alt="exx-logo" />
                            <p class="">{{ currentDate }}</p>
                        </div>
                        <div class="pdf-contact-info d-flex fx-col fx-col-h-right">
                            <p>Exxact Corporation</p>
                            <p>46221 Landing Pkwy</p>
                            <p>Fremont, CA 94538</p>
                            <p class="mb-3">www.exxactcorp.com</p>
                        </div>
                    </span>
                    <div class="top-quote-warning p-2 mb-3">
                        <p class="quote-bold bold mb-base">
                            NOT AN OFFICIAL QUOTE - PLEASE CONTACT SALES FOR SPECIAL PRICING AND APPLICABLE DISCOUNTS
                        </p>
                        <p>Pricing and Availability Subject to Change Without Notice.</p>
                    </div>
                    <h3 class="mpn font-md mb-3 mt-0">{{ configuration?.mpn }}</h3>
                    <span class="grid-descriptors fx-row">
                        <div class="descriptor-item p-0">
                            <p class="bold">Item</p>
                        </div>
                        <div class="p-0">
                            <div>
                                <div class="fx-row mb-0">
                                    <div class="descriptor-qty d-flex justify-content-start p-0 ml-1 mr-base">
                                        <p class="fx-col-h-left bold">Qty</p>
                                    </div>
                                    <div class="descriptor-desc product fx-row pr-0">
                                        <p class="fx-col-h-left bold">Item Description</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                    <span id="category-container">
                        <div *ngFor="let category of categoryKeys" class="fx-col summary-row">
                            <div
                                *ngIf="
                                    configuration.optimizedConfigurations[activeReference].selectedSpecs[category].length > 0 ||
                                    configuration?.optimizedConfigurations[activeReference]?.category[category]?.noThanks?.name
                                "
                                class="fx-row p-0"
                            >
                                <div class="p-0">
                                    <p class="category bold">{{ category }}</p>
                                </div>
                                <div class="pdf-products p-0">
                                    <div *ngFor="let product of configuration.optimizedConfigurations[activeReference].selectedSpecs[category]">
                                        <div class="fx-row mb-0">
                                            <div class="d-flex justify-content-center p-0 ml-1">
                                                <p *ngIf="product?.quantity" class="pdf-item-quantity fx-col-h-left">{{ product?.quantity }}x</p>
                                            </div>
                                            <div class="product fx-row pr-0">
                                                <p *ngIf="product?.name" class="pdf-item-name fx-col-h-left">
                                                    <ng-container *ngFor="let line of product.name.split(';'); let last = last">
                                                        <p [class.mb-halfbase]="!last">{{ line }}</p>
                                                    </ng-container>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="!configuration.optimizedConfigurations[activeReference].selectedSpecs[category].length"
                                        class="fx-row mb-0"
                                    >
                                        <div class="d-flex justify-content-center p-0 ml-1">
                                            <p class="pdf-item-quantity fx-col-h-left">---</p>
                                        </div>
                                        <div class="product fx-row pr-0">
                                            <p class="pdf-item-name fx-col-h-left">None</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr
                                *ngIf="
                                    configuration.optimizedConfigurations[activeReference].selectedSpecs[category].length > 0 ||
                                    configuration?.optimizedConfigurations[activeReference]?.category[category]?.noThanks?.name
                                "
                            />
                        </div>
                    </span>
                    <div class="pdf-totals-wrapper d-flex fx-row fx-row-h-space-between mt-3">
                        <span class="pdf-spec-totals d-flex fx-col">
                            <span class="spec-totals-amount d-flex flex-row align-items-center bold font-xs mb-base">
                                <img class="mr-1" [src]="getBaseUrl() + '/assets/images/watts.png'" alt="watts" />
                                {{ specTotals?.wattage }}
                                <p class="spec-totals-unit ml-1">Watts</p>
                            </span>
                            <span class="spec-totals-amount d-flex flex-row align-items-center bold font-xs mb-base">
                                <img class="mr-1" [src]="getBaseUrl() + '/assets/images/btu.png'" alt="btu" />
                                {{ specTotals?.btu }}
                                <p class="spec-totals-unit ml-1">BTU/Hr</p>
                            </span>
                            <span class="spec-totals-amount d-flex flex-row align-items-center bold font-xs">
                                <img class="mr-1" [src]="getBaseUrl() + '/assets/images/memory.png'" alt="memory" />
                                {{ specTotals?.memory }}
                                <p class="spec-totals-unit ml-1">GB Memory</p>
                            </span>
                        </span>
                        <div class="pdf-price-totals d-flex fx-col">
                            <div class="d-flex fx-row fx-row-h-space-between mb-base">
                                <p>Configured Price</p>
                                <p class="pdf-price bold">{{ configuration.optimizedConfigurations[activeReference].priceTotal }}</p>
                            </div>
                            <div class="d-flex fx-row fx-row-h-space-between">
                                <p>Shipping & Sales Tax</p>
                                <p class="pdf-ship-tax">TBD after system validation</p>
                            </div>
                        </div>
                    </div>
                </span>

                <div class="bottom-wrapper d-flex fx-row fx-row-h-space-between">
                    <div class="pdf-bottom-left">
                        <p class="sales-p bold mb-1">Sales</p>
                        <p class="sales-sub-p d-flex fx-row fx-row-v-center mb-base">
                            <img class="pdf-icon-bottom d-flex mr-1" [src]="getBaseUrl() + '/assets/images/phone.png'" alt="phone" />
                            510.226.7366
                        </p>
                        <p class="sales-sub-p d-flex fx-row fx-row-v-center">
                            <img class="pdf-icon-bottom d-flex mr-1" [src]="getBaseUrl() + '/assets/images/mail.png'" alt="mail" />
                            sales@exxactcorp.com
                        </p>
                    </div>
                    <div class="pdf-bottom-right">
                        <p>
                            This document is strictly private, confidential and personal to its recipients and should not be copied, distributed, or
                            reproduced in whole or in part, nor passed to any third party.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div leftPane class="fx-wrap configurator" id="leftPane">
            <div class="fx-row configurator-container">
                <div class="fx-row fx-wrap fx-row-container">
                    <div class="review-configuration col-md-2_5 col-sm-12">
                        <div class="review-configuration-container fx-col">
                            <div>
                                <h2 *ngIf="configuration.type">{{ configuration?.type }}</h2>
                                <h5
                                    class="configuration-mpn font-lg"
                                    [ngClass]="isNarrow ? 'd-flex justify-content-center' : ''"
                                    *ngIf="configuration.mpn"
                                >
                                    {{ configuration?.mpn }}
                                </h5>
                                <div *ngIf="!isNarrow" class="review-save pt-3">
                                    <div class="fx-row" (click)="saveSelectedData(true)">
                                        <p class="m-0 blue-link">Save PDF</p>
                                        <mat-icon class="blue-link">chevron_right</mat-icon>
                                    </div>
                                    <div class="fx-row">
                                        <button class="discount-button fx-row fx-row-h-space-around">
                                            <mat-icon class="mr-1 fx-row" svgIcon="price-tag"></mat-icon>
                                            <span class="font-xxs bold">Ask about Edu / Gov discounts</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="img-container">
                                    <img
                                        [ngClass]="isNarrow ? 'narrow-solution-img' : ''"
                                        [src]="getWebpImg(configuration?.imgUrl)"
                                        alt="Solution image"
                                    />
                                </div>
                            </div>
                            <div *ngIf="isNarrow" class="d-flex justify-content-center">
                                <button class="button-tag fx-row-h-space-around discount-button">
                                    <mat-icon class="mr-1" svgIcon="price-tag"></mat-icon>
                                    <span class="font-xxs">Ask about Edu / Gov discounts</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="configure-system col-md-6 col-offset-md-1 col-sm-12" [ngClass]="!isNarrow ? 'mb-5' : ''">
                        <section>
                            <h2 *ngIf="!isNarrow">Configure your System</h2>
                            <p class="title">Optimized Configurations</p>
                        </section>

                        <section class="featured-system-container fx-row fx-row-v-center pt-2">
                            <a
                                *ngIf="configuration?.optimizedConfigurations['good']?.costTotal != '0.00' || activeReference == 'good'"
                                class="button-references fx-col fx-col-h-center fx-col-v-center"
                                [ngClass]="activeReference == 'good' ? 'active' : ''"
                                (click)="selectReference('good')"
                            >
                                <div class="col-sm-9 p-0">
                                    <h5 *ngIf="!configuration.optimizedConfigurations['good'].title" class="text-center">Essential</h5>
                                    <h5 *ngIf="configuration.optimizedConfigurations['good'].title" class="text-center">
                                        {{ configuration?.optimizedConfigurations['good']?.title }}
                                    </h5>
                                </div>
                                <h4 class="m-0">{{ configuration.optimizedConfigurations['good'].priceTotal }}</h4>
                            </a>
                            <a
                                *ngIf="configuration?.optimizedConfigurations['better']?.costTotal != '0.00' || activeReference == 'better'"
                                class="button-references fx-col fx-col-h-center fx-col-v-center"
                                [ngClass]="activeReference == 'better' ? 'active' : ''"
                                (click)="selectReference('better')"
                            >
                                <div class="col-sm-9 p-0">
                                    <h5 *ngIf="!configuration.optimizedConfigurations['better'].title" class="text-center">Professional</h5>
                                    <h5 *ngIf="configuration.optimizedConfigurations['better'].title" class="text-center">
                                        {{ configuration?.optimizedConfigurations['better']?.title }}
                                    </h5>
                                </div>
                                <h4 class="m-0">{{ configuration.optimizedConfigurations['better'].priceTotal }}</h4>
                            </a>
                            <a
                                *ngIf="configuration?.optimizedConfigurations['best']?.costTotal != '0.00' || activeReference == 'best'"
                                class="button-references fx-col fx-col-h-center fx-col-v-center"
                                [ngClass]="activeReference == 'best' ? 'active' : ''"
                                (click)="selectReference('best')"
                            >
                                <div class="col-sm-6 p-0">
                                    <h5 *ngIf="!configuration.optimizedConfigurations['best'].title" class="text-center">Premium</h5>
                                    <h5 *ngIf="configuration.optimizedConfigurations['best'].title" class="text-center">
                                        {{ configuration?.optimizedConfigurations['best']?.title }}
                                    </h5>
                                </div>
                                <h4 class="m-0">{{ configuration.optimizedConfigurations['best'].priceTotal }}</h4>
                            </a>
                        </section>

                        <section class="fx-col">
                            <div *ngFor="let category of categoryKeys" class="category">
                                <div
                                    *ngIf="
                                        !isEmpty(get(configuration.products, category, '')) &&
                                        configuration?.category[category]?.isRequired &&
                                        !hasSharedRule(category, 'min/max') &&
                                        !hideNetworkCables(category)
                                    "
                                    id="{{ category }}"
                                    class="mt-4"
                                >
                                    <h5 class="title mb-base">{{ category }}</h5>
                                    <!-- <p *ngIf="categoryDescriptions.get(category) != ''" class="m-0 lh-sm font-xs color-gray-400">
                    {{ categoryDescriptions.get(category) }}
                  </p> -->
                                    <span class="copy-sm emli-text mt-0 mb-1" *ngIf="category === 'Software'" (click)="openModal(EMLIModal)"
                                        >See what's inside each EMLI package <mat-icon>chevron_right</mat-icon></span
                                    >
                                    <ng-template #EMLIModal>
                                        <emli-table-inner-content (close)="closeModal()"></emli-table-inner-content>
                                    </ng-template>

                                    <!-- Notes pulled from database object -->
                                    <div *ngIf="get(configuration.category, [category, 'note'], '')" class="fx-row fx-row-v-center">
                                        <div class="fx-row alert-container mt-1 align-items-center">
                                            <mat-icon class="pr-1 alert-circle-note d-flex" svgIcon="alert-circle"></mat-icon>
                                            <p class="config-note fx-row align-items-center">
                                                {{ get(configuration.category, [category, 'note'], '') }}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- Notes generated on frontend for NVLink -->
                                    <div *ngIf="category == 'NVIDIA NVLink'" class="fx-col fx-col-v-center">
                                        <div class="fx-row alert-container mt-1 w-fit-content align-items-center">
                                            <mat-icon class="pr-1 alert-circle-note d-flex" svgIcon="alert-circle"></mat-icon>
                                            <p class="config-note fx-row align-items-center">
                                                NVLinks only applies to pairs (2) of the same type of GPU
                                            </p>
                                        </div>
                                        <div *ngIf="gpuData.nvLinkMessage" class="fx-row alert-container mt-1 w-fit-content align-items-center">
                                            <mat-icon class="pr-1 alert-circle-note d-flex" svgIcon="alert-circle"></mat-icon>
                                            <p class="config-note fx-row align-items-center">{{ gpuData.nvLinkMessage }}</p>
                                        </div>
                                    </div>
                                    <!-- Other error notes -->
                                    <div *ngIf="checkError(category)">
                                        <div *ngIf="hasErrorCategory(category)">
                                            <div
                                                class="form-error fx-row fx-row-v-center error-padding mt-1"
                                                [class.show]="hasErrorCategory(category)"
                                            >
                                                <mat-icon class="pr-1" svgIcon="alert-triangle"></mat-icon> Missing parts for this category.
                                            </div>
                                        </div>
                                        <div *ngIf="hasErrorSelectMultiple(category)">
                                            <div
                                                class="form-error fx-row fx-row-v-center error-padding mt-1"
                                                [class.show]="hasErrorSelectMultiple(category)"
                                            >
                                                <mat-icon class="pr-1" svgIcon="alert-triangle"></mat-icon> Too many parts selected.
                                            </div>
                                        </div>
                                    </div>
                                    <!-- OS and Software Disabled -->
                                    <div *ngIf="category == 'OS' && osDisabled" class="fx-col fx-col-v-center">
                                        <div class="fx-row alert-container mt-1 w-fit-content align-items-center">
                                            <mat-icon class="pr-1 alert-circle-note d-flex" svgIcon="alert-circle"></mat-icon>
                                            <p class="config-note fx-row align-items-center">
                                                An operating system can be selected once a drive is added to the configuration
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="category == 'Software' && osDisabled" class="fx-col fx-col-v-center">
                                        <div class="fx-row alert-container mt-1 w-fit-content align-items-center">
                                            <mat-icon class="pr-1 alert-circle-note d-flex" svgIcon="alert-circle"></mat-icon>
                                            <p class="config-note fx-row align-items-center">
                                                Software can be selected once a drive and supported OS is added to the configuration
                                            </p>
                                        </div>
                                    </div>
                                    <!-- Products and product lines-->
                                    <div>
                                        <div
                                            *ngIf="configuration?.optimizedConfigurations[activeReference]?.category[category]?.noThanks?.name"
                                            (click)="selectNoThanks(category)"
                                            class="select-parts-container fx-row fx-row-h-space-between fx-row-v-center mt-1"
                                            [ngClass]="
                                                configuration?.optimizedConfigurations[activeReference]?.selectedSpecs[category].length == 0
                                                    ? 'active'
                                                    : ''
                                            "
                                        >
                                            <p>
                                                {{ configuration?.optimizedConfigurations[activeReference]?.category[category]?.noThanks.name }}
                                            </p>
                                            <p
                                                class="ml-2 bold"
                                                [ngClass]="
                                                    calcDiff(
                                                        category,
                                                        configuration?.optimizedConfigurations[activeReference]?.category[category]?.noThanks
                                                    ) == 'Included'
                                                        ? 'calc-diff'
                                                        : ''
                                                "
                                            >
                                                {{
                                                    calcDiff(
                                                        category,
                                                        configuration?.optimizedConfigurations[activeReference]?.category[category]?.noThanks
                                                    )
                                                }}
                                            </p>
                                        </div>
                                        <div
                                            *ngFor="let line of configuration?.category[category]?.productLineKeys; index as j"
                                            class="draggable-container"
                                            [class.hide]="category == 'Network Cable' && !networkingData.activeCableProductLines.includes(line)"
                                        >
                                            <div class="fx-row fx-row-v-center line-container m-0" *ngIf="line != 'null' && line != ''">
                                                <span class="mt-2 productline-title">{{ line }}</span>
                                            </div>
                                            <ng-container *ngIf="category != 'NVIDIA NVLink'">
                                                <div
                                                    *ngFor="
                                                        let product of configuration?.optimizedConfigurations[activeReference].category[category]
                                                            .productLines[line]
                                                    "
                                                    class="draggable fx-row fx-row-h-space-between"
                                                    [class.hide]="
                                                        category == 'Network Cable' && product.connectorType !== networkingData.activeConnectorType
                                                    "
                                                >
                                                    <span
                                                        *ngIf="
                                                            configuration?.category[category]?.options?.length != 1 &&
                                                            (product.type !== 'GPU' || calcMaxGPUQty(product.slotSpace, gpuData) != 1)
                                                        "
                                                        [ngClass]="
                                                            configuration?.category[category]?.options?.length == 1 ? 'wide-margin-right' : 'mr-1'
                                                        "
                                                    >
                                                        <!-- Product Quantity Selector -->
                                                        <mat-select
                                                            *ngIf="category != 'GPU' && category != 'Network Cable' && category != 'Memory'"
                                                            [value]="product?.quantity"
                                                            [disabled]="configuration?.category[category]?.options?.length == 1"
                                                            [ngClass]="
                                                                (includesProducts(category, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                ' ' +
                                                                (configuration?.category[category]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '') +
                                                                ' ' +
                                                                (category === 'Network Cable' &&
                                                                product.connectorType !== networkingData.activeConnectorType
                                                                    ? 'color-gray-400 disabled-arrow'
                                                                    : '')
                                                            "
                                                        >
                                                            <!-- updateGpuData is here specifically for nvLink only -->
                                                            <mat-option
                                                                *ngFor="let n of get(configuration?.category, [category, 'options'], '')"
                                                                [value]="n"
                                                                (click)="
                                                                    updateProductQuantity(n, category, product, false);
                                                                    updateGpuData(
                                                                        category,
                                                                        configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                    )
                                                                "
                                                            >
                                                                {{ n + 'x' }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <!-- Quantity Selector specific to GPUs -->
                                                        <!-- Uses maxGPU slot and GPU product slotSpace to determine min/max possible quantity -->
                                                        <mat-select
                                                            *ngIf="category == 'GPU'"
                                                            [value]="product?.quantity"
                                                            [disabled]="configuration?.category[category]?.options?.length == 1"
                                                            [ngClass]="
                                                                (includesProducts(category, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                '' +
                                                                (configuration?.category[category]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '')
                                                            "
                                                        >
                                                            <!-- if the slotSpace and maxGPUSlot attribute has not been populated,
                                                            default to using older 'options' attribute -->
                                                            <ng-container
                                                                *ngIf="product.slotSpace && configuration.maxGPUSlots; else gpu_fallback_quantity"
                                                            >
                                                                <mat-option
                                                                    *ngFor="
                                                                        let n of [].constructor(calcMaxGPUQty(product.slotSpace, gpuData));
                                                                        let i = index
                                                                    "
                                                                    [value]="i + 1"
                                                                    (click)="
                                                                        updateProductQuantity(i + 1, category, product, false);
                                                                        updateGpuData(
                                                                            category,
                                                                            configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                        )
                                                                    "
                                                                >
                                                                    {{ i + 1 + 'x' }}
                                                                </mat-option>
                                                            </ng-container>
                                                            <ng-template #gpu_fallback_quantity>
                                                                <mat-option
                                                                    *ngFor="let n of get(configuration?.category, [category, 'options'], '')"
                                                                    [value]="n"
                                                                    (click)="
                                                                        updateProductQuantity(n, category, product, false);
                                                                        updateGpuData(
                                                                            category,
                                                                            configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                        )
                                                                    "
                                                                >
                                                                    {{ n + 'x' }}
                                                                </mat-option>
                                                            </ng-template>
                                                        </mat-select>
                                                        <!-- Quantity Selector specific to Network Cables -->
                                                        <mat-select
                                                            *ngIf="
                                                                category == 'Network Cable' &&
                                                                networkingData.activeNetworkCard &&
                                                                product.connectorType == networkingData.activeConnectorType
                                                            "
                                                            [value]="product?.quantity"
                                                            [disabled]="
                                                                configuration?.category[category]?.options?.length == 1 ||
                                                                (category === 'Network Cable' &&
                                                                    product.connectorType !== networkingData.activeConnectorType)
                                                            "
                                                            [ngClass]="
                                                                (includesProducts(category, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                ' ' +
                                                                (configuration?.category[category]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '') +
                                                                ' '
                                                            "
                                                        >
                                                            <mat-option
                                                                *ngFor="let n of networkingData.numPorts"
                                                                [value]="n"
                                                                (click)="updateProductQuantity(n, category, product, false)"
                                                            >
                                                                {{ n + 'x' }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <!-- Quantity Selector Specific to Memory -->
                                                        <mat-select
                                                            *ngIf="category == 'Memory'"
                                                            [value]="product?.quantity"
                                                            [disabled]="configuration?.category[category]?.options?.length == 1"
                                                            [ngClass]="
                                                                (includesProducts(category, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                '' +
                                                                (configuration?.category[category]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '')
                                                            "
                                                        >
                                                            <!-- if the minTotalMemory attribute has not been populated,
                                                            default to using older 'options' attribute -->
                                                            <ng-container
                                                                *ngIf="configuration.minTotalMemory && product.memory; else memory_fallback_quantity"
                                                            >
                                                                <mat-option
                                                                    *ngFor="let n of calculateMemoryQuantity(product)"
                                                                    [value]="n"
                                                                    (click)="updateProductQuantity(n, category, product, false)"
                                                                >
                                                                    {{ n + 'x' }}
                                                                </mat-option>
                                                            </ng-container>
                                                            <ng-template #memory_fallback_quantity>
                                                                <mat-option
                                                                    *ngFor="let n of get(configuration?.category, [category, 'options'], '')"
                                                                    [value]="n"
                                                                    (click)="
                                                                        updateProductQuantity(n, category, product, false);
                                                                        updateGpuData(
                                                                            category,
                                                                            configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                        )
                                                                    "
                                                                >
                                                                    {{ n + 'x' }}
                                                                </mat-option>
                                                            </ng-template>
                                                        </mat-select>
                                                    </span>

                                                    <!-- Quantity display for single option quantities that are not 1 (desktop) -->
                                                    <!-- ex: a product with only a single option for 2x items -->
                                                    <div
                                                        *ngIf="
                                                            !isNarrow &&
                                                            configuration?.category[category]?.options?.length == 1 &&
                                                            configuration?.category[category]?.options[0] !== 1
                                                        "
                                                        [ngClass]="
                                                            configuration?.category[category]?.options?.length == 1 ? 'wide-margin-right' : 'mr-1'
                                                        "
                                                    >
                                                        <div
                                                            class="single-quantity-container font-sm bold"
                                                            [ngClass]="
                                                                (includesProducts(category, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                '' +
                                                                (configuration?.category[category]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '')
                                                            "
                                                        >
                                                            {{ configuration?.category[category]?.options[0] }}x
                                                        </div>
                                                    </div>
                                                    <!-- Main product selector -->
                                                    <!-- (click) has an extra condition to prevent selecting windows + additional software -->
                                                    <div
                                                        class="select-parts-container fx-row fx-row-h-space-between fx-row-v-center"
                                                        [ngClass]="
                                                            includesProducts(category, product)
                                                                ? 'active'
                                                                : '' + (category === 'Software' && softwareDisabled)
                                                                ? 'color-gray-400'
                                                                : '' + category === 'OS' && osDisabled
                                                                ? 'color-gray-400'
                                                                : ''
                                                        "
                                                        (click)="
                                                            (category === 'Software' && softwareDisabled) || (category === 'OS' && osDisabled)
                                                                ? null
                                                                : selectProduct(category, product);
                                                            updateGpuData(
                                                                category,
                                                                configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                            )
                                                        "
                                                    >
                                                        <!-- Single option quantity (mobile), product name, and calculated pricing -->
                                                        <div
                                                            *ngIf="
                                                                isNarrow &&
                                                                configuration?.category[category]?.options?.length == 1 &&
                                                                configuration?.category[category]?.options[0] !== 1
                                                            "
                                                            class="single-option-quantity h-100 d-flex align-items-start bold mr-2 mb"
                                                        >
                                                            {{ configuration?.category[category]?.options[0] }}x
                                                        </div>
                                                        <span
                                                            class="w-100 fx-row justify-content-between"
                                                            [ngClass]="
                                                                category === 'Software' && softwareDisabled
                                                                    ? 'color-gray-400'
                                                                    : '' + category === 'OS' && osDisabled
                                                                    ? 'color-gray-400'
                                                                    : ''
                                                            "
                                                        >
                                                            <span class="fx-col">
                                                                <ng-container *ngFor="let line of product.name.split(';'); let last = last">
                                                                    <p
                                                                        [ngClass]="
                                                                            category === 'Software' && softwareDisabled
                                                                                ? 'color-gray-400'
                                                                                : '' + category === 'OS' && osDisabled
                                                                                ? 'color-gray-400'
                                                                                : ''
                                                                        "
                                                                        [class.mb-halfbase]="!last"
                                                                    >
                                                                        {{ line }}
                                                                    </p>
                                                                </ng-container>
                                                            </span>
                                                            <span
                                                                *ngIf="category == 'Memory' && !isNarrow"
                                                                class="memory-totals color-gray-500 font-xs"
                                                                [ngClass]="includesProducts(category, product) ? 'bold' : ''"
                                                            >
                                                                {{ product.memory * product.quantity }} GB total
                                                            </span>
                                                        </span>
                                                        <p
                                                            class="ml-1 bold pricing h-100 d-flex flex-column align-items-end justify-content-start"
                                                            [ngClass]="
                                                                calcDiff(category, product) == 'Included'
                                                                    ? 'calc-diff'
                                                                    : '' + category === 'Software' && softwareDisabled
                                                                    ? 'color-gray-400'
                                                                    : '' + category === 'OS' && osDisabled
                                                                    ? 'color-gray-400'
                                                                    : ''
                                                            "
                                                        >
                                                            <ng-container *ngIf="category !== 'Software' && category != 'OS'">
                                                                {{ calcDiff(category, product) }}
                                                            </ng-container>
                                                            <ng-container *ngIf="category == 'Software'">
                                                                {{
                                                                    category === 'Software' && softwareDisabled
                                                                        ? 'Not Supported'
                                                                        : calcDiff(category, product)
                                                                }}
                                                            </ng-container>
                                                            <ng-container *ngIf="category === 'OS'">
                                                                {{ category === 'OS' && osDisabled ? 'Not Supported' : calcDiff(category, product) }}
                                                            </ng-container>
                                                            <span
                                                                *ngIf="category == 'Memory' && isNarrow"
                                                                class="color-gray-500 font-xs mt-base"
                                                                [ngClass]="includesProducts(category, product) ? 'bold' : 'weight-400'"
                                                            >
                                                                {{ product.memory * product.quantity }} GB total
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!-- NVLink specific product options -->
                                            <div
                                                *ngIf="category == 'NVIDIA NVLink'"
                                                class="select-parts-container fx-row fx-row-h-space-between fx-row-v-center mt-1"
                                                [ngClass]="
                                                    includesProducts(
                                                        category,
                                                        configuration?.optimizedConfigurations[activeReference].category[category].productLines[
                                                            line
                                                        ][0]
                                                    )
                                                        ? 'active'
                                                        : '' + ' ' + gpuData.isSupported && gpuData.totalLinks
                                                        ? ''
                                                        : 'color-gray-400'
                                                "
                                                (click)="
                                                    gpuData.isSupported && gpuData.totalLinks
                                                        ? selectProduct(
                                                              category,
                                                              configuration?.optimizedConfigurations[activeReference].category[category].productLines[
                                                                  line
                                                              ][0]
                                                          )
                                                        : null
                                                "
                                            >
                                                <span class="d-flex flex-column color-inherit">
                                                    <p class="color-inherit">Yes please.</p>
                                                    <p *ngIf="gpuData.totalLinks" class="p-nvLink">{{ gpuData.totalLinks }}x NVLink</p>
                                                </span>
                                                <p
                                                    class="ml-2 bold pricing h-100 d-flex align-items-start justify-content-end"
                                                    [ngClass]="
                                                        calcDiff(
                                                            category,
                                                            configuration?.optimizedConfigurations[activeReference].category[category].productLines[
                                                                line
                                                            ][0]
                                                        ) == 'Included'
                                                            ? 'calc-diff color-green-300'
                                                            : '' + ' ' + gpuData.isSupported && gpuData.totalLinks
                                                            ? ''
                                                            : 'color-gray-400'
                                                    "
                                                >
                                                    {{
                                                        gpuData.isSupported && gpuData.totalLinks
                                                            ? calcDiff(
                                                                  category,
                                                                  configuration?.optimizedConfigurations[activeReference].category[category]
                                                                      .productLines[line][0]
                                                              )
                                                            : 'Not Supported'
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Shared rule specific categories -->
                                <div *ngIf="hasSharedRule(category, 'min/max') && isSharedRuleAnchor(category, 'min/max')" class="category mt-4">
                                    <div *ngFor="let sharedCategory of getUsedCategoriesForSharedRuleAnchor(category, 'min/max')">
                                        <div class="title" *ngIf="isSharedRuleAnchor(sharedCategory, 'min/max')">
                                            {{ combineSharedRuleCategoryLabels(sharedCategory, 'min/max') }}
                                        </div>
                                        <!-- Shared Notes & Errors -->
                                        <!-- Notes pulled from database object -->
                                        <div *ngIf="get(configuration.category, [sharedCategory, 'note'], '')" class="fx-row fx-row-v-center">
                                            <div class="fx-row alert-container mt-1 align-items-center">
                                                <mat-icon class="pr-1 alert-circle-note d-flex" svgIcon="alert-circle"></mat-icon>
                                                <p class="config-note fx-row align-items-center">
                                                    {{ get(configuration.category, [sharedCategory, 'note'], '') }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- Shared rule error -->
                                        <div
                                            *ngIf="checkErrorSharedRule(sharedCategory, 'min/max') && isSharedRuleAnchor(sharedCategory, 'min/max')"
                                            class="form-error error-padding fx-row fx-row-v-center my-1"
                                            [class.show]="checkErrorSharedRule(sharedCategory, 'min/max')"
                                        >
                                            <mat-icon class="pr-1" svgIcon="alert-circle"></mat-icon>
                                            Shared quantity is either greater than max or less than min.
                                        </div>

                                        <!-- Other error notes -->
                                        <div *ngIf="checkError(sharedCategory)">
                                            <div *ngIf="hasErrorCategory(sharedCategory)">
                                                <div
                                                    class="form-error fx-row fx-row-v-center error-padding mt-1"
                                                    [class.show]="hasErrorCategory(sharedCategory)"
                                                >
                                                    <mat-icon class="pr-1" svgIcon="alert-triangle"></mat-icon> Missing parts for this category.
                                                </div>
                                            </div>
                                            <div *ngIf="hasErrorSelectMultiple(sharedCategory)">
                                                <div
                                                    class="form-error fx-row fx-row-v-center error-padding mt-1"
                                                    [class.show]="hasErrorSelectMultiple(sharedCategory)"
                                                >
                                                    <mat-icon class="pr-1" svgIcon="alert-triangle"></mat-icon> Too many parts selected.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Shared Products -->
                                    <div *ngFor="let sharedCategory of getUsedCategoriesForSharedRuleAnchor(category, 'min/max')">
                                        <div class="mt-0">
                                            <div *ngFor="let line of (configuration?.category)[sharedCategory]?.productLineKeys; index as j">
                                                <div class="fx-row fx-row-v-center m-0 line-container">
                                                    <span class="productline-title mt-2" *ngIf="!line || line == ' ' || line == 'null'">{{
                                                        sharedCategory
                                                    }}</span>
                                                    <span class="productline-title mt-2" *ngIf="line != 'null'">{{ line }}</span>
                                                </div>

                                                <!-- "No thanks" option -->
                                                <div
                                                    *ngIf="
                                                        ((configuration?.optimizedConfigurations)[activeReference]?.category)[sharedCategory]
                                                            ?.noThanks?.name
                                                    "
                                                    (click)="selectNoThanks(sharedCategory)"
                                                    class="select-parts-container fx-row fx-row-h-space-between fx-row-v-center mt-1"
                                                    [ngClass]="
                                                        ((configuration?.optimizedConfigurations)[activeReference]?.selectedSpecs)[sharedCategory]
                                                            .length == 0
                                                            ? 'active'
                                                            : ''
                                                    "
                                                >
                                                    <p>
                                                        {{
                                                            ((configuration?.optimizedConfigurations)[activeReference]?.category)[sharedCategory]
                                                                ?.noThanks.name
                                                        }}
                                                    </p>
                                                    <p
                                                        class="ml-2 bold"
                                                        [ngClass]="
                                                            calcDiff(
                                                                sharedCategory,
                                                                ((configuration?.optimizedConfigurations)[activeReference]?.category)[sharedCategory]
                                                                    ?.noThanks
                                                            ) == 'Included'
                                                                ? 'calc-diff'
                                                                : ''
                                                        "
                                                    >
                                                        {{
                                                            calcDiff(
                                                                sharedCategory,
                                                                ((configuration?.optimizedConfigurations)[activeReference]?.category)[sharedCategory]
                                                                    ?.noThanks
                                                            )
                                                        }}
                                                    </p>
                                                </div>

                                                <div
                                                    *ngFor="
                                                        let product of configuration?.optimizedConfigurations[activeReference].category[
                                                            sharedCategory
                                                        ].productLines[line]
                                                    "
                                                    class="draggable fx-row fx-row-h-space-between"
                                                >
                                                    <span
                                                        *ngIf="
                                                            configuration?.category[sharedCategory]?.options?.length != 1 &&
                                                            (product.type !== 'GPU' || calcMaxGPUQty(product.slotSpace, gpuData) != 1)
                                                        "
                                                        [ngClass]="
                                                            configuration?.category[sharedCategory]?.options?.length == 1
                                                                ? 'wide-margin-right'
                                                                : 'mr-1'
                                                        "
                                                    >
                                                        <!-- Product Quantity Selector -->
                                                        <mat-select
                                                            *ngIf="sharedCategory != 'GPU'"
                                                            [value]="product?.quantity"
                                                            [disabled]="configuration?.category[sharedCategory]?.options?.length == 1"
                                                            [ngClass]="
                                                                (includesProducts(sharedCategory, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                '' +
                                                                (configuration?.category[sharedCategory]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '')
                                                            "
                                                        >
                                                            <!-- updateGpuData is here specifically for nvLink only -->
                                                            <mat-option
                                                                *ngFor="let n of get(configuration?.category, [sharedCategory, 'options'], '')"
                                                                [value]="n"
                                                                (click)="
                                                                    updateProductQuantity(n, sharedCategory, product, false);
                                                                    updateGpuData(
                                                                        sharedCategory,
                                                                        configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                    )
                                                                "
                                                            >
                                                                {{ n + 'x' }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <!-- Quantity Selector specific to GPUs -->
                                                        <!-- Uses maxGPU slot and GPU product slotSpace to determine min/max possible quantity -->
                                                        <mat-select
                                                            *ngIf="sharedCategory == 'GPU'"
                                                            [value]="product?.quantity"
                                                            [disabled]="configuration?.category[sharedCategory]?.options?.length == 1"
                                                            [ngClass]="
                                                                (includesProducts(sharedCategory, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                '' +
                                                                (configuration?.category[sharedCategory]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '')
                                                            "
                                                        >
                                                            <!-- if the slotSpace and maxGPUSlot attribute has not been populated,
                                   default to using older 'options' attribute -->
                                                            <ng-container
                                                                *ngIf="product.slotSpace && configuration.maxGPUSlots; else gpu_fallback_quantity"
                                                            >
                                                                <mat-option
                                                                    *ngFor="
                                                                        let n of [].constructor(calcMaxGPUQty(product.slotSpace, gpuData));
                                                                        let i = index
                                                                    "
                                                                    [value]="i + 1"
                                                                    (click)="
                                                                        updateProductQuantity(i + 1, sharedCategory, product, false);
                                                                        updateGpuData(
                                                                            sharedCategory,
                                                                            configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                        )
                                                                    "
                                                                >
                                                                    {{ i + 1 + 'x' }}
                                                                </mat-option>
                                                            </ng-container>
                                                            <ng-template #gpu_fallback_quantity>
                                                                <mat-option
                                                                    *ngFor="let n of get(configuration?.category, [sharedCategory, 'options'], '')"
                                                                    [value]="n"
                                                                    (click)="
                                                                        updateProductQuantity(n, sharedCategory, product, false);
                                                                        updateGpuData(
                                                                            sharedCategory,
                                                                            configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                                        )
                                                                    "
                                                                >
                                                                    {{ n + 'x' }}
                                                                </mat-option>
                                                            </ng-template>
                                                        </mat-select>
                                                    </span>
                                                    <!-- Quantity display for single option quantities that are not 1 (desktop) -->
                                                    <!-- ex: a product with only a single option for 2x items -->
                                                    <div
                                                        *ngIf="
                                                            !isNarrow &&
                                                            configuration?.category[sharedCategory]?.options?.length == 1 &&
                                                            configuration?.category[sharedCategory]?.options[0] !== 1
                                                        "
                                                        [ngClass]="
                                                            configuration?.category[sharedCategory]?.options?.length == 1
                                                                ? 'wide-margin-right'
                                                                : 'mr-1'
                                                        "
                                                    >
                                                        <div
                                                            class="single-quantity-container font-sm bold"
                                                            [ngClass]="
                                                                (includesProducts(sharedCategory, product) ? 'highlight' : '') +
                                                                ' ' +
                                                                (product?.quantity > 9 ? 'mat-select-value-offset' : '') +
                                                                '' +
                                                                (configuration?.category[sharedCategory]?.options?.length == 1
                                                                    ? 'no-select'
                                                                    : 'selectable') +
                                                                ' ' +
                                                                (isNarrow ? 'narrow-quantity' : '')
                                                            "
                                                        >
                                                            {{ configuration?.category[sharedCategory]?.options[0] }}x
                                                        </div>
                                                    </div>
                                                    <!-- Main product selector -->
                                                    <div
                                                        class="select-parts-container fx-row fx-row-h-space-between fx-row-v-center"
                                                        [ngClass]="includesProducts(sharedCategory, product) ? 'active' : ''"
                                                        (click)="
                                                            selectProduct(sharedCategory, product);
                                                            updateGpuData(
                                                                sharedCategory,
                                                                configuration?.optimizedConfigurations[activeReference].selectedSpecs
                                                            )
                                                        "
                                                    >
                                                        <!-- Single option quantity (mobile), product name, and calculated pricing -->
                                                        <div
                                                            *ngIf="
                                                                isNarrow &&
                                                                configuration?.category[sharedCategory]?.options?.length == 1 &&
                                                                configuration?.category[sharedCategory]?.options[0] !== 1
                                                            "
                                                            class="single-option-quantity h-100 d-flex align-items-start bold mr-2 mb"
                                                        >
                                                            {{ configuration?.category[sharedCategory]?.options[0] }}x
                                                        </div>
                                                        <p class="fx-col">
                                                            {{ product.name }}
                                                            <span *ngIf="sharedCategory == 'Memory'" class="weight-400 color-gray-500 font-xs">
                                                                {{ product.memory * product.quantity }} GB total
                                                            </span>
                                                        </p>
                                                        <p
                                                            class="ml-1 bold pricing h-100 d-flex align-items-center justify-content-end"
                                                            [ngClass]="calcDiff(sharedCategory, product) == 'Included' ? 'calc-diff' : ''"
                                                        >
                                                            {{ calcDiff(sharedCategory, product) }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div *ngIf="isNarrow" class="review-save py-3 col-sm-12">
                        <div class="pb-2">
                            <a class="fx-row save" (click)="saveSelectedData(true)">
                                <p class="lh-1 d-flex align-items-center align-self-center m-0 save-text">Save Specs</p>
                                <mat-icon class="d-flex align-items-center color-btn-blue">chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Sticky footer (Spec totals, price, and order confirmation) -->
            <span class="footer-tab-wrapper">
                <div class="footer-tab-inner-wrapper">
                    <div class="footer-tab">
                        <button
                            *ngIf="specTotals?.memory || specTotals?.wattage || specTotals?.btu"
                            class="footer-tab-toggle justify-content-center align-items-center w-100"
                            (click)="bottomTabToggled = !bottomTabToggled"
                        >
                            <p class="m-0 font-sm bold">Hide system details</p>
                            <mat-icon class="m-0" [ngClass]="bottomTabToggled ? '' : 'flip-chevron'">chevron_right</mat-icon>
                        </button>
                        <div
                            id="config-floating-text-button"
                            class="fx-row fx-row-v-center fx-row-h-center"
                            [ngClass]="specTotals?.memory || specTotals?.wattage || specTotals?.btu ? '' : 'floating-text-no-totals'"
                        >
                            <div class="col-xl-12 col-lg-6 p-0">
                                <div
                                    class="spec-totals d-flex flex-col"
                                    *ngIf="(specTotals?.memory || specTotals?.wattage || specTotals?.btu) && (!isNarrow || bottomTabToggled)"
                                >
                                    <span class="d-flex flex-row align-items-center">
                                        <mat-icon svgIcon="ram"></mat-icon>
                                        {{ specTotals?.memory }} <span class="spec-totals-unit m-0">GB Memory</span>
                                    </span>
                                    <span class="d-flex flex-row align-items-center">
                                        <mat-icon svgIcon="lightning"></mat-icon>
                                        {{ specTotals?.wattage }} <span class="spec-totals-unit m-0">Watts</span>
                                    </span>
                                    <span class="d-flex flex-row align-items-center">
                                        <mat-icon svgIcon="fire"></mat-icon>
                                        {{ isNarrow ? specTotals?.btu.toFixed(0) : specTotals?.btu }}
                                        <span class="spec-totals-unit m-0">BTU/Hr</span>
                                    </span>
                                </div>
                            </div>
                            <div class="spacer"></div>
                            <div class="cta-wrapper d-flex flex-row col-xl-6 col-lg-7">
                                <span class="pricing-wrapper">
                                    <p class="m-0 bold copy-sm mb-base lh-1">Configured price</p>
                                    <h2 class="m-0 font-lg lh-1">
                                        {{ configuration.optimizedConfigurations[activeReference].priceTotal }}
                                    </h2>
                                </span>
                                <button-component
                                    id="cta"
                                    (click)="saveSelectedData(false); getSpecTotals()"
                                    [buttonOptions]="{
                                        text: 'Continue Order',
                                        type: 'primary',
                                        class: 'align-self-center'
                                    }"
                                ></button-component>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
    </app-slide-panel>
    <a id="link"></a>
</div>
