<div *ngIf="data" class="review-save p-1">
  <mat-icon svgIcon="close" class="close-button" [mat-dialog-close]="true"></mat-icon>
  <div id="show-specs-marketo" class="fx-col">
    <h2 class="config-name color-gray-600 mb-3">{{ data.configuration.name }}</h2>
    <div class="category-row-container mb-3">
      <div *ngFor="let category of data.renderedCategories" class="fx-row category-row">
        <div
          *ngIf="
            data.configuration.optimizedConfigurations[data.activeReference].selectedSpecs[category].length > 0 ||
            ((data?.configuration?.optimizedConfigurations)[data?.activeReference]?.category)[category]?.noThanks?.name
          "
          class="category-row-container-dynamic col-sm-12 p-0 d-flex"
        >
          <div class="col-sm-2 p-0 mb-1 mr-2">
            <p class="category bold font-sm">{{ category }}</p>
          </div>
          <div class="col-sm-12 p-0">
            <div
              *ngFor="
                let product of ((data?.configuration?.optimizedConfigurations)[data.activeReference]?.selectedSpecs)[
                  category
                ]
              "
            >
              <div class="fx-row">
                <div class="quantity col-sm-1 p-0">
                  <p class="fx-col-h-left font-sm color-gray-600">{{ product?.quantity }}x</p>
                </div>
                <div class="col-sm-11 product fx-row pr-0">
                  <p class="fx-col-h-left font-sm color-gray-600">{{ product?.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="spec-totals flex-col justify-content-between mb-2"
      *ngIf="data.specTotals?.memory || data.specTotals?.wattage || data.specTotals?.btu"
    >
      <span class="d-flex flex-row align-items-center color-gray-500 bold font-md">
        <mat-icon svgIcon="ram"></mat-icon>
        {{ data.specTotals?.memory }}
        <p class="m-0 bold font-sm d-flex align-items-center h-100 color-btn-blue">GB Memory</p>
      </span>
      <span class="d-flex flex-row align-items-center color-gray-500 bold font-md">
        <mat-icon svgIcon="lightning"></mat-icon>
        {{ data.specTotals?.wattage }}
        <p class="m-0 bold font-sm d-flex align-items-center h-100 color-btn-blue">Watts</p>
      </span>
      <span class="d-flex flex-row align-items-center color-gray-500 bold font-md">
        <mat-icon svgIcon="fire"></mat-icon>
        {{ data.specTotals?.btu }}
        <p class="m-0 bold font-sm d-flex align-items-center h-100 color-btn-blue">BTU/Hr</p>
      </span>
    </div>
  </div>
</div>
