<div class="fx-wrap fx-row-container">
    <div class="col-md-12 text-center pb-4">
        <h2>An EMLI Environment for Every Developer</h2>
    </div>
    <!-- Card 1 -->
    <div class="col-md-4 fx-col side-border">
        <div class="text-center">
            <img src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/Conda.svg" alt="Conda EMLI" width="90" class="py-1" />
        </div>

        <div class="top-card px-1 text-center">
            <h4 class="title text-center">Conda EMLI</h4>

            <div class="text-center">
                <span>Separated Frameworks</span>
                <p>
                    For developers who want pre-installed deep learning frameworks and their dependencies in separate Python environments installed
                    natively on the system.
                </p>
            </div>
        </div>
    </div>

    <!-- Card 2 -->
    <hr class="divider mb-0 mt-1" />
    <div class="col-md-4 fx-col">
        <div class="text-center">
            <img
                src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/Container.svg"
                alt="Container EMLI"
                width="90"
                class="py-1"
            />
        </div>

        <div class="top-card px-1 text-center">
            <h4 class="title text-center">Container EMLI</h4>

            <div class="text-center">
                <span>Flexible. Reconfigurable.</span>
                <p>
                    For developers who want pre-installed frameworks utilizing the latest NGC containers, GPU drivers, and libraries in ready to
                    deploy DL environments with the flexibility of containerization.
                </p>
            </div>
        </div>
    </div>
    <hr class="divider mb-0 mt-1" />

    <!-- Card 3 -->

    <div class="col-md-4 fx-col side-border">
        <div class="text-center">
            <img src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/DIY.svg" alt="DIY EMLI" width="90" class="py-1" />
        </div>

        <div class="top-card px-1 text-center">
            <h4 class="title text-center">DIY EMLI</h4>

            <div class="text-center">
                <span>Simple. Clean. Custom.</span>
                <p>
                    For experienced developers who want a minimalist install to set up their own private deep learning repositories or custom builds
                    of deep learning frameworks.
                </p>
            </div>
        </div>
    </div>

    <button-component
        class="mt-3"
        (click)="openEmliModal()"
        [buttonOptions]="{
            text: 'See What’s Inside',
            type: 'primary',
            iconPosition: 'right',
            iconName: 'chevron-down'
        }"
    ></button-component>
</div>
