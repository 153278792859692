<div class="emli-table-inner-content">
    <div *ngIf="!isNarrow">
        <div class="dialog-top-row">
            <mat-icon class="dialog-close" (click)="closeFunction()" [mat-dialog-close]="">close</mat-icon>
        </div>
        <div id="cms-emli-table" class="fx-wrap fx-row-h-center">
            <div class="text-center">
                <table>
                    <tbody>
                        <tr>
                            <td width="25%"></td>
                            <td width="25%"></td>
                            <td width="25%"></td>
                        </tr>
                    </tbody>
                    <tbody class="emli-table">
                        <tr>
                            <th class="pt-4 white-bkg">
                                <h2>An EMLI Environment for Every Developer</h2>
                            </th>
                            <td class="pt-3">
                                <img
                                    src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/Conda.svg"
                                    alt="Conda EMLI"
                                    width="90"
                                />
                                <h4>Conda EMLI</h4>
                                <small>Separated Frameworks</small>
                            </td>
                            <td class="pt-3">
                                <img
                                    src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/Container.svg"
                                    alt="Container EMLI"
                                    width="90"
                                />
                                <h4>Container EMLI</h4>
                                <small>Flexible. Reconfigurable.</small>
                            </td>
                            <td class="pt-3">
                                <img src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/DIY.svg" alt="DIY EMLI" width="90" />
                                <h4>DIY EMLI</h4>
                                <small>Simple. Clean. Custom.</small>
                            </td>
                        </tr>
                        <tr>
                            <th class="white-bkg"></th>
                            <td>
                                <p>
                                    For developers who want pre-installed deep learning frameworks and their dependencies in separate Python
                                    environments installed natively on the system.
                                </p>
                            </td>
                            <td>
                                <p>
                                    For developers who want pre-installed frameworks utilizing the latest NGC containers, GPU drivers, and libraries
                                    in ready to deploy DL environments with the flexibility of containerization.
                                </p>
                            </td>
                            <td>
                                <p>
                                    For experienced developers who want a minimalist install to set up their own private deep learning repositories or
                                    custom builds of deep learning frameworks.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <tbody id="emli-table-listing" class="emli-table">
                        <tr class="emli-table-border-top">
                            <th><h4>Frameworks*</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>TensorFlow V1</th>
                            <td><hr class="spec-line" /></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>TensorFlow V2</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>PyTorch</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>MXnet</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>Caffe</th>
                            <td><hr class="spec-line" /></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>Caffe2</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>Chainer</th>
                            <td><hr class="spec-line" /></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th class="pb-2">Microsoft Cognitive Toolkit</th>
                            <td class="pb-2"><hr class="spec-line" /></td>
                            <td class="pb-2"><span class="spec-better"></span></td>
                            <td class="pb-2"><hr class="spec-line" /></td>
                        </tr>
                        <tr class="emli-table-border-top">
                            <th><h4>Data Annotation*</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th class="pb-2">Image Annotation</th>
                            <td class="pb-2"><span class="spec-good"></span></td>
                            <td class="pb-2"><span class="spec-better"></span></td>
                            <td class="pb-2"><hr class="spec-line" /></td>
                        </tr>
                        <tr class="emli-table-border-top">
                            <th><h4>Libraries*</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NVIDIA cuDNN</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><span class="spec-best"></span></td>
                        </tr>
                        <tr>
                            <th>NVIDIA Rapids</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>Keras</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th>Theano</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th class="pb-2">OpenCV</th>
                            <td class="pb-2"><span class="spec-good"></span></td>
                            <td class="pb-2"><span class="spec-better"></span></td>
                            <td class="pb-2"><hr class="spec-line" /></td>
                        </tr>
                        <tr class="emli-table-border-top">
                            <th><h4>Software Environments</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NVIDIA CUDA Toolkit</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><span class="spec-best"></span></td>
                        </tr>
                        <tr>
                            <th>NVIDIA CUDA Dev Toolkit</th>
                            <td><hr class="spec-line" /></td>
                            <td><span class="spec-better"></span></td>
                            <td><span class="spec-best"></span></td>
                        </tr>
                        <tr>
                            <th>NVIDIA Digits</th>
                            <td><span class="spec-good"></span></td>
                            <td><span class="spec-better"></span></td>
                            <td><hr class="spec-line" /></td>
                        </tr>
                        <tr>
                            <th class="pb-2">Anaconda</th>
                            <td class="pb-2"><span class="spec-good"></span></td>
                            <td class="pb-2"><span class="spec-better"></span></td>
                            <td class="pb-2"><hr class="spec-line" /></td>
                        </tr>
                        <tr class="emli-table-border-top">
                            <th><h4>Container Management</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th class="pb-2">Docker</th>
                            <td class="pb-2"><hr class="spec-line" /></td>
                            <td class="pb-2"><span class="spec-better"></span></td>
                            <td class="pb-2"><span class="spec-best"></span></td>
                        </tr>
                        <tr class="emli-table-border-top">
                            <th><h4>Drivers</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th class="pb-2">NVIDIA-qualified Driver</th>
                            <td class="pb-2"><span class="spec-good"></span></td>
                            <td class="pb-2"><span class="spec-better"></span></td>
                            <td class="pb-2"><span class="spec-best"></span></td>
                        </tr>
                        <tr class="emli-table-border-top">
                            <th><h4>Orchestration</h4></th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th class="pb-2">Micro-K8s</th>
                            <td class="pb-2">Free upgrade available</td>
                            <td class="pb-2">Free upgrade available</td>
                            <td class="pb-2">Free upgrade available</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- MOBILE VIEW -->

    <div *ngIf="isNarrow" id="cms-emli-table" class="fx-wrap fx-row-h-center">
        <div class="dialog-top-row">
            <mat-icon class="dialog-close" (click)="closeFunction()" [mat-dialog-close]="">close</mat-icon>
        </div>
        <div class="text-center">
            <table>
                <tbody>
                    <tr>
                        <td width="33.33%"></td>
                        <td width="33.33%"></td>
                        <td width="33.33%"></td>
                    </tr>
                </tbody>
                <tbody class="emli-table">
                    <tr>
                        <td class="pt-2">
                            <img
                                src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/Conda.svg"
                                alt="Conda EMLI"
                                width="90"
                                class="py-1"
                            />
                            <h4>Conda EMLI</h4>
                            <small>Separated Frameworks</small>
                        </td>
                        <td class="pt-2">
                            <img
                                src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/Container.svg"
                                alt="Container EMLI"
                                width="90"
                                class="py-1"
                            />
                            <h4>Container EMLI</h4>
                            <small>Flexible. Reconfigurable.</small>
                        </td>
                        <td class="pt-2">
                            <img
                                src="https://assets.exxactcorp.com/img/exx/cms/solutions/dl-and-ai/images/DIY.svg"
                                alt="DIY EMLI"
                                width="90"
                                class="py-1"
                            />
                            <h4>DIY EMLI</h4>
                            <small>Simple. Clean. Custom.</small>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>
                                For developers who want pre-installed deep learning frameworks and their dependencies in separate Python environments
                                installed natively on the system.
                            </p>
                        </td>
                        <td>
                            <p>
                                For developers who want pre-installed frameworks utilizing the latest NGC containers, GPU drivers, and libraries in
                                ready to deploy DL environments with the flexibility of containerization.
                            </p>
                        </td>
                        <td>
                            <p>
                                For experienced developers who want a minimalist install to set up their own private deep learning repositories or
                                custom builds of deep learning frameworks.
                            </p>
                        </td>
                    </tr>
                </tbody>
                <tbody id="emli-table-listing" class="emli-table">
                    <tr class="emli-table-border-top">
                        <td><h4>Frameworks*</h4></td>
                        <td><h4>Frameworks*</h4></td>
                        <td><h4>Frameworks*</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>TensorFlow V1</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>TensorFlow V1</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>TensorFlow V1</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>TensorFlow V2</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>TensorFlow V2</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>TensorFlow V2</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>PyTorch</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>PyTorch</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>PyTorch</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>MXNet</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>MXNet</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>MXNet</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Caffe</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Caffe</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Caffe</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Caffe2</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Caffe2</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Caffe2</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Chainer</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Chainer</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Chainer</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-3">
                            <div>
                                <span class="text-left">Microsoft Cognitive Toolkit</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span class="text-left">Microsoft Cognitive Toolkit</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span class="text-left">Microsoft Cognitive Toolkit</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr class="emli-table-border-top">
                        <td><h4>Data Annotation*</h4></td>
                        <td><h4>Data Annotation*</h4></td>
                        <td><h4>Data Annotation*</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Label Studio</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Label Studio</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Label Studio</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr class="emli-table-border-top">
                        <td><h4>Libraries*</h4></td>
                        <td><h4>Libraries*</h4></td>
                        <td><h4>Libraries*</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>NVIDIA cuDNN</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>NVIDIA cuDNN</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>NVIDIA cuDNN</span>
                                <span class="spec-best"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>NVIDIA Rapids</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>NVIDIA Rapids</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>NVIDIA Rapids</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Keras</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Keras</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Keras</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Theano</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Theano</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Theano</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-3">
                            <div>
                                <span>OpenCV</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>OpenCV</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>OpenCV</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr class="emli-table-border-top">
                        <td><h4>Software Environments</h4></td>
                        <td><h4>Software Environments</h4></td>
                        <td><h4>Software Environments</h4></td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>NVIDIA CUDA Toolkit</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>NVIDIA CUDA Toolkit</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>NVIDIA CUDA Toolkit</span>
                                <span class="spec-best"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span class="text-left">NVIDIA CUDA Dev Toolkit</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="text-left">NVIDIA CUDA Dev Toolkit</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="text-left">NVIDIA CUDA Dev Toolkit</span>
                                <span class="spec-best"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span>Nvidia Digits</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Nvidia Digits</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span>Nvidia Digits</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-3">
                            <div>
                                <span>Anaconda</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>Anaconda</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>Anaconda</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                    </tr>
                    <tr class="emli-table-border-top">
                        <td><h4>Container Management</h4></td>
                        <td><h4>Container Management</h4></td>
                        <td><h4>Container Management</h4></td>
                    </tr>
                    <tr>
                        <td class="pb-3">
                            <div>
                                <span>Docker</span>
                                <span><hr class="spec-line" /></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>Docker</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>Docker</span>
                                <span class="spec-best"></span>
                            </div>
                        </td>
                    </tr>
                    <tr class="emli-table-border-top">
                        <td><h4>Drivers</h4></td>
                        <td><h4>Drivers</h4></td>
                        <td><h4>Drivers</h4></td>
                    </tr>
                    <tr>
                        <td class="pb-3">
                            <div>
                                <span>NVIDIA-qualified Driver</span>
                                <span class="spec-good"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>NVIDIA-qualified Driver</span>
                                <span class="spec-better"></span>
                            </div>
                        </td>
                        <td class="pb-3">
                            <div>
                                <span>NVIDIA-qualified Driver</span>
                                <span class="spec-best"></span>
                            </div>
                        </td>
                    </tr>
                    <tr class="emli-table-border-top">
                        <td class="text-left"><h4>Orchestration</h4></td>
                        <td class="text-left"><h4>Orchestration</h4></td>
                        <td class="text-left"><h4>Orchestration</h4></td>
                    </tr>

                    <tr>
                        <td class="padding-left-align">Micro-K8s</td>
                        <td class="padding-left-align">Micro-K8s</td>
                        <td class="padding-left-align">Micro-K8s</td>
                    </tr>
                    <tr>
                        <td class="padding-left-align pb-3">Free upgrade available</td>
                        <td class="padding-left-align pb-3">Free upgrade available</td>
                        <td class="padding-left-align pb-3">Free upgrade available</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
