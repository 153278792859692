<section *ngIf="get(valuePropsRow, 'value_props', false)" [class.gray-bg]="get(valuePropsRow, 'value_props.gray_background')">
    <div class="container grid partial-grid">
        <div
            *ngFor="let props of get(valuePropsRow, 'value_props.value_prop_set', [])"
            class="content g-col-sm-4 g-col-12 d-flex flex-column align-items-center"
        >
            <img [src]="getWebpImg(get(props, 'value_prop[0].icon.href', ''))" alt="value propositon" />
            <h4 class="old text-center">{{ get(props, 'value_prop[0].value_prop_title', '') }}</h4>
            <p>{{ get(props, 'value_prop[0].value_prop_description', '') }}</p>
        </div>
    </div>
</section>
<hr *ngIf="get(valuePropsRow, 'value_props.split_line', '')" class="m-0 mb-3" />
