<div id="header-search">
    <div
        id="header-search-input-container-wide"
        data-cy="exx-header-searchbar-desktop"
        *ngIf="!isNarrow"
        #searchInputContainer
    >
        <input
            class="header-search-input"
            #searchInput
            placeholder="Search"
            [class.mat-elevation-z2]="searchInputHasFocus"
            (focus)="onFocus()"
            (keyup)="onKeyup($event)"
            (keydown.enter)="onKeydownEnter()"
        />
        <mat-icon
            class="search"
            (click)="onClickSearch()"
            svgIcon="nav-search"
        ></mat-icon>
        <div
            *ngIf="isActive && isNarrow"
            id="header-search-narrow-close"
            #searchClose
            (click)="setIsActive()"
        >
            <div></div>
            <div></div>
        </div>
    </div>

    <div
        *ngIf="isNarrow"
        id="header-search-input-container-narrow"
        data-cy="exx-header-searchbar-mobile"
        #searchInputContainer
        class="fx-row fx-row-v-center fx-row-h-right"
    >
        <mat-icon svgIcon="nav-search" (click)="searchClicked()"></mat-icon>
        <input
            class="header-search-input"
            #searchInput
            id="search-icon"
            (focus)="onFocus()"
            (keyup)="onKeyup($event)"
            (keydown.enter)="onKeydownEnter()"
        />
    </div>

    <div
        id="header-search-menu"
        *ngIf="isActive || (isNarrow && searchInputHasFocus)"
        #searchMenu
        [class.initial-bg]="initialSearch"
    >
        <div
            *ngIf="
                !initialSearch && isSearching && suggested.products.length == 0
            "
        >
            <h5 class="header-search-menu-title">Searching...</h5>
        </div>

        <div
            *ngIf="
                !initialSearch && !isSearching && suggested.products.length == 0
            "
        >
            <h5 class="header-search-menu-title" #noResultsDisplay>
                No results match your search query.
            </h5>
        </div>

        <div
            *ngIf="suggested.terms.length > 0"
            id="header-search-menu-terms"
            #searchMenuTerms
        >
            <div id="header-search-menu-terms-links">
                <div
                    *ngFor="let term of suggested.terms"
                    class="term truncate-ellipsis"
                >
                    <a
                        routerLink="/category/search"
                        [queryParams]="{ q: term?.queryParams?.q, page: 1 }"
                        [innerHTML]="term.text"
                        (click)="setIsActive()"
                    ></a>
                </div>
            </div>
        </div>

        <div
            *ngIf="suggested.products.length > 0"
            id="header-search-menu-products"
        >
            <div
                *ngFor="let product of suggested.products"
                class="header-search-menu-products-links"
            >
                <a
                    class="fx-col fx-col-h-left fx-col-v-center fx-wrap"
                    [routerLink]="product.urlComponent"
                    (click)="setIsActive()"
                >
                    <div class="fx-row fx-row-h-left fx-row-v-center">
                        <div class="header-search-menu-product-image">
                            <img
                                *ngIf="product?.imageUrl"
                                [src]="getWebpImg(product.imageUrl)"
                                [alt]="product.title"
                            />
                        </div>
                        <div class="fx-col">
                            <span
                                class="truncate-ellipsis pl-3"
                                [innerHTML]="product.name"
                            ></span>
                            <span class="pl-3 pt-2 mpn-display"
                                >MPN: {{ product?.mpn }}</span
                            >
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div
            *ngIf="suggested.products.length > 0"
            id="header-search-menu-see-more-results"
            #searchSeeMore
        >
            <a
                id="header-search-menu-see-more-results-link"
                class="fx-row fx-row-v-center fx-row-h-space-between"
                (click)="setIsActive()"
            >
                <div (click)="onSearchClickResults()">
                    <span class="bold pr-1">View All</span>
                    <span class="total-results"
                        >({{ suggested.total }} items)</span
                    >
                </div>
                <mat-icon>chevron_right</mat-icon>
            </a>
        </div>
    </div>
</div>
