<div id="cms-partners">
  <div id="hero-banner">
    <div class="hero-banner-text fx-col fx-col-container fx-col-v-center">
      <div class="col-md-5">
        <h5>{{ entry?.hero_banner_label }}</h5>
        <h1>{{ entry?.hero_banner_title }}</h1>
        <div class="copy-lg">{{ entry?.hero_banner_copy }}</div>
      </div>
    </div>
  </div>

  <div id="row" *ngFor="let row of entry?.rows" [class.gray-background]="row.gray_background">
    <div id="row-top" class="mb-3">
      <div class="row-top-left text-right col-md-6">
        <img [src]="getWebpImg(row.image?.href)" [alt]="row.image?.title" />
      </div>
      <div class="row-top-right px-3 fx-col fx-col-v-center">
        <h2>{{ row.title }}</h2>
        <p>{{ row.copy }}</p>
        <div
          class="button-primary mt-2"
          (click)="bootstrapMarketoService.openBootstrapMarketoDialog(row.marketo_form?.id, 'display')"
        >
          Partner with us
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
    </div>
    <div id="row-bottom">
      <div *ngFor="let service of row.services_des" class="col-md-3 mb-3">
        <h4>{{ service.title }}</h4>
        <p>{{ service.copy }}</p>
      </div>
    </div>
  </div>
</div>
