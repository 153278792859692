import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GtmService } from 'lib/services/google/gtm.service';
import { MarketoService } from 'lib/services/marketo.service';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from 'lib/services/meta.service';
import { ProductComponentClass } from 'lib/components/product/product-component.class';
import { ProductSpecDialogComponent } from 'lib/components/product-spec-dialog/product-spec-dialog.component';
import { RouterService } from 'lib/services/router.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { ActivatedRoute } from '@angular/router';
const scriptName = 'product.component';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductComponent extends ProductComponentClass implements OnInit {
    // Properties
    adblockBackgroundURL: string = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/feature-backlink-bg.png';
    private xsBreakpointObserver: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

    slidesPerView: number = 2.5;
    spaceBetween: number = 10;
    breakpoints: object = {
        500: {
            slidesPerView: 2.5,
            spaceBetween: 25,
        },
        600: {
            slidesPerView: 3,
            spaceBetween: 25,
        },
        710: {
            slidesPerView: 3.5,
            spaceBetween: 25,
        },
        800: {
            slidesPerView: 4,
            spaceBetween: 25,
        },
        875: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
        1100: {
            slidesPerView: 5,
            spaceBetween: 40,
        },
    };

    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        cmsService: CmsService,
        gtmService: GtmService,
        marketoService: MarketoService,
        dialog: MatDialog,
        metaService: MetaService,
        productService: WebstoreProductService,
        routerService: RouterService,
        activatedRoute: ActivatedRoute
    ) {
        super({
            dependencies: {
                breakpointObserver,
                cmsService,
                dialog,
                environment,
                gtmService,
                marketoService,
                metaService,
                productService,
                routerService,
                activatedRoute,
            },
        });
        this.xsBreakpointObserver.subscribe((size) => {
            if (size.matches) {
                this.adblockBackgroundURL = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/feature-backlink-bg-mobile.png';
            } else {
                this.adblockBackgroundURL = 'https://assets.exxactcorp.com/img/exx/cms/ad-blocks/feature-backlink-bg.png';
            }
        });
    }

    ngOnInit() {
        try {
            this.init();
        } catch (err) {
            console.error(...new ExxComError(620988, scriptName, err).stamp());
        }
    }

    openProductSpecDialog(products?: any, options?: any) {
        try {
            const productsSpecs = new Set(['Product']);
            const productsValues = [];
            const productsLinks = [];
            if (products) {
                products.map((product: any) => {
                    const productValue = [];
                    productValue.push(product.title);
                    product.specs_group.map((specs) => {
                        productsSpecs.add(specs.spec);
                        productValue.push(specs.value);
                    });
                    productsValues.push(productValue);
                    productsLinks.push(product.product_link && product.product_link.routerLink);
                });
            }
            const productDialogRef = this.dialog.open(ProductSpecDialogComponent, {
                data: options
                    ? { options: options }
                    : {
                          specs: productsSpecs,
                          values: productsValues,
                          links: productsLinks,
                      },
                maxWidth: '1000px',
                panelClass: 'dialog-mob-only-product-spec',
            });
            const smallDialogSubscription = this.xsBreakpointObserver.subscribe((size) => {
                if (size.matches) {
                    productDialogRef.updateSize('100vw', '100vh');
                } else {
                    productDialogRef.updateSize('auto', 'auto');
                }
            });
            productDialogRef.afterClosed().subscribe(() => smallDialogSubscription.unsubscribe());
        } catch (err) {
            console.error(...new ExxComError(636442, scriptName, err).stamp());
        }
    }
}
