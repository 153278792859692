<ng-container *ngIf="buttonOptions.internalLink || buttonOptions.externalLink; else plainButton">
    <a *ngIf="buttonOptions.externalLink; else internalLink" [href]="buttonOptions.externalLink" class="w-100">
        <button
            [class]="buttonOptions.class + ' button'"
            [class.primary]="buttonOptions.type === 'primary'"
            [class.secondary]="buttonOptions.type === 'secondary'"
            [class.tertiary]="buttonOptions.type === 'tertiary'"
            [class.facet]="buttonOptions.type === 'facet'"
            [class.graph-swap]="buttonOptions.type === 'graph-swap'"
            [class.tiny-primary]="buttonOptions.type === 'tiny-primary'"
            [class.tiny-use-case]="buttonOptions.type === 'tiny-use-case'"
            [class.tag]="buttonOptions.type === 'tag'"
            [class.no-min-width]="buttonOptions.unsetWidth"
            [disabled]="buttonOptions.disabled"
        >
            <ng-container *ngIf="buttonOptions.iconPosition == 'left'">
                <div
                    class="icon-left"
                    [ngClass]="{
                        'icon-size-small': buttonOptions.iconSize === 'small',
                        'icon-size-medium': buttonOptions.iconSize === 'medium',
                        'icon-size-large': buttonOptions.iconSize === 'large'
                    }"
                >
                    <i-feather
                        class="button-icon"
                        [name]="buttonOptions?.iconName"
                        [ngClass]="{
                            'icon-size-small': buttonOptions.iconSize === 'small',
                            'icon-size-medium': buttonOptions.iconSize === 'medium',
                            'icon-size-large': buttonOptions.iconSize === 'large',
                            'stroke-width-small': buttonOptions.iconStrokeWidth === 'small',
                            'stroke-width-medium': buttonOptions.iconStrokeWidth === 'medium',
                            'stroke-width-large': buttonOptions.iconStrokeWidth === 'large'
                        }"
                    ></i-feather>
                </div>
            </ng-container>
            <div class="button-text">{{ buttonOptions.text }}</div>
            <ng-container *ngIf="buttonOptions.iconPosition == 'right'">
                <div
                    class="icon-right"
                    [ngClass]="{
                        'icon-size-small': buttonOptions.iconSize === 'small',
                        'icon-size-medium': buttonOptions.iconSize === 'medium',
                        'icon-size-large': buttonOptions.iconSize === 'large'
                    }"
                >
                    <i-feather
                        class="button-icon"
                        [name]="buttonOptions?.iconName"
                        [ngClass]="{
                            'icon-size-small': buttonOptions.iconSize === 'small',
                            'icon-size-medium': buttonOptions.iconSize === 'medium',
                            'icon-size-large': buttonOptions.iconSize === 'large',
                            'stroke-width-small': buttonOptions.iconStrokeWidth === 'small',
                            'stroke-width-medium': buttonOptions.iconStrokeWidth === 'medium',
                            'stroke-width-large': buttonOptions.iconStrokeWidth === 'large'
                        }"
                    ></i-feather>
                </div>
            </ng-container>
        </button>
    </a>
    <ng-template #internalLink>
        <a [routerLink]="buttonOptions.internalLink" class="w-100">
            <ng-container *ngTemplateOutlet="plainButton"></ng-container>
        </a>
    </ng-template>
</ng-container>

<ng-template #plainButton>
    <button
        [class]="buttonOptions.class + ' button'"
        [class.primary]="buttonOptions.type === 'primary'"
        [class.secondary]="buttonOptions.type === 'secondary'"
        [class.tertiary]="buttonOptions.type === 'tertiary'"
        [class.facet]="buttonOptions.type === 'facet'"
        [class.graph-swap]="buttonOptions.type === 'graph-swap'"
        [class.tiny-primary]="buttonOptions.type === 'tiny-primary'"
        [class.tiny-use-case]="buttonOptions.type === 'tiny-use-case'"
        [class.tag]="buttonOptions.type === 'tag'"
        [class.no-min-width]="buttonOptions.unsetWidth"
        [disabled]="buttonOptions.disabled"
    >
        <ng-container *ngIf="buttonOptions.iconPosition == 'left'">
            <div
                class="icon-left"
                [ngClass]="{
                    'icon-size-small': buttonOptions.iconSize === 'small',
                    'icon-size-medium': buttonOptions.iconSize === 'medium',
                    'icon-size-large': buttonOptions.iconSize === 'large'
                }"
            >
                <i-feather
                    class="button-icon"
                    [name]="buttonOptions?.iconName"
                    [ngClass]="{
                        'icon-size-small': buttonOptions.iconSize === 'small',
                        'icon-size-medium': buttonOptions.iconSize === 'medium',
                        'icon-size-large': buttonOptions.iconSize === 'large',
                        'stroke-width-small': buttonOptions.iconStrokeWidth === 'small',
                        'stroke-width-medium': buttonOptions.iconStrokeWidth === 'medium',
                        'stroke-width-large': buttonOptions.iconStrokeWidth === 'large'
                    }"
                ></i-feather>
            </div>
        </ng-container>
        <div class="button-text">{{ buttonOptions.text }}</div>
        <ng-container *ngIf="buttonOptions.iconPosition == 'right'">
            <div
                class="icon-right"
                [ngClass]="{
                    'icon-size-small': buttonOptions.iconSize === 'small',
                    'icon-size-medium': buttonOptions.iconSize === 'medium',
                    'icon-size-large': buttonOptions.iconSize === 'large'
                }"
            >
                <i-feather
                    class="button-icon"
                    [name]="buttonOptions?.iconName"
                    [ngClass]="{
                        'icon-size-small': buttonOptions.iconSize === 'small',
                        'icon-size-medium': buttonOptions.iconSize === 'medium',
                        'icon-size-large': buttonOptions.iconSize === 'large',
                        'stroke-width-small': buttonOptions.iconStrokeWidth === 'small',
                        'stroke-width-medium': buttonOptions.iconStrokeWidth === 'medium',
                        'stroke-width-large': buttonOptions.iconStrokeWidth === 'large'
                    }"
                ></i-feather>
            </div>
        </ng-container>
    </button>
</ng-template>
