/* eslint-disable camelcase */
import { SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExxComError } from 'lib/classes/exxcom-error.class';

import { ConfigurationDataV2, OptimizedConfigurationDataV2, ConfigurationSiteData, ConfigurationChanges } from './configuration-v2.interface';

const scriptName = 'configuration-v2.class';

export class ConfigurationV2 {
    _id?: string;
    isActive?: boolean;
    sierraActive?: boolean;
    referenceId?: string | ConfigurationDataV2;
    syncWithReference?: boolean;
    site: 'exx' | 'spc';

    mpn?: string;
    name?: string;
    imgUrl?: string;
    markupPercent?: number;
    markupAmount?: number;
    notes?: string;

    maxGPUSlots?: number;
    slotWidth?: number;

    minTotalMemory?: number;

    categories?: [
        {
            category?: string;
            isRequired?: boolean;
            isMultiple?: boolean;
            minQuantity?: number;
            maxQuantity?: number;
            setsOf?: number;
            productLines?: string[];
            products?: string[] | any[];
            note?: string;
            sharedSlots?: boolean;
        },
    ];

    products?: string[] | any[];

    optimizedConfigurations?: {
        good?: OptimizedConfigurationDataV2;
        better?: OptimizedConfigurationDataV2;
        best?: OptimizedConfigurationDataV2;
    };

    changes?: ConfigurationChanges[];
    createdAt?: string | Date;

    changed$?: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: ConfigurationDataV2) {
        if (data) {
            this.init(data);
        }
    }

    init(data: ConfigurationDataV2) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.isActive = data.isActive || this.isActive || null;
            this.sierraActive = data.sierraActive || this.sierraActive || null;
            this.referenceId = data.referenceId || this.referenceId || null;
            this.syncWithReference = data.syncWithReference || this.syncWithReference || false;
            this.site = data.site || this.site || null;

            this.mpn = data.mpn || this.mpn || null;
            this.name = data.name || this.name || null;
            this.imgUrl = data.imgUrl || this.imgUrl || null;
            this.markupPercent = data.markupPercent || this.markupPercent || null;
            this.markupAmount = data.markupAmount || this.markupAmount || null;

            this.notes = data.notes || this.notes || '';

            this.maxGPUSlots = data.maxGPUSlots || this.maxGPUSlots || null;
            this.slotWidth = data.slotWidth || this.slotWidth || null;

            this.minTotalMemory = data.minTotalMemory || this.minTotalMemory || null;

            this.categories = data.categories || this.categories || [{}];
            this.products = data.products || this.products || [];
            this.optimizedConfigurations = data.optimizedConfigurations ||
                this.optimizedConfigurations || {
                    good: {},
                    better: {},
                    best: {},
                };

            this.changes = data.changes || this.changes || null;
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(420477, scriptName, err).stamp());
        }
    }
}
