import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedWebstoreModule } from 'lib/modules/shared-webstore.module';
import { AppComponent } from './app.component';
import { CategoryComponent } from './category/category.component';
import { CategoryNoResultsComponent } from './category/category-no-results/category-no-results.component';
import { CategoryPaginationComponent } from './category/category-pagination/category-pagination.component';
import { CategoryQuickViewDialogComponent } from 'lib/components/category/category-quick-view-dialog/category-quick-view-dialog.component';
import { CmsAboutUsComponent } from './cms/pages/cms-about-us/cms-about-us.component';
import { CmsAccountApplicationComponent } from './cms/pages/cms-account-application/cms-account-application.component';
import { CmsB2bServiceComponent } from './cms/pages/cms-b2b-service/cms-b2b-service.component';
import { CmsBlogCategoryComponent } from './cms/blog//cms-blog-category/cms-blog-category.component';
import { CmsBlogHeaderSearchComponent } from './cms/blog/cms-blog-header-search/cms-blog-header-search.component';
import { CmsBlogHomepageComponent } from './cms/blog/cms-blog-homepage/cms-blog-homepage.component';
import { CmsBlogPostComponent } from './cms/blog/cms-blog-post/cms-blog-post.component';
import { CmsBlogSearchComponent } from './cms/blog/cms-blog-search/cms-blog-search.component';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { ConfiguratorDialogComponent } from './configurator/configurator-dialog/configurator-dialog.component';
import { CmsCareersComponent } from './cms/pages/cms-careers/cms-careers.component';
import { CmsContactUsComponent } from './cms/pages/cms-contact-us/cms-contact-us.component';
import { CmsGatedContentComponent } from './cms/pages/cms-gated-content/cms-gated-content.component';
import { CmsGatedContentPostComponent } from './cms/pages/cms-gated-content/cms-gated-content-post/cms-gated-content-post.component';
import { CmsHomepageComponent } from './cms/pages/cms-homepage/cms-homepage.component';
import { CmsImgComponent } from 'lib/components/cms/cms-img/cms-img.component';
import { CmsNewsEventsComponent } from './cms/pages/cms-news-events/cms-news-events.component';
import { CmsNewsEventsPostComponent } from './cms/pages/cms-news-events/cms-news-events-post/cms-news-events-post.component';
import { CmsPartialCategoryTopComponent } from './cms/partials/cms-partial-category-top/cms-partial-category-top.component';
import { CmsPartnersComponent } from './cms/pages/cms-partners/cms-partners.component';
import { CmsPoliciesComponent } from './cms/pages/cms-policies/cms-policies.component';
import { CmsResourceCenterComponent } from './cms/pages/cms-resource-center/cms-resource-center.component';
import { CmsRmaRequestComponent } from './cms/pages/cms-rma-request/cms-rma-request.component';
import { CmsSoftwareComponent } from './cms/pages/cms-software/cms-software.component';
import { CmsSupportedSoftwareComponent } from './cms/pages/cms-supported-software/cms-supported-software.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderContactbarComponent } from './header/header-contactbar/header-contactbar.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { MaterialModule } from 'lib/modules/material.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProductComponent } from './product/product.component';
import { ProductListingComponent } from './product/product-listing/product-listing.component';
import { QuotesComponent } from './cms/partials/quotes/quotes.component';
import { ResourceCardRelatedCardComponent } from './cms/partials/related-content-tile/resource-card-related-card.component';
import { ServicesInitializer } from './app.initializers';
import { appPageTitles } from './app-page-titles';
import { environment } from '../environments/environment';
import { EmliTableComponent } from './cms/partials/emli-table/emli-table.component';
import { EmliTableModalComponent } from './cms/partials/emli-table-modal/emli-table-modal.component';
import { EmliTableInnerContentComponent } from './cms/partials/emli-table-inner-content/emli-table-inner-content.component';
import { CmsTestDriveComponent } from './cms/pages/cms-test-drive/cms-test-drive.component';
import { CmsCustomersComponent } from './cms/pages/cms-customers/cms-customers.component';
import { CmsReferenceArchitectureComponent } from './cms/pages/cms-reference-architecture/cms-reference-architecture.component';
import { CmsRefArchitecturePagesComponent } from './cms/pages/cms-reference-architecture/cms-ref-architecture-pages/cms-ref-architecture-pages.component';
import { CmsBlogSearchAndDropdownComponent } from './cms/blog/cms-blog-search-and-dropdown/cms-blog-search-and-dropdown.component';
import { ColoredBlockComponent } from './cms/partials/cms-partial-category-top/components/colored-block/colored-block.component';
import { CategoryTopBannerComponent } from './cms/partials/cms-partial-category-top/components/category-top-banner/category-top-banner.component';
import { LeadGenComponent } from './cms/partials/cms-partial-category-top/components/lead-gen/lead-gen.component';
import { ValuePropsComponent } from './cms/partials/cms-partial-category-top/components/value-props/value-props.component';
import { TableComponent } from './cms/partials/cms-partial-category-top/components/table/table.component';
import { GatedContentHeaderComponent } from './cms/pages/cms-gated-content/components/gated-content-header/gated-content-header.component';
import { LogosComponent } from './cms/partials/cms-partial-category-top/components/logos/logos.component';
import { SubcategoryLinksComponent } from './cms/partials/cms-partial-category-top/components/subcategory-links/subcategory-links.component';
import { MultipleColumnsComponent } from './cms/partials/cms-partial-category-top/components/multiple-columns/multiple-columns.component';
import { MultipleColumnsComponentV2 } from './cms/partials/cms-partial-category-top/components/multiple-columns-v2/multiple-columns-v2.component';
import { FeatureSolutionsComponent } from './cms/partials/cms-partial-category-top/components/feature-solutions/feature-solutions.component';
import { FullWidthImageAndTextComponent } from './cms/partials/cms-partial-category-top/components/full-width-image-and-text/full-width-image-and-text.component';
import { ProductOptionsComponent } from './cms/partials/cms-partial-category-top/components/product-options/product-options.component';
import { ProductOptions2Component } from './cms/partials/cms-partial-category-top/components/product-options-2/product-options-2.component';
import { InternalLinksComponent } from './cms/partials/cms-partial-category-top/components/internal-links/internal-links.component';
import { RelatedContentComponent } from './cms/partials/cms-partial-category-top/components/related-content/related-content.component';
import { ImageAndTextComponent } from './cms/partials/cms-partial-category-top/components/image-and-text/image-and-text.component';
import { FacetsComponent } from './cms/partials/cms-partial-category-top/components/facets/facets.component';

import { SpecialModule } from 'lib/modules/special.module';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BootstrapCategoryQuickViewDialogComponent } from 'lib/components/category/bootstrap-category-quick-view-dialog/bootstrap-category-quick-view-dialog.component';
import { ExxFeaturedCardComponent } from './components/featured-card/exx-featured-card.component';
import { CmsEventFormPageComponent } from './cms/pages/cms-event-form-page/cms-event-form-page.component';
import { NvidiaDgxComponent } from './product/nvidia-dgx/nvidia-dgx.component';
import { ExxGatedContentHeaderComponent } from './components/exx-gated-content-header/exx-gated-content-header.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { StepByStepComponent } from './cms/partials/cms-partial-category-top/components/step-by-step/step-by-step.component';
import { readTimePipe } from 'lib/pipes/readTime.pipe';
import { readTimePipeBlog } from 'lib/pipes/readTimeBlog.pipe';
import { CmsRepositoryComponent } from './cms/pages/cms-repository/cms-repository.component';
import { RepositoryBannerComponent } from './components/banner/repository-banner.component';
import { RepositoryCardComponent } from './components/repository-card/repository-card.component';
import { ResourceCenterHeaderComponent } from './cms/blog/resource-center-header/resource-center-header.component';
import { ResourceCenterDropdownComponent } from './cms/blog/resource-center-dropdown/resource-center-dropdown.component';
import { HomeButtonComponent } from './components/home-button/home-button.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExxAccordionImageAndTextComponent } from './components/accordion-image-and-text/accordion-image-and-text.component';
import { AccordionComponent } from './cms/partials/cms-partial-category-top/components/accordion/accordion.component';
import { ExxLoadingIconComponent } from './components/loading-icon/loading-icon.component';
import { ProductPageImageTileComponent } from './components/product-page-image-tile/product-page-image-tile.component';
import { DragToScrollDirective } from './directives/scroll.directive';
import { TrimPipe } from 'lib/pipes/trim.pipe';
import { ExxUseCasesComponent } from './components/use-cases/use-cases.component';
import { ProductTileRowsComponent } from './components/product-tile-rows/product-tile-rows.component';
import { ConfigurationsGridComponent } from './components/configurations-grid/configurations-grid.component';
import { ProductListingSolutionComponent } from 'clients/spc/src/app/product/product-listing-solution/product-listing-solution.component';
import { ProductPageImageTileComponentSPC } from 'lib/components/product-page-image-tile-spc/product-page-image-tile-spc.component';
import { ButtonComponent } from './components/button/button.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';

@NgModule({
    declarations: [
        AppComponent,
        CategoryComponent,
        CategoryNoResultsComponent,
        CategoryPaginationComponent,
        CategoryQuickViewDialogComponent,
        CmsAboutUsComponent,
        CmsAccountApplicationComponent,
        CmsB2bServiceComponent,
        CmsBlogCategoryComponent,
        CmsBlogHeaderSearchComponent,
        CmsBlogHomepageComponent,
        CmsBlogPostComponent,
        CmsBlogSearchComponent,
        CmsCareersComponent,
        CmsContactUsComponent,
        CmsGatedContentComponent,
        CmsGatedContentPostComponent,
        CmsHomepageComponent,
        CmsImgComponent,
        CmsNewsEventsComponent,
        CmsNewsEventsPostComponent,
        CmsPartialCategoryTopComponent,
        CmsPartnersComponent,
        CmsPoliciesComponent,
        CmsResourceCenterComponent,
        CmsRmaRequestComponent,
        CmsSoftwareComponent,
        CmsSupportedSoftwareComponent,
        ConfiguratorComponent,
        ConfiguratorDialogComponent,
        FooterComponent,
        HeaderComponent,
        HeaderContactbarComponent,
        HeaderMenuComponent,
        HeaderSearchComponent,
        NotFoundComponent,
        ProductComponent,
        ProductListingComponent,
        EmliTableComponent,
        CmsTestDriveComponent,
        QuotesComponent,
        ResourceCardRelatedCardComponent,
        CmsCustomersComponent,
        CmsReferenceArchitectureComponent,
        CmsRefArchitecturePagesComponent,
        CmsBlogSearchAndDropdownComponent,
        EmliTableModalComponent,
        EmliTableInnerContentComponent,
        ColoredBlockComponent,
        CategoryTopBannerComponent,
        LeadGenComponent,
        ValuePropsComponent,
        TableComponent,
        GatedContentHeaderComponent,
        LogosComponent,
        SubcategoryLinksComponent,
        MultipleColumnsComponent,
        MultipleColumnsComponentV2,
        FeatureSolutionsComponent,
        FullWidthImageAndTextComponent,
        ProductOptionsComponent,
        ProductOptions2Component,
        InternalLinksComponent,
        RelatedContentComponent,
        ImageAndTextComponent,
        FacetsComponent,
        BootstrapCategoryQuickViewDialogComponent,
        ExxFeaturedCardComponent,
        CmsEventFormPageComponent,
        NvidiaDgxComponent,
        ExxGatedContentHeaderComponent,
        DropdownComponent,
        StepByStepComponent,
        readTimePipe,
        readTimePipeBlog,
        CmsRepositoryComponent,
        RepositoryBannerComponent,
        RepositoryCardComponent,
        ResourceCenterDropdownComponent,
        ResourceCenterHeaderComponent,
        HomeButtonComponent,
        ExxAccordionImageAndTextComponent,
        AccordionComponent,
        ExxLoadingIconComponent,
        ProductPageImageTileComponent,
        ProductPageImageTileComponentSPC,
        DragToScrollDirective,
        TrimPipe,
        ExxUseCasesComponent,
        ProductTileRowsComponent,
        ConfigurationsGridComponent,
        ProductListingSolutionComponent,
        ButtonComponent,
        CookiesComponent,
        PrivacyComponent,
        ImageZoomComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        SpecialModule.forRoot(),
        NgxPaginationModule,
        SharedWebstoreModule.forRoot(environment, appPageTitles),
    ],
    providers: [ServicesInitializer, BsModalRef],
    bootstrap: [AppComponent],
})
export class AppModule {}
