export const appIcons = [
    'account',
    'alert-circle',
    'alert-triangle',
    'book',
    'box',
    'calendar',
    'cart',
    'check',
    'chevron_down',
    'chevron_right',
    'clipboard',
    'clock',
    'close',
    'download',
    'envelope',
    'facebook',
    'feather',
    'file',
    'filter',
    'filter-hopper',
    'fire',
    'green-check',
    'grid',
    'grid-9',
    'info',
    'left-chevron-blog',
    'left-pagination-button',
    'lightning',
    'lightning-thick',
    'linkedin',
    'list',
    'mail',
    'menu',
    'message-square',
    'minus',
    'nav-search',
    'pencil',
    'plus',
    'price-tag',
    'ram',
    'ram-thick',
    'right-chevron-blog',
    'right-pagination-button',
    'share-2',
    'settings',
    'shopping-bag',
    'sliders',
    'star',
    'tag',
    'tool',
    'transfer-arrows',
    'trash',
    'triangle',
    'truck',
    'twitter',
    'user',
    'value-prop',
    'x',
    'x-logo',
];
