import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AccountService } from 'lib/services/account/account.service';
import { CartService } from 'lib/services/cart/cart.service';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GtmService } from 'lib/services/google/gtm.service';
import { MetaService } from 'lib/services/meta.service';
import { ProductListingComponentClass } from 'lib/components/product/product-listing-component.class';
import { SessionService } from 'lib/services/session.service';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { calculateReadingTime } from 'lib/tools';
import { isBrowser } from 'lib/tools';
import { WebpService } from 'lib/services/webp.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { cmsMarketoIDsService } from 'lib/services/cmsMarketoIDs.service';

const scriptName = 'product-listing.component';

@Component({
    selector: 'app-product-listing',
    templateUrl: './product-listing.component.html',
    styleUrls: ['./product-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductListingComponent extends ProductListingComponentClass implements OnInit {
    // Properties: Public
    @Input() product: WebstoreProduct;
    bsModalRef?: BsModalRef;
    bsModalService: BsModalService;
    selectedQuantity: number = 1;
    defaultSelectValue: number = 1;
    configuratorExists: boolean = false;
    startingPrice: number;
    configuratorUrl: string = '';
    initialSlide: number = 0;
    marketoIDLong: string;
    mutilatedHighlights: string[];
    calculateReadingTime: (entry: any) => string;
    webpAvailable: boolean = false;
    getWebpImg: (src: string) => void;
    isSolutionProduct: boolean;
    currentImageIndex: number = 0;
    amount = 0;
    translateAmount = 0;
    uniqueContainerClass: string;

    slidesPerView: number = 5;
    spaceBetween: number = 10;

    sierraConfigUrl = 'https://configurator.exxactcorp.com/configure/';

    constructor(
        @Inject('environment') environment: any,
        accountService: AccountService,
        breakpointObserver: BreakpointObserver,
        bsModalRef: BsModalRef,
        bsModalService: BsModalService,
        bootstrapMarketoService: BootstrapMarketoService,
        cartService: CartService,
        configuratorService: ConfiguratorService,
        public cmsMarketoIDsService: cmsMarketoIDsService,
        dialog: MatDialog,
        gtmService: GtmService,
        metaService: MetaService,
        productService: WebstoreProductService,
        sessionService: SessionService,
        webpService: WebpService
    ) {
        super({
            dependencies: {
                accountService,
                bootstrapMarketoService,
                breakpointObserver,
                bsModalService,
                bsModalRef,
                cartService,
                configuratorService,
                cmsMarketoIDsService,
                dialog,
                environment,
                gtmService,
                productService,
                sessionService,
                metaService,
            },
        });
        this.calculateReadingTime = calculateReadingTime;
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.init();
            this.uniqueContainerClass = `preview-swiper-${Math.random().toString(36).substring(2, 9)}`;
            let mpnSplit = this.product.mpn.split('-');
            mpnSplit = mpnSplit.slice(0, 2);
            const mpn = mpnSplit.join('-');
            this.setSolutionProduct();
            const config = await this.configuratorService.getConfigurationByUrlComponent('exx', mpn);
            if (config) {
                this.configuratorExists = config[0]?.isActive;
                const sierraActive = config[0]?.sierraActive || false;
                this.configuratorUrl = sierraActive ? this.sierraConfigUrl + this.product.mpn : this.product.mpn + '/configurator';
                const configPrice = config[0]?.optimizedConfigurations?.good?.priceTotal;
                if (configPrice > 0) {
                    this.startingPrice = configPrice;
                }
            }
            this.setFormIDs();
            this.mutilatedHighlights = this.manipulateHighlights(this.product?.highlights);
        } catch (err) {
            console.error(...new ExxComError(620034, scriptName, err).stamp());
        }
    }

    updateQuantity(quantity: number) {
        try {
            this.selectedQuantity = quantity;
        } catch (err) {
            console.error(...new ExxComError(620035, scriptName, err).stamp());
        }
    }

    setImageIndex(value: number) {
        this.currentImageIndex = value;
    }

    setSolutionProduct() {
        if (this.product) {
            this.isSolutionProduct = this.product?.manufacturer == 'Exxact';
        }
    }

    updateSlideIndex(index: number) {
        try {
            if (!isBrowser()) {
                return;
            }
            this.initialSlide = index;
        } catch (err) {
            console.error(...new ExxComError(854932, scriptName, err).stamp());
        }
    }
    /**
     * setFormIDs uses the getFormIDs function in the marketo service to return all in use marketo forms for product/solution
     * inquiries. We then call will use the solution or product inquiry depending on the manufacturer.
     * We deem our branded products as solutions, and thus will need to use that ID. Any other will be a normal product inquiry.
     */
    setFormIDs() {
        try {
            this.cmsMarketoIDsService.getFormIDs().then((res) => {
                const manufacturer = this.product.manufacturer.toLocaleLowerCase();
                if (manufacturer == 'exxact') {
                    this.marketoIDLong = `mktoForm_${res?.exx_solution_inquiry_id?.marketo_id}`;
                } else {
                    this.marketoIDLong = `mktoForm_${res?.exx_product_inquiry_id?.marketo_id}`;
                }
            });
        } catch (err) {
            console.error(...new ExxComError(992321, scriptName, err).stamp());
        }
    }
}
