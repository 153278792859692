import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-nvidia-dgx',
    templateUrl: './nvidia-dgx.component.html',
    styleUrls: ['./nvidia-dgx.component.scss'],
})
export class NvidiaDgxComponent implements OnInit {
    constructor(
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document
    ) {}

    ngOnInit() {
        const script = document.createElement('script');
        script.innerHTML = `
        ;(function (d, s, id, c, sId, aId) {
        var sdkUrl = "https://www.nvidia.partners/brandstore/common/d16daea03916cada8488"
        var js,
          njs = d.head
        js = d.createElement(s)
        js.id = id
        js.type = "text/javascript"
        js.src = sdkUrl
        njs.appendChild(js)
        js.onload = function () {
          try {
            nv.load(c, sId, aId)
          } catch (error) {}
        }
      })(document, "script", "nvidia-jssdk", "widget_container", "EXXC", "NDI1")
      `;
        document.head.appendChild(script);
    }
}
