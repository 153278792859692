import { OnInit, OnDestroy, Directive } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { CmsService } from 'lib/services/cms.service';
import { DisqusService } from 'lib/services/disqus.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isEmpty } from 'lodash';
import { isBrowser, getRelatedBlogPosts } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { calculateReadingTime } from 'lib/tools';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

const scriptName = 'cms-blog-post-component.class';

@Directive()
export class CmsBlogPostComponentClass extends CmsBlogComponentClass implements OnInit, OnDestroy {
    // Dependencies

    cmsService: CmsService;
    disqusService: DisqusService;
    routerService: RouterService;
    bsModalService: BsModalService;
    bsModalRef?: BsModalRef;

    // Properties: public

    allRelatedPosts: any = [];
    entry: any = {};
    date: string = '';
    end: number = 4;
    readingTime: string = '';
    postUrl: string = '';
    postLinkTitle: string = '';
    siteUrl: string = '';
    calculateReadingTime: (entry: any) => string;

    constructor({ dependencies }) {
        super({ dependencies });
        this.type = 'post';
        this.calculateReadingTime = calculateReadingTime;
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getBlogPost(`/${this.routerService.url.component}`);
            if (isEmpty(this.entry)) {
                this.routerService.router.navigateByUrl('Not-found');
            }
            this.initMetaData(this.entry.seo_meta_data);
            this.readingTime = this.calculateReadingTime(this.entry);
            this.date = this.reformatDate(this.entry);
            this.allRelatedPosts = await this.getRelatedPosts();
            this.disqusService.init();
            if (isBrowser()) {
                window.scrollTo(0, 0);
            }
            this.isInitialized = true;
            this.siteUrl = this.environment.urls.base;
            this.postUrl = this.siteUrl + this.routerService.url.current;
            this.postLinkTitle = this.entry.title + ` %7C ${this.environment.siteName} Blog`;
        } catch (err) {
            console.error(...new ExxComError(509922, scriptName, err).stamp());
        }
    }

    ngOnDestroy() {
        try {
            if (this.disqusService.active) {
                this.disqusService.removeScript();
            }
        } catch (err) {
            console.error(...new ExxComError(550992, scriptName, err).stamp());
        }
    }

    /**
     * @function getRelatedPosts
     * @description Recommend related posts by rule
     * 'category + first 2 tags > category + first tag > category'. Fetch posts in
     * the same category and sort them by the rule above.
     */
    private async getRelatedPosts() {
        try {
            const posts = await getRelatedBlogPosts({
                cmsService: this.cmsService,
                tags: this.entry.tags,
                entry: this.entry,
                environment: this.environment,
                limit: 4,
            });
            return posts;
        } catch (err) {
            console.error(...new ExxComError(468259, scriptName, err).stamp());
        }
    }
}
