<section
    class="product-options-container"
    [ngStyle]="
        get(productOptionsRow, 'product_options_2.product_options[0].gray_background', false)
            ? { 'background-color': '#F5F7FA', 'margin-bottom': '24px' }
            : { 'background-color': '#FFFFFF' }
    "
>
    <div class="container d-flex flex-row justify-content-center flex-wrap product-options">
        <div class="product-options-header col-12">
            <span class="tags text-center py-2 mb-1">{{ get(productOptionsRow, 'product_options_2.product_options[0].small_label', '') }}</span>
            <div class="category-top-title d-flex flex-row justify-content-center mb-4">
                <h2
                    *ngIf="get(productOptionsRow, 'product_options_2.product_options[0].header', false)"
                    class="product-options-header-title col-md-7 text-center"
                >
                    {{ productOptionsRow.product_options_2.product_options[0].header }}
                </h2>
            </div>
        </div>

        <div
            class="product-options-item-container"
            [class.product-options-item-container-duo]="get(productOptionsRow, 'product_options_2.product_options[0].feature_product').length < 3"
        >
            <div
                *ngFor="let product of get(productOptionsRow, 'product_options_2.product_options[0].feature_product', '')"
                class="product-options-item"
            >
                <a
                    class="product-options-anchor d-flex flex-column flex-wrap align-items-center"
                    [ngStyle]="
                        get(productOptionsRow, 'product_options_2.product_options[0].gray_background', false)
                            ? { 'background-color': '#FFFFFF' }
                            : { 'background-color': '#F5F7FA' }
                    "
                    [routerLink]="get(product, 'product_link.href', '')"
                >
                    <div class="product-options-image-container">
                        <img
                            class="product-options-image"
                            [src]="
                                get(product, 'use_custom_image', false)
                                    ? getWebpImg(get(product, 'product_image.href', ''))
                                    : getWebpImg(get(product, 'product_link.image_href', ''))
                            "
                            [alt]="get(product, 'title', 'product')"
                        />
                    </div>
                    <p *ngIf="get(product, 'title', false)" class="product-options-title">
                        {{ product.title }}
                    </p>
                </a>
                <div
                    *ngIf="product.spec_highlights?.host_interface && product.spec_highlights?.memory && product.spec_highlights?.power_tdp"
                    class="product-options-item-attributes-container"
                >
                    <div class="product-options-item-attributes">
                        <div class="product-options-item-attributes-type-container">
                            <mat-icon svgIcon="transfer-arrows"></mat-icon>
                            <h6 class="product-options-item-attributes-type">Host Interface</h6>
                        </div>
                        <p class="product-options-item-attributes-desc">{{ product.spec_highlights?.host_interface }}</p>
                    </div>
                    <div class="product-options-item-attributes">
                        <div class="product-options-item-attributes-type-container">
                            <mat-icon svgIcon="ram-thick"></mat-icon>
                            <h6 class="product-options-item-attributes-type">Memory</h6>
                        </div>
                        <p class="product-options-item-attributes-desc">{{ product.spec_highlights?.memory }}</p>
                    </div>
                    <div class="product-options-item-attributes">
                        <div class="product-options-item-attributes-type-container">
                            <mat-icon svgIcon="lightning-thick"></mat-icon>
                            <h6 class="product-options-item-attributes-type">Power</h6>
                        </div>
                        <p class="product-options-item-attributes-desc">{{ product.spec_highlights?.power_tdp }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isEmpty(get(productOptionsRow, 'product_options_2.product_options[0].tables', []))" class="d-flex flex-row justify-content-center">
        <a class="product-options-tables-button d-flex align-items-center justify-content-center" (click)="openTable(tableModalTemplate)">{{
            get(productOptionsRow, 'product_options_2.product_options[0].product_options_button_text', 'See all options')
        }}</a>
    </div>
</section>
<hr *ngIf="get(productOptionsRow, 'product_options_2.product_options[0].split_line', '')" class="m-0 mb-3" />

<ng-template #tableModalTemplate>
    <app-popup-dialog [modalRef]="bsModalRef" site="exx">
        <ng-template #inputTemplate>
            <cms-partial-category-top-component-table
                [tableRow]="productOptionsRow?.product_options_2?.product_options[0]?.tables[0]"
            ></cms-partial-category-top-component-table>
        </ng-template>
    </app-popup-dialog>
</ng-template>
