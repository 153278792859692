import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { forEach } from 'lodash';

const scriptName = 'cms-news-events.component';

@Component({
    selector: 'app-cms-news-events',
    templateUrl: './cms-news-events.component.html',
    styleUrls: ['./cms-news-events.component.scss'],
})
export class CmsNewsEventsComponent implements OnInit {
    categories: Map<string, Array<any>> = new Map(); // key: year, value: list of press posts which were posted in specific year
    chosenPosts: any = [];
    chosenYear: string;
    events: any = [];
    eventEntry: any = {};
    featurePost: any;
    pressEntries: any = [];
    years: string[] = [];

    constructor(private cmsService: CmsService) {}

    async ngOnInit() {
        try {
            this.pressEntries = await this.cmsService.getEntries('press_release');
            this.eventEntry = await this.cmsService.getEntry('news_events');
            this.events = this.eventEntry && this.eventEntry.events;
            if (this.pressEntries.length == 0) {
                return;
            }
            this.pressEntries.map((entry) => {
                if (!entry.date) {
                    return;
                }
                const key = entry.date.split('-')[0]; // get year
                this.categories[key] = this.categories[key] || [];
                this.categories[key].push(entry); // add entry to list
            });
            this.years = Object.keys(this.categories).reverse();
            this.onChosenYear(this.years[0]);
            forEach(Object.keys(this.categories), (key) => {
                this.categories[key].sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
            });
            this.featurePost = this.categories[this.years[0]][0];
        } catch (err) {
            console.error(...new ExxComError(644095, scriptName, err).stamp());
        }
    }

    parseDate(post: any) {
        try {
            if (!post.date) {
                return '';
            }
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
            } as const;
            return new Date(post.date).toLocaleDateString('en-US', options);
        } catch (err) {
            console.error(...new ExxComError(644096, scriptName, err).stamp());
        }
    }

    onChosenYear(year: string) {
        try {
            this.chosenYear = year;
            this.chosenPosts = this.categories[year];
        } catch (err) {
            console.error(...new ExxComError(644097, scriptName, err).stamp());
        }
    }
}
