import { Component, ElementRef, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { Location } from '@angular/common';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { trigger, animate, style, transition } from '@angular/animations';

const scriptName = 'cms-partial-category-top-component-image-and-text';
@Component({
    selector: 'cms-partial-category-top-component-image-and-text',
    templateUrl: './image-and-text.component.html',
    styleUrls: ['./image-and-text.component.scss'],
    animations: [trigger('imgChange', [transition('* => *', [animate(0, style({ opacity: '0' })), animate(500, style({ opacity: '1' }))])])],
    encapsulation: ViewEncapsulation.None,
})
export class ImageAndTextComponent extends CmsPartialComponentClass {
    @ViewChild('accordionImage') accordionImage: ElementRef;
    @Input() imageAndTextRow: any;
    @Input() uniqueIndex: number;
    @Input() dataUrl: string;
    @Input() dataTitle: string;
    @Input() solutionID: string;
    @Input() grayBackgroundRows: any = [];
    @Input() index: number;

    @ViewChild('buttonRowImageElement') buttonRowImageElement: ElementRef;
    activeButtonId: string = '';
    currentButtonRowImage: string = '';
    currentButtonRowImageAlt: string = '';

    currentAccordion = 0;
    getWebpImg: (src: string) => string;
    currentImage: string = '';
    newImage: string;
    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        public bootstrapMarketoService: BootstrapMarketoService,
        cmsService: CmsService,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                bootstrapMarketoService,
                breakpointObserver,
                cmsService,
                environment,
                routerService,
                location,
                categoryService,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    setAccordion(index: number) {
        try {
            this.currentAccordion = index === this.currentAccordion ? -1 : index;
            this.newImage = this.imageAndTextRow?.image_and_text?.image_and_text[0]?.accordion[index]?.accordion_image?.href;
            const isNewImage: boolean = this.newImage !== this.currentImage ? true : false;
            if (this.newImage === '') {
                return;
            }
            if (isNewImage && !this.isNarrow) {
                this.accordionImage.nativeElement.className = 'opacity-on';
                setTimeout(() => {
                    this.currentImage = this.newImage;
                    this.accordionImage.nativeElement.className = 'opacity-off';
                }, 250);
            } else if (isNewImage && this.isNarrow) {
                this.currentImage = this.newImage;
            }
        } catch (err) {
            console.error(...new ExxComError(923141, scriptName, err).stamp());
        }
    }

    /**
     *
     * @param i index from ngFor directive. Each index represents a new button with different text/image
     * @param buttonRowImage the image associated with the button prcess
     * @returns new image and active button
     */
    setActiveButtonAndImage(i: number, buttonRowImage: string) {
        try {
            const activeButtonPressed = `button-${i}`;
            this.activeButtonId = activeButtonPressed;

            if (buttonRowImage === this.currentButtonRowImage || '') {
                return;
            } else {
                this.currentButtonRowImage = buttonRowImage;
                this.currentButtonRowImageAlt = this.imageAndTextRow?.image_and_text?.image_and_text[0]?.button_row[i]?.button_image?.title;
            }
        } catch (err) {
            console.error(...new ExxComError(923112, scriptName, err).stamp());
        }
    }
}
