/**
 * Out of the box, Angular includes environment.ts and environment.prod.ts.
 * When the build command is run with the --prod flag, environments.prod
 * replaces environment. However, when building Universal without the --prod
 * flag, console.log does not work in the browser. Therefore, becacuse all
 * dev/prod specific settings can be handled in other ways, and because all the
 * data in the environment object is duplicated anyway, only one environment
 * file is used by setting production to true in the object below, and removing
 * the automatically generated environment file replacements in angular.json.
 */

export const environment = {
    production: false,
    siteAbbr: 'exx',
    siteName: 'Exxact',
    // eslint-disable-next-line no-useless-escape
    productUrlRegex: '-E[0-9]{6,9}$',
    hasCheckout: false,
    urls: {
        base: 'https://www.exxactcorp.com',
        image: {
            base: 'https://images.exxactcorp.com',
            logo: 'https://assets.exxactcorp.com/img/exx/misc/X-Exxact.svg', // ?
            noImage: 'https://assets.exxactcorp.com/webp/productimages/large/no-image-available-large.webp',
            productBase: 'https://images.exxactcorp.com/productimages/large/',
        },
    },
    contentstack: {
        baseUrl: 'https://cdn.contentstack.io/v3',
        sb: {
            name: 'sb-exx',
            api_key: 'blt71da4c740e00faaa',
            access_token: 'cs92ea715561c382b570600456',
        },
        prod: {
            name: 'prod-exx',
            api_key: 'blt71da4c740e00faaa',
            access_token: 'cs0a676599eaf96cae9e6e809a',
        },
    },
    google: {
        sb: {
            gtmId: 'GTM-K5TLFD4',
        },
        prod: {
            gtmId: 'GTM-T76L4W2',
        },
        grecaptchaSiteKey: '6Lfm8pkUAAAAAOooKvWwkExyMHXSoqZd4a89SZeA',
    },
    marketo: {
        clickyId: '101234646', // Sabre: 101241090
        forms: {
            baseUrl: 'https://go.exxactcorp.com',
        },
        munchkin: {
            id: '449-NMB-526',
            settings: { wsInfo: 'jVRU' },
        },
        rest: {
            baseUrl: 'https://449-NMB-526.mktorest.com/rest',
        },
    },
    searchspring: {
        siteId: 'hg9ly1',
        /**
         * In combination with col- classes, resultsPerPage defines the number of
         * columns in categories.component.html.
         * 2 columns = col-<xs/sm/md/lg/xl>-6
         *   Ex. If resultsPerPage is 16, there will be 8 rows
         * 3 columns = col-<xs/sm/md/lg/xl>-4
         *   Ex. If resultsPerPage is 16, there will be 6 rows, 1 with only 1 item
         * 4 columns = col-<xs/sm/md/lg/xl>-3
         *   Ex. If resutlsPerPage is 16, there will be 4 rows
         * 6 columns = col-<xs/sm/md/lg/xl>-2
         *   Ex. If resultsPerPage is 16, there will be 3 rows, 1 with 4 items
         */
        resultsPerPage: '24',
    },
    stripe: {
        sb: { publishableKey: '' },
        prod: { publishableKey: '' },
    },
};
