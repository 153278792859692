<div id="cms-about-us">
  <div
    [style.background-image]="'url(' + getWebpImg(entry?.hero_banner_background_image?.href) + ')'"
    class="fx-row fx-row-v-center fx-wrap hero-banner"
  >
    <div #heroContent class="col-md-12 col-sm-12 hero-content">
      <h5>{{ entry?.hero_banner_label }}</h5>

      <h1>{{ entry?.hero_banner_title }}</h1>

      <p>{{ entry?.hero_banner_copy }}</p>

      <div class="fx-row fx-wrap hero-blurb-container col-md-6 mt-3">
        <div class="col-md-4 hero-blurb">
          <h3 id="experience-number">{{ entry?.years_of_experience }}</h3>
          <p>Years of Experience</p>
        </div>

        <div class="col-md-4 hero-blurb">
          <h3 id="customers-number">{{ entry?.customers }}</h3>
          <p>Customers</p>
        </div>

        <div class="col-md-4 hero-blurb">
          <h3 id="certification-number">{{ entry?.certified }}</h3>
          <p>Certified</p>
        </div>
      </div>
    </div>
  </div>

  <section
    *ngFor="let row of entry?.rows"
    class="fx-row px-2 py-2 about-us-row py-4"
    [class.gray-bg]="row?.image_on_left_gray_background"
  >
    <div class="fx-row-container fx-row-v-center fx-wrap" [class.reverse]="row?.image_on_left_gray_background">
      <div class="col-md-6 col-sm-12">
        <h5>{{ row?.label }}</h5>

        <h1>{{ row?.title }}</h1>

        <p>{{ row?.copy }}</p>
      </div>

      <img class="col-sm-12 col-md-6 py-4" [src]="getWebpImg(row?.image?.href)" [alt]="row?.image?.title" />
    </div>
  </section>
</div>
