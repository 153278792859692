<section *ngIf="get(leadGenRow, 'lead_gen.lead_gen', false)" class="lead-gen">
    <div *ngFor="let row of get(leadGenRow, 'lead_gen.lead_gen', [])">
        <div class="container d-flex flex-row justify-content-center align-items-center">
            <div class="lead-gen-text-container d-flex flex-column" [ngStyle]="{ 'background-color': row?.background_color }">
                <p class="lead-gen-title">{{ get(row, 'lead_gen_title', '') }}</p>
                <h2 class="lead-gen-copy">{{ get(row, 'lead_gen_copy', '') }}</h2>
                <ng-container *ngIf="get(row, 'marketo_form_selection.form_selection', '') !== 'no_form'">
                    <button-component
                        data-cy="marketo-form"
                        *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'default_form'"
                        (click)="
                            bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                                title: dataTitle,
                                url: dataUrl
                            })
                        "
                        [buttonOptions]="{
                            text: get(row, 'lead_gen_link.title', ''),
                            type: 'tertiary'
                        }"
                    ></button-component>
                    <button-component
                        data-cy="marketo-form"
                        *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'other_form'"
                        (click)="
                            bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form_selection.marketo_forms.id', ''), 'edit', {
                                title: dataTitle,
                                url: dataUrl
                            })
                        "
                        [buttonOptions]="{
                            text: get(row, 'lead_gen_link.title', ''),
                            type: 'tertiary'
                        }"
                    ></button-component>
                </ng-container>
            </div>
        </div>
    </div>
</section>
<hr *ngIf="get(leadGenRow, 'lead_gen.split_line', '')" class="m-0 mb-3" />
