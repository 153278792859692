<div class="hero-banner text-center pb-5" [style.background-image]="'url(' + get(this.headerData, 'background_image.href', '') + ')'">
    <div class="banner-text-container container d-flex justify-content-center flex-column align-items-center">
        <div class="py-5 px-2 text-center" [class.font-light-theme]="get(this.headerData, 'font_color_light_theme', true)">
            <h1>{{ get(this.headerData, 'heading_title', '') }}</h1>
            <p>{{ get(this.headerData, 'heading_copy', '') }}</p>
        </div>
        <div>
            <lib-marketo-form
                [formId]="'mktoForm_' + get(this.headerData, 'marketo_form_selection.marketo_forms.id', '')"
                *ngIf="get(this.headerData, 'marketo_form_selection.marketo_forms.id', '')"
            ></lib-marketo-form>
            <img
                *ngIf="get(this.headerData, 'badge_image_link_and_alt_text.href', '')"
                [src]="get(this.headerData, 'badge_image_link_and_alt_text.href', '')"
                class="badge"
            />
        </div>
    </div>
</div>
