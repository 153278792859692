import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { get } from 'lodash';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';

const scriptName = 'app-cms-blog-search-and-dropdown.component';

@Component({
    selector: 'app-cms-blog-search-and-dropdown',
    templateUrl: './cms-blog-search-and-dropdown.component.html',
    styleUrls: ['./cms-blog-search-and-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsBlogSearchAndDropdownComponent extends ExxComComponentClass {
    @HostListener('document:click', ['$event.target']) click(target: HTMLElement) {
        try {
            if (target == get(this, 'blogCategoryButton.nativeElement')) {
                return;
            }
            const blogCategories: HTMLElement = get(this, 'blogCategories.nativeElement');
            if (blogCategories && target != blogCategories && !blogCategories.contains(target)) {
                this.blogCategoriesOpen = false;
            }
        } catch (err) {
            console.error(...new ExxComError(398993, scriptName, err).stamp());
        }
    }

    @Input() categories: any[];
    @Output() categorySelectionHandler = new EventEmitter<string>();
    @Input() blogTitle: string;
    blogCategoriesOpen: boolean = false;
    mobileOpen: boolean = false;

    @ViewChild('blogCategories') blogCategories: ElementRef;
    @ViewChild('blogCategoryButton') blogCategoryButton: ElementRef;
    // Properties Public

    constructor(b: BreakpointObserver) {
        super({
            dependencies: {
                breakpointObserver: b,
            },
        });
    }
    onChosenTitle(title: string) {
        try {
            this.categorySelectionHandler.emit(title);
            this.blogCategoriesOpen = false;
            this.mobileOpen = false;
        } catch (err) {
            console.error(...new ExxComError(809919, scriptName, err).stamp());
        }
    }
}
