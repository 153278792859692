import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lib/services/api.service';

@Component({
    selector: 'app-header-contactbar',
    templateUrl: './header-contactbar.component.html',
    styleUrls: ['./header-contactbar.component.scss'],
})
export class HeaderContactbarComponent implements OnInit {
    entry: any = {};

    constructor(private apiService: ApiService) {}

    async ngOnInit() {
        const res = await this.apiService.get('contactbar');
        if (!res.success) {
            throw res;
        }
        this.entry = res.data;
    }
}
