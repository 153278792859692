import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

interface ButtonOptions {
    text: string;
    type: 'primary' | 'secondary' | 'tertiary' | 'facet' | 'graph-swap' | 'tiny-primary' | 'tiny-use-case' | 'tag';
    iconPosition?: 'left' | 'right';
    iconName?: string;
    iconSize?: 'small' | 'medium' | 'large';
    iconStrokeWidth?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    externalLink?: boolean;
    internalLink?: boolean;
    class?: string;
    unsetWidth?: boolean;
}

@Component({
    selector: 'button-component',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
    @Input() buttonOptions: ButtonOptions = { text: '', type: 'primary' };
}
