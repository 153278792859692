<div id="card">
  <div id="links" class="d-flex flex-column">
    <div id="card-icon" *ngIf="entry?.icon_selection !== 'none'">
      <img [src]="getWebpImg(entry?.icon_selection)" />
    </div>
    <h3 *ngIf="get(entry, 'repository_title', '')" class="mb-1 mt-2">{{ entry?.repository_title }}</h3>
    <a
      class="repository-link"
      *ngFor="let link of get(entry, 'link_group', '')"
      target="_blank"
      [href]="link?.repository_document_link?.href"
      >{{ link?.repository_document_link?.title }}</a
    >
  </div>
</div>
