import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { get } from 'lodash';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { appIcons } from './app-icons';
import { CategoryService } from 'lib/services/category/category.service';
import { contains, isBrowser, scrollToTop, wait, getHeight } from 'lib/tools';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { RouterService } from 'lib/services/router.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MarketoService } from 'lib/services/marketo.service';

const scriptName = 'app.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    @ViewChild('infiniteScrollGlobalContainer')
    infiniteScrollGlobalContainer: ElementRef;
    resourcePages: string[] = ['blog', 'Case-Studies', 'eBooks', 'Reference-Architecture', 'Supported-Software', 'Whitepapers'];
    isResourcePage: boolean = false;
    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        @Inject(PLATFORM_ID) private platformId: string,
        @Inject(DOCUMENT) private document: Document,
        private categoryService: CategoryService,
        private domSanitizer: DomSanitizer,
        private infiniteScrollService: InfiniteScrollService,
        private marketoService: MarketoService,
        private matIconRegistry: MatIconRegistry,
        private routerService: RouterService
    ) {}

    ngOnInit() {
        try {
            this.initIcons();
            this.initInfiniteScroll();
            this.initRouteListener();
            if (!isBrowser()) {
                return;
            }
            getHeight();
            addEventListener('resize', () => {
                getHeight();
            });
        } catch (err) {
            console.error(...new ExxComError(198734, scriptName, err).stamp());
        }
    }

    private initIcons() {
        const isServer = isPlatformServer(this.platformId);
        appIcons.forEach((iconName: any) => {
            const path = `${isServer ? this.document.location.origin : ''}/assets/icons/${iconName}.svg?ignoreCache=1`;
            const url = this.domSanitizer.bypassSecurityTrustResourceUrl(path);
            this.matIconRegistry.addSvgIcon(iconName, url);
        });
    }

    private initRouteListener() {
        try {
            this.routerService.onRouteChange('appComponent', (event: any) => {
                this.isResourcePage = this.isResourcePages();
                if (!isBrowser()) {
                    return;
                }
                // Scroll to top of all except categories
                if (!contains(event.url, this.categoryService.paths)) {
                    scrollToTop(document.documentElement);

                    this.marketoService.init();
                    if (!this.routerService.url.current.includes('configurator')) {
                        this.marketoService.initLoaded();
                    }
                }
            });
        } catch (err) {
            console.error(...new ExxComError(997839, scriptName, err).stamp());
        }
    }
    isResourcePages() {
        if (isBrowser()) {
            let didFindResourcePage = false;
            this.resourcePages.forEach((page) => {
                if (contains(window.location.pathname, page)) {
                    didFindResourcePage = true;
                }
            });
            return didFindResourcePage;
        } else {
            let didFindResourcePage = false;
            this.resourcePages.forEach((page) => {
                if (contains(get(this.req, 'originalUrl'), page)) {
                    didFindResourcePage = true;
                }
            });
            return didFindResourcePage;
        }
    }

    async initInfiniteScroll() {
        if (!isBrowser()) {
            return;
        }
        await wait('10ms');
        this.infiniteScrollService.initGlobalContainer(this.infiniteScrollGlobalContainer.nativeElement);
    }
}
