<header id="header" data-cy="exx-header">
  <app-header-contactbar></app-header-contactbar>

  <div id="header-row2" class="fx-row fx-row-h-space-between fx-row-v-center header-row">
    <a id="header-logo-desk" routerLink="/">
      <img id="header-wide-logo" alt="Exxact" src="https://images.exxactcorp.com/img/exx/misc/X-Exxact.svg" />
    </a>

    <app-header-menu
      [isResourcePage]="isResourcePage"
      id="app-header-menu"
      data-cy="exx-header-menu"
      [isNarrow]="isNarrow"
      (isActiveChange)="onActiveChange('menu', $event, 100)"
    ></app-header-menu>

    <a id="header-logo-mob" routerLink="/">
      <img id="header-narrow-logo" alt="Exxact" src="https://images.exxactcorp.com/img/exx/misc/x-emblem.svg" />
    </a>

    <app-header-search
      id="searchbar"
      data-cy="exx-searchbar"
      [isResourcePage]="isResourcePage"
      [isNarrow]="isNarrow"
      (isActiveChange)="onActiveChange('search', $event, 100)"
      (searchActive)="onToggleSearch($event)"
    ></app-header-search>
    <mat-icon
      [class.hide]="!toggleSearch"
      *ngIf="isNarrow"
      #searchClose
      svgIcon="x"
      (click)="onToggleSearch($event)"
    ></mat-icon>
  </div>

  <lib-overlay [zIndex]="overlayZindex" [isVisible]="overlayVisible"></lib-overlay>
</header>
