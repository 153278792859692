<ng-container *ngFor="let row of get(relatedContentRow, 'rows', [])">
    <section *ngIf="get(row, 'related_content', '')" [class.gray-bg]="get(row, 'related_content.gray_background')">
        <div class="cms-related-content d-flex flex-column container">
            <div class="d-flex flex-row justify-content-between">
                <h2>Related Content</h2>
            </div>

            <div class="d-flex flex-row justify-content-between align-items-center pt-2">
                <div id="nav-links" class="d-flex flex-row flex-wrap justify-content-start">
                    <div *ngFor="let category of categories" (click)="onChosenTitle(category)">
                        <div
                            *ngIf="!isEmpty(getCategoryPosts(row?.related_content, category))"
                            class="title"
                            [class.active]="category == activeCategoryName"
                        >
                            <span>{{ category }}</span>
                            <div class="block"></div>
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button-component
                        [buttonOptions]="{
                            text: 'View more blogs',
                            internalLink: '/blog',
                            type: 'primary',
                            iconPosition: 'right',
                            iconName: 'chevron-right'
                        }"
                    ></button-component>
                </div>
            </div>

            <div class="cards grid partial-grid container-padless">
                <div
                    *ngFor="let entry of getCategoryPosts(row?.related_content).slice(0, isNarrow ? 4 : 3)"
                    class="card-container g-col-lg-4 g-col-12 d-flex flex-row align-items-center"
                    [class.border-bottom]="isNarrow"
                >
                    <app-resource-card-related-card
                        class="card"
                        [entry]="entry"
                        [relatedContentTileData]="{
                            title: entry?.title,
                            categoryTitle: entry?.blog_category[0]?.title,
                            image: entry?.feature_image?.url,
                            imageTitle: entry?.feature_image?.title,
                            date: entry?.date,
                            link: entry?.routerLink,
                            entry: entry,
                            isRelatedContent: true,
                            hideTimeNarrow: true
                        }"
                    ></app-resource-card-related-card>
                </div>
            </div>
        </div>
    </section>
    <hr *ngIf="get(row, 'related_content.split_line', '')" class="m-0 mb-3" />
</ng-container>
