import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { RouterService } from 'lib/services/router.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { WebpService } from 'lib/services/webp.service';
import { Location } from '@angular/common';
import { ProductSpecDialogComponent } from 'lib/components/product-spec-dialog/product-spec-dialog.component';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';

const scriptName = 'cms-partial-category-top-component-product-options-2.component';

@Component({
    selector: 'cms-partial-category-top-component-product-options-2',
    templateUrl: './product-options-2.component.html',
    styleUrls: ['./product-options-2.component.scss'],
})
export class ProductOptions2Component extends CmsPartialComponentClass implements OnInit {
    @Input() productOptionsRow: any;
    getWebpImg: (src: string) => string;
    private xsBreakpointObserver: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

    targetHighlights = ['Host Interface', 'Standard Memory', 'Power'];

    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        routerService: RouterService,
        webpService: WebpService,
        bsModalRef: BsModalRef,
        bsModalService: BsModalService,
        location: Location,
        categoryService: CategoryService,
        private productService: WebstoreProductService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                bsModalRef,
                bsModalService,
                environment,
                routerService,
                location,
                categoryService,
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    openProductSpecDialog(products?: any, options?: any) {
        try {
            const productsSpecs = new Set(['Product']);
            const productsValues = [];
            const productsLinks = [];
            if (products) {
                products.map((product: any) => {
                    const productValue = [];
                    productValue.push(product.title);
                    product.specs_group.map((specs) => {
                        productsSpecs.add(specs.spec);
                        productValue.push(specs.value);
                    });
                    productsValues.push(productValue);
                    productsLinks.push(product.product_link && product.product_link.routerLink);
                });
            }
            const productDialogRef = this.dialog.open(ProductSpecDialogComponent, {
                data: options
                    ? { options: options }
                    : {
                          specs: productsSpecs,
                          values: productsValues,
                          links: productsLinks,
                      },
                maxWidth: '1000px',
                panelClass: 'dialog-mob-only-product-spec',
            });
            const smallDialogSubscription = this.xsBreakpointObserver.subscribe((size) => {
                if (size.matches) {
                    productDialogRef.updateSize('100vw', '100vh');
                } else {
                    productDialogRef.updateSize('auto', 'auto');
                }
            });
            productDialogRef.afterClosed().subscribe(() => smallDialogSubscription.unsubscribe());
        } catch (err) {
            console.error(...new ExxComError(636423, scriptName, err).stamp());
        }
    }

    openTable(template: TemplateRef<any>) {
        this.bsModalRef = this.bsModalService.show(template, {
            class: 'product-options-table-modal',
        });
    }

    ngOnInit() {
        this.fetchProductImages();
        this.overrideRowCountCutoff();
    }

    fetchProductImages() {
        this.productOptionsRow.product_options_2.product_options[0].feature_product.forEach(async (product) => {
            const productData = await this.productService.getProductByUrlComponent(product.product_link.href);
            product.product_link.image_href = productData.imageUrl;
        });
    }

    overrideRowCountCutoff() {
        if (this.productOptionsRow?.product_options_2?.product_options[0]?.tables[0]?.new_table_format_2?.row_count_cut_off) {
            this.productOptionsRow.product_options_2.product_options[0].tables[0].new_table_format_2.row_count_cut_off = 999;
        }
    }
}
