import { Component, Input } from '@angular/core';
import { get } from 'lodash';

const scriptName = 'cms-gated-content-header.component';

@Component({
    selector: 'app-gated-content-header',
    templateUrl: './gated-content-header.component.html',
    styleUrls: ['./gated-content-header.component.scss'],
})
export class GatedContentHeaderComponent {
    @Input() headerData: any = {};

    constructor() {}

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
