<div
    class="accordion-container d-flex flex-row flex-nowrap grid partial-grid container"
    [class.flex-row-reverse]="imageOnRight"
    [class.justify-content-center]="isNarrow"
>
    <!-- Desktop Image Container -->
    <div class="accordion-image-container">
        <div
            class="accordion-image-container-background px-4"
            [@colorChange]="{
                value: colorChangeState,
                params: { backgroundColor: currentBackgroundColor, nextBackgroundColor: nextBackgroundColor }
            }"
        >
            <img
                [class]="'accordion-image accordion-image-' + uniqueIndex"
                [ngClass]="subImgState == 'visible' ? 'accordion-image-visible' : 'accordion-image-hidden'"
                #accordionImage
                [src]="currentImage !== '' ? currentImage : mainImage"
            />
        </div>
        <img
            class="accordion-image accordion-image-new"
            #accordionImage
            [src]="newCurrentImage !== '' ? newCurrentImage : mainImage"
            [@imgChange]="imgState"
            [ngStyle]="{ 'max-width': currentImageSize }"
        />
    </div>

    <!-- Title, body, & instances -->
    <div [id]="'partialAccordion-' + uniqueIndex" class="accordion-content-container">
        <!-- Main Title & body -->
        <div *ngIf="mainTitle || mainBody" class="accordion-content-container-main">
            <h4 class="accordion-label mb-base mt-0" *ngIf="mainSubtitle">
                {{ mainSubtitle }}
            </h4>
            <h2 class="accordion-title mb-3">{{ mainTitle }}</h2>
            <div
                class="accordion-mainbody"
                [ngClass]="(mainButtonLinkTitle && mainButtonLink) || mainButtonMarketoFormSelection !== 'no_form' ? 'mt-3 mb-0' : 'my-3'"
            >
                <div *ngIf="mainBody" [innerHTML]="mainBody | safe: 'html'"></div>
            </div>

            <!-- Button Link -->
            <a [href]="mainButtonLink" *ngIf="mainButtonLinkTitle && mainButtonLink" class="accordion-mainlink d-flex fx-row-h-center mt-2 mb-3">
                <span>{{ mainButtonLinkTitle }}</span>
                <mat-icon svgIcon="chevron_right"></mat-icon>
            </a>

            <!-- Button Marketo Form -->
            <button
                *ngIf="mainButtonMarketoFormSelection !== 'no_form'"
                class="accordion-mainform d-flex fx-row-h-center mt-2 mb-3"
                (click)="
                    bootstrapMarketoService.openBootstrapMarketoDialog(mainButtonMarketoFormID.toString(), 'edit', {
                        title: dataTitle,
                        url: dataUrl
                    })
                "
            >
                <span>Form {{ mainButtonMarketoFormID }}</span>
                <mat-icon svgIcon="chevron_right"></mat-icon>
            </button>
        </div>

        <!-- Tablet & Mobile Image -->
        <div *ngIf="mainTitle || mainBody" class="accordion-image-container-tablet mb-3" [ngStyle]="{ 'background-color': backgroundColor }">
            <img
                class="accordion-image-tablet"
                [ngClass]="subImgState == 'visible' ? 'accordion-image-visible' : 'accordion-image-hidden'"
                #accordionImage
                [src]="currentImage !== '' ? currentImage : mainImage"
            />
            <img
                class="accordion-image-tablet accordion-image-tablet-new"
                #accordionImage
                [src]="newCurrentImage !== '' ? newCurrentImage : mainImage"
                [@imgChange]="imgState"
            />
        </div>

        <!-- Instances (Accordion Items) -->
        <span class="accordion-item-container">
            <div
                class="accordion-item"
                [ngClass]="
                    !(!mainTitle && !mainBody)
                        ? i == currentAccordion
                            ? 'accordion-is-open'
                            : 'accordion-is-closed'
                        : i == currentAccordion
                        ? 'accordion-is-open-with-image'
                        : 'accordion-is-closed-with-image'
                "
                *ngFor="let accordion of instances; index as i"
                id="{{ 'accordion-item-' + uniqueIndex + i }}"
                [ngStyle]="mainTitle == '' && i == 0 && currentAccordion == 0 && { 'border-top': 'none' }"
                (click)="setAccordion(i)"
            >
                <div class="accordion-item-header-container" id="{{ 'headingImageAndText-' + uniqueIndex + i }}">
                    <div class="accordion-item-button d-flex fx-col-h-left p-0" type="button">
                        <!-- Subheader -->
                        <h5
                            class="accordion-item-label"
                            [ngClass]="i === currentAccordion ? 'subheader-visible' : 'subheader-hidden'"
                            *ngIf="accordion?.subheader"
                        >
                            {{ accordion?.subheader }}
                        </h5>

                        <!-- Header -->
                        <span class="d-flex fx-row-h-space-between align-items-center w-100">
                            <span class="accordion-item-title">{{ accordion?.header }}</span>
                            <span class="accordion-item-chevron d-flex">
                                <mat-icon
                                    svgIcon="chevron_down"
                                    [@accordionChange]="i == currentAccordion ? 'chevron_up' : 'chevron_down'"
                                ></mat-icon>
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    id="{{ 'collapseAccordion-' + uniqueIndex + i }}"
                    class="accordion-collapse"
                    [ngClass]="i === currentAccordion ? 'body-visible' : 'body-hidden'"
                >
                    <div class="accordion-item-body p-0">
                        <!-- Body Copy -->
                        <div class="accordion-item-copy">
                            <div [innerHTML]="accordion?.body_copy | safe: 'html'"></div>
                        </div>
                    </div>

                    <!-- Image to display if no body content is set (Tablet & Mobile) -->
                    <div
                        *ngIf="!mainTitle && !mainBody"
                        class="accordion-image-container-tablet mb-1 mt-3"
                        [ngStyle]="{ 'background-color': backgroundColor }"
                    >
                        <img
                            class="accordion-image-tablet"
                            [ngClass]="subImgState == 'visible' ? 'accordion-image-visible' : 'accordion-image-hidden'"
                            #accordionImage
                            [src]="currentImage !== '' ? currentImage : mainImage"
                        />
                        <img
                            class="accordion-image-tablet accordion-image-tablet-new"
                            #accordionImage
                            [src]="newCurrentImage !== '' ? newCurrentImage : mainImage"
                            [@imgChange]="imgState"
                        />
                    </div>
                </div>
            </div>
        </span>
    </div>
</div>
