import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';

import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';
import { isBrowser } from 'lib/tools';

const scriptName = 'not-found.component';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent implements OnInit {
    getWebpImg: (src: string) => string;
    constructor(
        @Optional() @Inject(RESPONSE) private res: Response,
        private metaService: MetaService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    ngOnInit() {
        try {
            this.metaService.add({ title: '404: Not Found | Exxact' });
            if (!isBrowser()) {
                return;
            }
            this.res.status(404);
        } catch (err) {
            console.error(...new ExxComError(549949, scriptName, err).stamp());
        }
    }
}
