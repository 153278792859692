import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'cms-resource-center.component';

@Component({
    selector: 'app-cms-resource-center',
    templateUrl: './cms-resource-center.component.html',
    styleUrls: ['./cms-resource-center.component.scss'],
})
export class CmsResourceCenterComponent implements OnInit {
    entry: any = {};

    constructor(private cmsService: CmsService) {}

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('resource_center');
        } catch (err) {
            console.error(...new ExxComError(689933, scriptName, err).stamp());
        }
    }
}
