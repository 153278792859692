import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-partners.component';

@Component({
    selector: 'app-cms-partners',
    templateUrl: './cms-partners.component.html',
    styleUrls: ['./cms-partners.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsPartnersComponent implements OnInit {
    entry: any = {};
    getWebpImg: (src: string) => string;

    constructor(
        private cmsService: CmsService,
        public bootstrapMarketoService: BootstrapMarketoService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('partnerships');
        } catch (err) {
            console.error(...new ExxComError(699995, scriptName, err).stamp());
        }
    }
}
