<div id="cms-resource-center">
  <h1 class="text-center">Resource Center</h1>

  <div class="blocks fx-wrap fx-row-container fx-row-h-left mb-4">
    <div *ngFor="let block of entry.group" class="col-md-4 mt-4">
      <a class="block fx-col" [routerLink]="block.link.routerLink">
        <div class="fx-row fx-row-v-center mb-1">
          <mat-icon [svgIcon]="block.icon"></mat-icon>
          <span>{{ block.name }}</span>
        </div>
        <p>{{ block.description }}</p>
      </a>
    </div>
  </div>
</div>
