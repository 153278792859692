<section id="encapsulation-wrapper-feature-card">
  <h4 class="featured-subheader bold">{{ featuredContentData.featuredContentType }}</h4>
  <div class="grid container no-gap" id="featured-container">
    <div class="g-col-xl-6 g-col-12 feature-item left-tile" [routerLink]="featuredContentData.featuredContentLink">
      <img id="featured-content-image" [src]="featuredContentData.featuredContentImage" />
    </div>
    <div class="g-col-xl-6 g-col-12 feature-item right-tile d-flex flex-column" id="featured-text-container">
      <h4 id="featured-content-category-title" class="mb-2 old">
        {{ featuredContentData.featuredContentCategoryTitle }}
      </h4>
      <h3 id="featured-content-title" [routerLink]="featuredContentData.featuredContentLink">
        {{ featuredContentData.featuredContentTitle }}
      </h3>
      <div class="d-flex flex-row" id="read-and-date">
        <p id="date">{{ featuredContentData.featuredContentDate }}</p>
        <p id="read">{{ featuredContentData.featuredContentReadTime }}</p>
      </div>
    </div>
  </div>
</section>
