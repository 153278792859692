<div class="dropdown">
  <button
    class="dropdown-toggle resource-dropdown-button p-0"
    type="button"
    [id]="dropdownData?.dropdownMenuID"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <div class="d-flex flex-row align-items-center justify-content-between">
      <span id="current-selection">{{ currentTitle ? currentTitle : initialDropdownTitle }}</span>
      <div class="d-flex flex-row align-items-center py-base icon-border">
        <mat-icon id="gated-content-header-icon" class="mx-base" svgIcon="chevron_down"></mat-icon>
      </div>
    </div>
  </button>
  <ul class="dropdown-menu" [attr.aria-labelledby]="dropdownData?.dropdownMenuID">
    <div
      *ngFor="let item of dropdownData.listItems; let i = index"
      class="dropdown-item-container"
      [routerLink]="item.href"
      (click)="currentTitle = item?.title"
    >
      <li class="dropdown-list">
        <a class="dropdown-item" class="px-3">{{ item.title }}</a>
      </li>
    </div>
    <div id="return-home-container">
      <li *ngIf="returnToHomepageLink === true" class="mx-3 dropdown-list return-home">
        <div>
          <a class="dropdown-item" routerLink="/">Return to Homepage</a>
        </div>
      </li>
    </div>
  </ul>
</div>
