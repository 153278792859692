<section id="repository-banner">
  <div>
    <!-- background image banner -->
    <ng-container *ngIf="entry?.background?.background_image?.href !== ''">
      <div
        class="d-flex flex-row align-items-center justify-content-center"
        id="banner-background-gradient"
        [ngStyle]="{
          'background-image': 'url(' + getWebpImg(entry?.background?.background_image?.href) + ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }"
      >
        <div class="container">
          <h5 id="banner-subheading" *ngIf="get(entry, 'banner_subheading', '') !== ''">
            {{ get(entry, "banner_subheading", "") }}
          </h5>
          <h1 id="banner-title" *ngIf="get(entry, 'banner_title', '') !== ''">{{ get(entry, "banner_title", "") }}</h1>
          <p id="banner-body" class="m-0" *ngIf="get(entry, 'banner_body', '') !== ''">
            {{ get(entry, "banner_body", "") }}
          </p>
        </div>
      </div>
    </ng-container>
    <!-- gradient banner -->
    <ng-container
      *ngIf="entry?.background?.top_gradient_color !== '' && entry?.background?.bottom_gradient_color !== ''"
    >
      <div
        class="d-flex flex-row align-items-center justify-content-center"
        id="banner-background-gradient"
        [ngStyle]="{
          background:
            'linear-gradient(180deg, ' +
            entry?.background?.top_gradient_color +
            ' 0%,' +
            entry?.background?.bottom_gradient_color +
            ' 100%)'
        }"
      >
        <div class="container d-flex flex-column justify-content-center">
          <h5 id="banner-subheading" *ngIf="get(entry, 'banner_subheading', '') !== ''">
            {{ get(entry, "banner_subheading", "") }}
          </h5>
          <h1 id="banner-title" *ngIf="get(entry, 'banner_title', '') !== ''">{{ get(entry, "banner_title", "") }}</h1>
          <p id="banner-body" class="m-0" *ngIf="get(entry, 'banner_body', '') !== ''">
            {{ get(entry, "banner_body", "") }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</section>
