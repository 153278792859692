import { Component, Input } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';

const scriptName = 'exx-use-cases';

@Component({
    selector: 'app-exx-use-cases',
    templateUrl: './use-cases.component.html',
    styleUrls: ['./use-cases.component.scss'],
})
export class ExxUseCasesComponent extends CmsPartialComponentClass {
    @Input() row;
    activeCategory = 0;
    activeSolutions = null;

    constructor() {
        super();
    }

    ngOnInit() {
        this.activeSolutions = this.row?.category[this.activeCategory]?.solution;
    }

    changeActiveCategory(targetCategory: number) {
        try {
            this.activeCategory = targetCategory;
            this.activeSolutions = this.row?.category[this.activeCategory]?.solution;
        } catch (err) {
            console.error(...new ExxComError(444000, scriptName, err).stamp());
        }
    }
}
