<div>
  <div id="tiles-container" class="container">
    <h2 class="tiles-title">{{ get(tiles, "tile_group_title", "") }}</h2>
    <div *ngFor="let tileRow of get(tiles, 'tile_group.tile_row')" class="tile-row">
      <ng-container *ngFor="let tile of tileRow.tile">
        <div
          class="tile"
          [style.background-color]="get(tile, 'tile_reference[0].tile_color')"
          [class.small]="tile.tile_width == 1"
          [class.medium]="tile.tile_width == 2"
          [class.large]="tile.tile_width == 3"
          [routerLink]="get(tile, 'tile_reference[0].learn_more_button.button_text_and_link.routerLink', '')"
        >
          <span
            class="tag"
            *ngIf="get(tile, 'tile_reference[0].tag.tag_text', '') !== 'none'"
            [style.background-color]="get(tile, 'tile_reference[0].tag.tag_color')"
            >{{ get(tile, "tile_reference[0].tag.tag_text", "") }}</span
          >
          <div class="tile-image-container d-flex align-items-center justify-content-center">
            <img [src]="getWebpImg(get(tile, 'tile_reference[0].image.image_link_and_title.href', ''))" />
          </div>
          <div class="text-container">
            <div class="text-container-contents">
              <h4 class="title">{{ get(tile, "tile_reference[0].image.image_link_and_title.title") }}</h4>
              <div class="d-flex">
                <a
                  [routerLink]="get(tile, 'tile_reference[0].learn_more_button.button_text_and_link.routerLink', '')"
                  class="link d-flex align-items-center"
                >
                  <span>{{ get(tile, "tile_reference[0].learn_more_button.button_text_and_link.title", "") }}</span>
                  <i-feather class="chevron-right" name="chevron-right"></i-feather
                ></a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
