<div id="cms-partial-category-top">
    <!-- ROWS -->

    <div *ngIf="!isEmpty(get(data, 'rows'))" id="rows">
        <div *ngFor="let row of get(data, 'rows', []); let i = index">
            <cms-partial-category-top-hero-banner
                [bannerRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                *ngIf="get(row, 'category_top_banner', '')"
            >
            </cms-partial-category-top-hero-banner>

            <!-- STEP BY STEP -->
            <cms-partial-category-top-step-by-step [stepByStepRow]="row" *ngIf="get(row, 'step_by_step', '')">
            </cms-partial-category-top-step-by-step>

            <!-- FULL WIDTH IMAGE AND TEXT -->
            <cms-partial-category-top-component-full-width-image-and-text
                [fullWidthImageAndTextRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                *ngIf="get(row, 'image_and_text.full_width_image_and_text', '')"
            >
            </cms-partial-category-top-component-full-width-image-and-text>

            <!-- FEATURE SOLUTIONS -->
            <cms-partial-category-top-feature-solutions
                [featureSolutionsRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                *ngIf="get(row, 'feature_solutions', '')"
            ></cms-partial-category-top-feature-solutions>

            <!-- TABLE -->
            <cms-partial-category-top-component-table
                [tableRow]="row.tables.tables[0]"
                *ngIf="get(row, 'tables', '')"
            ></cms-partial-category-top-component-table>

            <!-- INTERNAL LINKS -->
            <cms-partial-category-top-internal-links
                [internalLinksRow]="row"
                *ngIf="get(row, 'internal_links', '')"
            ></cms-partial-category-top-internal-links>

            <!-- FULL WIDTH BLOCK -->
            <section *ngIf="get(row, 'full_width_block')" class="fx-col full-width-block">
                <img class="full-width-block-img" [src]="getFullWidthBlockSrc(row.full_width_block)" />
                <div
                    *ngIf="row?.full_width_block?.free_form"
                    [innerHTML]="row?.full_width_block?.free_form_content | safe: 'html'"
                    class="full-width-block-content"
                ></div>
            </section>

            <!-- VALUE PROPS -->
            <cms-partial-category-top-component-value-props
                [valuePropsRow]="row"
                *ngIf="get(row, 'value_props', '')"
            ></cms-partial-category-top-component-value-props>

            <!-- LEAD GEN -->
            <cms-partial-category-top-lead-gen
                [leadGenRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                *ngIf="get(row, 'lead_gen', '')"
            ></cms-partial-category-top-lead-gen>

            <!-- LOGOS -->
            <cms-partial-category-top-logos [logosRow]="row" *ngIf="get(row, 'logos', '')"></cms-partial-category-top-logos>

            <!-- IMAGE AND TEXT -->
            <cms-partial-category-top-component-image-and-text
                [imageAndTextRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                [uniqueIndex]="i"
                *ngIf="!isEmpty(get(row, 'image_and_text.image_and_text', '')) || !isEmpty(get(row, 'image_and_text.text_block', ''))"
            >
            </cms-partial-category-top-component-image-and-text>

            <!-- ACCORDION -->
            <cms-partial-category-top-component-accordion
                [accordionRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                [uniqueIndex]="i"
                *ngIf="!isEmpty(get(row, 'accordion.accordion', ''))"
            ></cms-partial-category-top-component-accordion>

            <!-- SUBCATEGORY LINKS -->
            <cms-partial-category-top-component-subcategory-links
                [subcategoryLinksRow]="row"
                *ngIf="get(row, 'subcategory_links', '')"
            ></cms-partial-category-top-component-subcategory-links>

            <!-- EMLI TABLE -->
            <div>
                <section
                    *ngIf="get(row, 'miscellaneous_content.emli_table', false)"
                    [class.gray-bg]="get(row, 'miscellaneous_content.gray_background', false)"
                >
                    <div class="fx-row-container fx-wrap px-3">
                        <app-emli-table></app-emli-table>
                    </div>
                </section>
                <hr *ngIf="get(row, 'miscellaneous_content.split_line', '')" class="m-0 mb-3" />
            </div>

            <!-- RELATED CONTENT -->
            <cms-partial-category-top-component-related-content
                [relatedContentRow]="data"
                *ngIf="get(row, 'related_content', '')"
            ></cms-partial-category-top-component-related-content>

            <!-- MULTIPLE COLUMNS -->
            <cms-partial-category-top-multiple-columns
                [multipleColumnsRow]="row"
                *ngIf="get(row, 'multi_columns', '')"
            ></cms-partial-category-top-multiple-columns>

            <!-- MULTIPLE COLUMNS -->
            <cms-partial-category-top-multiple-columns-v2
                [multipleColumnsRow]="row"
                *ngIf="get(row, 'multi_column_v2', '')"
                [solutionID]="marketoSolutionsID"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
            ></cms-partial-category-top-multiple-columns-v2>

            <!-- PRODUCT OPTIONS -->
            <cms-partial-category-top-component-product-options
                [productOptionsRow]="row"
                *ngIf="get(row, 'product_options', '')"
            ></cms-partial-category-top-component-product-options>

            <!-- PRODUCT OPTIONS 2 (R - Product Options) -->
            <cms-partial-category-top-component-product-options-2
                [productOptionsRow]="row"
                *ngIf="!isEmpty(get(row, 'product_options_2.product_options', ''))"
            ></cms-partial-category-top-component-product-options-2>

            <!-- COLOR BLOCK -->
            <cms-partial-category-top-component-colored-block
                [coloredBlockRow]="row"
                [dataUrl]="data?.url"
                [dataTitle]="data?.title"
                [solutionID]="marketoSolutionsID"
                *ngIf="get(row, 'colored_block', '')"
            ></cms-partial-category-top-component-colored-block>
        </div>
    </div>

    <!-- CATEGORY TOP FACETS -->
    <cms-partial-category-top-component-facets *ngIf="!isEmpty(get(data, 'rowSets'))" [facetRowSet]="data">
    </cms-partial-category-top-component-facets>
</div>
