import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { CmsService } from 'lib/services/cms.service';
import { RouterService } from 'lib/services/router.service';

type headerData = {
    headerIcon: string;
    headerTitle: string;
};

const scriptName = 'exx-gated-content-header.component';

@Component({
    selector: 'exx-gated-content-header',
    templateUrl: './exx-gated-content-header.component.html',
    styleUrls: ['./exx-gated-content-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExxGatedContentHeaderComponent implements OnInit {
    @Input() headerData: headerData;
    @Input() showBlogSearchbar: boolean = false;

    blogCategories: any;

    constructor(
        private routerService: RouterService,
        private cmsService: CmsService
    ) {}

    async ngOnInit() {
        this.blogCategories = await this.cmsService.getEntries('exx_blog_category', {});
    }
    onChosenTitle(title: string) {
        try {
            this.routerService.navigate(['blog', title]);
        } catch (err) {
            console.error(...new ExxComError(657993, scriptName, err).stamp());
        }
    }
}
