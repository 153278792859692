import { Component, Input } from '@angular/core';
import { WebpService } from 'lib/services/webp.service';
import { get } from 'lodash';

@Component({
    selector: 'app-repository-banner',
    templateUrl: './repository-banner.component.html',
    styleUrls: ['./repository-banner.component.scss'],
})
export class RepositoryBannerComponent {
    @Input() entry: any;
    getWebpImg: (src: string) => string;
    constructor(webpService: WebpService) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
