<div id="cms-gated-content" class="px-2">
  <div class="gated-content-container fx-wrap fx-row-container fx-row-h-left mt-4">
    <div *ngFor="let gatedContent of entries" class="col-md-4 col-sm-6 mb-4 fx-col-h-center">
      <app-resource-card-related-card
        class="card"
        [entry]="gatedContent"
        [relatedContentTileData]="{
          title: gatedContent?.title,
          categoryTitle: gatedContent?.type[0]?.title,
          image: gatedContent?.preview_image?.href,
          imageTitle: gatedContent?.preview_image?.title,
          date: gatedContent?.date,
          readTime: gatedContent?.character_count,
          link:
            gatedContent?.type[0]?.title === 'Case Studies'
              ? gatedContent?.gated_content_asset?.href
              : gatedContent?.url
        }"
      ></app-resource-card-related-card>
    </div>
  </div>
</div>
