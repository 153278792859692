import { Component, Input, ViewChild, ElementRef, ViewEncapsulation, Inject } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { state, trigger, animate, style, transition } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

const scriptName = 'exx-accordion-image-and-text';

export interface ExxAccordionInstance {
    button_link: {
        title: string;
        href: string;
        routerLink: string;
    };
    image: {
        href: string;
        webp: string;
        routerLink: string;
        title: string;
    };
    subheader: string;
    header: string;
    body_copy: HTMLInputElement;
    instance_background_color: string;
}

@Component({
    selector: 'exx-accordion-image-and-text',
    templateUrl: './accordion-image-and-text.component.html',
    styleUrls: ['./accordion-image-and-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('imgChange', [
            state('hidden', style({ opacity: 0 })),
            state('visible', style({ opacity: 1 })),
            transition('hidden => visible', [animate('500ms')]),
            transition('visible => hidden', [animate(0)]),
        ]),

        trigger('colorChange', [
            state('currentColor', style({ background: '{{backgroundColor}}' }), { params: { backgroundColor: 'none' } }),
            state('nextColor', style({ background: '{{nextBackgroundColor}}' }), { params: { nextBackgroundColor: 'none' } }),
            transition('currentColor => nextColor', [animate('500ms')]),
            transition('nextColor => currentColor', [animate(0)]),
        ]),

        trigger('accordionChange', [
            state('chevron_down', style({ transform: 'rotate(0deg)' })),
            state('chevron_up', style({ transform: 'rotate(180deg)' })),
            transition('chevron_down => chevron_up', [animate('250ms ease-out')]),
            transition('chevron_up => chevron_down', [animate('150ms ease-in')]),
        ]),
    ],
})
export class ExxAccordionImageAndTextComponent extends CmsPartialComponentClass {
    // Contentstack specific requirement
    // can be set to anything if using as internal component
    @Input() uniqueIndex: number;

    // Marketo Form Buttons
    @Input() dataUrl: string;
    @Input() dataTitle: string;
    @Input() solutionID: string;

    @Input() imageOnRight: boolean = true;
    @Input() mainImage: string = '';
    @Input() mainSubtitle: string = '';
    @Input() mainTitle: string = '';
    @Input() mainBody: HTMLInputElement = null;
    @Input() useCustomBackgroundColor: boolean;
    @Input() predefinedBackgroundColor: string;
    @Input() backgroundColor: string;
    @Input() instanceBackgroundColorToggle: boolean = false;
    @Input() instances: ExxAccordionInstance[] = [];

    @Input() mainButtonLinkTitle: string = '';
    @Input() mainButtonLink: string = '';
    @Input() mainButtonMarketoFormSelection: string = '';
    @Input() mainButtonMarketoFormID: number = null;

    @ViewChild('accordionImage') accordionImage: ElementRef;

    currentAccordion = -1;
    currentImage: string = '';
    newCurrentImage: string = '';
    currentImageSize: string = '456px';

    imgState = 'hidden';
    subImgState = 'visible';
    accordionState = 'hidden';

    colorChangeState = 'currentColor';
    currentBackgroundColor: string = '';
    nextBackgroundColor: string = '';

    constructor(
        public bootstrapMarketoService: BootstrapMarketoService,
        @Inject(DOCUMENT) private document: Document
    ) {
        super({
            dependencies: {
                bootstrapMarketoService,
            },
        });
    }

    ngOnInit() {
        this.currentImage = this.mainImage;
        if (!this.predefinedBackgroundColor) this.predefinedBackgroundColor = this.backgroundColor;
        if (this.useCustomBackgroundColor) {
            this.currentBackgroundColor = this.backgroundColor;
        } else {
            this.currentBackgroundColor = this.predefinedBackgroundColor;
            this.backgroundColor = this.predefinedBackgroundColor;
        }

        // When you pass a method to setInterval() or any other function, it is invoked with the wrong this value.
        // https://developer.mozilla.org/en-US/docs/Web/API/setInterval#the_this_problem
        const self = this;
        const accordionLoaded = setInterval(() => {
            const currentAccordion = this.document.querySelector<HTMLElement>(`#partialAccordion-${self.uniqueIndex}`);
            if (currentAccordion) {
                self.resetAccordionHeights();
                if (self.mainTitle == '' && !self.mainBody) {
                    self.openAccordionAnimate(0);
                }
                clearInterval(accordionLoaded);
            }
        }, 1000);
    }

    getWebpImg: (src: string) => string;

    // The accordion image get sets within imageAnimate()
    // this prevents issues with images loading in before an animation finishes
    async setAccordion(index: number) {
        try {
            this.accordionAnimate(index);
            const newImage = this.currentAccordion == -1 ? this.mainImage : this.instances[index]?.image?.href;
            if (!this.instanceBackgroundColorToggle) {
                this.nextBackgroundColor = this.currentAccordion == -1 ? this.backgroundColor : this.instances[index]?.instance_background_color;
            } else {
                this.nextBackgroundColor = this.backgroundColor;
            }
            this.imageAnimate(newImage == '' ? this.mainImage : newImage);
        } catch (err) {
            console.error(...new ExxComError(923141, scriptName, err).stamp());
        }
    }

    accordionAnimate(newIndex: number) {
        this.closeAccordionAnimate(newIndex);
        this.openAccordionAnimate(newIndex);
    }

    closeAccordionAnimate(newIndex: number) {
        const currentInstance = this.document.querySelectorAll<HTMLElement>(`#accordion-item-${this.uniqueIndex}${this.currentAccordion}`);
        const currentInstanceHeader = this.document.querySelectorAll<HTMLElement>(
            `#accordion-item-${this.uniqueIndex}${this.currentAccordion} .accordion-item-title`
        );

        for (let i = 0; i < currentInstance.length; i++) {
            if (currentInstanceHeader[i]) {
                currentInstance[i].style.height = currentInstanceHeader[i].offsetHeight + 32 + 'px';
            }
        }
    }

    // This method also handles currentAccordion index changes
    openAccordionAnimate(newIndex: number) {
        const closing = this.currentAccordion == newIndex;
        const nextInstance = this.document.querySelectorAll<HTMLElement>(`#accordion-item-${this.uniqueIndex}${newIndex}`);
        const nextInstanceHeader = this.document.querySelectorAll<HTMLElement>(
            `#accordion-item-${this.uniqueIndex}${newIndex} .accordion-item-title`
        );
        const nextInstanceSubheader = this.document.querySelectorAll<HTMLElement>(
            `#accordion-item-${this.uniqueIndex}${newIndex} .accordion-item-label`
        );
        const nextInstanceBody = this.document.querySelectorAll<HTMLElement>(`#accordion-item-${this.uniqueIndex}${newIndex} .accordion-item-copy`);
        const nextInstanceImage = this.document.querySelectorAll<HTMLElement>(
            `#accordion-item-${this.uniqueIndex}${newIndex} .accordion-image-tablet`
        );

        for (let i = 0; i < nextInstance.length; i++) {
            let nextInstanceHeight = 32; // 32 is for default padding

            if (i == 0) this.currentAccordion = newIndex === this.currentAccordion ? -1 : newIndex;

            if (nextInstanceHeader[i]) {
                nextInstanceHeight += nextInstanceHeader[i].offsetHeight;
            }

            if (!closing) {
                if (nextInstanceSubheader[i]) {
                    nextInstanceHeight += nextInstanceSubheader[i].scrollHeight + 4; // 4 accounts for subheader margin
                }

                if (nextInstanceBody[i]) {
                    nextInstanceHeight += nextInstanceBody[i].offsetHeight + 32; // 32 accounts for body margins
                }

                if (nextInstanceImage.length > 2 && nextInstanceImage[i + 2]?.offsetHeight) {
                    nextInstanceHeight += nextInstanceImage[i + 2].offsetHeight + 24; // 24 accounts for top img margin
                } else if (nextInstanceImage[i]?.offsetHeight) {
                    nextInstanceHeight += nextInstanceImage[i].offsetHeight + 24; // 24 accounts for top img margin
                }
            }

            nextInstance[i].style.height = nextInstanceHeight + 'px';
        }
    }

    resetAccordionHeights() {
        for (let i = 0; i < this.instances.length; i++) {
            const targetInstance = this.document.querySelectorAll<HTMLElement>(`#accordion-item-${this.uniqueIndex}${i}`);
            const targetInstanceHeader = this.document.querySelectorAll<HTMLElement>(`#accordion-item-${this.uniqueIndex}${i} .accordion-item-title`);

            for (let i = 0; i < targetInstance.length; i++) {
                targetInstance[i].style.height = targetInstanceHeader[i].offsetHeight + 32 + 'px';
            }
        }
        if (this.currentAccordion !== -1) {
            this.openAccordionAnimate(this.currentAccordion);
        }
    }

    imageAnimate(newImage: string) {
        // The following two lines of code is to account for the in-between viewports from desktop to tablet.
        // Because we use two image elements to accomplish the overlay fade in effect
        //  and one of the images has absolute positioning
        //  that particular image does not scale to its parent container.
        // Thus we apply its new max-width manually here.
        const targetImage = this.document.querySelector<HTMLElement>('.accordion-image-' + this.uniqueIndex);
        this.currentImageSize = targetImage.offsetWidth + 'px';

        if (this.currentImage != newImage) this.subImgState = 'hidden';
        this.imgState = 'visible';
        this.newCurrentImage = newImage;
        this.colorChangeState = 'nextColor';
        setTimeout(() => {
            this.currentImage = newImage;
            this.subImgState = 'visible';
            this.imgState = 'hidden';
            this.currentBackgroundColor = this.nextBackgroundColor;
            this.colorChangeState = 'currentColor';
        }, 500);
    }
}
