<div id="cms-news-events" class="px-2">
  <div class="title mt-5">
    <div class="fx-row fx-row-v-center mb-1">
      <mat-icon svgIcon="message-square"></mat-icon>
    </div>
    <h3>News and Events</h3>
  </div>

  <div class="row-1 mt-5">
    <div class="col-md-7 p-0">
      <div class="sub-heading mb-3">The Latest</div>

      <div *ngIf="featurePost" class="feature-release fx-col">
        <div>
          <div class="sub-heading mb-1">Press Release</div>
          <a [routerLink]="featurePost.routerLink">
            <h4 class="press-title">{{ featurePost.press_release_title }}</h4>
          </a>
        </div>
        <span>{{ parseDate(featurePost) }}</span>
      </div>
    </div>

    <div class="events col-md-5 p-0">
      <div class="sub-heading">Events</div>

      <div *ngFor="let event of events" class="fx-row mt-3">
        <div class="date p-0">
          <div>{{ event.date[0] }} <span *ngIf="event.date[1]">-</span></div>
          <div>{{ event.date[1] }}</div>
        </div>

        <div class="event-info p-0">
          <span class="event-name">{{ event.event_name }}</span>
          <div class="location-booth">
            <span class="mr-2">{{ event.location }}</span>
            <span>Booth {{ event.booth_number }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row-2">
    <div class="sub-heading mb-2">Browse</div>

    <div class="years fx-row fx-wrap">
      <div *ngFor="let year of years" class="year" [class.active]="chosenYear == year">
        <span (click)="onChosenYear(year)">{{ year }}</span>
        <div class="block"></div>
      </div>
    </div>

    <div class="posts fx-wrap fx-row-container fx-row-h-left">
      <div *ngFor="let post of chosenPosts" class="news-events-post-container col-md-4">
        <div class="news-events-post fx-col">
          <div>
            <div class="sub-heading mb-1">Press Release</div>
            <a [routerLink]="post.routerLink">
              <h4 class="old thin">{{ post.press_release_title }}</h4>
            </a>
          </div>
          <span>{{ parseDate(post) }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
