<section id="resource-header" class="d-flex flex-row align-items-center">
  <div class="d-flex flex-row align-items-center">
    <app-home-button [buttonLink]="['/']"></app-home-button>
    <app-resource-center-dropdown
      [dropdownData]="dropdownMenu"
      [returnToHomepageLink]="true"
      [initialDropdownTitle]="initialDropdownTitle"
    ></app-resource-center-dropdown>
  </div>
</section>
