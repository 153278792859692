<div id="category-no-results" class="fx-row fx-wrap">
  <div id="category-no-results-1" class="col-lg-3 col-xl-3 pl-0">
    There are no results to refine. If you need additional help, please try our <strong>"Suggestions"</strong>
    <span> below.</span>
    <span> to the right.</span>
  </div>

  <div id="category-no-results-2" class="fx-col col-lg-9 col-xl-9">
    <h3 class="mb-0">Your search for "{{ category.searchTerm }}" returned no results.</h3>

    <h3 *ngIf="category.didYouMean" class="mt-1 mb-0">
      Did you mean
      <a class="link-amber" routerLink="/category/search" [queryParams]="{ q: category.didYouMean }">
        <em>{{ category.didYouMean }}</em>
      </a>
      ?
    </h3>

    <div class="mt-3">
      <h4>Suggestions</h4>
      <ul>
        <li>Check for misspellings.</li>
        <li>Remove possible redundant keywords (ie. "products").</li>
        <li>Use other words to describe what you are searching for.</li>
      </ul>
    </div>
  </div>
</div>
