import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { copyText } from 'lib/tools';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { isBrowser } from 'lib/tools';

const scriptName = 'cms-news-events-post.component';

@Component({
    selector: 'app-cms-news-events-post',
    templateUrl: './cms-news-events-post.component.html',
    styleUrls: ['./cms-news-events-post.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsNewsEventsPostComponent extends ExxComComponentClass implements OnInit {
    // Dependencies

    cmsService: CmsService;

    // Properties: public

    date: any;
    entry: any = {};
    isBrowser: boolean = isBrowser();
    location: string = 'FREMONT, CA';

    constructor(b: BreakpointObserver, c: CmsService) {
        super({
            dependencies: {
                breakpointObserver: b,
                cmsService: c,
            },
        });
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('press_release');
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
            } as const;
            this.date = new Date(this.entry.date).toLocaleDateString('en-US', options);
        } catch (err) {
            console.error(...new ExxComError(553115, scriptName, err).stamp());
        }
    }

    copyLink(event: any) {
        try {
            this.isBrowser && copyText(event, window.location.href);
        } catch (err) {
            console.error(...new ExxComError(553117, scriptName, err).stamp());
        }
    }
}
