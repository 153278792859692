<section *ngIf="!isEmpty(get(accordionRow, 'accordion.accordion[0]', false))">
  <exx-accordion-image-and-text
    [uniqueIndex]="uniqueIndex"
    [dataUrl]="dataUrl"
    [dataTitle]="dataTitle"
    [solutionID]="solutionID"
    [imageOnRight]="get(accordionRow, 'accordion.accordion[0].layout', '') === 'Image on the right'"
    [mainImage]="get(accordionRow, 'accordion.accordion[0].main_image.href', '')"
    [mainSubtitle]="get(accordionRow, 'accordion.accordion[0].main_subheader', '')"
    [mainTitle]="get(accordionRow, 'accordion.accordion[0].main_header', '')"
    [mainBody]="get(accordionRow, 'accordion.accordion[0].main_body_copy', '')"
    [useCustomBackgroundColor]="get(accordionRow, 'accordion.accordion[0].use_custom_background_color', '')"
    [predefinedBackgroundColor]="get(accordionRow, 'accordion.accordion[0].predefined_image_background_color', '')"
    [backgroundColor]="get(accordionRow, 'accordion.accordion[0].color_picker', '')"
    [instanceBackgroundColorToggle]="get(accordionRow, 'accordion.accordion[0].instance_background_color_toggle', '')"
    [instances]="get(accordionRow, 'accordion.accordion[0].accordion_instances', [])"
    [mainButtonLinkTitle]="get(accordionRow, 'accordion.accordion[0].main_button_link.title', '')"
    [mainButtonLink]="get(accordionRow, 'accordion.accordion[0].main_button_link.href', '')"
    [mainButtonMarketoFormSelection]="
      get(accordionRow, 'accordion.accordion[0].main_button_marketo_form_selection.form_selection', '')
    "
    [mainButtonMarketoFormID]="
      get(accordionRow, 'accordion.accordion[0].main_button_marketo_form_selection.marketo_forms.id', null)
    "
  ></exx-accordion-image-and-text>
</section>
