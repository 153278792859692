import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { isBrowser } from 'lib/tools';
import { MetaService } from 'lib/services/meta.service';

@Component({
    selector: 'app-cms-policies',
    templateUrl: './cms-policies.component.html',
    styleUrls: ['./cms-policies.component.scss'],
})
export class CmsPoliciesComponent implements OnInit {
    entry: any = {};
    title: string;

    constructor(
        private cmsService: CmsService,
        private metaService: MetaService
    ) {}

    async ngOnInit() {
        // this.entry = await this.cmsService.getPage('policy_page');
        // this.title = this.getTitle(this.entry.title);
        // this.metaService.add(this.entry.seo_meta_data);
    }

    getTitle(title: string) {
        const splitTitle = title.split(' ');
        return splitTitle[0] + '-' + splitTitle[1];
    }

    getSectionId(sectionRowTitle: string) {
        const splitSectionRowTitle = sectionRowTitle.split(' ');
        return splitSectionRowTitle[0];
    }

    onClick(title: string): void {
        if (!isBrowser()) {
            return;
        }
        const id = this.getSectionId(title);
        const el: HTMLElement | null = document.getElementById(id);
        if (el) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }
}
