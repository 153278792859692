import { Component, ElementRef, EventEmitter, HostListener, Inject, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Input } from '@angular/core';

import { each, get } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { getStyleIntValue, isBrowser, wait } from 'lib/tools';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { RouterService } from 'lib/services/router.service';
import { SearchSpringService } from 'lib/services/searchspring.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'header-search.component';

@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnChanges, OnInit {
    @HostListener('document:mouseover', ['$event.target']) onHover(target: any) {
        this.onHoverMenu(target);
    }
    @HostListener('document:click', ['$event.target']) click(target: any) {
        this.onClickAway(target);
    }

    @Input() isNarrow: boolean;
    @Input() isResourcePage: boolean;
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() searchActive: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('searchInputContainer') searchInputContainer: ElementRef;
    @ViewChild('searchMenu') searchMenu: ElementRef;
    @ViewChild('searchMenuTerms') searchMenuTerms: ElementRef;
    @ViewChild('searchSeeMore') searchSeeMore: ElementRef;
    @ViewChild('noResultsDisplay') noResultsDisplay: ElementRef;

    getWebpImg: (src: string) => string;
    imgSelector: any = {};
    initialSearch: boolean;
    isActive: boolean;
    isSearching: boolean = false;
    menuSelector: any = {};
    searchSelector: any = {};
    suggested: any = { products: [], terms: [] };
    wasActive: boolean;

    // Getters and setters

    get router() {
        return this.routerService.router;
    }
    get inputElement() {
        return this.searchInput && this.searchInput.nativeElement;
    }
    get searchValue() {
        return this.inputElement && this.inputElement.value;
    }
    set searchValue(value: string) {
        if (this.inputElement) {
            this.inputElement.value = value;
        }
    }
    get menuElement() {
        return this.searchMenu && this.searchMenu.nativeElement;
    }
    get menuTermsElement() {
        return this.searchMenuTerms && this.searchMenuTerms.nativeElement;
    }
    get searchSeeMoreElement() {
        return this.searchSeeMore && this.searchSeeMore.nativeElement;
    }
    get noResultsDisplayElement() {
        return this.noResultsDisplay && this.noResultsDisplay.nativeElement;
    }
    get inputContainerElement() {
        return this.searchInputContainer && this.searchInputContainer.nativeElement;
    }
    get searchInputHasFocus() {
        if (isBrowser()) {
            return this.inputElement && document.activeElement == this.inputElement;
        } else {
            return false;
        }
    }
    get searchInputHasValue() {
        return this.searchValue != '';
    }

    constructor(
        @Inject('environment') private environment: any,
        private routerService: RouterService,
        private searchSpringService: SearchSpringService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    // Initialization

    async ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes.isNarrow && changes.isNarrow.previousValue != changes.isNarrow.currentValue) {
                if (!this.isResourcePage) {
                    this.setIsActive();
                    this.clearInput();
                }
            }
        } catch (err) {
            console.error(...new ExxComError(914999, scriptName, err).stamp());
        }
    }

    ngOnInit() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.routerService.onRouteChange('headerSearchComponent', async (event: any) => {
                if (!this.isResourcePage) {
                    this.setIsActive();
                    this.clearInput();
                }
            });
            this.menuSelector = document.getElementById('app-header-menu');
            this.imgSelector = document.getElementById('header-logo-mob');
            this.searchSelector = document.getElementsByClassName('searchbar')[0];
        } catch (err) {
            console.error(...new ExxComError(915000, scriptName, err).stamp());
        }
    }

    clearInput() {
        try {
            this.searchValue = '';
            this.inputElement && this.inputElement.blur();
        } catch (err) {
            console.error(...new ExxComError(915001, scriptName, err).stamp());
        }
    }

    // Helpers

    async setIsActive() {
        try {
            if (!isBrowser()) {
                return;
            }
            if (this.isResourcePage) {
                return;
            }
            await wait('');
            this.wasActive = this.isActive;
            this.isActive = this.searchInputHasFocus && this.searchInputHasValue;
            if (!this.searchInputHasFocus || !this.searchInputHasValue) {
                this.suggested = { products: [], terms: [] };
            }
            this.searchActive.emit(this.searchInputHasFocus);
            if ((!this.wasActive && this.isActive) || (this.wasActive && !this.isActive)) {
                this.isActiveChange.emit(this.isActive);
            }
            // for narrow view, we want to toggle full screen search bar
            const menuSelector = document.getElementById('app-header-menu');
            if (!menuSelector) {
                return;
            }
            const imgSelector = document.getElementById('header-logo-mob');
            const searchSelector: HTMLElement | null = document.getElementById('searchbar');
            // add logic to not check if navbar is hidden
            if (this.searchInputHasFocus && this.isNarrow) {
                menuSelector.classList.add('hide');
                imgSelector.classList.add('hide');
                searchSelector.style.width = '100%';
                this.inputElement.style.width = '100%';
                this.inputElement.setAttribute('placeholder', 'Search');
                this.inputElement.style.padding = '4px 8px';
            } else if (this.isNarrow) {
                this.clearInput();
                searchSelector.style.width = '';
                menuSelector.classList.remove('hide');
                imgSelector.classList.remove('hide');
                this.inputElement.setAttribute('placeholder', '');
                this.inputElement.style.width = '0';
                this.inputElement.style.padding = '';
            }
        } catch (err) {
            console.error(...new ExxComError(915002, scriptName, err).stamp());
        }
    }

    // TODO: Do we need this function?
    /* eslint-disable */
    private async setMenuHeight() {
        try {
            if (!isBrowser()) {
                return;
            }
            await wait('');
            const menu = this.menuElement;
            const menuTerms = this.menuTermsElement;
            const searchSeeMore = this.searchSeeMoreElement;
            const noResults = this.noResultsDisplayElement;
            let suggestedSearchTermsHeight = 0;
            if (!menu) {
                return;
            }
            // setting the height of the menu to the height of no results div
            if (!menuTerms && !searchSeeMore) {
                menu.style.height = '45px';
                return;
            }
            if (menuTerms) {
                suggestedSearchTermsHeight = getStyleIntValue(menuTerms, 'height');
            }
            menu.style.height = '';
            const searchSeeMoreHeight = searchSeeMore ? getStyleIntValue(searchSeeMore, 'height') : 0;
            /*
      menu product images don't load in when determining the size of the div we
      instead determine the height by how many suggested products are in the array
      */
            const menuProductsHeight = get(this.suggested, 'products.length', 0) > 0 ? get(this.suggested, 'products.length', 0) * 85 : 54;
            const menuContentHeight = suggestedSearchTermsHeight + menuProductsHeight + searchSeeMoreHeight;
            const windowHeight = window.innerHeight;
            const header = document.getElementById('header');
            const headerHeight = getStyleIntValue(header, 'height');
            const calculatedMenuHeight = windowHeight - headerHeight;
        } catch (err) {
            console.error(...new ExxComError(915003, scriptName, err).stamp());
        }
    }
    /* eslint-enable */

    // On focus

    async onFocus() {
        try {
            await this.setIsActive();
            if (this.isNarrow) {
                this.initialSearch = true;
            }
            this.setMenuHeight();
        } catch (err) {
            console.error(...new ExxComError(915004, scriptName, err).stamp());
        }
    }

    // On key up

    async onKeyup(event: KeyboardEvent) {
        try {
            if (!isBrowser() || event.code == 'Enter') {
                return;
            }
            const searchValue = this.searchValue;
            this.isSearching = true;
            if (this.searchInputHasValue && this.isNarrow) {
                this.initialSearch = false;
            }
            if (!this.searchInputHasValue && this.isNarrow) {
                this.initialSearch = true;
            }
            const suggestRes = await this.searchSpringService.suggest(searchValue);
            this.isSearching = false;
            if (get(suggestRes, 'products.length', 0) == 0) {
                return;
            }
            await this.setIsActive();
            const allRes = await this.searchSpringService.search('search', searchValue, null, null, null, true);
            this.parseResults(allRes, suggestRes);
            this.setMenuHeight();
        } catch (err) {
            console.error(...new ExxComError(915005, scriptName, err).stamp());
        }
    }

    private parseResults(allRes: any, suggestedRes: any) {
        try {
            if (!this.searchInputHasFocus || !this.searchInputHasValue) {
                return;
            }
            this.suggested = {
                products: [],
                terms: [],
                total: get(allRes, 'pagination.totalResults', 0),
            };
            each(suggestedRes.terms, (term: any) => this.suggested.terms.push(this.parseTerm(term)));
            each(get(allRes, 'results').slice(0, 4), (product: any) => {
                product.isSearchSpring = true;
                this.suggested.products.push(
                    new WebstoreProduct(product, {
                        environment: this.environment,
                    })
                );
            });
        } catch (err) {
            console.error(...new ExxComError(915006, scriptName, err).stamp());
        }
    }

    private parseTerm(term: string) {
        try {
            return {
                text: term.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>'),
                queryParams: { q: term.replace(/<\/?em>/g, '') },
            };
        } catch (err) {
            console.error(...new ExxComError(915007, scriptName, err).stamp());
        }
    }

    private async onHoverMenu(target: any) {
        try {
            const menu = document.getElementsByClassName('header-menu-wide-level1')[0];
            if (menu && menu.contains(target)) {
                this.inputElement && this.inputElement.blur();
                await this.setIsActive();
            }
        } catch (err) {
            console.error(...new ExxComError(915008, scriptName, err).stamp());
        }
    }

    // On click away

    async onClickAway(target: HTMLElement) {
        try {
            if (this.menuWasClicked(target) || this.inputWasClicked(target)) {
                return;
            }
            await this.setIsActive();
        } catch (err) {
            console.error(...new ExxComError(915009, scriptName, err).stamp());
        }
    }

    menuWasClicked(target: HTMLElement) {
        try {
            const menu = this.menuElement;
            return menu && (menu == target || menu.contains(target));
        } catch (err) {
            console.error(...new ExxComError(915010, scriptName, err).stamp());
        }
    }

    inputWasClicked(target: HTMLElement) {
        try {
            return this.inputElement == target;
        } catch (err) {
            console.error(...new ExxComError(915011, scriptName, err).stamp());
        }
    }

    // On pressing enter

    onKeydownEnter() {
        try {
            if (!isBrowser() || this.searchValue === '') {
                return;
            }
            this.router.navigateByUrl(`/category/search?q=${this.searchValue}&page=1`);
        } catch (err) {
            console.error(...new ExxComError(915232, scriptName, err).stamp());
        }
    }

    onSearchClickResults() {
        try {
            if (!isBrowser() || this.searchValue === '') {
                return;
            }
            this.router.navigateByUrl(`/category/search?q=${this.searchValue}&page=1`);
        } catch (err) {
            console.error(...new ExxComError(915232, scriptName, err).stamp());
        }
    }

    async searchClicked() {
        try {
            document.getElementById('search-icon').focus();
            await this.setIsActive();
        } catch (err) {
            console.error(...new ExxComError(121004, scriptName, err).stamp());
        }
    }

    // on pressing search icon

    onClickSearch() {
        try {
            if (!isBrowser() || this.searchValue === '') {
                return;
            }
            this.router.navigateByUrl(`/category/search?q=${this.searchValue}&page=1`);
        } catch (err) {
            console.error(...new ExxComError(901231, scriptName, err).stamp());
        }
    }
}
