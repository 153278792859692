<div *ngFor="let row of get(bannerRow, 'category_top_banner.category_top_banner', [])">
    <section *ngIf="row" id="hero-banner" [ngClass]="isNarrow ? 'pt-0 pb-4' : 'p-0'">
        <div
            id="hero-banner-image"
            [ngStyle]="{
                'background-image': 'url(' + get(row, 'background_image_wide.href', '') + ')'
            }"
        >
            <div id="badge-container" class="d-flex flex-row justify-content-center">
                <div class="category-badge d-flex align-items-end" *ngIf="get(row, 'badge') != 'none'">
                    <img [src]="getWebpImg(get(row, 'badge', ''))" alt="Category top banner badge image" class="category-badge-img" />
                </div>
            </div>
        </div>
        <div id="hero-banner-content" class="grid container align-items-center">
            <div class="g-col-lg-6 g-col-12 d-flex flex-column">
                <h5>{{ get(row, 'banner_label', '') }}</h5>
                <h1 [ngClass]="get(row, 'font_color_light_theme') && !isNarrow ? 'font-light-theme' : ''">
                    {{ get(row, 'banner_title', '') }}
                </h1>
                <p [ngClass]="get(row, 'font_color_light_theme') && !isNarrow ? 'font-light-theme banner-copy' : 'banner-copy'">
                    {{ get(row, 'banner_copy', '') }}
                </p>
                <!-- Marketo Form Button -->
                <div class="d-flex flex-row" *ngIf="get(row, 'marketo_form_selection.form_selection', '') !== 'no_form'">
                    <button-component
                        (click)="
                            bootstrapMarketoService.openBootstrapMarketoDialog(solutionID, 'edit', {
                                title: dataTitle,
                                url: dataUrl
                            })
                        "
                        *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'default_form'"
                        data-cy="marketo-form"
                        [buttonOptions]="{
                            text: get(row, 'button_text_select', ''),
                            type: 'primary',
                            iconPosition: 'right',
                            iconName: 'chevron-right'
                        }"
                    ></button-component>
                    <button-component
                        (click)="
                            bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form_selection.marketo_forms.id', ''), 'edit', {
                                title: dataTitle,
                                url: dataUrl
                            })
                        "
                        *ngIf="get(row, 'marketo_form_selection.form_selection', '') == 'other_form'"
                        data-cy="marketo-form"
                        [buttonOptions]="{
                            text: get(row, 'button_text_select', ''),

                            type: 'primary',
                            iconPosition: 'right',
                            iconName: 'chevron-right'
                        }"
                    ></button-component>
                </div>

                <!-- RouterLink Button -->
                <div class="d-flex flex-row" *ngIf="get(row, 'banner_button.href', '')">
                    <button
                        *ngIf="get(row, 'banner_button.href', '') !== ''"
                        class="button-primary"
                        [routerLink]="get(row, 'banner_button.routerLink', '')"
                    >
                        <span>{{ get(row, 'button_text_select', '') }}</span>
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                    <button-component
                        *ngIf="get(row, 'banner_button.href', '') !== ''"
                        [buttonOptions]="{
                            text: get(row, 'button_text_select', ''),
                            internalLink: get(row, 'banner_button.routerLink', ''),
                            type: 'primary',
                            iconPosition: 'right',
                            iconName: 'chevron-right'
                        }"
                    ></button-component>
                </div>
            </div>
        </div>
    </section>
</div>
