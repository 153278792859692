import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';

@Component({
    selector: 'app-home-button',
    templateUrl: './home-button.component.html',
    styleUrls: ['./home-button.component.scss'],
})
export class HomeButtonComponent extends ExxComComponentClass {
    @Input() buttonLink: String;
    constructor(breakpointObserver: BreakpointObserver) {
        super({ dependencies: { breakpointObserver } });
    }
}
