import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';

import { CmsAboutUsComponent } from './cms/pages/cms-about-us/cms-about-us.component';
import { CmsAccountApplicationComponent } from './cms/pages/cms-account-application/cms-account-application.component';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { CmsB2bServiceComponent } from './cms/pages/cms-b2b-service/cms-b2b-service.component';
import { CmsBlogCategoryComponent } from './cms/blog/cms-blog-category/cms-blog-category.component';
import { CmsBlogHomepageComponent } from './cms/blog/cms-blog-homepage/cms-blog-homepage.component';
import { CmsBlogPostComponent } from './cms/blog/cms-blog-post/cms-blog-post.component';
import { CmsBlogSearchComponent } from './cms/blog/cms-blog-search/cms-blog-search.component';
import { CmsCareersComponent } from './cms/pages/cms-careers/cms-careers.component';
import { CmsCustomersComponent } from './cms/pages/cms-customers/cms-customers.component';
import { CmsContactUsComponent } from './cms/pages/cms-contact-us/cms-contact-us.component';
import { CmsGatedContentComponent } from './cms/pages/cms-gated-content/cms-gated-content.component';
import { CmsGatedContentPostComponent } from './cms/pages/cms-gated-content/cms-gated-content-post/cms-gated-content-post.component';
import { CmsHomepageComponent } from './cms/pages/cms-homepage/cms-homepage.component';
import { CmsNewsEventsComponent } from './cms/pages/cms-news-events/cms-news-events.component';
import { CmsNewsEventsPostComponent } from './cms/pages/cms-news-events/cms-news-events-post/cms-news-events-post.component';
import { CmsPartnersComponent } from './cms/pages/cms-partners/cms-partners.component';
import { CmsResourceCenterComponent } from './cms/pages/cms-resource-center/cms-resource-center.component';
import { CmsRmaRequestComponent } from './cms/pages/cms-rma-request/cms-rma-request.component';
import { CmsSoftwareComponent } from './cms/pages/cms-software/cms-software.component';
import { CmsSupportedSoftwareComponent } from './cms/pages/cms-supported-software/cms-supported-software.component';
import { CmsTestDriveComponent } from './cms/pages/cms-test-drive/cms-test-drive.component';
import { CmsReferenceArchitectureComponent } from './cms/pages/cms-reference-architecture/cms-reference-architecture.component';
import { CmsRefArchitecturePagesComponent } from './cms/pages/cms-reference-architecture/cms-ref-architecture-pages/cms-ref-architecture-pages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProductComponent } from './product/product.component';

import { environment } from '../environments/environment';
import { CategoryComponent } from './category/category.component';
import { CmsEventFormPageComponent } from './cms/pages/cms-event-form-page/cms-event-form-page.component';
import { NvidiaDgxComponent } from './product/nvidia-dgx/nvidia-dgx.component';
import { CmsRepositoryComponent } from './cms/pages/cms-repository/cms-repository.component';
import { ProductResolver } from 'lib/services/webstore-product/webstore-product-resolver';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';

export function matchProducts(url: UrlSegment[]) {
    const isMatch = url.length == 1 && new RegExp(environment.productUrlRegex).test(url[0].path);
    return isMatch ? { consumed: url } : null;
}

export const matchServiceCategory = (url: UrlSegment[]) => {
    if (url.length > 1 && url[0].path === 'Services') {
        return { consumed: url };
    }
    return null;
};

const matchCmsGatedContent = (url: UrlSegment[], type: string) => {
    const paths = ['Case-Studies', 'eBooks', 'Whitepapers'];
    const isUrlMatch = (type == 'main' && url.length == 1) || (type == 'post' && url.length == 2);
    const isMatch = isUrlMatch && paths.find((path: string) => path == url[0].path);
    return isMatch ? { consumed: url } : null;
};
export function matchCmsGatedContentMain(url: UrlSegment[]) {
    return matchCmsGatedContent(url, 'main');
}
export function matchCmsGatedContentPost(url: UrlSegment[]) {
    return matchCmsGatedContent(url, 'post');
}

const routes: Routes = [
    { path: '', component: CmsHomepageComponent },
    { path: 'blog', component: CmsBlogHomepageComponent },
    { path: 'blog/category/:category', component: CmsBlogSearchComponent },
    { path: 'blog/result/:type/:value', component: CmsBlogSearchComponent },
    { path: 'blog/:category', component: CmsBlogCategoryComponent },
    { path: 'blog/:category/:post', component: CmsBlogPostComponent },
    { path: 'About-Us', component: CmsAboutUsComponent },
    { path: 'Account-Application', component: CmsAccountApplicationComponent },
    { path: 'B2B-Services', component: CmsB2bServiceComponent },
    { path: 'Careers', component: CmsCareersComponent },
    { path: 'category/:category', component: CategoryComponent },
    { path: ':product/configurator', component: ConfiguratorComponent },
    { path: 'Contact-Us', component: CmsContactUsComponent },
    { path: 'Customers', component: CmsCustomersComponent },
    { path: 'Event/:page', component: CmsEventFormPageComponent },
    { path: 'News-Events', component: CmsNewsEventsComponent },
    { path: 'News-Events/:post', component: CmsNewsEventsPostComponent },
    { path: 'DGX-Store', component: NvidiaDgxComponent },
    { path: 'Partners', component: CmsPartnersComponent },
    { path: 'Resource-Center', component: CmsResourceCenterComponent },
    { path: 'Asset-Repository', component: CmsRepositoryComponent },
    { path: 'RMA-Request', component: CmsRmaRequestComponent },
    { path: 'Supported-Software', component: CmsSupportedSoftwareComponent },
    { path: 'Test-Drive', component: CmsTestDriveComponent },
    { path: 'Supported-Software/:page', component: CmsSoftwareComponent },
    { path: 'Privacy', component: PrivacyComponent },
    { path: 'Cookies', component: CookiesComponent },
    { matcher: matchServiceCategory, component: CategoryComponent },
    {
        path: 'Reference-Architecture',
        component: CmsReferenceArchitectureComponent,
    },
    {
        path: 'Reference-Architecture/:page',
        component: CmsRefArchitecturePagesComponent,
    },
    { matcher: matchCmsGatedContentMain, component: CmsGatedContentComponent },
    {
        matcher: matchCmsGatedContentPost,
        component: CmsGatedContentPostComponent,
    },
    {
        matcher: matchProducts,
        component: ProductComponent,
        resolve: { productData: ProductResolver },
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }, // Must be last
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
