<div id="row-sets">
    <div *ngFor="let rowSet of get(facetRowSet, 'rowSets', [])">
        <div *ngFor="let facet of get(rowSet, 'products.category_top_facets', [])" id="products" data-cy="exx-product-category-facet-top">
            <div *ngIf="get(facet, 'category_title', false)" class="d-flex flex-wrap flex-row container-padless category-title">
                <h1 class="col-sm-12 bold">
                    {{ facet.category_title }}
                </h1>
            </div>
            <section *ngIf="!isEmpty(get(facet, 'type_facets', false))" class="d-flex flex-wrap container-padless products-type">
                <h3 class="col-sm-12 text-center mb-4">
                    {{ get(facet, 'type_facets_title', '') }}
                </h3>
                <div class="col-sm-12 d-flex justify-content-center p-0 type-container">
                    <div
                        class="d-flex flex-row type-container type-container-center"
                        [class.justify-content-center]="get(facet, 'type_facets', []).length <= 2"
                    >
                        <div *ngFor="let type of get(facet, 'type_facets', [])" class="right-16">
                            <div class="type-icon-facet d-flex flex-row align-items-center px-2">
                                <a (click)="filter(get(type, 'link.routerLink', ''), get(type, 'link.queryParams', {}))" data-cy="facet-item">
                                    <img [src]="getWebpImg(get(type, 'image.href', ''))" alt="{{ get(type, 'link.title', '') }}" />
                                </a>
                            </div>

                            <p class="type-icon-facet-text text-center">{{ get(type, 'link.title', '') }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section *ngIf="!isEmpty(get(facet, 'chipset_screen_facets', false))" class="d-flex flex-wrap container-padless flex-row products-type">
                <h3 class="col-sm-12 text-center mb-4">
                    {{ get(facet, 'chipset_screen_facets_title', '') }}
                </h3>

                <div class="col-sm-12 p-0">
                    <div
                        class="d-flex flex-row type-container type-container-center"
                        [class.justify-content-center]="get(facet, 'chipset_screen_facets', []).length <= 2"
                    >
                        <div *ngFor="let chip of get(facet, 'chipset_screen_facets', [])" class="right-16 gray-circle">
                            <div class="type-icon-facet-background d-flex flex-column justify-content-center p-1">
                                <a (click)="filter(get(chip, 'link.routerLink', ''), get(chip, 'link.queryParams', {}))" data-cy="facet-item">
                                    <img *ngIf="get(chip, 'image.href', '')" [src]="getWebpImg(get(chip, 'image.href', ''))" alt="Chip facet image" />
                                </a>
                            </div>

                            <p class="text-center">{{ get(chip, 'link.title', '') }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section *ngIf="!isEmpty(get(facet, 'logo_facets', []))" class="d-flex flex-row flex-wrap container-padless products-type">
                <h3 class="col-sm-12 text-center mb-4">
                    {{ get(facet, 'logo_facets_title', '') }}
                </h3>

                <div class="col-sm-12 p-0">
                    <div
                        class="d-flex flex-row type-container type-container-center"
                        [class.justify-content-center]="get(facet, 'logo_facets', []).length <= 2"
                    >
                        <div *ngFor="let logo of get(facet, 'logo_facets', [])" class="right-16">
                            <div class="type-icon-facet fx-row fx-row-v-center px-2">
                                <a (click)="filter(get(logo, 'link.routerLink', ''), get(logo, 'link.queryParams', {}))" data-cy="facet-item">
                                    <img
                                        *ngIf="get(logo, 'logo_image.href', '')"
                                        [src]="getWebpImg(get(logo, 'logo_image.href', ''))"
                                        [alt]="get(logo, 'logo_image.title', '')"
                                    />
                                </a>
                            </div>

                            <p class="text-center">{{ get(logo, 'link.title', '') }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section *ngIf="!isEmpty(get(facet, 'text_facets', false))" class="d-flex flex-row flex-wrap container-padless products-text">
                <h3 class="col-sm-12 text-center mb-4">
                    {{ get(facet, 'text_facets_title', '') }}
                </h3>

                <div class="col-sm-12 p-2">
                    <div class="d-flex flex-row flex-wrap justify-content-center text-container">
                        <div *ngFor="let text of get(facet, 'text_facets', [])" class="mb-2">
                            <button-component
                                data-cy="facet"
                                *ngIf="get(text, 'link.title')"
                                (click)="filter(get(text, 'link.routerLink', ''), get(text, 'link.queryParams', {}))"
                                [buttonOptions]="{
                                    text: get(text, 'link.title', ''),
                                    type: 'facet'
                                }"
                            ></button-component>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
